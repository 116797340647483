import React from 'react';
import PropTypes from 'prop-types';

const TR = (props) => {

    const {role, tabIndex, onClick} = props

    return (
        <tr 
            className="row"
            role={role}
            tabIndex={tabIndex}
            onClick={onClick}
        >
            {props.children}
        </tr>
    )
}

TR.propTypes = {
    role: PropTypes.string,
    tabIndex: PropTypes.number,
    onClick: PropTypes.func
}

TR.defaultProps = {
    role: 'row',
    tabIndex: 0
}

export default TR