import {Component} from "react";
import DocumentTitle from "react-document-title";
import {connect} from 'react-redux';
import React from "react";
import {getRawPayload} from "../../actions/openDocsActions";
import OpenDocsApiResponse from "../../components/OpenDocsApiResponse";
import OpenDocsHeader from "../../components/OpenDocsHeader";
import Input from "../../components/Input";


const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps,
        openDocs: state.openDocs,
        authToken: state.user.data.authToken,
    }
};

class ApiDocsSessionDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tryItUrl: '',
            sessionId: '',
        };
    }

    componentWillReceiveProps(newProps) {
        if (typeof newProps.openDocs !== "undefined") {
            const newOpenDocs = newProps.openDocs;
            if (newOpenDocs && newOpenDocs[this.state.tryItUrl]) {
                this.setState({
                    payloadRsp: newOpenDocs[this.state.tryItUrl].payload,
                    headersRsp: newOpenDocs[this.state.tryItUrl].headers,
                    status: newOpenDocs[this.state.tryItUrl].status,
                });
            }
        }
    }

    handleChange(event) {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        const obj = {};
        obj[fieldName] = fieldValue;
        this.setState(obj)
    }

    handleOnClick() {
        const {dispatch, authToken} = this.props;
        const ep = `sessionDetail`;
        this.setState({
            tryItUrl: ep
        });

        dispatch(getRawPayload(ep, {sessionId: parseInt(this.state.sessionId,10)}, authToken));
    }

    render() {
        const {payloadRsp, headersRsp, status} = this.state;

        return (
            <DocumentTitle title="API Access - Session Detail | Bootcamp Spot">
                <section>
                    <OpenDocsHeader
                        endpoint={"/api/instructor/v1/sessionDetail"}
                        description={"Lists details for the specified session."}
                        type={"POST"}
                        headers={[{name: "Content-Type", value: "application/json", description: ""},
                            {name: "authToken", value: "<valid token>", description: "The token generated via /login"}]}
                        exampleBody={{sessionId: 1}}
                        exampleResponses={[
                            {
                                code: 200,
                                body: {
                                    "session": {
                                        "session": {
                                            "id": 1,
                                            "courseId": 2,
                                            "contextId": 1,
                                            "name": "The Zen of Coding",
                                            "shortDescription": "Short descr.",
                                            "longDescription": "Long descr.",
                                            "startTime": "2017-03-20T19:30:00-03:00",
                                            "endTime": "2017-03-20T22:30:00-03:00",
                                            "chapter": "1.1"
                                        },
                                        "context": {
                                            "id": 1,
                                            "contextCode": "academic",
                                            "name": "Academic"
                                        },
                                        "eventType": {
                                            "code": "academic",
                                            "name": "Academic"
                                        },
                                        "classroom": null,
                                        "videoUrlList": []
                                    },
                                    "students": [
                                        {
                                            "student": {
                                                "id": 119,
                                                "userName": "jhon@mail.com",
                                                "firstName": "Jhon",
                                                "lastName": "Doe",
                                                "email": "jhon@testemail.com",
                                                "githubUserName": "jhondoe",
                                                "phoneNumber": null,
                                                "avatarUrl": "https://avatar1",
                                                "mailingLogoUrl": null,
                                                "passwordResetRequired": false,
                                                "active": true,
                                                "bcsExtends": {
                                                    "id": 119,
                                                    "isAdmin": false,
                                                    "jobTrackActive": false
                                                },
                                                "nexusAvatarUrl": null
                                            },
                                            "attendance": {
                                                "id": 2830,
                                                "sessionId": 1,
                                                "studentEnrollmentId": 11808,
                                                "remote": false,
                                                "punctualityId": 1
                                            },
                                            "punctuality": {
                                                "id": 1,
                                                "punctualityCode": "early",
                                                "name": "Early"
                                            },
                                            "remoteAttendanceRequest": null,
                                            "remoteAttendanceApproval": null,
                                            "remoteAttendanceDenial": null,
                                            "absence": null
                                        }
                                    ]
                                },
                                rows: 15,
                                description: "Successful response."
                            }
                        ]}
                    />

                    <div className="row">
                        <div className="col-xs-12">
                            <h1 className={"border-bottom"}>Try it out</h1>

                            <Input
                                label="sessionId"
                                type="number"
                                id="sessionId"
                                placeholder="<sessionId>"
                                helper="Fetch information for this session Id"
                                aria-required={true}
                                onChange={this.handleChange.bind(this)}
                            />
                            <button onClick={this.handleOnClick.bind(this)}>Submit</button>
                        </div>
                    </div>


                    <OpenDocsApiResponse
                        headers={headersRsp}
                        payload={payloadRsp}
                        status={status}
                    />

                </section>
            </DocumentTitle>
        )
    }
};

export default connect(mapStateToProps)(ApiDocsSessionDetail);
