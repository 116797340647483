import React, {Component} from 'react';
import Input from '../../components/Input/index';

class LicenseStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: ""
        };
        this.props.onChange(this.props.step.step.id, this.state.checked);
    }

    commitStep = async (e) => {
        const chk = e.target.checked? "true":"";
        await this.setState({
            checked: chk
        });
        this.props.onChange(this.props.step.step.id, this.state.checked);
    };

    render() {
        const msg = this.props.step.step.definition.text;

        return (
            <div className="display-block">
                <span dangerouslySetInnerHTML={{__html: msg}}/>
                <Input
                    label="I agree to the Terms & Conditions and the Privacy Policy"
                    required={true}
                    type="checkbox"
                    id="agreementCheckboxId"
                    value={this.state.checked}
                    onChange={this.commitStep}
                />
            </div>
        );
    }
}

export default LicenseStep;
