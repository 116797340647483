import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import UniversityLogo from '../../components/UniversityLogo/';
import {Table, TBody, THead, TR, TD, TH} from '../../components/Table';
import Loading from "../../components/Loading";
import {toLowerHyphen} from '../../helpers/toCase.js';
import {sortCohortsByStartDate} from '../../helpers/coursesFormatter';
import {userActions} from "../../actions";
import {resetEnrollmentInfo} from '../../actions/enrollmentsActions.js';
import moment from 'moment';
import { isEqual } from 'lodash'

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

class Cohorts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coursesByUniversity: this.props.user.cohorts ? this.addActiveStatus(sortCohortsByStartDate(this.props.user.cohorts)) : [],
            initialCourses: [],
            filteredCourses: [],
            universityLogo: '',
            universityName: '',
            selectedProgramType: "all",
            selectedActiveStatus: 'all',
            programTypeFilters: [
                {
                    type: "all",
                    display: "All program types"
                }
            ],
            activeStatusFilters: [
                {
                    type: 'active',
                    display: 'Active'
                },
                {
                    type: 'past',
                    display: 'Past'
                },
                {
                    type: 'upcoming',
                    display: 'Upcoming'
                },
                {
                    type: 'all',
                    display: 'All statuses'
                }
            ]
        };

        this.props.removeNav();
    }

    componentDidMount(){
        if(this.props.user && this.props.user.cohorts){
            this.sortCourses(this.props.user.cohorts)
            this.handleCustomBackButtonBehavior();
        }
    }

    componentDidUnmount() {
        window.onpopstate = () => {}
    }

    handleCustomBackButtonBehavior = () => {
        window.onpopstate = () => {
            this.props.dispatch(userActions.resetCohorts())
            this.props.dispatch(resetEnrollmentInfo())
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user.cohorts && !isEqual(this.props.user.cohorts, nextProps.user.cohorts)) {
            this.sortCourses(nextProps.user.cohorts)
        }
    }

    sortCourses(cohorts){
        const sorted = sortCohortsByStartDate(cohorts)
            let coursesByUniversity = this.addActiveStatus(sorted);
            this.setState({
                coursesByUniversity: coursesByUniversity,
                initialCourses: coursesByUniversity,
                filteredCourses: coursesByUniversity,
                universityLogo: coursesByUniversity[0].program.university.logoUrl,
                universityName: coursesByUniversity[0].program.university.name,
                programTypeFilters: this.getProgramFilters(coursesByUniversity)
            });
    }

    addActiveStatus = (cohorts) => {
        cohorts.forEach((cohort, index) => {
            const isActive = moment().isBetween(cohort.startDate, cohort.endDate);
            const hasEnded = moment().isAfter(cohort.endDate);
            const upcoming = moment().isBefore(cohort.startDate);

            cohort.activeStatus = hasEnded ? 'past' : isActive ? 'active' : upcoming ? 'upcoming' : '';
        })
        return cohorts
    }

    getProgramFilters = (cohorts) => {
        let programTypeFilters = [];
        cohorts.forEach((cohort) => {
            if (programTypeFilters.indexOf(cohort.program.programType.name) < 0) {
                programTypeFilters.push(cohort.program.programType.name);
            }
        })
        programTypeFilters.forEach((programTypeFilter, index, array) => {
            array.splice(index, 1, {
                type: programTypeFilter,
                display: programTypeFilter
            });
        })
        programTypeFilters.unshift({
            type: "all",
            display: "All program types"
        })
        return programTypeFilters;
    }

    filterCohorts = () => {
        let filteredCourses = this.state.initialCourses;
        const selectedProgramType = this.state.selectedProgramType;
        const selectedActiveStatus = this.state.selectedActiveStatus

        if (selectedProgramType !== 'all') {
            filteredCourses = filteredCourses.filter((cohort) => {
                if (cohort.program.programType.name === selectedProgramType) {
                    return cohort;
                }
                return false
            })
        }

        if (selectedActiveStatus !== 'all') {
            filteredCourses = filteredCourses.filter((cohort) => {
                if (cohort.activeStatus === selectedActiveStatus) {
                    return cohort;
                }
                return false
            })
        }

        this.setState({filteredCourses});
    }

    selectProgramTypeFilter = (event) => {
        const selectedProgramType = event.target.value;
        this.setState({selectedProgramType}, this.filterCohorts);
    }

    selectActiveStatusFilter = (event) => {
        const selectedActiveStatus = event.target.value;
        this.setState({selectedActiveStatus}, this.filterCohorts);
    }

    selectCohort = (cohort) => {
        const {dispatch} = this.props;
        const universityNameURL = toLowerHyphen(this.state.universityName);
        const cohortExt = toLowerHyphen(cohort.name);
        dispatch(userActions.getEnrollments(cohort.id, this.props.user.data.authToken));
        this.props.history.push(`/universities/${universityNameURL}/cohorts/${cohortExt}`)
    }

    render() {

        const uniImgStyles = {
            position: "absolute",
            top: "0.6rem",
            left: "1rem",
            maxWidth: "2rem",
            maxHeight: "2rem",
            marginRight: "1rem"
        }

        const uniCellStyles = {
            position: "relative"
        }

        const disabledLink = {
            pointerEvents: "none",
            color: "#4A4A4A",
            borderBottom: "none"
        }
        const tableHeadings = [
            'School',
            'Program Type',
            'Cohort',
            'Start Date',
            'End Date'
        ]

        return (
            <DocumentTitle title="Select Cohort | Bootcamp Spot">
            <section>
                <div className="row">
                    <div className="col-xs-12">
                        <p>
                            <Link to='/universities' style={this.props.user.universities.length > 1 ? {} : disabledLink}>Universities</Link>{' > '}
                            {this.state.universityName}
                        </p>
                        <h1>Select a Cohort from {this.state.universityName}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <label htmlFor="filterByProgramType">Filter by Program Type</label>
                        <select aria-label="filter by program type" id="filterByProgramType" value={this.state.selectedProgramType} onChange={this.selectProgramTypeFilter} onBlur={this.selectProgramTypeFilter}>
                            {this.state.programTypeFilters.map( (programTypeFilter, index) => {
                                return (
                                    <option key={index} value={programTypeFilter.type}>{programTypeFilter.display}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <label htmlFor="filterByActiveStatus">Filter by Active Status</label>
                        <select aria-label="filter by active status" id="filterByActiveStatus" value={this.state.selectedActiveStatus} onChange={this.selectActiveStatusFilter} onBlur={this.selectActiveStatusFilter}>
                            {this.state.activeStatusFilters.map( (activeStatusFilter, index) => {
                                return (
                                    <option key={index} value={activeStatusFilter.type}>{activeStatusFilter.display}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-xs-12">
                        <Table>
                            <THead>
                                <TR>
                                    <TH col="3">{tableHeadings[0]}</TH>
                                    <TH col="3">{tableHeadings[1]}</TH>
                                    <TH col="3">{tableHeadings[2]}</TH>
                                    <TH>{tableHeadings[3]}</TH>
                                    <TH>{tableHeadings[4]}</TH>
                                </TR>
                            </THead>
                            <TBody>
                                {this.state.filteredCourses.length ?
                                    this.state.filteredCourses.map((universityCohort, index) => {
                                        return (
                                            <TR key={index}
                                                role="button"
                                                onClick={() => this.selectCohort(universityCohort)}
                                            >
                                                <TD
                                                    col="3"
                                                    heading={tableHeadings[0]}
                                                    style={uniCellStyles}
                                                    className="padding-l-3-xs padding-l-6-md"
                                                >
                                                    <UniversityLogo
                                                        imgSrc={this.state.universityLogo}
                                                        inlineStyles={uniImgStyles}
                                                        imgAlt={this.state.universityName}
                                                        options="hide-xs show-md"
                                                    />
                                                    <span className="padding-l-0-xs padding-l-3-md">{this.state.universityName}</span>
                                                </TD>
                                                <TD col="3" heading={tableHeadings[1]}>{universityCohort.program.programType.name}</TD>
                                                <TD col="3" heading={tableHeadings[2]}>{universityCohort.name}</TD>
                                                <TD heading={tableHeadings[3]}>{moment(universityCohort.startDate).format('MMMM D, YYYY')}</TD>
                                                <TD heading={tableHeadings[4]}>{moment(universityCohort.endDate).format('MMMM D, YYYY')}</TD>
                                            </TR>
                                        )
                                    })
                                : <TR>
                                    <TD>
                                        <Loading />
                                    </TD>
                                </TR>}
                            </TBody>
                        </Table>
                    </div>
                </div>
            </section>
            </DocumentTitle>
        )
    }
};

export const COHORTS = Cohorts

export default connect(mapStateToProps)(Cohorts);
