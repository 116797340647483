import React from 'react';

const THead = (props) => {

    return (
        <thead>
            {props.children}
        </thead>
    )
}

export default THead
