import React, {Component} from 'react'
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import Loading from '../../components/Loading';
import Alert from '../../components/Alert';
import {courseworkActions} from '../../actions/courseworkActions';
import {isRole, isInstructor, isTA, isAdmin, isCD} from '../../helpers/roles';
import {roleTypes} from '../../constants/dataTypes'
import moment from 'moment';
import MarkdownRender from '../../components/MarkdownRenderer/markdownRenderer'
import ReactGA from 'react-ga';
import {isEmpty} from 'lodash'
import FormNavigationPrompt from '../../components/NavigationPrompt'
import ProfilePic from "../ProfilePic";

var classNames = require('classnames');

const mapStateToProps = (state, ownProps) => {
    return {
        userId: state.user.data.userId,
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        user: state.user,
        enrollmentId: state.user.enrollmentInfo.id,
        ...ownProps
    }
}

class CommentManager extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editableCommentId: null,
            activeCommentEdit: '',
            feedback: '',
            comments: [],
            commentsLoading: false,
            commentAdded: false,
            //submission: this.props.submission ? this.props.submission : {},
            lastAction: '',
            OWN_COMMENT: 'OWN_COMMENT',
            preview: false,
            formTouched: false
        }
    }

    handleChange = (event) => {
        let fieldName = event.target.name
        let fieldValue = event.target.value
        let obj = {}
        obj[fieldName] = fieldValue
        this.setState({formTouched: true})
        this.setState(obj)
    }

    handleEditToggle = (e, index) => {
        if (e.target.id === 'edit') {
            this.setState({
                editableCommentId: index
            })
        } else {
            this.setState({
                editableCommentId: null,
                activeCommentEdit: ''
            })
        }
    }

    submitFeedback = async () => {
        ReactGA.event({
            category: 'Homework',
            action: 'Submit Feedback'
        })

        const {enrollmentId, submission} = this.props;
        const submissionId = submission.id;
        this.setState({commentsLoading: true, commentAdded: false, preview: false})

        let payload = {
            id: null,
            submissionId: submissionId,
            enrollmentId: enrollmentId,
            comment: this.state.feedback
        }
        await courseworkActions.addSubmissionComment(payload);

        await this.props.refresh();
        this.refreshCourseworkState('added');
    }

    previewFeedback = () => {
        const previewState = this.state.preview;
        this.setState({preview: !previewState})
    }

    handleFeedbackModify = async (event, commentObj) => {
        event.preventDefault()
        const {id} = event.target; // we know if we're editing or deleting based on button id
        const submissionId = commentObj.submissionId;
        const {enrollmentId} = this.props;
        if (id === 'edit') {
            if (window.confirm('Are you sure you want to edit this comment?')) {
                ReactGA.event({
                    category: 'Homework',
                    action: 'Edit Feedback'
                })
                let payload = {
                    id: commentObj.id,
                    submissionId: submissionId,
                    enrollmentId: enrollmentId,
                    comment: this.state.activeCommentEdit
                }
                await courseworkActions.updateSubmissionComment(payload)
                await this.props.refresh();
                await this.refreshCourseworkState('edited')
                this.setState({
                    editableCommentId: null,
                    activeCommentEdit: ''
                })
            }
        } else if (id === 'delete') {
            if (window.confirm('Are you sure you want to permanently delete this comment?')) {
                ReactGA.event({
                    category: 'Homework',
                    action: 'Delete Feedback'
                })
                let payload = {
                    id: commentObj.id
                }
                await courseworkActions.deleteSubmissionComment(payload)
                await this.props.refresh();
                this.refreshCourseworkState('deleted')
            }
        } else {
            console.warn("DEVELOPER WARNING: handleFeedbackModify is only designed for editing and deleting, check GradeAssignment around line 162")
            return
        }
    }

    refreshCourseworkState = (action) => {
        this.setState({
            lastAction: action,
            commentsLoading: false,
            commentAdded: true,
            feedback: '',
            formTouched: false
        })
    }

    checkPermissions = (commenterUserId, commenterRole) => {
        const {activeRole, userId} = this.props;
        if (userId === commenterUserId) { // if userId and comment's ID are the same, we good.
            return true
        } else if (isAdmin(activeRole)) { // if admin, can delete anything
            return true
        } else if (isRole([roleTypes.CD, roleTypes.SSM], activeRole)) { // if SSM/CD...
            if (isInstructor(commenterRole) || isTA(commenterRole)) { // can delete TA/Instructor comments
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    renderModifyButtons = (item, index) => {
        let buttons = []
        if (this.props.userId === item.author.id) {
            if (this.state.editableCommentId === index) { // if we're editing a comment, show submit/cancel
                buttons.push(
                    <React.Fragment key={index - 1}>
                        <button key={index} className='btn-small btn-edit margin-1-left' id='edit'
                                onClick={(e) => this.handleFeedbackModify(e, item)}>Submit Edit
                        </button>
                        <button key={index + 1} className='btn-small btn-cancel margin-1-left' id='cancel-edit'
                                onClick={(e) => this.handleEditToggle(e, index)}>Cancel Edit
                        </button>
                    </React.Fragment>
                )
            } else { // else show edit toggle
                buttons.push(<button key={index} className='btn-small btn-edit margin-1-left  margin-t-3' id='edit'
                                     onClick={(e) => this.handleEditToggle(e, index)}>Edit this Comment</button>)
            }
        }
        const deletePermission = this.checkPermissions(item.author.id, item.authorRole.courseRoleCode) // deletions have extra permissions for SSM/CD/Admin
        if (deletePermission) {
            buttons.push(<button key={index + 3} id='delete'
                                 className='btn-small btn-tertiary btn-delete margin-1-left margin-t-3'
                                 onClick={(e) => this.handleFeedbackModify(e, item)}>Delete this Comment</button>)
        }
        return buttons
    }

    renderComments = () => {
        const {submission} = this.props;
        const { formTouched } = this.state
        return submission.submissionCommentList.map((item, index) => {
            return (
                <div className="col-xs-12" key={index}>
                    <FormNavigationPrompt message='Are you sure you want to navigate away from this page while you have a comment in progress?' valueExists={formTouched} />
                    <div className="card-with-title card-wide">
                        <div className="card-title">
                            <ProfilePic user={item.author}
                                        inlineStyles={{maxWidth: '150px', height: 'auto'}}/>
                            <p><em>from {item.author.firstName + ' ' + item.author.lastName}
                                <br/>{moment(item.date).format('MMMM Do')}, {moment(item.date).format('h:mm a')} Local</em>
                            </p>
                        </div>
                        <div className="card-content">
                            {this.state.editableCommentId === index ?
                                <textarea name='activeCommentEdit' value={this.state.activeCommentEdit || item.comment}
                                          onChange={this.handleChange}/>
                                : <MarkdownRender text={item.comment} isPcCase={item.isPcCase}/>}
                            {this.renderModifyButtons(item, index)}
                        </div>
                    </div>
                </div>
            )
        })
    }

    render() {
        //FIXME: OK, wat. Why are there two duplicate keys each in feedbackButtonClass and previewButtonClass and they're all opposites. Halp.
        // (In case it wasn't clear, that needs refactoring)
        const {submission, activeRole } = this.props;
        const { formTouched } = this.state
        let feedbackButtonClass = classNames({
            'btn-save': !this.state.feedback.length,
            //  eslint-disable-next-line
            'btn-save': this.state.feedback.length,
            'btn-loading': this.state.commentsLoading
        })
        let previewButtonClass = classNames({
            'btn-edit': !this.state.feedback.length,
             //  eslint-disable-next-line
            'btn-edit': true,
            'btn-loading': this.state.commentsLoading
        })
        if (this.state.commentsLoading || isEmpty(this.props.submission)) {
            return <Loading>Comments Loading...</Loading>
        }
        return (
            <React.Fragment>
                <FormNavigationPrompt message='Are you sure you want to navigate away from this page while you have a comment in progress?' valueExists={formTouched} />
                <div className="row">
                    {this.state.commentAdded &&
                    <div className="col-xs-12 col-md-8 col-gutter-tb">
                        <Alert id='comment-action' type="success">
                            Your feedback has been {this.state.lastAction}!
                        </Alert>
                    </div>
                    }
                    {(!isCD(activeRole) && !this.props.career && !this.props.grade) && <div className="col-xs-12 col-gutter-tb">
                        <div className="field">
                            <label htmlFor='comment'>Comment</label>
                            <textarea name="feedback" value={this.state.feedback} onChange={this.handleChange}
                                      placeholder={`Enter your feedback${this.props.isStudent ? "" : " for this student's work "}, Markdown is supported`}></textarea>
                        </div>
                        <div className="field">
                            <button className={feedbackButtonClass} onClick={this.submitFeedback}
                                    disabled={!this.state.feedback.length}>
                                Add Your Feedback
                            </button>
                            <button className={previewButtonClass} onClick={this.previewFeedback}
                                    disabled={!this.state.feedback.length}>
                                {this.state.preview ? 'Hide preview' : 'Preview Your Feedback'}
                            </button>
                        </div>
                    </div>}
                </div>
                <div className="row">
                    {this.state.preview ? (
                        <div className="col-xs-12 col-gutter-tb bg-secondary">
                            <h2>Comment Preview</h2>
                            <div className="card-with-title card-wide">
                                <div className="card-title">
                                    <ProfilePic user={this.props.user.profile}
                                                inlineStyles={{maxWidth: '150px', height: 'auto'}}/>
                                    <p>
                                        <em>from {this.props.user.profile.firstName} {this.props.user.profile.lastName}<br/>{moment().format('MMMM Do, h:mm a')}
                                        </em>
                                    </p>
                                </div>
                                <div className="card-content">
                                    <MarkdownRender text={this.state.feedback}/>
                                </div>
                            </div>
                        </div>
                    ) : null
                    }
                    <div className="col-xs-12 col-gutter-tb">
                        <h2>Feedback/Comments</h2>
                    </div>
                    {(submission.submissionCommentList && submission.submissionCommentList.length > 0) ?
                        this.renderComments() : (
                            <div className="col-xs-12 col-gutter-tb"><p>No Comments</p></div>
                        )}
                </div>
            </React.Fragment>
        )

    }
}

export default withRouter(connect(mapStateToProps)(CommentManager));
