import { lockActionTypes } from '../constants/actionTypes'
import { fulfilled } from '../helpers/asyncStatusGenerator';


const initialState = {
    data: null
};

export const routeLockReducer = (state=initialState, action) => {
    switch (action.type) {
        case fulfilled(lockActionTypes.ROUTE_LOCK):
            return {
                ...state,
            data: action.payload
            };
        case fulfilled(lockActionTypes.ROUTE_LOCK_RELEASE):
            return {
                ...state,
            data: null
            };
        default:
            return state
    }
}