import { combineReducers } from 'redux';
import { features } from '../config/featureFlag';
import { createFlagsReducer } from 'flag';
import { dashboardReducer } from "./dashboardReducer";
import { userReducer } from './userReducer';
import { sessionReducer } from './sessionReducer';
import { roleReducer } from './roleReducer';
import { courseworkReducer } from './courseworkReducer';
import {routeLockReducer} from './routelockReducer';
import { timeReducer } from './timeReducer';
import {activeEnrollmentReducer, enrollmentsReducer, } from "./enrollmentsReducer";
import {careerServicesReducer} from "./careerServicesReducer";
import {billingReducer} from "./billingReducer";
import {openDocsReducer} from "./openDocsReducer";
import {announcementsReducer} from './announcementsReducer';
import {notificationsReducer} from "./notificationsReducer";
import {backButtonReducer} from './backButtonReducer';
import {condenseNavReducer} from "./condenseNavReducer";


const rootReducer = combineReducers({
    flags: createFlagsReducer(features),
    dashboard: dashboardReducer,
    sessions: sessionReducer,
    user: userReducer,
    roles: roleReducer,
    coursework: courseworkReducer,
    routeLock: routeLockReducer,
    time: timeReducer,
    enrollments: enrollmentsReducer,
    activeEnrollment: activeEnrollmentReducer,
    careerServices: careerServicesReducer,
    billing: billingReducer,
    openDocs: openDocsReducer,
    announcements: announcementsReducer,
    notifications: notificationsReducer,
    backButton: backButtonReducer,
    navState: condenseNavReducer
});

export default rootReducer;
