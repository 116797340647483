import React from 'react';
import { Table, TBody, THead, TR, TD, TH } from '../../components/Table';

const OpenDocsHeader = (props) => {
    const { endpoint, description, type, exampleBody, exampleResponses, parameters, headers } = props;
    const taName = "openDocTextArea" + endpoint;

    return (
        <div className="row">
            <div className="col-xs-12">
                <h1 className={"border-bottom"}>{endpoint}</h1>
                <p>{description}</p>
                <h3>Type: {type}</h3>
                {type === 'POST'
                    ? <p><b>IMPORTANT:</b> The payload <b>must</b> be stringified.</p>
                    : null}
                {parameters && parameters.map((p, i) =>
                    <React.Fragment key={i}>
                        <h3>Parameters</h3>
                        <div className="row" >
                            <div className="col-xs-3">
                                <p className={"text-italic"}>{p.name}</p>
                            </div>
                            <div className="col-xs">
                                <span>{p.description}</span>
                            </div>
                        </div>

                    </React.Fragment>
                )}

                {headers &&
                    <React.Fragment>
                        <h3>Headers</h3>
                        <div className="margin-b-3">
                            <Table className="bcs-table">
                                <THead>
                                    <TR>
                                        <TH>Key</TH>
                                        <TH>Value</TH>
                                        <TH>Description</TH>
                                    </TR>
                                </THead>
                                <TBody>
                                    {headers.map((h, i) => (
                                        <TR key={i}>
                                            <TD>{h.name}</TD>
                                            <TD>{h.value}</TD>
                                            <TD>{h.description}</TD>
                                        </TR>
                                    ))}
                                </TBody>
                            </Table>
                        </div>
                    </React.Fragment>
                }

                {exampleBody &&
                    <React.Fragment>
                        <h3>Example body</h3>
                        <textarea name={taName} readOnly={true} rows="4" cols="80"
                            value={`JSON.stringify(${JSON.stringify(exampleBody, null, 2)})`} />
                    </React.Fragment>
                }

                {exampleResponses &&
                    <React.Fragment>
                        <h3>Example responses</h3>
                        <ol>
                            {exampleResponses.map((er, i) =>
                                <li key={i}>
                                    <h4>Status code: {er.code}</h4>
                                    {er.description && <p>{er.description}</p>}
                                    {er.body !== null &&
                                        <React.Fragment>
                                            <h4>Body</h4>
                                            <textarea readOnly={true} rows={er.rows} cols="80"
                                                value={JSON.stringify(er.body, null, 2)} />
                                        </React.Fragment>}
                                </li>
                            )}
                        </ol>
                    </React.Fragment>}
            </div>
        </div>
    )
}

export default OpenDocsHeader;