import axios from 'axios';

export const fetchGradebookSubmissions = async(courseId) => {
    let payload = {courseId: courseId};
    let axiosResult = await axios.post("/broker/gradebook", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

export const fetchGrades = async(enrollmentId) => {
    let payload = {enrollmentId: enrollmentId};
    let axiosResult = await axios.post("/broker/grades", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}
