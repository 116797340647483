import React, {Component} from 'react';
import SelectPlaces from '../SelectPlaces';

class SelectPlacesStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            places: []
        }
    }

    commitStep = (placelist) => {
        this.props.onChange(this.props.step.step.id, placelist);
    };

    render() {
        return (
            <div className="margin-b-3 margin-l-6">
                <label htmlFor='select-places' aria-required={this.props.step.step.required ? "true" : "false"}>{this.props.step.step.definition.text}</label>
                <SelectPlaces id='select-places' multi onSelect={this.commitStep} autocompletionRequest={{types: ['(cities)']}} placelist={this.props.step.step.answer ? this.props.step.step.answer.value : []} />
            </div>
        )
    }
}

export default SelectPlacesStep;
