import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Link, Redirect} from 'react-router-dom';
import DocumentTitle from 'react-document-title'

import Alert from '../../components/Alert'

import { isStudent } from '../../helpers/roles';
import { panoptoActions } from '../../actions/sessionActions'
import { urlValidator } from '../../helpers/validators'

import ReactGA from 'react-ga'

const mapStateToProps = (state) => {
    return{
        user: state.user,
        userId: state.user.data.userId,
        enrollmentId: state.user.enrollmentInfo.id,
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        selectedSession: state.sessions.selected,
        sessionId: state.sessions.selected.session.session.id,
        sessionName: state.sessions.selected.session.session.name,
        chapter: state.sessions.selected.session.session.chapter,
        authToken: state.user.data.authToken
    }
}

export class PanoptoVideoCreate extends Component {
    constructor(props){
        super(props)
        this.state = {
            calledApiLoading: false,
            apiLoadingError: '',
            videoData: [],
            urlError: null,
            apiError: '',
            submissionSucces: false,
            sessionId: this.props.match.params.sessionId,
            sessionError: '',
            priorVideoData: false,
            initialVideoData: [],
            numberOfVideos: 1,
            error: '',
            initialLoad: true
        }
    }

    async componentDidMount() {
        if(!isStudent(this.props.activeRole)){
            const { sessionId } = this.state
            if(sessionId) {
                const payload = {id : parseInt(sessionId, 10)};
                const result = await panoptoActions.getPanoptoSessionVideos(this.props.authToken, payload);
                if(result.error){
                    this.setState({error: 'Your selected videos could not be loaded. Please try again, or contact BCS-Support'})
                } else {
                    let initialVideoState = []
                    // have to massage incoming data for display purposes
                    let priorVideoData;
                    if(result.data.length){
                        initialVideoState = result.data.map((videoObject) => {return videoObject.url})
                        priorVideoData = true
                    }
                    this.setState({
                        videoData: initialVideoState,
                        initialVideoData: initialVideoState,
                        priorVideoData: priorVideoData,
                        numberOfVideos: initialVideoState.length || 1
                    })
                }
            }
        }
    }

    componentWillReceiveProps(nextProps){
        // FIXME: Make sure we're not accidentally relying on the typeConversion here and correct.
        //
        if(nextProps.selectedSession === ''){
            this.setState({error: "Your selected session could not be loaded. Please try again, or contact BCS-Support"})
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault()
        const videosToPost = this.state.videoData.map(string => string.trim());

        let newVideos = videosToPost.filter((url) => { return !this.state.initialVideoData.includes(url)})
        if(videosToPost.length === 0 || !newVideos || !newVideos.length) {
            window.alert('You have not added any session videos yet!')
            return
        }
        if(PanoptoVideoCreate.checkUrlValidity(this.state.videoData)){
            const sessionId = parseInt(this.props.sessionId, 10)
            this.setState({calledApiLoading: true})
            const result = await panoptoActions.createPanoptoVideos(this.props.authToken, sessionId, videosToPost)
            if(result.error){
                ReactGA.event({
                    category: 'Panopto Video',
                    action: 'Video Add Error'
                });
                this.setState({calledApiLoading: false, error: "Your videos failed to be attached to this session. Please try again, or contact BCS-Support",  submissionSuccess: result.success})
            } else {
                ReactGA.event({
                    category: 'Panopto Video',
                    action: 'Video Added'
                 });
                this.setState({calledApiLoading: false, submissionSuccess: result.success})
            }
        } else {
            this.setState({
                error: 'One of the URLs is not valid. Please make sure each includes zoom.us or panopto.com.'
            })
        }
    }

    // return true when URL is valid, false otherwise.
    static checkUrlValidity = (videoDataUrls = []) => {
        return videoDataUrls.every((url) => {
            if(urlValidator(url)){
                const { hostname = '' } = new URL(url);
                return hostname.endsWith('.panopto.com') || hostname.endsWith('.zoom.us') || ['zoom.us', 'panopto.com'].includes(hostname)
            }
            return false;
        });
    }

    handleUrlChange = (event, index) => {
        this.setState({initialLoad: false}) // make sure that any new videos added wont have disabled text fields
        // copy videoData so we dont modify state directly
        let newState = this.state.videoData.slice()
        newState[index] = event.target.value
        this.setState({
           videoData: newState
        })
    }

    handleVideoSlotChange = (e, action) => {
        e.preventDefault()
        if(action === 'add'){
            this.setState({ numberOfVideos: this.state.numberOfVideos + 1})
        } else if(action === 'remove'){
            if(this.state.numberOfVideos === 1){ return } // dont let the user remove the last video addition field
            this.setState({ numberOfVideos: this.state.numberOfVideos - 1})
        } else {
            console.warn('DEVELOPER WARNING  - VIDEO SLOT CHANGE ONLY ADDS OR REMOVES')
            return
        }
    }

    render(){
        if(isStudent(this.props.activeRole)){
            return <Redirect to='/unauthorized'/>
        }
        // if we just succeeded in adding videos, offer user success msg and link to session detail
        if(this.state.submissionSuccess){
            const sessionId = this.props.match.params.sessionId
            return(
                <Alert type="success">
                    Your Videos have been added to this session.<br/>
                    <Link to={`/sessions/${sessionId}`} className="btn btn-edit btn-sm">Continue to Session</Link>
                </Alert>
            )
        }
        // three separate error displays, all for different errors in different visual positions.
        // TODO refactor into a component error state
        const errorDisplay = (
            <Alert type="warn">
                 {this.state.error}<br/>
            </Alert>
        )
        const videoDisplay = [...Array(this.state.numberOfVideos)].map((item, index) => {
            const video = this.state.videoData[index]
            // we want to disable the text areas for the videos already there, edits should be done via edit page. Only want this disabled on initial load
            const disableInputToPreventEdit =  this.state.initialLoad && this.state.initialVideoData.includes(video)
            return(
                <React.Fragment key={index}>
                   <label htmlFor='URL-Input'> Session Video {`${index+1}`}: </label>
                   <input type="text"
                        disabled={disableInputToPreventEdit}
                        placeholder="Enter Video URL"
                        value={video || ''}
                        onChange={(event) => this.handleUrlChange(event, index)}
                    />
                 </React.Fragment>
            )
        })
        const { selectedSession } = this.props
        return(
            <DocumentTitle title="Add Videos | Bootcamp Spot">
                <section className="page" data-component="panopto-video-add">
                    <div className="row">
                        <div className="col-xs-12 col-gutter-lr">
                            <h1>Add Video Links</h1>
                            <h2>For {this.props.sessionName}, Unit {this.props.chapter}</h2>
                        </div>
                        <div className="col-xs-12 col-md-8">
                        <form>
                            {this.state.priorVideoData ? <Link className='btn btn-edit' to={`/sessions/${selectedSession.SessionID}/video/update`}>Edit or Delete these Videos</Link> : ''}
                            {this.state.error ? errorDisplay : ''}
                            <p>Our Academic policy requires that all videos be uploaded through Panopto or Zoom.</p>
                            {videoDisplay}
                            <button type='button' className='btn-save btn-small' onClick={(event) => this.handleVideoSlotChange(event, 'add')}>Add Another Session Video</button>
                            <button type='button' className='btn-delete btn-small btn-tertiary' onClick={(event) => this.handleVideoSlotChange(event, 'remove')}>Remove Added Session Video</button>
                            <button type='submit' className='btn-submit' onClick={this.handleSubmit}>Submit Links</button>
                        </form>
                        </div>
                    </div>
                </section>
            </DocumentTitle>
        )
    }

}

export default connect(mapStateToProps)(PanoptoVideoCreate)
