import { notificationsActionTypes } from '../constants/actionTypes'
import {fulfilled, pending, rejected} from "../helpers/asyncStatusGenerator";

const initialState = {
    notificationList: [],
    unreadNotifications: 0,
    notificationsFilter: "all",
    notificationFilteredList: [],
    notificationsDropdownList: []
};

export const notificationsReducer = (state=initialState, action) => {
    switch (action.type) {
        case pending(notificationsActionTypes.FETCH_NOTIFICATIONS):
            return {
                ...state,
                notificationListLoading: true,
            };
        case fulfilled(notificationsActionTypes.FETCH_NOTIFICATIONS):
            return {
                ...state,
                notificationListLoading: false,
                notificationList: action.payload.notificationList,
                unreadNotifications: action.payload.notificationsUnread
            };
        case rejected(notificationsActionTypes.FETCH_NOTIFICATIONS):
            return {
                ...state,
                notificationListLoading: false
            };

        case notificationsActionTypes.NOTIFICATIONS_CHANGE_FILTER:
            return {
                ...state,
                notificationsFilter: action.payload.notificationsFilter,
                notificationFilteredList: action.payload.notificationFilteredList
            };

        case fulfilled(notificationsActionTypes.FETCH_NOTIFICATIONS_DROPDOWN):
            return {
                ...state,
                notificationsDropdownList: action.payload.latest,
                unreadNotifications: action.payload.unreadCount
            };

        case pending(notificationsActionTypes.NOTIFICATIONS_UPDATE):
            return {
                ...state,
                notificationListLoading: true,
            };
        case fulfilled(notificationsActionTypes.NOTIFICATIONS_UPDATE):
            return {
                ...state,
                notificationListLoading: false,
                notificationList: action.payload.notificationList,
                unreadNotifications: action.payload.notificationsUnread,
                notificationsDropdownList: action.payload.latest
            };
        case rejected(notificationsActionTypes.NOTIFICATIONS_UPDATE):
            return {
                ...state,
                notificationListLoading: false
            };

        default:
            return state
    }
}

