import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import axios from 'axios';
import { ytConfig } from '../../constants/youTube';
import Loading from '../../components/Loading';
import Alert from '../../components/Alert';
import Icon from '../../components/Icon';
import { isTA, isInstructor, isStudent, isCD, isAdmin, isSSM } from '../../helpers/roles';


const mapStateToProps = (state, ownProps) => {
    return{
        ...state,
        ...ownProps
    }
  }

class Videos extends Component {

    constructor(props)
    {
        super(props)

        const {YT_ALL_PLAYLIST, YT_FSF_PLAYLIST, YT_DATAVIZ_PLAYLIST, YT_UIUX_PLAYLIST, YT_CAREER_PLAYLIST} = ytConfig;

        this.state = {
            videos: [],
            playlists: [
                {
                    label: 'All Videos',
                    playlistId: YT_ALL_PLAYLIST
                },
                {
                    label: 'Full Stack Flex',
                    playlistId: YT_FSF_PLAYLIST
                },
                {
                    label: 'UI/UX',
                    playlistId: YT_UIUX_PLAYLIST
                },
                {
                    label: 'Data Visualization',
                    playlistId: YT_DATAVIZ_PLAYLIST
                },
                {
                    label: 'Career Services',
                    playlistId: YT_CAREER_PLAYLIST
                }
            ],
            loading: true,
            error: false
        }

    }

    componentDidMount(){
        const {YT_ALL_PLAYLIST, YT_SUPPORT_STUDENT, YT_SUPPORT_INSTRUCTOR, YT_SUPPORT_CAREER, TY_SUPPORT_ADMINSSM} = ytConfig;
        const { enrollmentInfo } = this.props.user;
        const role = enrollmentInfo.courseRole.courseRoleCode;
        let pls = this.state.playlists;

        if(isStudent(role))
        {
            pls.push({label: 'BCS Support', playlistId: YT_SUPPORT_STUDENT});
        }
        if(isInstructor(role) || isTA(role))
        {
            pls.push({label: 'BCS Support', playlistId: YT_SUPPORT_INSTRUCTOR});
        }
        if(isCD(role))
        {
            pls.push({label: 'BCS Support', playlistId: YT_SUPPORT_CAREER});
        }
        if(isAdmin(role) || isSSM(role))
        {
            pls.push({label: 'BCS Support', playlistId: TY_SUPPORT_ADMINSSM});
        }

        this.setState({playlists: pls});

        //load the 'All Videos' playlist by default
        this.getPlaylist(YT_ALL_PLAYLIST)
        .then(video => {
            if(video.data.items.length){
                this.setState({videos: video.data.items, loading: false});
            }else{
                throw new Error("No videos found in playlist");
            }
        })
        .catch(err => {
            this.setState({error: true, loading: false});
        });
    }

    async getPlaylist(playlist)
    {
        const { YT_API_KEY, YT_API_URL} = ytConfig;
        const playlistUrl = `${YT_API_URL}/playlistItems?key=${YT_API_KEY}&playlistId=${playlist}&part=snippet,status&maxResults=50`;
        return await axios.get(playlistUrl);
    }

    onPlaylistChange(e)
    {
        this.setState({loading: true})
        this.getPlaylist(e.target.value)
        .then(video => {
            if(video.data.items.length){
                this.setState({videos: video.data.items, loading: false, error: false});
            }else{
                throw new Error("No videos found in playlist");
            }
        })
        .catch(err => {
            this.setState({error: true, loading: false});
        });
    }


    render()
    {
        const {videos, loading, error, playlists} = this.state;
        return(
            <DocumentTitle title="Video Library | Bootcamp Spot">
                <section className="page" data-component="videos">

                <div className="row margin-t-3">
                        <div className="col-xs-12 col-md-6">
                            <h1>Video Library</h1>
                        </div>
                        <div className="col-xs-12 col-md-6 text-right">
                            <label htmlFor="filterVideosByType">Filter Videos By Type</label>
                            <select aria-label="filter videos by Type" id="filterVideosByType" onChange={this.onPlaylistChange.bind(this)} onBlur={this.onPlaylistChange.bind(this)}>
                            {playlists.map((item,index)=>{
                                return(
                                    <option value={item.playlistId} key={index}>{item.label}</option>
                                )
                            })}
                            </select>
                        </div>
                        <div className="col-xs-12">
                            <p>Behold your video kingdom! Videos labeled "Intro" are fantastic to watch before you start a unit. Videos labeled "Review" are a great way to wrap up after a unit. Videos labeled "Extra" are just that - extra learning!</p>
                        </div>
                    </div>

                    {loading ? (
                        <Loading> Loading videos... </Loading>
                    ) : (
                        error ? (
                            <Alert type="warn">
                                <p>There are no videos added to this playlist. If you feel like this is an error, please try again or reach out to support.</p>
                            </Alert>
                        ) : (
                            videos.length ? (
                                <div className="row card-group">
                                    {videos.map((item,index)=>{
                                        return (
                                        <div className="col-xs-12 col-sm-6 col-md-4" key={index}>
                                            <div className="card">
                                                <Link to={`/videos/watch/${item.snippet.resourceId.videoId}`}><img src={item.snippet.thumbnails.medium.url} alt={`${item.title} Thumbnail`} style={{maxWidth: '100%'}}/></Link>
                                                <h4>{item.snippet.title}</h4>
                                                <div className="card-footer">
                                                    <Link to={`/videos/watch/${item.snippet.resourceId.videoId}`}><Icon icon="film" /> Watch Video</Link>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>
                            ) : (
                                <Alert type="warn">
                                    <p>No videos were found to display in the library. If you feel this is an error, try refreshing the page or reaching out to support</p>
                                </Alert>
                            )

                        )
                    )}
                </section>
            </DocumentTitle>
        )
    }

}

export default connect(mapStateToProps)(Videos);
