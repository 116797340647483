import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'
import DocumentTitle from 'react-document-title';

import StudentCouseworkDetails from './studentCourseworkDetail'
import InstructionalCourseworkDetails from './instructionalCourseworkDetail'
import Loading from '../../components/Loading'
import Alert from '../../components/Alert'

import {courseworkActions} from '../../actions/courseworkActions'
import {isStudent} from '../../helpers/roles'
import get from 'lodash/get';

function mapStateToProps(state){
    return {
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        hideExtendDueDate: get(state,'user.enrollmentInfo.course.cohort.cohortUiControl.hideExtendDueDate',false),
        enrollmentId: state.user.enrollmentInfo.id,
        selectedAssignment: state.coursework.selectedAssignment,
        assignmentLoading: state.coursework.assignmentLoading
    }
}

class CourseworkDetail extends Component {
    constructor(props){
        super(props);

        this.state = {
            assignment: {},
            assignmentLoading: true
        }
    }

    componentDidMount() {
        this.refreshAssignment();
    }

    refreshAssignment = async() => {
        const { match } = this.props;
        const assignmentId = parseInt(match.params.courseworkId, 10);
        try {
            let result = await courseworkActions.fetchSingleCoursework(assignmentId);
            this.setState({
                assignment: result.data,
                assignmentLoading: false
            })
        } catch(err) {
            console.error(err)
        }
    };

    render() {
        const { activeRole, enrollmentId, hideExtendDueDate } = this.props;
        const { assignment, assignmentLoading } = this.state;

        if( !activeRole || !enrollmentId){
            return (<Loading />)
        }
        return(
            <DocumentTitle title="Coursework Detail | Bootcamp Spot">
                <section className="page" data-component="session-detail">
                    {assignmentLoading ? <Loading />
                        : assignment ?
                            (isStudent(activeRole) ? <StudentCouseworkDetails selectedAssignment={assignment} refreshAssignment={this.refreshAssignment}/> :
                                <InstructionalCourseworkDetails hideExtendDueDate={hideExtendDueDate} selectedAssignment={assignment} refreshAssignment={this.refreshAssignment}/>)
                            : <Alert type="warn">That coursework doesn't seem to exist - maybe try the <Link to="/coursework">Coursework page</Link> to get yourself back on track</Alert>
                    }

                </section>
            </DocumentTitle>
        )
    }
}

export default connect(mapStateToProps)(CourseworkDetail)
