import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import Icon from '../../components/Icon';
import Loading from '../../components/Loading'

import { roleTypes } from '../../constants/dataTypes'

const renderLinks = (userRole, coursework) => {
    if (userRole === roleTypes.STUDENT) {
        return (
                <ul className="card-footer-list">
                    <li>
                        <Link
                            aria-label="view assignment details"
                            to={{pathname: `/coursework/${coursework.id}/show`, state: coursework}}
                        >
                            <Icon icon="copy"/> Assignment Details
                        </Link>
                    </li>
                        {/* we dont render read comments for career coursework, since they dont have comments */}
                        {
                            coursework && coursework.context.id !== 2 && 
                            <li>
                                <Link to={{pathname: `/coursework/${coursework.id}/show`,
                                            state: coursework
                                            }}
                                        aria-labelledby={`view coursework comments`}
                                >
                                    <Icon icon="comments" />
                                    <span>Read Comments</span> 
                                </Link>
                            </li>
                        }
                    <li>
                        <Link
                            className="btn btn-small btn-save"
                            to={{
                                pathname: `/coursework/${coursework.id}/submit`,
                                state: coursework
                            }}
                            aria-labelledby={coursework.submission ? `view coursework submission for ${coursework.title}` : `submit ${coursework.title}`}
                        >
                            {coursework.submission ? (
                                <React.Fragment>
                                    <Icon icon="eye"/> View Submission
                                </React.Fragment>
                            ) : 'Submit'}
                        </Link>
                    </li>
                </ul>
        )
    } else if (userRole !== roleTypes.STUDENT) {
        return(
                <ul className="card-footer-list">
                    <li>
                        <Link to={{
                            pathname: `/coursework/${coursework.id}/show`,
                            state: coursework
                        }}>
                            <Icon icon="graduation-cap"/> Grade This Coursework
                        </Link>
                    </li>
                </ul>
        )
    } else {
        return(<Loading />)
    }
}

const singleCourseworkLinks =  (props) => {
    const { userRole, coursework } = props
    return renderLinks(userRole, coursework);
}

singleCourseworkLinks.propTypes = {
    userRole: PropTypes.string.isRequired
}

export default singleCourseworkLinks
