import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Icon from '../../components/Icon';
import ReactGA from 'react-ga';

import Loading from '../Loading'
import {handleNotification, updateNotifications} from "../../actions/notificationsActions";
import {getGradeName, gradeColorsMap} from '../../constants/grade';
import { getNotificationClickUrl, parseNotificationMessage } from "../../constants/notifications";
import { notificationSpecs } from "../../constants/notifications";
import { generateNotificationAndAccouncementClassnames } from '../../helpers/accessibility';
import { removePunctuation } from '../../helpers/punctuation';

const mapStateToProps = (state) => {
    return {
        userId: state.user.data.userId,
        enrollmentInfo: state.user.enrollmentInfo,
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        notifications: state.notifications
    }
};

class NotificationCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            notificationLoading: false
        }
    }

    handleNotificationClick = async (event, notification) => {
        // this is here to prevent the default navigation action since we use history.push to navigate below.
        event.preventDefault()
        try {
            this.setState({notificationLoading: true})
            ReactGA.event({
                category: 'Notifications',
                action: 'Click (Notifications)',
            });

            const { userId, enrollmentInfo, activeRole } = this.props;

            let payload = {notificationId: notification.id};
            await handleNotification(payload)

            let handledNotificationList = this.props.notifications.notificationList;
            for (let i = 0; i < handledNotificationList.length; i++) {
                if (handledNotificationList[i].id === notification.id)
                    handledNotificationList[i].handled = true;
            }

            let handledLatest = this.props.notifications.notificationsDropdownList;
            for (let i = 0; i < handledLatest.length; i++) {
                if (handledLatest[i].id === notification.id)
                    handledLatest[i].handled = true;
            }

            this.props.dispatch(updateNotifications(handledNotificationList, handledLatest));

            const universityId = enrollmentInfo.course.cohort.program.university.id;
            let notificationClickUrl = getNotificationClickUrl(notification, activeRole, userId, universityId);

            if (notificationClickUrl.startsWith("http")) {
                window.location = notificationClickUrl;
                this.setState({notificationLoading: false})
            } else {
                this.props.history.push(notificationClickUrl)
            }

        } catch(err) {
            console.error(err)
        }
    };

    render() {
        const notification = this.props.item;
        const currentNotification = notificationSpecs(notification);
        let classNames = generateNotificationAndAccouncementClassnames(notification.handled, "notification");
        const {notificationLoading} = this.state;
        const displayMessage = parseNotificationMessage(notification);

        return (
            <div className="row margin-b-2">
                <div className="col-xs-12 col-no-gutter">
                    <div className={`card-with-title ${classNames.notification}`} role="img" aria-label={`${classNames.label}`}>
                        { currentNotification ? (
                            <div
                                className={`text-${currentNotification.titleIcon.color} card-title-icon`}
                                aria-label={removePunctuation(currentNotification.contentPre) + ' notification'}
                                role="img"
                            >
                                { notification.type === 'homeworkGraded' && notification.content.grade ? (
                                    <span className={gradeColorsMap.get(notification.content.grade)}>{getGradeName(notification.content.grade)}</span>
                                ) : (
                                    <Icon icon={currentNotification.titleIcon.type} options={`text-${currentNotification.titleIcon.color}`}/>
                                )}
                            </div>
                        ) : (<Icon icon={'exclamation'} options={`text-primary`}/>) }
                        <div className="card-content">
                            <p>
                                { currentNotification ? (<strong>{currentNotification.contentPre}</strong>) : '' }

                                {displayMessage}
                            </p>
                        </div>
                        {notification.content.linkText && <div className="card-footer">
                            <ul>
                                <li>
                                {notificationLoading ? <Loading />
                                                              : <Link onClick={(e) => this.handleNotificationClick(e, notification)} to="">
                                                                    <Icon icon={currentNotification ? currentNotification.footerIcon : 'forward'} options="margin-r-2"/>
                                                                    {notification.content.linkText}
                                                                </Link>

                                }

                                </li>
                            </ul>
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect (mapStateToProps)(NotificationCard));
