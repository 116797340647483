// This rule is flagging labels that have correct association
// the rule has actually been
// [deprecated](https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/label-has-for.md)
/* eslint-disable jsx-a11y/label-has-for */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import DocumentTitle from 'react-document-title';
import isEmpty from 'lodash/isEmpty';
import * as constants from '../../constants/casesConstants';
import ReactGA from 'react-ga';
import Input from '../../components/Input';
import FormNavigationPrompt from '../../components/NavigationPrompt';
import {sfUat, sfProd} from '../../constants/salesforceConstants';
import Alert from "../../components/Alert";
import {fetchStudentSessions} from "../../actions/sessionActions";
import {isProduction} from "../../helpers/utils";
import {AskBCSCallToAction} from '../../components/AskBcsCallToAction'
import AskBCS from "../../components/AskBCS";
import {config, getBucketName} from "../../config/awsS3Config";
import Loading from '../../components/Loading';
import { v4 as uuidv4 } from 'uuid';
import {isInstructor, isTA, isCD, isSSM} from '../../helpers/roles';
import AWS from "aws-sdk";
import {get} from 'lodash';

AWS.config.update(config);

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
}

class WebToCase extends Component {
    constructor(props) {
        super(props)
        const selectedRole = get(props.user.enrollmentInfo.courseRole, 'name', null);
        const trilogyUser = isSSM(selectedRole) || isCD(selectedRole);
        const salesforceOrgConfig = isProduction() ? sfProd : sfUat;

        this.state = {
            trilogyUser,
            selectedRole,
            salesforceOrgConfig,
            priority: '',
            callPending: false,
            callFailed: false,
            callSuccess: false,
            category: '',
            selectedCategory: null,
            selectedSubcategory: null,
            selectedCategoryError: '',
            selectedSubcategoryError: '',
            subCategory: '',
            emptyQuestionError: '',
            description: '',
            formTouched: false,
            academicSessions: [],
            preferredDays: new Map(),
            preferredTimes: new Map(),
            emptyPreferredDaysError: "",
            emptyPreferredTimesError: "",
            tutorChangeReason: null,
            emptyTutorChangeReasonError: "",
            pastDueValue: null,
            emptyPastDueValueError: "",
            attendOffice: null,
            emptyAttendOfficeError: "",
            tutorChangeOtherReason: "",
            selectedSession: "",
            emptySessionError: "",
            noSessionsMessage: "",
            sessionRequired: false,
            photoUrl: "",
            photoKey: null,
            photoUploadLoading: false,
            photoUploadError: null,
            isSubmitted: false
        }
    }

    componentWillMount(){
        const {removeNav} = this.props
        const {enrollmentInfo} = this.props.user
        // if the enrollmentInfo is empty, don't show the nav. Navigating through the nav with no enrollmentInfo is not supported yet.
        if(isEmpty(enrollmentInfo)){
            removeNav()
        }
    }

    componentWillUnmount(){
        if (this.state.photoKey && !this.state.isSubmitted) {
            this.deletePhoto()
        }
    }

    selectCategory = async(e) => {
        this.setState({
            selectedCategory: e.target[e.target.selectedIndex].getAttribute('data-subcat') || e.target.value,
            selectedSubcategory: null,
            exampleQuestion: null,
            subCategory: '',
            priority: '',
            category: e.target.value,
            formTouched: true
        });

        if (e.target[e.target.selectedIndex].getAttribute('data-subcat') === 'other') {
            this.setState({
                priority: constants.categoryInfo.other[0].priority,
                exampleQuestion: constants.categoryInfo.other[0].exampleQuestion,
                subCategory: 'Other',
                selectedSubcategory: 'other',
                formTouched: true
            })
        }

        if (e.target[e.target.selectedIndex].getAttribute('data-subcat') === 'tutorRequest') {
            try {
                let axiosResult = await fetchStudentSessions(this.props.activeEnrollment.id);
                if (!axiosResult.data.sessions) {
                    this.setState({
                        academicSessions: [],
                        noSessionsMessage: "We’re sorry, a system error occurred and sessions could not be loaded.",
                        sessionRequired: false
                    })
                } else if (!!axiosResult.data.sessions && axiosResult.data.sessions.length === 0) {
                    this.setState({
                        academicSessions: [],
                        noSessionsMessage: "No sessions available.",
                        sessionRequired: false
                    })
                } else {
                    this.setState({
                        academicSessions: axiosResult.data.sessions,
                        noSessionsMessage: "",
                        sessionRequired: true
                    })
                }
            } catch (e) {
                this.setState({
                    academicSessions: [],
                    noSessionsMessage: "We’re sorry, a system error occurred and sessions could not be loaded.",
                    sessionRequired: false
                })
            }
        }
    }

    selectSubcategory = (e) => {
        this.setState({
            priority: e.target[e.target.selectedIndex].getAttribute('data-priority'),
            exampleQuestion: e.target[e.target.selectedIndex].getAttribute('data-example'),
            subCategory: e.target.value,
            selectedSubcategory: e.target.value,
            formTouched: true
        })
    }

    selectSession = (e) => {
        this.setState({
            selectedSession: e.target.value
        })
    }

    handleChange = (event) => {
        // just don't name any fieldnames "formTouched" please
        let fieldName = event.target.name
        let fieldValue = event.target.value
        let obj = {formTouched: true} // have to hit our check since user has started using form
        obj[fieldName] = fieldValue
        if (fieldName !== 'screenshot') {            
            this.setState(obj)
        } else {
            this.uploadPhoto()
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const errors = this.validateForm();

        if (!errors){
            ReactGA.event({
                category: 'Support',
                action: 'Web To Case Submitted'
            });
            this.setState({formTouched: false, isSubmitted: true}, () => {
                document.getElementById('webToCase').submit();
            })
        }
    };

    validateSize(size) {
        if (size > 5 * Math.pow(10, 6)) { // 5MB in bytes
           return true
        } else {
            return false
        }
    }

    uploadPhoto = () => {
        const files = document.getElementById("screenshot").files;
        if (!files.length) {
            document.getElementById("screenshot").value = null
            return alert("Please choose a file to upload first.");
        };

        const file = files[0];
        const fileName = file.name;
        const isImageSizeValid = this.validateSize(file.size);
        if (isImageSizeValid) {
            document.getElementById("screenshot").value = null
            return alert("File size must under 5MB! Please choose a smaller file.");
        };

        if (fileName.length > 1024) {
            document.getElementById("screenshot").value = null
            return alert("File name must be under 1024 characters! Please choose a smaller file name.");
        };
        
        this.setState({
            photoUploadLoading: true
        }, () => {            
            const extension = file.type.split('/')[1];
            const name = fileName.split(`.${extension}`);
            const uniqueFileName = `${name}-${uuidv4()}.${extension}`
            const albumPhotosKey = encodeURIComponent('screenshots') + "/";
            const photoKey = albumPhotosKey + uniqueFileName;
            const upload = new AWS.S3.ManagedUpload({
                params: {
                ContentType: file.type,
                Bucket: getBucketName(),
                Key: photoKey,
                Body: file
                }
            });
            
            const promise = upload.promise();
            promise.then((data) => {
                this.setState({
                    photoKey,
                    photoUrl: data.Location,
                    photoUploadLoading: false
                })

            },(err) => {
                console.error(err)
                this.setState({
                    photoUploadError: err.message,
                    photoUploadLoading: false
                })  
            });
        })
    };

    deletePhoto = async () => {
        const s3 = new AWS.S3({
            apiVersion: "2006-03-01",
            params: { Bucket: getBucketName() }
        });

        s3.deleteObject({ Key: this.state.photoKey }, function(err, data) {
            if (err) {
                console.error(err);
            }
        });
    }

    validateForm = () => {
        const salesforceOrgConfig = isProduction() ? sfProd : sfUat;
        const {selectedSubcategory, preferredTimes, preferredDays, tutorChangeReason, pastDueValue, attendOffice, sessionRequired} = this.state;

        let isError = false;
        const errors = {
            selectedCategoryError: "",
            selectedSubcategoryError: "",
            emptyQuestionError: "",
            emptyPreferredDaysError: "",
            emptyPreferredTimesError: "",
            emptyTutorChangeReasonError: "",
            emptyPastDueValueError: "",
            emptyAttendOfficeError: "",
            emptySessionError: ""
        };

        if (!this.state.selectedCategory) {
            isError = true;
            errors.selectedCategoryError = "Question Category is required.";
        }

        if (!this.state.selectedSubcategory && this.state.selectedCategory !== "Graduation Eligibility") {
            isError = true;
            errors.selectedSubcategoryError = "Question Subcategory is required.";
        }

        if (!this.state.description) {
            isError = true;
            errors.emptyQuestionError = "Description is required.";
        }

        if (sessionRequired && !this.state.selectedSession) {
            isError = true;
            errors.emptySessionError = "Session is required.";
        }

        if (selectedSubcategory === "Request a Tutor") {
            if (!pastDueValue) {
                isError = true;
                errors.emptyPastDueValueError = "Past due question is required.";
            }
            if (!attendOffice) {
                isError = true;
                errors.emptyAttendOfficeError = "Attend office hours question is required.";
            }
        }

        if (selectedSubcategory === "Tutor Change (Reassignment) Request") {
            const {tutorChangeOtherReason} = salesforceOrgConfig;
            if (!tutorChangeReason || (tutorChangeReason === "tutorChangeOtherReason" && !this.state[tutorChangeOtherReason])) {
                isError = true;
                errors.emptyTutorChangeReasonError = "A reason is required.";
            }

            if (preferredTimes.size === 0) {
                isError = true;
                errors.emptyPreferredTimesError = "A preferred time is required.";
            }

            if (preferredDays.size === 0) {
                isError = true;
                errors.emptyPreferredDaysError = "A preferred day is required.";
            }
        }

        this.setState({
            ...this.state,
            ...errors
        });

        return isError;
    }

    goBack = async (e) => {
        e.preventDefault();
        if (this.state.selectedCategory || this.state.description) {
            if ( window.confirm('Are you sure you want to cancel your submission and go back?') ) {
                this.props.history.goBack();
            } else {
                return;
            }
        }
        this.props.history.goBack();
    }

    handleRoleChange = (event) => {
        let fieldValue = event.target.value
        if (fieldValue === 'Student Success Manager' || fieldValue === 'Career Director'){
            this.setState({trilogyUser:true})
        } else {
            this.setState({trilogyUser:false})
        }

        if (fieldValue !== 'Student' && this.state.selectedCategory === 'tutorRequest') {
            this.setState({
                selectedCategory: null,
                selectedSubcategory: null,
                selectedRole: fieldValue
            })
        } else {
            this.setState({
                selectedRole: fieldValue
            })
        }
    }

    handleRadioChange = (e, question) => {
        let fieldId = e.target.id;
        if (question === "pastDue") {
            this.setState({
                formTouched: true,
                pastDueValue: fieldId
            })
        } else if (question === "attendOffice") {
            this.setState({
                formTouched: true,
                attendOffice: fieldId
            })
        } else if (question === "reason") {
            this.setState({
                formTouched: true,
                tutorChangeReason: fieldId
            })
        }
    }

    renderRequestTutorFields(sessions, salesforceOrgConfig) {
        const {emptyPastDueValueError, emptyAttendOfficeError, selectedSession, emptySessionError} = this.state;
        return (
            <React.Fragment>
                <Alert type="info">For any questions related to how tutoring works, please visit our Student Guide:
                    <a href="http://bit.ly/CentralSupportStudentGuidelines"
                       target="_blank"
                       rel="noopener noreferrer"
                       >
                           http://bit.ly/CentralSupportStudentGuidelines
                    </a>
                </Alert>

                <Alert type="info">All questions except the tutor name are required.</Alert>

                <label htmlFor="session">Currently, which sessions would you like to discuss? *</label>
                <select id="session" name={salesforceOrgConfig.classSession} title="Session" aria-label="session" value={selectedSession} aria-required={true} onChange={this.selectSession} onBlur={this.selectSession}>
                    <option disabled value="">Choose a Session</option>
                    <option data-test="prework" value="Prework Assignment">Prework Assignment</option>
                    {sessions}
                </select>
                {emptySessionError && <Alert type="error">{emptySessionError}</Alert>}

                <label htmlFor={salesforceOrgConfig.spokenTutorFirstName} >If you have spoken to a specific tutor and they have agreed to be your tutor, please put their name
                    here:</label>
                <div className={"row"}>
                    <div className="col-xs-12 col-md-6 padding-l-0 padding-t-0">
                        <Input id={salesforceOrgConfig.spokenTutorFirstName}
                               onChange={this.handleChange}
                               name={salesforceOrgConfig.spokenTutorFirstName}
                               placeholder="First name"
                        />
                    </div>
                    <div className="col-xs-12 col-md-6 padding-r-0 padding-t-0">
                        <Input id={salesforceOrgConfig.spokenTutorLastName}
                               onChange={this.handleChange}
                               name={salesforceOrgConfig.spokenTutorLastName}
                               placeholder="Last name"
                        />
                    </div>
                </div>

                <fieldset>
                    <legend >Are you past due on any homework assignment? *</legend>
                    <label htmlFor="pastDueOnHomework-yes" className="label-active">
                        <input type="radio" name={salesforceOrgConfig.pastDueOnHomework} id="pastDueOnHomework-yes"
                               value="Yes"
                               onChange={(e) => this.handleRadioChange(e, 'pastDue')}
                        />
                        Yes
                    </label>
                    <label htmlFor="pastDueOnHomework-no" className="label-active">
                        <input type="radio" name={salesforceOrgConfig.pastDueOnHomework} id="pastDueOnHomework-no"
                               value="No"
                               onChange={(e) => this.handleRadioChange(e, 'pastDue')}/>
                        No
                    </label>
                </fieldset>
                {(emptyPastDueValueError) && <Alert type="error">{emptyPastDueValueError}</Alert>}

                <fieldset>
                    <legend>Do you attend office hours regularly? *</legend>
                    <label htmlFor="attendOfficeHours-yes" className="label-active">
                        <input type="radio" name={salesforceOrgConfig.attendOfficeHours} id="attendOfficeHours-yes"
                               value="Yes"
                               onChange={(e) => this.handleRadioChange(e, 'attendOffice')}/>
                        Yes
                    </label>
                    <label htmlFor="attendOfficeHours-schedule" className="label-active">
                        <input type="radio" name={salesforceOrgConfig.attendOfficeHours} id="attendOfficeHours-schedule"
                               value="Yes, when my schedule allows"
                               onChange={(e) => this.handleRadioChange(e, 'attendOffice')}/>
                        Yes, when my schedule allows
                    </label>
                    <label htmlFor="attendOfficeHours-no" className="label-active">
                        <input type="radio" name={salesforceOrgConfig.attendOfficeHours} id="attendOfficeHours-no"
                               value="No"
                               onChange={(e) => this.handleRadioChange(e, 'attendOffice')}/>
                        No
                    </label>
                </fieldset>
                {(emptyAttendOfficeError) && <Alert type="error">{emptyAttendOfficeError}</Alert>}

                <Input id={salesforceOrgConfig.hoursOfStudy}
                       onChange={this.handleChange}
                       name={salesforceOrgConfig.hoursOfStudy}
                       label="Approximately how many hours a week do you study?"
                       required={true}
                />
            </React.Fragment>
        )
    }

    handleCheckboxChange = (e, type) => {
        const item = e.currentTarget.name;
        const isChecked = e.target.checked;
        const removeItem = (preferredDays) => {
            preferredDays.delete(item);
            return preferredDays;
        }
        if (type === 'days') {
            this.setState(prevState => ({
                preferredDays: isChecked ? prevState.preferredDays.set(item, isChecked) : removeItem(prevState.preferredDays),
                formTouched: true
            }));
        } else if (type === 'times') {
            this.setState(prevState => ({
                preferredTimes: prevState.preferredTimes.set(item, isChecked),
                formTouched: true
            }));
        }
    };

    renderChangeTutorFields = (sessions, salesforceOrgConfig) => {
        const preferredDaysOptions = [
            {
                name: `${salesforceOrgConfig.tutorPDMonday}`,
                label: "Mondays"
            },
            {
                name: `${salesforceOrgConfig.tutorPDTuesday}`,
                label: "Tuesdays"
            },
            {
                name: `${salesforceOrgConfig.tutorPDWednesday}`,
                label: "Wednesdays"
            },
            {
                name: `${salesforceOrgConfig.tutorPDThursday}`,
                label: "Thursdays"
            },
            {
                name: `${salesforceOrgConfig.tutorPDFriday}`,
                label: "Fridays"
            },
            {
                name: `${salesforceOrgConfig.tutorPDSaturday}`,
                label: "Saturdays"
            },
            {
                name: `${salesforceOrgConfig.tutorPDSunday}`,
                label: "Sundays"
            }
        ];

        const preferredTimesOptions = [
            {
                name: `${salesforceOrgConfig.tutorPTEarly}`,
                label: "Early (6-9 am)"
            },
            {
                name: `${salesforceOrgConfig.tutorPTMorning}`,
                label: "Mornings (9 am - noon)"
            },
            {
                name: `${salesforceOrgConfig.tutorPTMidday}`,
                label: "Midday (noon - 5 pm)"
            },
            {
                name: `${salesforceOrgConfig.tutorPTEvening}`,
                label: "Evenings (5-8 pm)"
            },
            {
                name: `${salesforceOrgConfig.tutorPTNight}`,
                label: "Night (After 8 pm)"
            }
        ];

        const {tutorChangeReason, emptyTutorChangeReasonError, emptyPreferredDaysError, emptyPreferredTimesError, selectedSession, emptySessionError} = this.state;
        return (
            <React.Fragment>
                <Alert type="info">For any questions related to how tutoring works, please visit our Student Guide:
                    <a href="http://bit.ly/CentralSupportStudentGuidelines"
                       target="_blank"
                       rel="noopener noreferrer"
                    >
                        http://bit.ly/CentralSupportStudentGuidelines
                    </a>
                </Alert>
                <Alert type="info">All questions except spoken tutor name are required.</Alert>

                <Alert type="warn">If you have not been assigned a tutor in the past, please select Request a Tutor from the Question Subcategory option above.</Alert>

                <label htmlFor="session">Currently, what session would you like to discuss? *</label>
                <select id="session" name={salesforceOrgConfig.classSession} title="Session" aria-label="session" value={selectedSession} aria-required={true} onChange={this.selectSession} onBlur={this.selectSession}>
                    <option disabled value="">Choose a Session</option>
                    <option data-test="prework" value="Prework Assignment">Prework Assignment</option>
                    {sessions}
                </select>
                {emptySessionError && <Alert type="error">{emptySessionError}</Alert>}

                <label htmlFor="specificTutorName">If you have spoken to a specific tutor and they have agreed to be your tutor, please put their name here:</label>
                <div id="specificTutorName" className="row">
                    <div className="col-xs-12 col-md-6 padding-l-0 padding-t-0 padding-b-0">
                        <Input id={salesforceOrgConfig.spokenTutorFirstName}
                               onChange={this.handleChange}
                               name={salesforceOrgConfig.spokenTutorFirstName}
                               placeholder="First name"
                        />
                    </div>
                    <div className="col-xs-12 col-md-6 padding-r-0 padding-t-0 padding-b-0">
                        <Input id={salesforceOrgConfig.spokenTutorLastName}
                               onChange={this.handleChange}
                               name={salesforceOrgConfig.spokenTutorLastName}
                               placeholder="Last name"
                        />
                    </div>
                </div>

                <fieldset className={tutorChangeReason === "tutorChangeOtherReason" ? "margin-b-0" : ""}>
                    <legend>Reason for requesting a tutor change: *</legend>
                    <label htmlFor="availabilityDoesNotMatch" className="label-active">
                        <input type="radio" name={salesforceOrgConfig.availabilityDoesntMatch} id="availabilityDoesNotMatch"
                               value="1"
                               checked={tutorChangeReason === "availabilityDoesNotMatch"}
                               onChange={(e) => this.handleRadioChange(e, 'reason')}
                        />
                        Availability doesn’t match
                    </label>
                    <label htmlFor="tutorVacation" className="label-active">
                        <input type="radio" name={salesforceOrgConfig.tutorVacation} id="tutorVacation"
                               value="1"
                               checked={tutorChangeReason === "tutorVacation"}
                               onChange={(e) => this.handleRadioChange(e, 'reason')}
                        />
                        Tutor on vacation for 7 days or more
                    </label>
                    <label htmlFor="tutorResigned" className="label-active">
                        <input type="radio" name={salesforceOrgConfig.tutorResigned} id="tutorResigned"
                               value="1"
                               checked={tutorChangeReason === "tutorResigned"}
                               onChange={(e) => this.handleRadioChange(e, 'reason')}
                        />
                        Tutor resigned
                    </label>
                    <label htmlFor="tutorChangeOtherReason" className="label-active">
                        <input type="radio" id="tutorChangeOtherReason"
                        value="1"
                        checked={tutorChangeReason === "tutorChangeOtherReason"}
                               onChange={(e) => this.handleRadioChange(e, 'reason')}
                        />Other
                    </label>
                </fieldset>
                {tutorChangeReason === "tutorChangeOtherReason" &&
                <Input onChange={this.handleChange}
                       name={salesforceOrgConfig.tutorChangeOtherReason}
                       id={salesforceOrgConfig.tutorChangeOtherReason}
                />
                }
                {emptyTutorChangeReasonError && <Alert type="error">{emptyTutorChangeReasonError}</Alert>}

                <Input id={salesforceOrgConfig.timeZone}
                       onChange={this.handleChange}
                       name={salesforceOrgConfig.timeZone}
                       label="Time Zone"
                       required={true}
                />

                <fieldset>
                    <legend>What are your preferred days? *</legend>
                    {preferredDaysOptions.map((item, index) =>
                    {
                        return <label htmlFor={item.name} className="label-active" key={index}>
                            <input onChange={(e) => this.handleCheckboxChange(e, 'days')}
                                name={item.name}
                                id={item.name}
                                value="1"
                                type="checkbox"
                            />{item.label}
                        </label>}
                    )}
                </fieldset>
                {emptyPreferredDaysError && <Alert type="error">{emptyPreferredDaysError}</Alert>}

                <fieldset>
                    <legend>What are your preferred times? (We’ll do our best to find a tutor for your preferred times) *</legend>
                    {preferredTimesOptions.map((item, index) =>
                        <label htmlFor={item.name} className="label-active" key={index}>
                        <input onChange={(e) => this.handleCheckboxChange(e, 'times')}
                            name={item.name}
                            value="1"
                            type="checkbox"
                        />{item.label}
                        </label>
                    )}
                </fieldset>
                {emptyPreferredTimesError && <Alert type="error">{emptyPreferredTimesError}</Alert>}
            </React.Fragment>
        )
    }

    renderRoleInput = () => {
        const { selectedRole, salesforceOrgConfig } = this.state;
        const checkRole = selectedRole.toLowerCase();
        const selectedRoleLabel = isInstructor(checkRole) || isTA(checkRole) ? 'Instructional Staff' : selectedRole;
        const { enrollmentInfo } = this.props.user;

        return <React.Fragment>
            <label htmlFor="role">Role</label>
            <select disabled={!isEmpty(enrollmentInfo)} id="role" name={salesforceOrgConfig.roleId} title="Role" aria-label="question role" defaultValue={selectedRole} aria-required={true} onChange={this.handleRoleChange} onBlur={this.handleRoleChange}>
                {isEmpty(enrollmentInfo) ?
                    constants.roles.map(role => <option key={role.toLowerCase()} value={role} aria-label={role}>{role}</option>)
                    :
                    <option key={selectedRole.toLowerCase()} value={selectedRole} aria-label={selectedRoleLabel}>{selectedRoleLabel}</option>}
            </select>
        </React.Fragment>
    }

    filterCategoryOptions = () => {
        const {selectedRole} = this.state;
        let categories = constants.categories;
        if (selectedRole === "Instructor") {
            categories = constants.categories.filter(({category}) => {
                return category !== "Tutor Request" && category !== "Facilities and Parking" && category !== "Tuition & Financing"
            })
        } else if (selectedRole === "Student") {
            categories = constants.categories.filter(({category}) => {
                return category !== "Class Dates" && category !== "Login Issues" && category !== "Onboarding" && category !== "Human Resources"
            })
        } else if (selectedRole === "Student Success Manager") {
            categories = constants.categories.filter(({category}) => {
                return category !== "Tutor Request" && category !== "Facilities and Parking" && category !== "Tuition & Financing" && category !== "My Schedule"
            })
        } else if (selectedRole === "TA") {
            categories = constants.categories.filter(({category}) => {
                return category !== "Human Resources"
            })
        } else if (selectedRole === "Career Director") {
            categories = constants.categories.filter(({category}) => {
                return category !== "Human Resources"
            })
        }
        return categories
    }

    render() {
        const { user } = this.props;
        const { formTouched, selectedCategory, selectedRole, selectedSubcategory, academicSessions, noSessionsMessage, salesforceOrgConfig} = this.state;
        // FIXME: need to come up with a better solution
        const root = window.location.origin;

        const categories = this.filterCategoryOptions();
        const catOptions = categories.map((cat, i) => {
            return <option key={i} data-subcat={cat.subCategory} value={cat.category}>{cat.category}</option>
        })
        
        const subCatOptions = (selectedCategory && constants.categoryInfo[selectedCategory]) ? constants.categoryInfo[selectedCategory].map((cat, i) => {
            return <option key={i} data-priority={cat.priority} data-example={cat.exampleQuestion}
                           value={cat.subCategory}>{cat.subCategory}</option>
        }) : null;
        const showSisenseExtraFields = selectedCategory === "sisenseSupport";
        const showRequestTutorFields = selectedSubcategory === "Request a Tutor";
        const showChangeTutorFields = selectedSubcategory === "Tutor Change (Reassignment) Request";
        const sensitiveInfoMessage = "Please do not include any personal information such as your SSN/SIN, date of birth, and/or medical information.";

        const sessions = academicSessions.map((session, index) =>
            <option key={index} value={session.name}>{session.name}</option>
        );

        return (
            <DocumentTitle title="Support | Bootcamp Spot">
                <section className="page" data-component="webtocase">
                <FormNavigationPrompt message='Are you sure you want to navigate away from this page while you have this form in progress?' valueExists={formTouched} />
                    <div className="row">
                        <div className="col-xs-12 col-md-8">
                            <h1>Support</h1>
                            <AskBCS
                                onDeepLinkAvailable={(props) => 
                                    <AskBCSCallToAction {...props} />
                                }
                            />
                            <form onSubmit={e => this.handleSubmit(e)} id="webToCase" name="webToCase" className="row" action={`https://${salesforceOrgConfig.webToCaseActionRoot}/servlet/servlet.WebToCase?encoding=UTF-8`} method="POST">
                                {/* using HTML input instead of Input component due to data not being sent with Input component */}
                                <input type="hidden" aria-disabled="true" name="orgid" value={salesforceOrgConfig.orgId} />
                                <input type="hidden" aria-disabled="true" name="retURL" value={`${root}/casesuccess`} />
                                <input type="hidden" aria-disabled="true" id="recordType" name="recordType" value="01241000000zAc1" />
                                <input type="hidden" aria-disabled="true" id="priority" name="priority" value={this.state.priority} />
                                <input type="hidden" aria-disabled="true" value={`${user.profile.firstName} ${user.profile.lastName}`} name="name" />
                                <input type="hidden" aria-disabled="true" value={user.profile.email} name="email" />
                                <input type="hidden" aria-disabled="true" name="origin" value="BCS (Web)" />
                                <input type="hidden" aria-disabled="true" name="Role__c" value={selectedRole} />

                                <div>
                                    <label className="fill" htmlFor="name" id="name">
                                        Name
                                        <input className="input-disabled fill" id="name" disabled value={`${user.profile.firstName} ${user.profile.lastName}`} name="name" type="text" />
                                    </label>
                                    <label className="fill" htmlFor="email" id="email">
                                        Email
                                        <input  className="input-disabled fill" label="Email Address" id="email" disabled value={user.profile.email} name="email" type="email" />
                                    </label>
                                    {this.renderRoleInput()}
                                    {this.state.trilogyUser &&
                                      <React.Fragment>
                                        <Input label="Org Email Address" id="tril-email" maxlength="255" name={salesforceOrgConfig.trilMail} size="20" type="email" />
                                      </React.Fragment>
                                    }
                                    <label htmlFor="category-sel">Question Category *</label>
                                    <select id="category-sel" name="00N4100000SIzOk" aria-label="question category" value={this.state.category} onChange={this.selectCategory} onBlur={this.selectCategory} aria-required={true}>
                                        <option disabled value="">Choose a Category</option>
                                        <AskBCS
                                            onDeepLinkAvailable={() =>
                                                ([
                                                    ...catOptions,
                                                    <option
                                                        key={catOptions.length}
                                                        data-subcat="ask"
                                                        value="AskBCS Learning Assistant">
                                                        AskBCS Learning Assistant
                                                    </option>
                                                ])
                                            }>
                                                {catOptions}
                                        </AskBCS>
                                    </select>
                                    
                                    {(this.state.selectedCategoryError) && <p className="context-error">{this.state.selectedCategoryError}</p>}
                                    {noSessionsMessage && <Alert type="warn">{noSessionsMessage}</Alert>}

                                    {selectedCategory === 'tutorRequest' &&
                                      <input type="hidden" aria-disabled="true" name={salesforceOrgConfig.sisenseClass} value={user.enrollmentInfo.course.code} />
                                    }
                                    {/* {this.state.subCategory && this.state.subCategory !== "Other" && this.state.exampleQuestion ?
                                    <p className="context-warn">
                                        Example Question: {this.state.exampleQuestion}
                                    </p> : null} */}
                                    {selectedCategory === 'humanResources' &&
                                    <p className="font-xs"
                                       data-test="sensitive-info-note"
                                       aria-label="sensitive information note"
                                       aria-describedby={sensitiveInfoMessage}
                                       aria-live="assertive"
                                    >
                                        {sensitiveInfoMessage}
                                    </p>
                                    }
                                    {selectedCategory && subCatOptions ?
                                      <React.Fragment>
                                        <label htmlFor="subcategory-sel">Question Subcategory *</label>
                                        <select id="subcategory-sel" name="00N4100000cUduS" aria-label="question subcategory" value={this.state.subCategory} onChange={this.selectSubcategory} onBlur={this.selectSubcategory}>
                                            <option disabled value="">Choose a Subcategory</option>
                                                { selectedRole === "Student" && selectedCategory === "azure" ?
                                                    subCatOptions.filter(subCat => subCat.props.value === "Azure Account Access")
                                                    :
                                                    selectedRole === "Student" && selectedCategory === "login" ?
                                                        subCatOptions.filter(subCat => subCat.props.value !== "Blank screen when I log in")
                                                        :
                                                        subCatOptions}
                                        </select>
                                      {(this.state.selectedSubcategoryError) && <p className="context-error">{this.state.selectedSubcategoryError}</p>}
                                      </React.Fragment> : null}

                                    {showRequestTutorFields &&
                                        this.renderRequestTutorFields(sessions, salesforceOrgConfig)
                                    }
                                    {showChangeTutorFields &&
                                        this.renderChangeTutorFields(sessions, salesforceOrgConfig)
                                    }
                                    {showSisenseExtraFields &&
                                        <React.Fragment>
                                            <Input label="Cohort" id="cohortid" onChange={this.handleChange} name={salesforceOrgConfig.sisenseCohort} />
                                            <Input label="Class" id="classid" onChange={this.handleChange} name={salesforceOrgConfig.sisenseClass} />
                                            <Input label="Dashboard URL" id="dashboardurlid" onChange={this.handleChange} name={salesforceOrgConfig.sisenseDashboardUrl} />
                                        </React.Fragment>
                                    }

                                    <label htmlFor="screenshot">Screenshot</label>
                                    <input id="screenshot" className="fill" label="Screenshot" name="screenshot" type="file" onChange={this.handleChange} accept="image/*" />
                                    {this.state.photoUploadLoading ? <Loading/> : null}
                                   
                                    <p className="font-xs">
                                        <span className="text-bold">Note: </span>When submitting a screenshot, please take care not to include anything in the screenshot that you might consider private or sensitive in nature.
                                    </p>
                                    <input label="Screenshot" name="Data_photo_URL__c" value={this.state.photoUrl} type="hidden"/>
                                    {this.state.photoUploadError && <Alert type="error">Error uploading photo. Please refresh and try again. If issue persists, please contact support without screenshot.</Alert>}

                                    <label htmlFor="subject">{selectedCategory === "tutorRequest" ? "Additional notes" : "What can we help you with?"} *</label>
                                    <textarea id="subject" onChange={this.handleChange} name="description" aria-label="what can we help you with?" rows="8" cols="80" />
                                    {(this.state.emptyQuestionError) && <p className="context-error">{this.state.emptyQuestionError}</p>}

                                    <button className='btn-small btn-submit' disabled={this.state.photoUploadLoading}>Submit Support Ticket</button>
                                    <button className="btn-small btn-delete btn-tertiary" onClick={(e) => this.goBack(e)}>Cancel, Go Back</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </DocumentTitle>
        )
    }
}

export const WEBTOCASE = WebToCase;

export default connect(mapStateToProps)(WebToCase);
