import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import LoginScreenLayout from "../../components/LoginScreenLayout";

class Login extends Component {
    render() {
        return (
            <DocumentTitle title="System Unsupported | Bootcamp Spot">
                <section className="page">
                    <LoginScreenLayout>
                        <h1>Bootcamp Spot</h1>
                        <p
                            className="text-center"
                            style={{ margin: "0 auto" }}
                        >
                            This platform is no longer supported and is being decommissioned at this time.
                        </p>
                    </LoginScreenLayout>
                </section>
            </DocumentTitle>
        );
    }
}

export default Login;
