import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import FormComponent from '../../components/FormComponent/FormComponent';
import { SurveyName } from '../../constants/surveys';
import {lockActionTypeMessages} from '../../constants/messages'
import {lockRouteForUser, releaseRouteForUser} from "../../actions/routeLockActions";

const mapStateToProps = (state, ownProps) => {
    return {
        dispatch: state.dispatch,
        location: state.location,
        ...ownProps
    }
};

class CareerSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formIsVisible: false
        };
        this.props.removeNav()
    }

    componentDidMount () {
        const {dispatch, location} = this.props;
        dispatch(lockRouteForUser(location.pathname,lockActionTypeMessages.CAREER_SURVEY));
    }

    onSuccess = () => {
        const { dispatch, history } = this.props
        dispatch(releaseRouteForUser());
        history.replace('/careerSuccess');
    };

    render() {
        return (
            <React.Fragment>
                <p>
                    Please take a moment to share with us what you hope to achieve after you graduate from this program. The Career Services team wants to begin to  understand your goals so that we can customize the support we provide. If you are seeking 1:1 Career Coaching, you will receive an email from your Career Director shortly to schedule your first coaching session. Please be sure  to respond to them to initiate your 1:1 coaching.
                    Your responses to the following questions do not negate your ability to contact your Career Director in the future should you change your mind.
                    First, we want to know what you want to achieve after graduation. Then we will ask you how which of our services will best support you.
                </p>
                <FormComponent {...this.props}
                   formName={SurveyName.CsPreferences}
                   showDbOnlyError={true}
                   noun={"survey"}
                   loadingErrorDescr={"career survey"}
                   onSuccessCallback={this.onSuccess} />
            </React.Fragment>
        );
    }
}

export const CAREERSURVEY = CareerSurvey;

export default withRouter(connect(mapStateToProps)(CareerSurvey));
