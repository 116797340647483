import moment from 'moment';

export const coursesFormatter = courses => {
    let coursesByUniversity = [],
        uniqueCohortNames =  []
    // Create coursesByUniversity array of unique universities
    courses.forEach((course) => {
        if (coursesByUniversity.indexOf(course.course.cohort.program.university.name) < 0) {
            coursesByUniversity.push(course.course.cohort.program.university.name);
        }
    })

    //  Restructure coursesByUniversity to contain an array to be used for courses for each unique university
    coursesByUniversity.forEach((courseByUni, index, array) => {
        array.splice(index, 1, {
            university: courseByUni,
            courses: [],
            universityLogo: "",
            uniqueCohorts: []
        });
    })

    // add courses to each object in the coursesByUniversity array
    coursesByUniversity.forEach((courseByUni) => {
        courses.forEach((course) => {
            if (courseByUni.university === course.course.cohort.program.university.name) {
                courseByUni.courses.push(course);
            }
        })

        courseByUni.universityLogo = courseByUni.courses[0].course.cohort.program.university.logoUrl;

        // check for duplicates and create arrays of unique cohort and class names and ids on each courseByUniversity object
        courseByUni.courses.forEach((course, index) => {
            const isActive = moment().isBetween(course.course.startDate, course.course.endDate);
            const hasEnded = moment().isAfter(course.course.endDate);
            const upcoming = moment().isBefore(course.course.startDate);

            if (uniqueCohortNames.indexOf(course.course.cohort.name) < 0) {
                uniqueCohortNames.push(course.course.cohort.name);
                course.activeStatus = hasEnded ? 'past' : isActive ? 'active' : upcoming ? 'upcoming' : '';
                courseByUni.uniqueCohorts.push(course);
            }
        })
    })

    return coursesByUniversity;
}

export const alphabetize = (array) => {
    return array.sort((a, b) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })
}

export const sortCohortsByStartDate = (array) => {
    return array.sort((a,b) => moment(b.startDate).format('YYYYMMDD') - moment(a.startDate).format('YYYYMMDD'))
}

export const sortClassesByStartDate = (array) => {
    return array.sort((a,b) => moment(b.course.startDate).format('YYYYMMDD') - moment(a.course.startDate).format('YYYYMMDD'))
}