import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import FormComponent from '../../components/FormComponent/FormComponent';
import {lockRouteForUser, releaseRouteForUser} from "../../actions/routeLockActions";
import {lockActionTypeMessages} from "../../constants/messages";

const mapStateToProps = (state, ownProps) => {
    return {
        enrollmentInfo: state.user.enrollmentInfo,
        ...state,
        ...ownProps
    }
};

class CareerServicesCurriculum extends Component {
    constructor(props) {
        super(props);
        const snameKey = 'CS_CURRICULUM_SURVEY_NAME';
        const savedSurveyName = window.localStorage.getItem(snameKey);
        this.state = {
            formIsVisible: false,
            surveyName: (props.location.state && props.location.state.surveyName) || (props.location && props.location.surveyName) || savedSurveyName,
            snameKey: snameKey,
            ignoreSteps: [] 
        }
        // these are the survey formsteps to be ignored, all relate to feedback on profile coaches that we are no longer collecting in this survey
        this.ignoreSteps = {
            ptfsf: [
                16, 17, 18, 19
            ],
            ftfsf: [
                14, 15, 16, 17
            ],
            dataViz: [
                16, 17, 18, 19
            ],
            uxui: [
                16, 17, 18, 19
            ],
            cyber: [] // none yet, placeholder
        }
        this.props.removeNav();

        if(this.state.surveyName){
            window.localStorage.setItem(snameKey, this.state.surveyName);
        }
    }

    componentWillUnmount(){
        window.localStorage.removeItem(this.state.snameKey);
    }

    componentDidMount(){
        const {dispatch, location} = this.props;
        const { programType } = this.props.enrollmentInfo.course.cohort.program;
        dispatch(lockRouteForUser(location.pathname, lockActionTypeMessages.CS_CURRICULUM_SURVEY));
        if (programType.name.includes('Full Stack Flex')) {
            if(programType.name.includes('Full-Time')){
                this.setState({ignoreSteps: this.ignoreSteps.ftfsf});
            } else {
                this.setState({ignoreSteps: this.ignoreSteps.ptfsf});
            }
        }
        if (programType.name.includes('Data V')) {
            this.setState({ignoreSteps: this.ignoreSteps.dataViz});
        }
        if (programType.name.includes('UX')) {
            this.setState({ignoreSteps: this.ignoreSteps.uxui});
        }
        if (programType.name.includes('Cyber')) {
            this.setState({ignoreSteps: this.ignoreSteps.cyber});
        }
    }

    changeFormVisibility = () => {
        this.setState({
            formIsVisible: true
        })
    }

    onSuccess = (stepsList) => {
        this.props.dispatch(releaseRouteForUser());
        this.props.history.replace('/cscurriculumsuccess');
    }
  
    render() {
        let content;
        const {surveyName} = this.state;
        if (!surveyName) {
            return (<div role="alert"><p className="context-error">Survey name not specified.</p></div>);  
        }
        if (!this.state.formIsVisible) {
            content = <section className="page" data-content="NPSForm">
            <div className="row card-group">
                <div className="col-xs-12">
                    <div className="card">
                    <div>
                        <p>Thank you for taking the time to give us feedback on our career services curriculum. Your input is important to us to provide you with everything you need to become Employer Competitive. Please take a few minutes to give us your thoughts as we continuously improve our service
                        </p>
                    </div>
                    <button className="btn-secondary btn-small"
                            onClick={() => this.changeFormVisibility()}>Start the Survey
                    </button>
                    </div>
                </div>
            </div>
            </section>
        } else {
            content = <FormComponent {...this.props} 
                        formName={surveyName}
                        onSuccessCallback={this.onSuccess}
                        ignoreSteps={this.state.ignoreSteps}
                        />
        }
   
        return (content)
    }
}

export const CAREERSERVICESCURRICULM = CareerServicesCurriculum;

export default withRouter(connect(mapStateToProps)(CareerServicesCurriculum));
