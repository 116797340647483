import { get, debounce } from 'lodash'

import { userActions } from '../../actions/userActions'

// safely fetch token, if its there, ping nexus to see if its valid via action. action returns a bool
export async function getAndValidateToken(props) {
    const token = get(props, 'authToken', null)
    if(token){
        const isTokenValid = await userActions.validateUserNexusSession(token)
        if(isTokenValid){
            return true
        } else {
            return false
        }
    } else{
        return false
    }
}

export const debouncedGetAndValidateToken = debounce(getAndValidateToken, 60000, { leading: true, trailing: false}) // one minute debounce, hit the API on the leading edge of that

export const notValidRenderer = (dispatch) => {
    window.alert("Your session has expired, please log in again.") // pause till user acknowledges logout
    dispatch(userActions.logout()) // log out via redux
}