export const sessionCreateValidation = {
    sessionName: 'Session Title is required',
    chapter: 'Session Chapter Number is required',
    sessionDate: 'Session Date is required',
    sessionStartTime: 'Session Start Time is required',
    sessionEndTime: 'Session End Time is required',
    sessionStartTime_meridian: 'Session Start Time AM/PM is required',
    sessionEndTime_meridian: 'Session End Time AM/PM is required'
};

export const sessionEditValidation = {
    sessionName: 'Session Title is required',
    chapter: 'Session Chapter Number is required',
    sessionDate: 'Session Date is required',
    sessionStartTime: 'Session Start Time is required',
    sessionEndTime: 'Session End Time is required',
    sessionStartTime_meridian: 'Session Start Time AM/PM is required',
    sessionEndTime_meridian: 'Session End Time AM/PM is required'
};

