import React from 'react'
import Icon from '../Icon';
import {careerServiceResourceLink} from "../../constants/careerConstants";

export default function RenderLastResourceBullets({state, showCareerServicesEvents}) {
  return (
      <ul className="bulleted">
          <li>
              <a href={careerServiceResourceLink} target="_blank" rel="noreferrer noopener" aria-label="Link opens in new window"><Icon icon="book"/> Career Resources &amp; Support</a>
              <p>(Link opens in new window)</p>
          </li>
          { showCareerServicesEvents ? (
              <li>
                  <a href="https://careerservicesonlineevents.splashthat.com" target="_blank" rel="noreferrer noopener" aria-label="Link opens in new window"><Icon icon="calendar-alt"/> CS Online Events</a>
                  <p>(Link opens in new window)</p>
              </li>
          ) : null }
          {/* Example Excel download */}
          {/* <li>
              <a href="link-to-excel-file" target="_blank" rel="noreferrer noopener" aria-label="Download the file"><Icon icon="file-excel"/> Excel File</a>
              <p>(Download file)</p>
          </li> */}
      </ul>
  )
}
