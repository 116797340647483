import React from 'react';
import useAxios from 'axios-hooks';
import {connect} from 'react-redux';
import askBcsLogo from "./ask-bcs-logo.svg";
import askBcsLogoDark from './ask-bcs-logo-black.svg'
import askBcsIcon from './ask-bcs-icon-32px.png'
import { isStudent } from "../../helpers/roles";
import { get } from 'lodash'

const mapStateToProps = (state, ownProps) => {
    return {
        cohortId: get(state, 'activeEnrollment.course.cohortId'),
        isStudentRole: isStudent(get(state, 'user.enrollmentInfo.courseRole.courseRoleCode')),
        ...ownProps
    }
};
export const AskBCSDeepLinkContext = React.createContext({ loading: false, deepLink: '', error: undefined });
export const AskBCSProvider = connect(mapStateToProps)(AskBCSProviderContainer);
const AskBCS = ({ onLoading = () => null, onError = () => null, onDeepLinkAvailable, children = null }) => {
    const { loading, deepLink, error } = React.useContext(AskBCSDeepLinkContext);

    if (typeof onLoading !== 'function') {
        throw new Error('Invalid handler onLoading')
    }
    if (typeof onError !== 'function') {
        throw new Error('Invalid handler onError')
    }
    if (typeof onDeepLinkAvailable !== 'function') {
        throw new Error('Invalid handler onDeepLinkAvailable')
    }
    if (loading) return onLoading({ dataBcsProp: { 'data-bcs': 'ask-bcs-loading' } }) || children;
    if (error) return onError({ dataBcsProp: { 'data-bcs': 'ask-bcs-error' } }) || children;
    if (deepLink) {
        return onDeepLinkAvailable({ deepLink, askBcsLogo, askBcsLogoDark, askBcsIcon, dataBcsProp: { 'data-bcs': 'ask-bcs-deep-link' } });
    }

    return children;
};
export default AskBCS;

function AskBCSProviderContainer(props) {
    if (!props.isStudentRole) {
        return props.children;
    }
    return AskBCSDeepLinkProvider(props);
}

const useAskBCSDeepLink = ({ cohortId }) => useAxios({
    url: "/broker/askBcsDeepLink",
    method: 'POST',
    data: { cohortId },
});

function AskBCSDeepLinkProvider({ cohortId, children }) {
    const [{ loading, error, data }] = useAskBCSDeepLink({ cohortId })

    return (
        <AskBCSDeepLinkContext.Provider value={{ loading, deepLink: (data && data.deepLink) || '', error }}>
            {children}
        </AskBCSDeepLinkContext.Provider>
    )
}
