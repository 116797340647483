import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import ReactGA from 'react-ga';
import Loading from '../../components/Loading';
import Alert from '../../components/Alert';
import {isEmpty} from 'lodash';
import {fetchSingleSession} from '../../actions/sessionActions';
import ExternalLink from '../../components/ExternalLink'

const mapStateToProps = (state, ownProps) => {
    return {
        sessions: state.sessions,
        ...ownProps
    }
}

class PanoptoVideoView extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading: true,
            videoUrl: null
        }
        ReactGA.event({
            category: 'Panopto Video',
            action: 'Video View',
        });
    }

    async componentDidMount(){
        const {match, dispatch} = this.props;
        const sessionId = parseInt(match.params.sessionId, 10);
        
        dispatch(fetchSingleSession(sessionId));  
    }

    componentWillReceiveProps(nextProps){

        const {match,sessions} = this.props;
        let videoObject = null;
        
        if( nextProps.sessions.selected !== sessions.selected )
        {
            if(!isEmpty(nextProps.sessions.selected)){
                videoObject = nextProps.sessions.selected.session.videoUrlList.filter( ( element) => {
                return element.id.toString() === match.params.videoId} )[0]
            }
            this.setState({
                videoUrl: videoObject ? videoObject.url : null,    
                loading: false
            })
        }
    }

    renderVideo = (videoUrl) => {
        const iframeStyles = {
            height: '710px',
            width: '100%'
        };
        // zoom does not support an iFrame embed - it works "sometimes". Documentation requires us to open in a new tab
        if(videoUrl.includes('zoom')){
            return (<ExternalLink to={videoUrl} text="Please click here to view the Zoom Video" title="Zoom Video Link"></ExternalLink>)
        } else {
            return (<iframe allowFullScreen="0" frameBorder="0" style={iframeStyles} title='panopto-video' src={videoUrl}></iframe>)
        }
    }

    render()
    {
        const {loading, videoUrl} = this.state;
        const sessionId = this.props.match.params.sessionId
       
        return(
            <DocumentTitle title='Watch Video | Bootcamp Spot'>
                <section className="page" data-component="panopto-video-watch">
                    <div className="row">
                        <div className="col-xs-12">
                            <Link to={`/sessions/${sessionId}`} className="btn btn-secondary btn-small">Return to Session Details</Link>
                        </div>
                    </div>
                    <div className="row margin-t-3">
                        <div className="col-xs-12 video-details card">
                            {loading ? (
                                <Loading>Loading Video</Loading>
                            ) : (
                                videoUrl ? (
                                    this.renderVideo(videoUrl)
                                ) : (
                                    <Alert type="warn">There was an error fetching the details for this video. Try refreshing the page. If the problem persists, reach out to support</Alert>
                                )
                            )}
                        </div>
                    </div>
                </section>
            </DocumentTitle>
        )
    }

}
export const PANOPTOVIDEOVIEW = PanoptoVideoView ; 

export default connect(mapStateToProps) (PanoptoVideoView);
