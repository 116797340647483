import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    filterDataByEventType,
    filterDataByCategory,
    getCalendarEventClass,
    getCalendarEventLink,
    calendarBuild
} from "../../helpers/calendar";
import Calendar from '../../components/Calendar/Calendar';
import moment from "moment/moment";
import queryString from 'query-string';

import {isEqual} from "lodash";

const mapStateToProps = (state) => {
    return {
        enrollmentInfo: state.user.enrollmentInfo,
        calendarData: state.dashboard.dashboardCalendar,
    }
};

class CalendarContainer extends Component {
    constructor(props) {
        super(props);
        const currentMonth = moment();
        const calendarStartDate = moment(this.props.enrollmentInfo.course.startDate).subtract(3, 'months');
        const calendarEndDate = moment(this.props.enrollmentInfo.course.endDate).add(3, 'month');
        const calendarSelectedDate = currentMonth.isBetween(calendarStartDate, calendarEndDate) ? currentMonth : calendarStartDate;
        const calendarStartYearMonth = calendarSelectedDate.format('YYYY-MM');
        const currentCalendar  = calendarBuild(this.props.calendarData,'all')

        this.state = {
            calendarData : this.props.calendarData,
            filteredData: currentCalendar.filteredData,
            eventTypes: currentCalendar.eventTypes,
            sessionCategories:  currentCalendar.sessionCategories,
            courseworkCategories:  currentCalendar.courseworkCategories,
            selectedEventType: 'all',
            selectedSessionCategory: 'all',
            selectedCourseworkCategory: 'all',
            selectedMonth: calendarStartYearMonth,
            calendarStartDate: moment(this.props.enrollmentInfo.course.startDate).subtract(3, 'months'),
            calendarEndDate: moment(this.props.enrollmentInfo.course.endDate).add(3, 'month'),
            calendarStartYearMonth
        }
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if (!isEqual(props.calendarData,state.calendarData)){
            return {
                ...calendarBuild(props.calendarData,state.selectedEventType),
                calendarData : props.calendarData
            };
        }
        return null
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.filterCalendar();
        }
    }

    updateStateAndParams = (e, month) => {
        let selectedFilter, selectedChoice;
        if (e) {
            e.target.blur();
            selectedFilter = e.target.getAttribute('data-selected-filter');
            selectedChoice = e.target.value;
        } else {
            selectedFilter = 'selectedMonth';
            selectedChoice = month ? month : this.state.calendarStartYearMonth;
        }
        
        this.setState({ [`${selectedFilter}`]: selectedChoice }, () => {
            this.pushParams();
        });
    }

    pushParams = () => {
        const {
            selectedEventType,
            selectedSessionCategory,
            selectedCourseworkCategory,
            selectedMonth
        } = this.state;
  
        const routeParams = {
          selectedEventType,
          selectedCourseworkCategory,
          selectedSessionCategory,
          selectedMonth
        };
  
        const stringifiedParams = queryString.stringify(routeParams);
        this.props.history.push(`?${stringifiedParams}`);
    }

    filterCalendar = () => {
        if (!this.props.location.search) {
            this.resetCalendarFilters();
            return
        }
        const parsed = queryString.parse(this.props.location.search)
        const {calendarData} = this.state;
        let {
            selectedCourseworkCategory,
            selectedEventType,
            selectedSessionCategory,
            selectedMonth,
        } = parsed;
        let filteredData;

        if (selectedEventType !== 'all') {
            filteredData = filterDataByEventType(calendarData, selectedEventType);       
        }

        if (selectedCourseworkCategory !== 'all') {
            const data = calendarData.filter((item) => item.eventType.code === "coursework");
            filteredData = filterDataByCategory(data, selectedCourseworkCategory, "coursework");
            selectedEventType = 'coursework'
        }

        if (selectedSessionCategory !== 'all') {
            const data = calendarData.filter((item) => item.eventType.code === "session");
            filteredData = filterDataByCategory(data, selectedSessionCategory, "session");
            selectedEventType = 'session'
        }

        this.setState({
            selectedMonth,
            selectedCourseworkCategory,
            selectedSessionCategory,
            selectedEventType,
            filteredData: filteredData ? filteredData : calendarData,
        })
    }

    renderEventFilter = () => {
        const { selectedEventType } = this.state;
        return (
            <div className="col-xs-12 col-md-6 col-gutter-lr">
                <label htmlFor="eventTypes">Event Type</label>
                <select
                    htmlFor="eventTypes"
                    aria-label="event types"
                    data-selected-filter="selectedEventType"
                    id="eventTypes"
                    value={selectedEventType}
                    onChange={this.updateStateAndParams}
                    onBlur={(e) => {
                        if (e.currentTarget !== e.target) {
                            this.updateStateAndParams();
                        }
                    }}
                >
                    {this.state.eventTypes.length > 1 ? (
                        <option value={"all"} key={0}>
                            All Types
                        </option>
                    ) : null}
                    {this.state.eventTypes.map((eventType) => {
                        return (
                            <option key={eventType.code} value={eventType.code}>
                                {eventType.name}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }

    renderSessionFilter = () => {
        const { selectedSessionCategory, sessionCategories, selectedEventType } = this.state;
        return (
            <div className="col-xs-12 col-md-6 col-gutter-lr">
                <label htmlFor="sessionTypes">Session Type</label>
                <select
                    htmlFor="sessionTypes"
                    aria-label="session types"
                    id="sessionTypes"
                    data-selected-filter="selectedSessionCategory"
                    disabled={selectedEventType === "coursework"}
                    className={
                        selectedEventType === "coursework"
                            ? "input-disabled"
                            : ""
                    }
                    value={selectedSessionCategory}
                    onChange={this.updateStateAndParams}
                    onBlur={(e) => {
                        if (e.currentTarget !== e.target) {
                            this.updateStateAndParams();
                        }
                    }}
                >
                    {sessionCategories.length > 1 ? (
                        <option value={"all"} key={0}>
                            All Types
                        </option>
                    ) : null}
                    {sessionCategories.map((category) => {
                        return (
                            <option key={category.code} value={category.code}>
                                {category.name}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }

    renderCourseworkFilter = () => {
        const { selectedCourseworkCategory, selectedEventType, courseworkCategories } = this.state;
        return (
            <div className="col-xs-12 col-md-6 col-gutter-lr">
                <label htmlFor="courseworkTypes">Coursework Type</label>
                <select
                    aria-label="filter by coursework type"
                    id="courseworkTypes"
                    data-selected-filter="selectedCourseworkCategory"
                    disabled={selectedEventType === "session"}
                    className={
                        selectedEventType === "session" ? "input-disabled" : ""
                    }
                    value={selectedCourseworkCategory}
                    onChange={this.updateStateAndParams}
                    onBlur={(e) => {
                        if (e.currentTarget !== e.target) {
                            this.updateStateAndParams();
                        }
                    }}
                >
                    {courseworkCategories.length > 1 ? (
                        <option value={"all"} key={0}>
                            All Types
                        </option>
                    ) : null}
                    {courseworkCategories.map((category) => {
                        return (
                            <option key={category.code} value={category.code}>
                                {category.name}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }

    renderCalendarMonthDropdown = () => {
        const { selectedMonth } = this.state;
        let monthOptions = [];
        let optionText = '';
        let currentMonth = moment.utc();
        const startDate = moment(this.props.enrollmentInfo.course.startDate).subtract(3, 'months');
        const endDate = moment(this.props.enrollmentInfo.course.endDate).add(3, 'month');
        let monthRange = endDate.startOf('month').diff(startDate.startOf('month'), 'months');

        for (let i = 0; i <= monthRange; i++) {
            if (startDate.get('month') === currentMonth.get('month') && startDate.get('year') === currentMonth.get('year')) {
                optionText = `This Month (${currentMonth.format('MMM YYYY')})`;
            } else {
                optionText = startDate.format('MMM YYYY');
            }
            monthOptions.push(<option key={i} value={startDate.format('YYYY-MM')}>{optionText}</option>)
            startDate.add(1, 'months');
        }

        return (
            <div className="col-xs-12 col-md-6 col-gutter-lr">
                <label htmlFor="filterByMonth">Filter By Month</label>
                <select
                    aria-label="filter by month"
                    id="filterByMonth"
                    data-selected-filter="selectedMonth"
                    value={selectedMonth}
                    onChange={this.updateStateAndParams}
                    onBlur={(e) => {
                        if (e.currentTarget !== e.target) {
                            this.updateStateAndParams();
                        }
                    }}
                >
                    {monthOptions}
                </select>
            </div>
        );
    }

    

    resetCalendarFilters = () => {
        let data = this.state.calendarData;
        this.setState({
            filteredData: data,
            selectedEventType: 'all',
            selectedSessionCategory: 'all',
            selectedCourseworkCategory: 'all',
            selectedMonth: this.state.calendarStartYearMonth
        })
    }

    render() {
        const { calendarStartDate, calendarEndDate, selectedMonth,filteredData} = this.state;
        return (
            <div className="col-xs-12 col-no-gutter">
                <div className="row">
                    <div className="col-xs-12">
                        <h2>Course Calendar</h2>
                    </div>
                </div>

                <div className="row">
                    {this.renderCalendarMonthDropdown()}
                    {this.renderEventFilter()}
                    {this.renderSessionFilter()}
                    {this.renderCourseworkFilter()}
                </div>

                <Calendar
                    data={filteredData}
                    startDate={calendarStartDate}
                    endDate={calendarEndDate}
                    selectedMonth={selectedMonth}
                    eventLinkCallback={getCalendarEventLink}
                    eventCssClassCallback={getCalendarEventClass}
                    selectedMonthChangedCallback={this.updateStateAndParams}
                />

                <div className="row">
                    <div className="col-xs-12 text-center">
                        <button className="btn-small btn-edit" onClick={this.resetCalendarFilters}>Reset Calendar</button>
                    </div>
                </div>
            </div>
        )
    }
}
export const CALENDARCONTAINER = CalendarContainer;

export default connect(mapStateToProps)(CalendarContainer);
