import React, {Component} from 'react';
import moment from 'moment';
import Loading from '../../../components/Loading';
import Alert from '../../../components/Alert';
import StudentTable from '../StudentTable';
import AdminTable from '../AdminTable';
import {isStudent} from '../../../helpers/roles';
import {
    fetchRemoteAttendanceRequests, deleteRemoteRequest, approveRemoteRequest, denyRemoteRequest,
    fetchAllRemoteAttendanceRequests
} from '../../../actions/attendanceActions';
import ReactGA from 'react-ga';
import { getRemoteRequestStatusText } from "../../../helpers/common";
import {patchEnrollmentList, setActiveEnrollment} from "../../../actions/enrollmentsActions";
import {connect} from 'react-redux';

const mapStateToProps = (state, ownProps) => {
    return {
        enrollmentList: state.enrollments.enrollmentList,
        ...ownProps
    }
};

export class RemoteAttendanceRequestManager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            remoteRequestsLoading: true,
            remoteAttendanceRequests: [],
            filteredRemoteAttendanceRequests: [],
            warningMessage: `You have not submitted any remote attendance requests for this course, when you do they can be managed here`,
            cancelling: false,
            cancelSuccess: false,
            denyRequest: false,
            cancelError: false,
            cohortList: [],
            working: false,
            requestsFilter: '',
            errorApproving: false
        }

    }

    componentWillReceiveProps(nextProps) {
        if (isStudent(this.props.roles) && nextProps.remoteAttendanceRequests !== this.props.remoteAttendanceRequests) {
            this.setState({
                remoteAttendanceRequests: nextProps.remoteAttendanceRequests,
                filteredRemoteAttendanceRequests: nextProps.remoteAttendanceRequests,
                remoteRequestsLoading: false
            })

        }
    }

    async componentDidMount() {
        if (!isStudent(this.props.roles)) {
            await this.refreshRemoteAttendanceRequests();
        }
        if (this.props.allowedStaff) {
            let filter = {
                target: {
                    value: "Pending"
                }
            };
            this.filterRemoteAttendanceRequests(filter);
        }
    }

    refreshRemoteAttendanceRequests = async () => {
        this.setState({remoteRequestsLoading: true});
        if (this.props.generalManager) {
            let result = await fetchAllRemoteAttendanceRequests();
            this.setState({
                remoteAttendanceRequests: result.data,
                remoteRequestsLoading: false
            })
        } else {
            let payload = {
                enrollmentId: this.props.enrollmentId
            };
            let result = await fetchRemoteAttendanceRequests(payload);
            this.setState({
                remoteAttendanceRequests: result.data,
                remoteRequestsLoading: false
            })
        }
    }

    async cancelRemoteAttendanceRequest(e, item) {
        e.preventDefault();
        if (window.confirm(`Are you sure you want to cancel the remote attendance request for ${item.session.name} on ${moment(item.session.startTime).format('MMMM D, YYYY')}`)) {
            this.setState({cancelling: true, cancelSuccess: false, cancelError: false});

            try {
                let payload = {
                    id: item.remoteAttendanceRequest.id
                };
                const axiosResult = await deleteRemoteRequest(payload);
                const {dispatch, enrollmentList} = this.props;
                const modifiedEnrollment = axiosResult.data.enrollment;
                const patchEnrollmentListPayload = {
                    currentEnrollmentList: enrollmentList,
                    enrollment: modifiedEnrollment
                };

                dispatch(patchEnrollmentList(patchEnrollmentListPayload));
                dispatch(setActiveEnrollment(modifiedEnrollment));

                ReactGA.event({
                    category: 'Remote Attendance Request',
                    action: 'Cancel Request',
                });
                if (this.props.cancelRefreshCB) {
                    this.props.cancelRefreshCB();
                }
                this.setState({cancelSuccess: true, cancelling: false})
            } catch (err) {
                this.setState({cancelError: true, cancelling: false})
            }
        }

    }

    filterRemoteAttendanceRequests(e) {
        let baseRemoteRequestList = this.state.remoteAttendanceRequests;
        if (e.target.value === 'All') {
            this.setState({
                filteredRemoteAttendanceRequests: baseRemoteRequestList,
                requestsFilter: e.target.value
            });
        } else {
            this.setState({remoteRequestsLoading: true});
            const results = baseRemoteRequestList.filter((item) => {
                let attendanceStatus = getRemoteRequestStatusText(item);
                if (attendanceStatus === e.target.value) {
                    return item;
                } else {
                    return false
                }
            });
            if (!results.length) {
                this.setState({
                    warningMessage: `You have no ${e.target.value} remote attendance requests for this course, when you do they can be managed here`,
                    remoteRequestsLoading: false,
                    filteredRemoteAttendanceRequests: [],
                    requestsFilter: e.target.value
                })
            } else {
                this.setState({
                    remoteRequestsLoading: false,
                    filteredRemoteAttendanceRequests: results,
                    requestsFilter: e.target.value
                })
            }
        }
    }

    async approveRequest(e, item) {
        e.preventDefault();
        this.setState({working: true, cancelSuccess: false})
        let response;
        try {
            let payload = {
                id: null,
                remoteAttendanceRequestId: item.remoteAttendanceRequest.id,
            };
            response = await approveRemoteRequest(payload);
            if(response.error){
                throw new Error(response)
            }
            ReactGA.event({
                category: 'Remote Attendance Request',
                action: 'Approve Request',
            });
            this.setState({working: false});

            await this.refreshRemoteAttendanceRequests();
            let filter = {
                target: {
                    value: this.state.requestsFilter !== '' ? this.state.requestsFilter : "Pending"
                }
            };
            this.filterRemoteAttendanceRequests(filter);
        } catch (err) {
            this.setState({working: false, errorApproving: true, error: response});
            await this.refreshRemoteAttendanceRequests();
            ReactGA.event({
                category: 'Remote Attendance',
                action: 'Remote Request : Approve Button :: Approval Failure',
                label: JSON.stringify(item) + JSON.stringify(err)
             });
        }
    }

    async denyRequest(e, item) {
        e.preventDefault();
        this.setState({working: true, denyRequest: false})
        try {
            let payload = {
                id: null,
                remoteAttendanceRequestId: item.remoteAttendanceRequest.id
            };
            await denyRemoteRequest(payload);
            ReactGA.event({
                category: 'Remote Attendance Request',
                action: 'Deny Request',
            });
            this.setState({working: false, denyRequest: true});

            await this.refreshRemoteAttendanceRequests();
            let filter = {
                target: {
                    value: this.state.requestsFilter !== '' ? this.state.requestsFilter : "Pending"
                }
            };
            this.filterRemoteAttendanceRequests(filter);
        } catch (err) {
            console.error(err);
        }
    }

    render() {
        let errorMessage;
        const {error} = this.state
        if(this.state.error){
            if(error.errorMessage.includes("Attendance already set")){
                errorMessage = "attendance for this session has already been set for this student"
            }
        }
        return (
            <div className="absence-requests" data-template="remote-attendance-request-manager">
                <div className="row">
                    <div className="col-xs-12 col-md-8">
                        <h1>Remote Attendance Requests</h1>
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <label htmlFor="remoteAttendanceRequests">Show</label>
                        <select aria-label="remote attendance filter" id="remoteAttendanceRequests" value={this.state.requestsFilter}
                                disabled={!this.state.remoteAttendanceRequests.length} 
                                onBlur={(e) => {this.filterRemoteAttendanceRequests(e)}}
                                onChange={(e) => {this.filterRemoteAttendanceRequests(e)}}
                        >
                            <option value="All">All</option>
                            <option value="Pending">Pending</option>
                            <option value="Approved">Approved</option>
                            <option value="Denied">Denied</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        {this.state.remoteRequestsLoading ? (
                            <Loading/>
                        ) : (
                            <div className="absence-listing-table">
                                {this.state.denyRequest === true &&
                                <Alert type="success">
                                    Remote Attendance request has been denied
                                </Alert>
                                }
                                {this.state.cancelSuccess === true &&
                                <Alert type="success">
                                    Remote Attendance request has been cancelled
                                </Alert>
                                }
                                {this.state.cancelError &&
                                <Alert type="error">
                                    Remote Attendance request could not be cancelled - please try again. If the problem persists,
                                    please contact BCS Support
                                </Alert>
                                }
                                {this.state.errorApproving &&
                                <Alert type="error">
                                    The remote attendance approval could not be completed because {errorMessage || "of a system error"}. Please contact support for assistance.
                                </Alert>
                                }
                                {this.state.filteredRemoteAttendanceRequests.length ? (
                                    <React.Fragment>
                                        {this.props.allowedStaff &&
                                            <AdminTable
                                                cancelling={this.state.cancelling}
                                                working={this.state.working}
                                                handleApprove={this.approveRequest.bind(this)}
                                                handleDeny={this.denyRequest.bind(this)}
                                                filteredRemoteAttendanceRequests={this.state.filteredRemoteAttendanceRequests}
                                            />
                                        }
                                        {isStudent(this.props.roles) &&
                                            <StudentTable
                                                cancelling={this.state.cancelling}
                                                handleCancel={this.cancelRemoteAttendanceRequest.bind(this)}
                                                filteredRemoteAttendanceRequests={this.state.filteredRemoteAttendanceRequests}
                                            />
                                        }
                                    </React.Fragment>
                                ) : (
                                    <Alert type="warn">
                                        {this.state.warningMessage}
                                    </Alert>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(RemoteAttendanceRequestManager);
