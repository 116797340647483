import { lockActionTypes } from '../constants/actionTypes';
import { fulfilled } from '../helpers/asyncActionGenerator';

export const lockRouteForUser = (route, message) => {
    return dispatch => dispatch(fulfilled(lockActionTypes.ROUTE_LOCK, {message: message, path: route}))
}

export const releaseRouteForUser = () => {
    return dispatch => dispatch(fulfilled(lockActionTypes.ROUTE_LOCK_RELEASE))
}
