import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getAttendanceStatusText} from "../../helpers/common";
import {remoteAllowanceWarn} from "../../helpers/remoteAllowanceWarn";
import {assignmentContextTypes} from "../../constants/dataTypes";
import {isStudent} from '../../helpers/roles';
import moment from 'moment';
import get from 'lodash/get';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        graduationRequirements: state.user.enrollmentInfo.course.graduationRequirements,
        hideRemoteAttendance: get(state,'user.enrollmentInfo.course.cohort.cohortUiControl.hideRemoteAttendance',true),
        remoteAttendanceCount: state.user.enrollmentInfo.remoteAttendanceCount,
        pendingRemoteAttendanceRequestCount: state.user.enrollmentInfo.pendingRemoteAttendanceRequestCount,
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
    }
};

class AttendanceInfo extends Component {
    render() {
        const {
            graduationRequirements, remoteAttendanceCount, pendingRemoteAttendanceRequestCount, attendanceHistory,
            title, showWarning, ssmS, topLevel, remoteAttendanceRequests, activeRole, hideRemoteAttendance
        } = this.props;
        const {maxRemoteAttendance, maxAbsence} = graduationRequirements;

        const attendanceHistoryAcademic = attendanceHistory.filter(ah => ah.context.contextCode === assignmentContextTypes.ACADEMIC);
        const absencesCount = attendanceHistoryAcademic.filter(item => getAttendanceStatusText(item) === 'Absent').length;
        const presentsCount = attendanceHistoryAcademic.filter(item => getAttendanceStatusText(item) === 'Present' || getAttendanceStatusText(item) === 'Exception').length;
        const remoteRequests = remoteAttendanceCount + pendingRemoteAttendanceRequestCount;
        const remainingAbsences = (maxAbsence - absencesCount > 0)? maxAbsence - absencesCount:0;

        let remoteAttendance = remoteAttendanceCount;
        let remainingRemoteAttendances =  (maxRemoteAttendance - remoteRequests > 0) ? maxRemoteAttendance - remoteRequests : 0;
        if (!isStudent(activeRole) && !!remoteAttendanceRequests){
            const approvedOrPendingRemoteRequests = remoteAttendanceRequests.filter(
                rar => rar.remoteAttendanceRequest !== null
                    && rar.absence == null
                    && rar.remoteAttendanceDenial == null
                    && (!rar.remoteAttendanceApproval || (!!rar.remoteAttendanceApproval && moment(rar.session.endTime).isBefore(moment())))
            ).length;

            const remoteAttendanceArr = attendanceHistory.filter(rar => rar.attendance !== null && rar.attendance.remote === true && moment(rar.session.endTime).isBefore(moment()));
            remoteAttendance = remoteAttendanceArr.length;

            const remoteAttendanceWithOutRequest = remoteAttendanceArr.filter(rar => rar.remoteAttendanceRequest === null).length;
            const remaining = maxRemoteAttendance - (approvedOrPendingRemoteRequests + remoteAttendanceWithOutRequest);
            remainingRemoteAttendances = (remaining > 0)? remaining:0;
        }
        const approvedRemoteRequestsInTheFuture = remoteAttendanceRequests.filter(
            rar => rar.remoteAttendanceRequest !== null
                && rar.absence == null
                && rar.remoteAttendanceDenial == null
                && rar.attendance !== null
                && rar.remoteAttendanceApproval !== null && moment(rar.session.endTime).isAfter(moment())
        ).length;

        // Takes into account the approved remote attendance requests that are in the future, meaning,
        // they still don't count as "present remote" but do count against the student's remaining remote attendances
        remainingRemoteAttendances -= approvedRemoteRequestsInTheFuture;
        remainingRemoteAttendances = (remainingRemoteAttendances > 0)? remainingRemoteAttendances:0;


        const title1 = "Overall";
        const title2 = "Absence Details";
        const title3 = "Remote Details";

        return (
            <React.Fragment>
                <div className="col-xs-12 col-gutter-lr">
                    {topLevel? <h1>{title}</h1> : <h2>{title}</h2>}
                </div>

                <div className="col-xs-12">
                    <div className="col-xs-12">
                        {topLevel? <h2>{title1}</h2>:<h3>{title1}</h3>}
                    </div>
                    <div className="card">
                        <div className="row around-xs text-center">
                            <div className="col-xs-12 col-md-4">
                                <p className="title">Present</p>
                                <p className="headline">{presentsCount}</p>
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <p className="title">Absent</p>
                                <p className="headline">{absencesCount}</p>
                            </div>
                            {
                                !hideRemoteAttendance &&
                                <div className="col-xs-12 col-md-4">
                                    <p className="title">Present Remotely</p>
                                    <p className="headline">{remoteAttendance}</p>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="col-xs-12">
                        {topLevel? <h2>{title2}</h2>:<h3>{title2}</h3>}
                    </div>
                    <div className="card margin-t-2">
                        <div className="row around-xs text-center">
                            <div className="col-xs-12 col-md-6">
                                <p className="title">Allowable Absences</p>
                                <p className="headline">{maxAbsence}</p>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <p className="title">Remaining Absences</p>
                                <p className="headline">{remainingAbsences}</p>
                            </div>
                        </div>
                    </div>
                    {
                        !hideRemoteAttendance &&
                        <div className="remoteDetails">
                            <div className="col-xs-12">
                                {topLevel? <h2>{title3}</h2>:<h3>{title3}</h3>}
                            </div>
                            <div className="card margin-t-2">
                                <div className="row around-xs text-center">
                                    <div className="col-xs-12 col-md-4">
                                        <p className="title">Allowable Remote Attendance</p>
                                        <p className="headline">{maxRemoteAttendance}</p>
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                        <p className="title">Pending Remote Attendance</p>
                                        <p className="headline">{pendingRemoteAttendanceRequestCount}</p>
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                        <p className="title">Remaining Remote Attendance</p>
                                        <p className="headline">{remainingRemoteAttendances}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {!hideRemoteAttendance && !!showWarning && remainingRemoteAttendances === 0 && remoteAllowanceWarn(ssmS)}
            </React.Fragment>
        )
    }
}

export const ATTENDANCEINFO = AttendanceInfo
export default withRouter(connect(mapStateToProps)(AttendanceInfo));
