import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import {NavLink} from 'react-router-dom'
import Icon from '../Icon';
import { isStudent, isInstructor, isTA, isSSM, isAdmin, isCD } from '../../helpers/roles';
import {getPaymentPortalLink, setPaymentPortalCookie} from "../../helpers/utils";
import get from 'lodash/get';
import {resetEnrollmentInfo} from "../../actions/enrollmentsActions";
import {userActions} from '../../actions';

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        hideRemoteAttendance: get(state,'user.enrollmentInfo.course.cohort.cohortUiControl.hideRemoteAttendance',true),
        ...ownProps
    }
};

class Navigation extends Component {

    constructor(props) {
        super(props)
        this.state = {
            courses: this.props.enrollments.enrollmentList || [],
            course: {title: 'Full Stack Web Development', index: 0}
        }
    }

    logout = () => {
        const {dispatch} = this.props;
        dispatch(userActions.logout());
    }

    renderPaymentPortalLink = () => {
        const url = getPaymentPortalLink();
        const universityId = this.props.user.enrollmentInfo.course.cohort.program.university.id;
        return (
            <a href={`${url}/billing?ref=${universityId}`} target="_blank" rel="noopener noreferrer" role="menuitem" aria-label="Billing" title="Billing"
                  onClick={() => setPaymentPortalCookie(this.props.user.data.authToken)}>
                <Icon icon="list-alt" options="fa-fw" ariaHidden="false"/> Billing
            </a>
        )
    }

    render() {
        let activeRole = '';
        let programTypeName = null;
        if (this.props.user.enrollmentInfo) {
            activeRole = this.props.user.enrollmentInfo.courseRole.courseRoleCode;
            programTypeName = this.props.user.enrollmentInfo.course.cohort.program.programType.name;
        }
        
        const hideCareerServices = get(this.props,'user.enrollmentInfo.course.cohort.cohortUiControl.hideCareerServices',false);
        const {hideRemoteAttendance} = this.props;
        return (
            <nav
                className={`bcs-navigation ${this.props.active ? 'active' : ''} ${this.props.navState.condenseNav ? 'condensed' : ''}`}
                data-component="navigation"
            >
                <div className="skip-to-content">
                    <a href="#main-content" className="skip-to-content">Skip To Content</a>
                </div>
                <div className="course-list">
                    <nav>
                        <ul>
                            {!this.props.user.singleEnrollment ? (
                                <li>
                                    <NavLink
                                        to="/universities"
                                        activeClassName="active"
                                        role="menuitem"
                                        tabIndex="0"
                                        aria-label="Course Select"
                                        title="Universities"
                                        onClick={() => {
                                            this.props.dispatch(resetEnrollmentInfo())
                                            this.props.dispatch(userActions.resetCohorts())
                                        }}
                                    >
                                        <Icon icon="chevron-left" options="fa-fw" ariaHidden="false" /> Course Select
                                    </NavLink>
                                </li>
                            ) : null }
                            <li>
                                <NavLink
                                    exact to="/"
                                    activeClassName="active"
                                    role="menuitem"
                                    tabIndex="0"
                                    aria-label="Dashboard"
                                    title="Dashboard"
                                >
                                    <Icon icon="list-alt" options="fa-fw" ariaHidden="false" /> Dashboard
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/sessions" activeClassName="active" role="menuitem" aria-label="Class Sessions" title="Sessions">
                                    <Icon icon="book" options="fa-fw" ariaHidden="false"/> Sessions
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/coursework" activeClassName="active" role="menuitem" aria-label="Coursework" title="Coursework">
                                    <Icon icon="edit" options="fa-fw" ariaHidden="false"/> Coursework
                                </NavLink>
                            </li>
                            {
                                !hideCareerServices &&
                                <li>
                                    <NavLink to="/career-services" activeClassName="active" role="menuitem" aria-label="Career Services" title="Career Services">
                                        <Icon icon="briefcase" options="fa-fw" ariaHidden="false"/> Career Services
                                    </NavLink>
                                </li>
                            }

                           {isStudent(activeRole) ? (
                                <li>
                                    <NavLink to="/grades" activeClassName="active" role="menuitem" aria-label="Grades" title="Grades">
                                        <Icon icon="graduation-cap" options="fa-fw" ariaHidden="false"/> Grades
                                    </NavLink>
                                </li>
                            ) : null}

                            {isInstructor(activeRole) || isTA(activeRole) || isSSM(activeRole) || isAdmin(activeRole) || isCD(activeRole) ? (
                                <li>
                                    <NavLink to="/gradebook" activeClassName="active" role="menuitem" aria-label="Gradebook" title="Gradebook">
                                        <Icon icon="graduation-cap" options="fa-fw" ariaHidden="false"/> Gradebook
                                    </NavLink>
                                </li>
                            ) : null}

                            {isStudent(activeRole) ? (
                                <li>
                                    <NavLink to="/attendance" activeClassName="active" role="menuitem" aria-label="Attendance" title="Attendance">
                                        <Icon icon="calendar-check" options="fa-fw" ariaHidden="false"/> Attendance
                                    </NavLink>
                                </li>
                            ) : null}
                            {!hideRemoteAttendance && (isInstructor(activeRole) || isTA(activeRole) || isSSM(activeRole) || isAdmin(activeRole)) ? (
                                <li>
                                    <NavLink to="/remote-attendance-requests" activeClassName="active" role="menuitem" aria-label="Remote Requests" title="Remote Requests" data-test="remote-requests">
                                        <Icon icon="calendar-check" options="fa-fw" ariaHidden="false"/> Remote Requests
                                    </NavLink>
                                </li>
                            ) : null}
                            <li>
                                <NavLink activeClassName="active" to="/videos" aria-label="BCS Video Library" role="menuitem" title="Video Library">
                                    <Icon icon="film" options="fa-fw" ariaHidden="false"/> Video Library
                                </NavLink>
                            </li>

                            {isStudent(activeRole) ? (
                                <li>
                                    <NavLink to="/class-contacts" activeClassName="active" role="menuitem" aria-label="Class Contacts" title="Class Contacts">
                                        <Icon icon="users" options="fa-fw" ariaHidden="false"/> Class Contacts
                                    </NavLink>
                                </li>
                            ) : null}
                            {isInstructor(activeRole) || isSSM(activeRole) || isAdmin(activeRole) || isTA(activeRole)? (
                                <li>
                                    <NavLink to="/weekly-feedback-report" activeClassName="active" role="menuitem" aria-label="Weekly Feedback Report" title="Weekly Feedback">
                                        <Icon icon="chart-line" options="fa-fw" ariaHidden="false"/> Weekly Feedback
                                    </NavLink>
                                </li>
                            ) : null}
                            {isStudent(activeRole) ? (
                                <li>
                                    <NavLink to={`/students/${this.props.user.enrollmentInfo.id}`} activeClassName="active" role="menuitem" aria-label="My Profile" title="My Profile">
                                        <Icon icon="user" options="fa-fw" ariaHidden="false"/> My Profile
                                    </NavLink>
                                </li>
                            ) : null}
                            {isInstructor(activeRole) || isCD(activeRole) || isSSM(activeRole) || isAdmin(activeRole) || isTA(activeRole) ? (
                                <li>
                                    <NavLink to="/students" activeClassName="active" role="menuitem" aria-label="Student Profiles" title="Student Profiles">
                                        <Icon icon="users" options="fa-fw" ariaHidden="false"/> Student Profiles
                                    </NavLink>
                                </li>
                            ) : null}
                            {isInstructor(activeRole) || isTA(activeRole)? (
                            <li>
                                <NavLink to="/instructor-api-docs" activeClassName="active" role="menuitem" tabIndex="0"
                                         aria-label="API Access" title="API Access">
                                    <Icon icon="book" options="fa-fw" ariaHidden="false"/> API Access
                                </NavLink>
                            </li>
                            ) : null}
                        </ul>
                    </nav>
                </div>
                <div className="admin-list hide-md">
                    <nav>
                        <ul>
                            {isStudent(activeRole) ? (
                            <li>
                                {this.renderPaymentPortalLink()}
                            </li>
                            ) : null}
                            <li>
                                <NavLink to="/support" activeclass="active" role="menuitem" aria-label="Support" title="Support">
                                    <Icon icon="book" options="fa-fw" ariaHidden="false"/> Support
                                </NavLink>
                            </li>
                            <li>
                                <Link to="/" role="menuitem" onClick={()=>this.logout()} aria-label="Sign out of BCS" title="Logout">
                                    <Icon icon="power-off" options="fa-fw" ariaHidden="false"/> Logout
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="course-info">
                    <span className="title">{programTypeName}</span>
                    {isInstructor(activeRole) || isTA(activeRole) || isSSM(activeRole) || isAdmin(activeRole) || isCD(activeRole) ? (
                        <p className="font-xxs font-xs-md">{this.props.user.enrollmentInfo.course.name}</p>
                    ) : null}
                </div>
            </nav>
        )
    }

}

export const NAVIGATION = Navigation;
export default connect(mapStateToProps)(Navigation);
