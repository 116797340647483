import React, {Component} from 'react';

class BoolStep extends Component {
    render() {
        return (
            <div>bool</div>
        )
    }
}

export default BoolStep;
