import React from 'react';

const PanoptoVideoDelete = (props) => {
    const { deleteVideo, videoID, calledApiLoading } = props

    return(
        <button type='submit'
                disabled={calledApiLoading}
                className={`btn ${calledApiLoading ? 'btn-loading' : 'btn-delete'}`}
                onClick={(event) => deleteVideo(event, videoID)}
        > 
            Delete Video 
        </button>
    )
}

export default PanoptoVideoDelete