import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Icon from '../Icon';
import Alert from '../Alert';
import ProfilePic from "../ProfilePic";

class FileUpload extends Component {
  constructor() {
    super()
    this.state = {
      fileArr: [],
      uploadError: false,
      preview: null
    }
  }

  onDropAccepted = (fileArr) => {
    this.setState({ fileArr });
    this.props.prepImgFile(fileArr[0]);
    this.setState({ uploadError: false });
  }

  onDropRejected = () => {
    this.setState({ uploadError: true });
  }

  handleOnDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      this.onDropRejected()
    } else {
      const fileType = acceptedFiles[0];
      if(!fileType || !fileType.type.includes("image")) {
        this.onDropRejected();
        console.error("File type not allowed for image upload.", fileType);
      } else {
        this.onDropAccepted(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
      }
    }
  }

  /* just displays chosen accepted file types, doesn't check for type */
  displayAcceptedFileTypes = () => {
    return this.props.fileTypes ? (
      <p>
        <em>
          Accepted file types:{' '}
          {this.props.fileTypes.map((fileType, index) => {
            const punctuation = index === (this.props.fileTypes.length - 1) ? '.' : ',';
            return `${fileType}${punctuation} `;
          })}
        </em>
      </p>
    ) : null;
  }

  /* dropZone only accepts comma separated str, not arr. Here, arr => str */
  renderAcceptedFileTypes = () => {

    let fileTypesStr = '';
    if (this.props.fileTypes) {
      this.props.fileTypes.forEach((fileType, index) => {
        fileTypesStr += `image/${fileType}, `;
      });
    }
    return fileTypesStr;
  }

  renderMaxFileSize = () => {
    return this.props.maxFileSize ? (
      <p><em>File size must not exceed <b>{this.props.maxFileSize} KB.</b></em></p>
    ) : null;
  }

  renderConstraints = () => {
    return this.props.constraints ? (
      <p><em>{this.props.constraints}</em></p>
    ) : null;
  }

  render() {
    const { multipleFiles, maxFileSize, user } = this.props;
    const { fileArr } = this.state;
    const label = this.props.label ? this.props.label : 'Drag and drop files here.';
    const imgStyles = Object.assign({
      'maxWidth': '100%'
    }, this.props.imagePreviewInlineStyles);
    let showPicToBeUploaded = (!!fileArr && fileArr.length > 0);

    return (
      <section className="row">
        <div className={`col-xs-12 ${this.props.imagePreview ? 'col-md-8' : ''}`}>
          {this.state.uploadError || this.props.uploadError ? (
            <Alert type="error">
              <p><strong>File rejected</strong></p>
              {this.displayAcceptedFileTypes()}
              {this.renderMaxFileSize()}
              {this.renderConstraints()}
            </Alert>
          ) : null}
          <Dropzone 
            onDrop={this.handleOnDrop}
            className="border margin-b-3 padding-4-xs padding-6-md text-center bg-secondary radius-1"
            maxSize={parseInt(maxFileSize, 10) * 1000}
            multiple={multipleFiles}
            accept={this.renderAcceptedFileTypes()}
            >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p><strong>{label}</strong></p>
                  <p className="title">- or -</p>
                  <button className="btn-primary margin-r-0 margin-b-4"><Icon icon="upload" options="margin-r-1" />Upload File{multipleFiles ? 's' : ''}</button>
                  {this.displayAcceptedFileTypes()}
                  {this.renderMaxFileSize()}
                  {this.renderConstraints()}
                </div>
              </section>
            )}
          </Dropzone>
          <aside>
            <p className="title">File{multipleFiles ? 's' : ''} Added:</p>
            <ul className="bulleted">{this.state.fileArr.map(f => <li key={f.name}>{f.name} - <span className="font-xxs-xs font-xs-sm font-sm-md">{f.size / 1000} kb</span></li>)}</ul>
          </aside>
        </div>
        {
          this.props.imagePreview ? (
            <div className="col-xs-12 col-md-4">
              {showPicToBeUploaded ?
                <img src={this.state.fileArr[0].preview}
                  alt="Avatar to be updated"
                  className={this.props.imagePreviewOptions}
                  style={imgStyles}
                />
                : <ProfilePic user={user} className={this.props.imagePreviewOptions} style={imgStyles} />
              }
            </div>
          ) : null}
      </section>
    );
  }
}

FileUpload.propTypes = {
  label: PropTypes.string,
  options: PropTypes.string,
  inlineStyles: PropTypes.object,
  multipleFiles: PropTypes.bool,
  fileTypes: PropTypes.array,
  imagePreview: PropTypes.bool,
  imagePreviewOptions: PropTypes.string,
  imagePreviewInlineStyles: PropTypes.object,
  maxFileSize: PropTypes.string,
  constraints: PropTypes.string,
  endPoint: PropTypes.string
}

FileUpload.defaultProps = {
  label: '',
  options: '',
  inlineStyles: {},
  multipleFiles: false,
  fileTypes: [],
  imagePreview: false,
  imagePreviewOptions: '',
  imagePreviewInlineStyles: {},
  maxFileSize: '',
  constraints: '',
  endPoint: ''
}

export default FileUpload;
