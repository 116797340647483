import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import FileUpload from '../../components/FileUpload';
import Alert from '../../components/Alert'
import { updateStudentProfile } from '../../actions/studentActions';
import { userActions } from '../../actions/userActions'

function mapStateToProps(state){
  return {
    userProfile: state.user.profile,
    enrollmentInfo: state.user.enrollmentInfo,
    authToken: state.user.data.authToken
  }
}

class ProfileManage extends Component {

  constructor(props) {
    super(props);
    this.state = {
        studentError: false,
        uploadError: false,
        errorLoading: false
    }
  }

  /* turn file into binary; set as state */
  prepImgFile = (uploadedFile) => {
    const reader = new FileReader();

    reader.onload = () => {
      this.setState({
        uploadedFileBinary: reader.result,
        uploadedFileName: uploadedFile.name,
        fileMimeType: uploadedFile.type,
        uploadError: false
      });
      return reader.result;
    };

    reader.onabort = () => {
      this.setState({
        uploadError: true
      });
    }
    reader.onerror = () => {
      this.setState({
        uploadError: true
      });
    }
    reader.readAsDataURL(uploadedFile);
  }

  handleSaveChanges = async () => {
      // FIXME - this should be disabled if we haven't uploaded anything
      const {uploadedFileBinary, fileMimeType} = this.state;
      const {userProfile} = this.props;
      const payload = {
          profilePicture: uploadedFileBinary,
          pictureMimeType: fileMimeType,
          studentId: userProfile.id
      };
      try {
        let axiosResult = await updateStudentProfile(payload);
      if (axiosResult.status === 200) {
          this.setState({errorLoading: false})
          this.props.dispatch(userActions.cacheBustNexusAvatarUrl())
          this.props.history.push(`/students/${this.props.enrollmentInfo.id}`);
      }
      } catch (error) {
          this.setState({errorLoading: true})
      }
      
  }

  render() {
    let { userProfile } = this.props
    let { errorLoading } = this.state

    return(
      <DocumentTitle title={`${userProfile.firstName} ${userProfile.lastName} Manage Profile | Bootcamp Spot`}>
        <section className="page" data-component="profile-manage">
            <div className="row">
              <div className="col-xs-12">
                <h1>Edit Profile Picture</h1>
              </div>
              <div className="col-xs-12 col-no-gutter">
                <FileUpload
                  label={'Drag and Drop Your Profile Picture Here.'}
                  fileTypes={['jpeg','jpg','png','svg']}
                  maxFileSize={'200'}
                  multipleFiles={false}
                  imagePreview={true}
                  imagePreviewOptions={'rounded'}
                  user={userProfile}
                  prepImgFile={this.prepImgFile}
                  uploadError={this.state.uploadError}
                />
              </div>
              <div className="col-xs-12 col-md-8">
                {errorLoading ? <Alert type='error'>Error Saving Image, Please Try Again</Alert> : null}
                <button className="margin-t-3 btn-save" onClick={this.handleSaveChanges}>Save</button>
              </div>
            </div>
        </section>
      </DocumentTitle>
    )
  }
}

export default connect(mapStateToProps)(ProfileManage);
