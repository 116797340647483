import React, {Component} from 'react';

const Align = {
    horizontal: 'horizontal',
    vertical:   'vertical'
  }

class OptionsStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: ''
        }

    }

    commitStep = async (e) => {
        try {
            await this.setState({
                checked: e.target.value
            })
            this.props.onChange(this.props.step.step.id, this.state.checked);
        } catch (e) {
            console.log(e)
        }

    }

    handleOtherValueChange = async (e, option) => {
        try {
            await this.setState({
                checked: option.value + ": " + e.target.value
            })
            this.props.onChange(this.props.step.step.id, this.state.checked);
        } catch (e) {
            console.log(e)
        }
    }

    isChecked = (option) => {
        return ((this.state.checked === option.value.toString()) || (option.additionalInfoLabel !== undefined && this.state.checked.startsWith(option.value)));
    }

    render() {
        let isHorizontalAlign = (this.props.step.step.definition.ui.align && this.props.step.step.definition.ui.align === Align.horizontal) ? true : false
        const options = this.props.step.step.definition.options;

        let optionList = options.map((option) => {
            if (isHorizontalAlign) {
                return (
                    <label key={this.props.step.step.id + '-' + option.value} htmlFor={this.props.step.step.id + '-' + option.value} className="label-active margin-r-3">
                        <input type="radio" name={this.props.step.step.id + '-' + option.value} id={this.props.step.step.id + '-' + option.value}
                            checked={this.isChecked(option)}
                            onChange={this.commitStep}
                            value={option.value}/>
                        {option.display}
                        {option.additionalInfoLabel && this.isChecked(option) &&
                        <div className="label-active margin-r-3">
                            <label htmlFor="textarea">{option.additionalInfoLabel}</label>
                            <textarea onChange={(e) => this.handleOtherValueChange(e, option)}
                                        rows="1"
                                        cols="80"
                                        maxLength="250"
                                        className="margin-b-1"/>
                        </div> }
                    </label>
                );
            } else {
                return (
                    <li key={this.props.step.step.id + '-' + option.value} className="margin-b-1">
                        <label htmlFor={this.props.step.step.id + '-' + option.value} className="label-active">
                            <input type="radio" name={this.props.step.step.id + '-' + option.value} id={this.props.step.step.id + '-' + option.value}
                                checked={this.isChecked(option)}
                                onChange={this.commitStep}
                                value={option.value}/>
                            {option.display}
                        </label>
                        {option.additionalInfoLabel && this.isChecked(option) &&
                        <div className="margin-t-3 margin-b-0">
                            <label htmlFor="textarea">{option.additionalInfoLabel}</label>
                            <textarea onChange={(e) => this.handleOtherValueChange(e, option)}
                                        rows="4"
                                        cols="80"
                                        maxLength="250"
                                        className="margin-b-1"/>
                        </div> }
                    </li>
                );
            }
        });

        let content = null;
        if (isHorizontalAlign){
            content = optionList
        } else {
            content = <ul>{optionList}</ul>
        }

        return (
            <li className="margin-b-3">
                <fieldset>
                    <legend className="label" aria-required={this.props.step.step.required ? "true" : "false"}>{this.props.step.step.definition.text}{this.props.step.step.required ? ' *' : null}</legend>
                    <div className="row">
                        {content}
                    </div>
                </fieldset>
            </li>
        )
    }
}

export default OptionsStep;
