import React from 'react';
import Tag from "../Tag";

const Index = (props) => {
    return (
        props.tags && props.tags.length > 0 ?
            <div className="padding-t-0 bcs-filter-tags">
                {props.tags.map((value, i) =>
                    <Tag value={value} onRemove={props.onRemove} key={i} /> )}
            </div> : null
    );
}

export default Index;
