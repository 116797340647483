import React from 'react';

import Alert from '../Alert'
import SupportLink from '../SupportLink'
import LogoutButton from '../LogoutButton'

const NoEnrollmentPage = (props) => {
    return (
        <div className="row margin-t-5">
                <Alert type="error">
                    <h1>Whoops!</h1>
                    <p>
                        We've got you logged in, but there seems to be no enrollments or staff assignments associated with your account.
                        Please contact support and provide them this message so they can better assist you: <SupportLink/>
                    </p>
                    <LogoutButton {...props}/>
                </Alert>
        </div>
    )
}

export default NoEnrollmentPage