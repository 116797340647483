import React, {Component} from 'react';
import DocumentTitle from 'react-document-title';
import {Link} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash'
import get from 'lodash/get';
import SingleCoursework from '../Coursework/singleCoursework'
import Icon from '../../components/Icon';
import Loading from '../../components/Loading';
import RenderNextTwoCareerSessions from '../../components/RenderNextTwoCareerSessions';
import RenderLastResourceBullets from '../../components/RenderLastResourceBullets';
import {fetchCareerServices} from '../../actions/careerServicesActions'
import {isRole} from '../../helpers/roles';
import { getProgramTypeShortName } from '../../helpers/enrollments'
import {roleTypes} from '../../constants/dataTypes';
import {careerServiceResourceLink} from "../../constants/careerConstants";

const mapStateToProps = (state, ownProps) => {
    return {
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        enrollmentInfo: state.user.enrollmentInfo,
        careerServices: state.careerServices,
        allCareerAssignments: state.allCareerAssignments,
        authToken: state.user.data.authToken,
        profile: state.user.profile,
        ...ownProps
    }
}

class CareerServices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            programType: '',
            programName: ''
        }
    }

    componentDidMount() {
        const {dispatch, enrollmentInfo} = this.props;
        let payload = {
            enrollmentId: enrollmentInfo.id
        }
        dispatch(fetchCareerServices(payload));
        const programTypeShortName = getProgramTypeShortName(get(enrollmentInfo, 'course.cohort.program.programType.name', ''))
        const programName = get(enrollmentInfo, 'course.cohort.program.name', '')
        this.setState({programType: programTypeShortName, programName: programName})
    }

    render() {
        const {careerServices, activeRole, enrollmentInfo} = this.props;
        const {programType} = this.state;
        const showCareerServicesEvents = get(enrollmentInfo, 'showCareerServicesEvents', false)

        return (
            <DocumentTitle title="Career Services | Bootcamp Spot">
                <section className="page" data-component="career-services">
                    {careerServices.careerServicesLoading ? <div className="row middle-xs" style={{height: '100vh'}}>
                            <div className="col-xs"><Loading/></div>
                        </div>
                        :
                        <div>
                            <div className="row">
                                <div className="col-xs-12 col-md-8 col-gutter-lr">
                                    <h1>Career Services</h1>
                                </div>
                                {isRole([roleTypes.CD, roleTypes.ADMIN, roleTypes.SSM], activeRole) &&
                                <div className="col-xs-12 col-md-4">
                                    <Link to="/sessions/create" className="btn btn-primary">Create New Career Session</Link>
                                </div>
                                }
                                <div className='row'>
                                    <div className="col-xs-12">
                                        <div className="card margin-b-3-xs margin-b-6-sm">
                                            <h2>Become Employer-Ready!</h2>
                                            <p className="title">We have spoken to thousands of employers and we know exactly what it takes for you to be competitive in the job market.</p>
                                            <ul className="bulleted">

                                                <li><HashLink tabIndex="0" to="#upcomingCareerSessions">View Upcoming Career Workshops</HashLink></li>
                                                <li><HashLink tabIndex="0" to="#allCareerMilestones">View All Career Milestones</HashLink></li>
                                                <li><HashLink tabIndex="0" to="#careerResources">View Additional Resources</HashLink></li>
                                            </ul>
                                            <h3>We are here to help</h3>
                                            <ul className="bulleted">
                                                <li>
                                                    <p><strong>Work with your Career Material Advisor until your materials are Employer Competitive.</strong> The link below will take you to the Career Milestones where you can submit your professional materials for review. <strong>Your Career Material Advisor will provide unlimited feedback on all of your materials.</strong> Although we have suggested due dates, you can <strong>complete the Milestones in any order and at any time</strong>. </p>
                                                    <p><HashLink tabIndex="0" to="#allCareerMilestones">View All Career Milestones</HashLink></p>
                                                </li>
                                                <li>
                                                    <p>Shortly before graduation, <strong>your Career Coach will invite you to your first 1: 1 coaching session.</strong> Consistently meet with your Career Coach by committing to regularly scheduled sessions. Visit the <a href="https://careernetwork.2u.com/?utm_medium=BCSMilestones&utm_source=boot_camp" rel="noopener noreferrer" target="_blank" aria-label="Link opens in new window">Career Engagement Network</a> for helpful resources including job search strategies, networking advice, and salary negotiation strategies.</p>
                                                </li>
                                                { programType ? (
                                                    <li>
                                                        <a href={careerServiceResourceLink} target="_blank" rel="noreferrer noopener" aria-label="Link opens in new window"><Icon icon="book"/> Career Resources &amp; Support</a>
                                                        <p>(Link opens in new window)</p>
                                                    </li>
                                                ) : null }
                                                { showCareerServicesEvents ? (
                                                    <li>
                                                        <p><strong>Attend our weekly Career Services Online Events & Workshops</strong> to refine your career building skills. Find the schedule of events here: <a href="https://careernetwork.2u.com/events/?utm_medium=BCSMilestones&utm_source=boot_camp" rel="noopener noreferrer" target="_blank" aria-label="Link opens in new window">View All Upcoming Workshops</a></p>
                                                    </li>
                                                ) : null }
                                                <li>
                                                    <p><strong>Start early and get Employer Ready by graduation.</strong> You are more likely to secure the job you are looking
                                                    for when you enter the job market before or close to graduation.</p>
                                                </li>
                                                <li>
                                                    <p>The <a href="https://careernetwork.2u.com/?utm_medium=BCSMilestones&utm_source=boot_camp" rel="noopener noreferrer" target="_blank" aria-label="Link opens in new window">Career Engagement Network</a> is your go-to resource to become Employer-Competitive. Bookmark this for quick access to career resources and information, a job board, and all career services offerings.</p>
                                                </li>
                                                <li>
                                                    <p>Questions about career support? Contact us at <a href="mailto:cssupport@bootcampspot.com">cssupport@bootcampspot.com</a> / <a href="mailto:cssupportaustralia@bootcampspot.com">cssupportaustralia@bootcampspot.com</a>.</p>
                                                </li>
                                                <li>
                                                    <p>Questions about workshops or events? Contact us at <a href="mailto:careerworkshops@2u.com">careerworkshops@2u.com</a>.</p>
                                                </li>
                                            </ul>

                                        </div>
                                        <div id="upcomingCareerSessions" className="margin-b-3-xs margin-b-6-sm">
                                            <h2>Next Upcoming Workshops</h2>
                                                <RenderNextTwoCareerSessions careerServices={this.props.careerServices}/>
                                        </div>
                                        <div id="allCareerMilestones">
                                            <h2>All Career Milestones</h2>
                                            {careerServices.allCareerAssignments && !isEmpty(careerServices.allCareerAssignments) ? (
                                                careerServices.allCareerAssignments.map((careerAssignment, index, array) => {
                                                    return (
                                                        <SingleCoursework
                                                            courseworkType="career"
                                                            key={careerAssignment.id}
                                                            userRole={activeRole} // this should be where the role is passed VERY IMPORTANT!!
                                                            coursework={careerAssignment}
                                                            overdue={false}
                                                            programName={enrollmentInfo.course.cohort.program.programType.name}
                                                            optionalClasses='margin-b-6'
                                                            hideDueDate={true}
                                                            id="careermilestones"
                                                        />
                                                    )
                                                })
                                            ) : (
                                                <p><strong>No Upcoming Career Milestones Assigned</strong></p>
                                            )}
                                        </div>
                                        <div id="careerResources">
                                            <h2>Resources</h2>
                                            { programType
                                                ? <RenderLastResourceBullets
                                                    csResourceLinks={this.csResourceLinks}
                                                    showCareerServicesEvents={showCareerServicesEvents}
                                                    state={this.state}
                                                    />
                                                : null }
                                        </div>
                                        <div id="csCourseCalendarCTA" className="padding-t-4-xs padding-t-6-md">
                                            <HashLink to={'/#coursecalendar'}>
                                                <button className="btn-small btn-tertiary btn-create">View Course Calendar</button>
                                            </HashLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </section>
            </DocumentTitle>
        )
    }

}

export const CAREERSERVICES = CareerServices;

export default connect(mapStateToProps)(CareerServices);
