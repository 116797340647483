import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import {fetchSessions} from '../../actions/sessionActions';
import Loading from '../../components/Loading';
import {Table, TBody, THead, TR, TD, TH} from '../../components/Table';
import {
    getDayNumber, getDayString, getFormattedTime, getMonthString,
    getYear
} from "../../helpers/dateTime";
import {sessionContextTypes} from "../../constants/dataTypes";


const mapStateToProps = (state, ownProps) => {
    return {
        enrollmentId: state.user.enrollmentInfo.id,
        sessions: state.sessions,
        enrollmentInfo: state.user.enrollmentInfo,
        sessionListLoading: state.sessions.sessionListLoading,
        university: state.user.enrollmentInfo.course.cohort.program.university.name,
        courseCode: state.user.enrollmentInfo.course.code,
        programName: state.user.enrollmentInfo.course.cohort.program.programType.name,
        ...ownProps
    }
}

export class SessionSyllabus extends Component {
    componentDidMount() {
        const {enrollmentId, dispatch} = this.props;
        dispatch(fetchSessions(enrollmentId))
    }

    goToSession = (sessionId) => {
        this.props.history.push(`/sessions/${sessionId}`)
    }

    render() {
        const {sessions, sessionListLoading, university, courseCode, programName} = this.props;
        const academicSessions = sessions.calendarSessions.filter((session) => {
            return session.context.contextCode === sessionContextTypes.ACADEMIC
        })
        const tableHeadings = [
            'Session Number',
            'Session Name',
            'Date & Time'
        ]
        return (
            <DocumentTitle title="Session Syllabus | Bootcamp Spot">
                <section className="page" data-component="session-syllabus">
                    <div className="row">
                        <div className="col-xs-12 col-md-8 col-lg-9 padding-b-0">
                            <h1>Session Syllabus</h1>
                            <p><b>University:</b> {university}</p>
                            <p><b>Class:</b> {courseCode}</p>
                            <p><b>Program:</b> {programName}</p>
                        </div>
                        <div className="col-xs-12 col-md-4 col-lg-3 first-xs last-md text-right">
                            <Link to="/sessions">View all Sessions</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-gutter-lr">
                            {sessionListLoading === false ? (
                                <Table>
                                    <THead>
                                        <TR>
                                            <TH col="2">{tableHeadings[0]}</TH>
                                            <TH col="6">{tableHeadings[1]}</TH>
                                            <TH col="4">{tableHeadings[2]}</TH>
                                        </TR>
                                    </THead>
                                    <TBody>
                                    {academicSessions.map((session, index) => {
                                        return (
                                            <TR role="button" key={index} onClick={() => this.goToSession(session.session.id)}>
                                                <TD col="2" heading={tableHeadings[0]}>{session.session.chapter}</TD>
                                                <TD col="6" heading={tableHeadings[1]}>{session.session.name}</TD>
                                                <TD col="4" heading={tableHeadings[2]}>
                                                    <div>
                                                        <span>{getDayString(session.session.startTime)} </span>
                                                        <span>{getMonthString(session.session.startTime)} </span>
                                                        <span>{getDayNumber(session.session.startTime, true)}, </span>
                                                        <span>{getYear(session.session.startTime)} </span>
                                                    </div>
                                                    <span>{getFormattedTime(session.session.startTime)} - {getFormattedTime(session.session.endTime)} Local</span>
                                                </TD>
                                            </TR>
                                        )
                                    })}
                                    </TBody>
                                </Table>

                            ) : <Loading/>
                            }
                        </div>
                    </div>

                </section>
            </DocumentTitle>
        )
    }
}

export default connect(mapStateToProps)(SessionSyllabus);
