//FIXME: this needs to be in a folder with a proper name, etc. And have a more descriptive filename.
export default class FormRunner {
    constructor(baseUrl, placeholders) {
        this.pageMap = new Map();
        this.stepDataMap = new Map();
        this.formId = null;
        this.authToken = null;
        this.formExecutionId = null;
        this.visibilityFuncString = null;
        this.stepDataList = null;
        this.currentPage = 0;
        this.pageCount = 0;
        this.baseUrl = baseUrl;
        this.userDataId = 0;
        this.extra = null;
        this.description = null;
        this.formName = null;
        this.placeholders = placeholders;
        this.ignoreSteps = [];
    }

    async loadForm(authToken, formName, userDataId, extra) {
        this.init();
        let payload = {formName: formName}
        let response = await fetch(this.baseUrl + "/broker/loadForm",
            {
                headers: {'Content-Type': 'application/json', 'authToken': authToken},
                method: "POST",
                body: JSON.stringify(payload)
            }
        );

        if (response.ok) {
            let jsonData = await response.json();
            if (jsonData === null){
                console.warn("Null body form response");
                return false;
            }
            this.load(authToken, jsonData);
        } else {
            console.warn("Load form with response " + response.status);
            return false;
        }

        this.userDataId = userDataId;
        this.extra = extra;
        return true;
    }

    init() {
        this.pageMap = new Map();
        this.stepDataMap = new Map();
        this.formId = null;
        this.authToken = null;
        this.formExecutionId = null;
        this.visibilityFuncString = null;
        this.stepDataList = null;
        this.currentPage = 0;
        this.pageCount = 0;
        this.description = "";
        this.userDataId = 0;
        this.extra = null;
        this.formName = "";
    }

    async load(authToken, jsonData) {

        this.formId = jsonData.form.id
        this.formName = jsonData.form.name;
        this.authToken = authToken;
        this.formExecutionId = jsonData.form.formExecutionId;
        this.visibilityFuncString = jsonData.form.visibilityFunc;
        this.validationFuncString = jsonData.form.validationFunc;
        this.stepDataList = jsonData.steps;
        this.description = jsonData.form.description;

        for (let i = 0; i < this.stepDataList.length; i++) {
            let stepData = this.stepDataList[i];
            stepData.step.answer = null;
            stepData.step.error = null;

            for (const [key, value] of this.placeholders.entries()) {
                var p = "{" + key + "}";
                stepData.step.definition.text = stepData.step.definition.text.replace(p, value);
            };

            this.stepDataMap.set(stepData.step.id, stepData);
            let page = stepData.step.page;
            let stepDataByPage = this.pageMap.get(page);
            if (stepDataByPage === undefined) {
                this.pageMap.set(page, [stepData]);
            } else {
                stepDataByPage.push(stepData);
            }
        }

        this.pageCount = jsonData.form.pageCount;
        this.currentPage = 1;

        this.runVisibilityFunc();
    }

    setIgnoreSteps(stepList) {
        this.ignoreSteps = stepList;
    }

    getPageCount() {
        return this.pageCount;
    }

    getCurrentPage() {
        return this.currentPage;
    }

    setCurrentPage(page) {
        this.currentPage = page;
    }

    getStepDataByStepId(id) {
        return this.stepDataMap.get(id);
    }

    getCurrentPageStepsCount() {
        return this.pageMap.get(this.currentPage).length;
    }

    getDescription() {
        return this.description;
    }

    listCurrentPageStepData() {
        return this.pageMap.get(this.currentPage);
    }

    listPageStepData(page) {
        return this.pageMap.get(page);
    }

    commitStep(stepId, answerValue) {
        let stepData = this.getStepDataByStepId(stepId);

        if (answerValue !== null) {
            stepData.step.error = null;
            stepData.step.answer = {"value": answerValue};
            let stepDataList = this.listCurrentPageStepData();
            for (let i = 0; i < stepDataList.length; i++) {
                let stepDataInMap = stepDataList[i];
                if (stepDataInMap.step.id === stepData.step.id) {
                    stepDataList[i] = stepData;
                }
            }
        }
        this.runVisibilityFunc();
    }

    runVisibilityFunc() {
        //FIXME: Security vendor looking at this.
        //eslint-disable-next-line
        var visibilityFunc = new Function("stepDataList", this.visibilityFuncString);
        visibilityFunc(this.stepDataList);
        for (let i = 0; i < this.ignoreSteps.length ; i++) {
            const step = this.ignoreSteps[i];
            if (step < this.stepDataList.length){
                this.stepDataList[step].step.visible = false;
            }
        }
    }

    checkPageValidity() {
        if (this.validationFuncString && this.validationFuncString !== ""){
          //FIXME: Security vendor looking at this.
          //eslint-disable-next-line
            var validationFunc = new Function("stepDataList", "page", this.validationFuncString);
            var ret = validationFunc(this.listCurrentPageStepData(), this.currentPage);
            return ret;
        }
        return true;
    }

    async submit() {
        let stepsWithAnswers = [];
        for (let i = 1; i <= this.getPageCount(); i++) {
            this.pageMap.get(i).forEach(function (element) {
                let step = element.step;
                let stepAnswer = {};
                stepAnswer.stepId = step.id;
                if (step.visible){
                    stepAnswer.answer = step.answer;
                } else {
                    stepAnswer.answer = null;
                }
                stepsWithAnswers.push(stepAnswer);
            });
        }
        let formExec = {"formId": this.formId, "enrollmentId": this.userDataId, "userDataId": this.userDataId, "extra": this.extra, "formName": this.formName, "steps": stepsWithAnswers};
        var resp = await this.submitForm(formExec);
        return resp;
    }

    async submitForm(formExec) {
        let response = await fetch(this.baseUrl + '/broker/submitForm',
            {
                headers: {'Content-Type': 'application/json', 'authToken': this.authToken},
                method: "POST",
                body: JSON.stringify(formExec)
            }
        );
        if (response.ok){
            let jsonData = await response.json();
            if (jsonData === null){
                console.warn("Null body submitform response");
                return {success: false, error: "Internal error"};
            }
            return jsonData;
        }

        if(response.status === 401) {
            return {success: false, error: "Authorization failed, please log out and try logging in again.", redirectToLogout: true}
        }

        console.warn("SubmitForm response with status " + response.status);
        return {success: false, error: "Please try again later"}
    }
}
