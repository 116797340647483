import React, {Component} from 'react';
import Icon from '../../components/Icon';
import {isEmpty} from 'lodash'

import {getGradeName, gradeColorsMap} from '../../constants/grade';
import {assignmentContextTypes, availableCareerStatus} from '../../constants/dataTypes'

export class GradeDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            commentAdded: false,
            feedback: '',
            assignment: this.props.assignment || {},
            submission: this.props.submission || {}
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.submission && !isEmpty(nextProps.submission) && (nextProps.submission !== this.state.submission)) {
            this.setState({
                submission: nextProps.submission
            })
        }
        if (nextProps.assignment && !isEmpty(nextProps.assignment) && (nextProps.assignment !== this.state.assignment)) {
            this.setState({
                assignment: nextProps.assignment
            })
        }
    }

    refreshAssignment = () => {
        this.props.refreshAssignment()
    }

    handleChange = (event) => {
        let fieldName = event.target.name
        let fieldValue = event.target.value
        let obj = {}
        obj[fieldName] = fieldValue
        this.setState(obj)
    }

    render() {
        const {assignment, submission} = this.state;
        let hasSubmission = false;
        let studentSubmission = {}
        let urls = [];
        if ((submission && !isEmpty(submission))) {
            studentSubmission = submission;
            hasSubmission = true;
            if (submission.submissionUrlList) {
                urls = submission.submissionUrlList.filter(item => item.url.length)
            }
        }
        return (
            (hasSubmission === true) ? (
                <div className="col-xs-12 padding-b-0" data-component="grade-detail">
                    <div className="row">
                        {(assignment.context.contextCode === assignmentContextTypes.CAREER) ? (
                            <div className="col-xs-12 col-md-6 col-no-gutter text-center margin-b-3">
                                <div className='card'>
                                    <p className="title">Career Feedback: </p>
                                    {/* add extra ternary? */}
                                    {(studentSubmission.careerFeedback.careerFeedbackStatusId === 1) ? (
                                        <h1 className="text-yellow">{availableCareerStatus[0].label}</h1>) : null}
                                    {(studentSubmission.careerFeedback.careerFeedbackStatusId === 2) ? (
                                        <h1 className="text-green">{availableCareerStatus[1].label}</h1>) : null}
                                    {(studentSubmission.careerFeedback.careerFeedbackStatusId === 3) ? (
                                        <h1 className="text-red">{availableCareerStatus[2].label}</h1>) : null}
                                    {(studentSubmission.careerFeedback.careerFeedbackStatusId === 4) ? (
                                        <h1 className="text-yellow">{availableCareerStatus[3].label}</h1>) : null}
                                    {(studentSubmission.careerFeedback.careerFeedbackStatusId === 5) ? (
                                        <h1 className="text-green">{availableCareerStatus[4].label}</h1>) : null}
                                    {(studentSubmission.careerFeedback.careerFeedbackStatusId === 6) ? (
                                        <h1 className="text-green">{availableCareerStatus[5].label}</h1>) : null}
                                    {(studentSubmission.careerFeedback.careerFeedbackStatusId === 7) ? (
                                        <h1 className="text-red">{availableCareerStatus[6].label}</h1>) : null}
                                </div>
                            </div>
                        ) : (
                            (studentSubmission.submissionGrade) ? (
                                    <div className="col-xs-12 col-md-6 col-no-gutter text-center margin-b-3">
                                        <div className='card'>
                                            <h3>Coursework Grade: </h3>
                                            <h1 className={gradeColorsMap.get(studentSubmission.submissionGrade.grade)}>{getGradeName(studentSubmission.submissionGrade.grade) || 'N/A'}</h1>
                                        </div>
                                    </div>
                                )
                                : ''
                        )}
                        {urls.length ? (
                            <div className="col-md-12 col-md-6 col-no-gutter text-left margin-b-3 padding-t-3">
                                <h2>You Submitted:</h2>
                                <ul>
                                    {
                                        urls.map((url, index) => {
                                            return (
                                                <li key={index}><a href={url.url}><Icon icon="cogs"/> {url.url}</a></li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null
        )
    }
}

export default GradeDetail
