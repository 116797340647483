import React, { Component } from 'react';
import {connect} from 'react-redux';
import DocumentTitle from 'react-document-title';
import { RemoteAttendanceRequestManager } from '../../components/RemoteAttendanceRequest/Manager/index';
import { isRole } from '../../helpers/roles';


const mapStateToProps = (state, ownProps) => {
    return{
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        enrollmentId: state.user.enrollmentInfo.id,
        ...ownProps
    }
}

export class RemoteAtendanceRequestManagement extends Component {

    constructor(props) {
        super(props)
        if (this.props.removeNav) {
            this.props.removeNav()
        }
        this.state = {
            absenceRequests: [],
            absenceRequestOrg: [],
            absenceRequestsLoading: true
        }
    }

    componentDidMount() {
        const {activeRole} = this.props;
        if (!isRole(['admin', 'ssm', 'ta', 'instructor'], activeRole)) {
            this.props.history.push('/unauthorized');
        }
    }

    render() {
        const {activeRole} = this.props;
        let allowed = isRole(['admin', 'ssm', 'ta', 'instructor'], activeRole);

        return(
            <DocumentTitle title="Manage Remote Attendance Requests | Bootcamp Spot">
                <section className="page" data-component="remote-attendance-request-manager">
                        <RemoteAttendanceRequestManager
                            user={this.props.user}
                            allowedStaff={allowed}
                            roles={activeRole}
                            showAll={this.props.showAll}
                            enrollmentId={this.props.enrollmentId}
                        />
                </section>
            </DocumentTitle>
        )
    }

}

export default connect(mapStateToProps)(RemoteAtendanceRequestManagement);
