import React from 'react'
import moment from "moment/moment";
import {Link} from 'react-router-dom';
import {getDayString, getMonthString, getDayNumber, getFormattedTime} from '../../helpers/dateTime'
import Icon from '../../components/Icon';

export default function RenderNextTwoCareerSessions(props) {
  // we want the next two career sessions date wise with no backend changes. Current max for items to iterate through is 25-30.
  const { careerServices } = props;
  let careerServicesSessionsSorted = careerServices.calendarSessions.filter((singleSessionObject) => {
      let singleCareerSession = singleSessionObject.session
      return moment(singleCareerSession.startTime) >= moment()
  }).sort((a, b) => {
      return moment(a.session.startTime).diff(moment(b.session.startTime))
  })

  if (!careerServicesSessionsSorted.length) {
      return (
          <div className="card-with-title">
              <div className="">
                  <p>No Upcoming Workshops</p>
              </div>
          </div>
      )
  }
  
  return careerServicesSessionsSorted.slice(0, 2).map((singleSessionObject) => {
      let singleCareerSession = singleSessionObject.session
      return (
        <div key={singleSessionObject.id} className="card-with-title card-wide margin-b-3-xs margin-b-6-md">
            <div className="card-title">
                <p className="title">{getDayString(singleCareerSession.startTime)}</p>
                <p className="card-title-month">{getMonthString(singleCareerSession.startTime)}</p>
                <p className="card-title-date">{getDayNumber(singleCareerSession.startTime, true)}</p>
                <p className="card-title-time">{getFormattedTime(singleCareerSession.startTime)} - {getFormattedTime(singleCareerSession.endTime)} Local</p>
            </div>
            <div className="card-content">
                <h3>{singleCareerSession.chapter} - {singleCareerSession.name}</h3>
            </div>
            <div className="card-footer">
                <ul>
                    <li><Link to={`/sessions/${singleCareerSession.id}`}>
                            <Icon icon="copy"/>{' '}
                            Workshop Details
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
      )
  })
}
