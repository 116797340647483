import {calendarEventTypeClassNames} from "../constants/calendar";
const session = "session";
const coursework = "coursework";

export const convertNexusEventToCalendarEvent = (data) => {
    let formattedData = [];
    data.forEach((event) => {
        let calendarEvent;
        if (event.domain === session) {
            calendarEvent = {
                id: event.event.session.id,
                title: (event.event.session.chapter ? event.event.session.chapter + ': ' : '') + event.event.session.name,
                date: event.event.session.startTime,
                eventType: {code: event.domain, name: event.domain.charAt(0).toUpperCase() + event.domain.substring(1)},
                category: event.event.category
            }
        } else if (event.domain === coursework) {
            calendarEvent = {
                id: event.event.id,
                title: event.event.title,
                date: event.event.effectiveDueDate,
                eventType: {code: event.domain, name: event.domain.charAt(0).toUpperCase() + event.domain.substring(1)},
                category: event.event.category
            }
        }
        formattedData.push(calendarEvent)
    });
    return formattedData;
}

export const getCalendarEventLink = (event) => {
    if (event.eventType.code === session) {
        return `/sessions/${event.id}`
    } else if (event.eventType.code === coursework) {
        return `/coursework/${event.id}/show`
    }
}

export const getCalendarEventClass = (event) => {
    if (event.eventType.code === session) {
        const eventTypeObj = calendarEventTypeClassNames[session];
        return eventTypeObj[event.category.code];
    } else {
        if (event.eventType.code === coursework) {
                const eventTypeObj = calendarEventTypeClassNames["assignment"];
                return eventTypeObj[event.category.code];
            }
    }
}

const parseEventTypes = (data) => {
    let map = new Map(data.map(item => [item.eventType.code, item.eventType]));
    return Array.from(map.values()).sort((a, b) => { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; })
}

const parseSessionCategories = (data) => {
    let map = new Map(data.map(item => [item.category.code, item.category]));
    return Array.from(map.values()).sort((a, b) => { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; })
}

const parseCourseworkCategories = (data) => {
    let map = new Map(data.map(item => [item.category.code, item.category]));
    return Array.from(map.values()).sort((a, b) => { return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0; })
}

export const calendarBuild = (data,selectedEventType) => {
    if ( data === undefined || data.length <= 0 ){
        return {
            filteredData:[],
            eventTypes: [],
            courseworkCategories: [],
            sessionCategories: []
        }
    }

    let courseworkData = data.filter((item) => item.eventType.code === "coursework");
    let sessionData = data.filter((item) => item.eventType.code === "session");
    return {
        filteredData: filterDataByEventType(data, selectedEventType),
        eventTypes: parseEventTypes(data),
        courseworkCategories: parseCourseworkCategories(courseworkData),
        sessionCategories: parseSessionCategories(sessionData)
    }
}

export const filterDataByEventType = (dataArray, eventTypeCode) => {
    if (eventTypeCode === 'all') {
        return dataArray
    } else {
        return dataArray.filter(x => x.eventType.code === eventTypeCode);
    }
}

export const filterDataByCategory = (dataArray, category, eventType) => {
    if (category === 'all') {
        return dataArray
    } else {
        return dataArray.filter(x => x.category.code === category && x.eventType.code === eventType);
    }
}