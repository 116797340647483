import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import ReactGA from 'react-ga';


const mapStateToProps = (state) => {
    return {
        ...state
    }
}

class AnnouncementDetail extends Component {
    constructor(props) {
        super(props)

        ReactGA.event({
            category: 'Announcement',
            action: 'Detail View',
        });

    }

    capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    render() {
        const announcementList = this.props.announcements.announcementList;
        const announcementId = this.props.match.params.announcementId;
        let announcement = announcementList.filter((announcement) => {
            return announcement.id.toString() === announcementId
        });

        return (
            <DocumentTitle title="Announcement Detail | Bootcamp Spot">
                <section className="page" data-component="announcement-detail">
                    <div className="row">

                        <div className="col-xs-12 col-md-8 col-lg-9">
                            <h1>Announcement</h1>
                            <p><strong>Title:</strong> {announcement[0].title}</p>
                            <p><strong>Context:</strong> {this.capitalizeFirstLetter(announcement[0].contextCode)}</p>
                        </div>
                        <div className="col-xs-12 col-md-4 col-lg-3 first-xs last-md text-right">
                            <Link to="/announcements">View all Announcements</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="card">
                                <p>{announcement[0].body}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </DocumentTitle>
        )
    }

}

export const ANNOUNCEMENTDETAIL = AnnouncementDetail;

export default connect(mapStateToProps)(AnnouncementDetail);
