import {openDocsTypes} from "../constants/actionTypes";
import {fulfilled, pending, rejected} from "../helpers/asyncActionGenerator";

export function getRawPayload(endpoint, payload, authToken) {
    const {REQUEST} = openDocsTypes;

    return async (dispatch) => {
        dispatch(pending(REQUEST));
        try {
            const response = await fetch("/api/instructor/v1/"+endpoint,
                {
                    headers: {'Content-Type': 'application/json', 'authToken': authToken},
                    method: "POST",
                    body: JSON.stringify(payload)
                }
            );

            let headers = {};
            for(let header of response.headers){
                headers[header[0]] = header[1];
            }

            let rspBody = '';
            if(response.ok){
                rspBody = await response.json();
            }
            else {
                rspBody = await response.text();
            }

            rspBody = (typeof rspBody  === "undefined")? '':rspBody ;

            const result = {
                endpoint: endpoint,
                response: {
                    payload: rspBody,
                    status:  response.status+' '+response.statusText,
                    headers: headers,
                }
            };

            dispatch(fulfilled(REQUEST, result));
        } catch (error) {
            const result = {
                endpoint: endpoint,
                response: {
                    payload: null,
                    status:  null,
                    headers: null,
                },
            };

            dispatch(rejected(REQUEST, result));
        }
    }
}
