import React, {Component} from 'react';
import {connect} from 'react-redux';
import DocumentTitle from 'react-document-title';

import Loading from '../../components/Loading';
import Alert from '../../components/Alert';
import ProfilePic from '../../components/ProfilePic';
import Icon from '../../components/Icon';
import {Link} from 'react-router-dom';
import {isStudent, isRole} from '../../helpers/roles';
import {assignmentContextTypes, roleTypes} from '../../constants/dataTypes';
import {fetchStudentProfile} from '../../actions/studentActions';
import moment from "moment/moment";
import {getContacts} from "../../actions/contactActions";
import AttendanceInfo from "../../components/AttendanceInfo";
import {isGradeIncomplete, minimumScoreRequiredToGrade} from "../../constants/grade";
import {Table, TBody, TH, THead, TR, TD} from "../../components/Table";
import {getFormattedDateString} from "../../helpers/dateTime";
import {displayGrade} from "../../helpers/grade";

var classNames = require('classnames');

const mapStateToProps = (state) => {
    return {
        user: state.user,
        enrollmentInfo: state.user.enrollmentInfo,
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        graduationRequirements: state.user.enrollmentInfo.course.graduationRequirements,
    }
}

export class StudentDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            student: {},
            selectedSection: null,
            pageLoaded: false,
            remoteAttendanceRequests: [],
            attendanceHistory: [],
            grades: [],
            metrics: {
                careerCompletion: null,
                academicAverage: null,
                academicAverageValue: null,
                academicRequiredForGraduationSubmitted: null,
                academicGeneralAssignmentsSubmitted: null,
                academicOptionalAssignmentSubmitted: null,
                careerGeneralAssignmentsSubmitted: null,
                careerOptionalAssignmentSubmitted: null,
            },
            unsubmittedAcademicAssignmentList: []
        }
    }

    /*
      - get student enrollment id from params
      - check if active role is 'student'; check if student is auth'd
      - pass enrollment id to fetchStudentProfile ("payload")
        - --> [return] data.data = student obj (id, grades, absences, etc.)
      - Use grades arr to calc metrics
      - Set student data as state
    */
    async componentDidMount() {
        const {activeRole, enrollmentInfo} = this.props;
        const studentEnrollmentId = this.props.match.params.studentEnrollmentId;

        if (isStudent(activeRole)) {
            if (enrollmentInfo.id !== parseInt(studentEnrollmentId, 10)) {
                this.props.history.push('/unauthorized');
            }
        }
        let payload = {enrollmentId: parseInt(studentEnrollmentId, 10)};
        let result = await fetchStudentProfile(payload);
        const grades = result.data.grades;
        const average = result.data.academicAverageGrade;
        let metrics = await this.calculateMetrics(grades, average);
        metrics.academicAverageValue = result.data.roundAcademicAverageValue;
        let contacts= await getContacts(enrollmentInfo.courseId);
        const unsubmittedAcademicAssignmentList = grades.filter(item => !item.submission && item.context.contextCode === assignmentContextTypes.ACADEMIC);

        this.setState({
            student: result.data.student,
            pageLoaded: true,
            remoteAttendanceRequests: result.data.remoteAttendanceRequests,
            attendanceHistory: result.data.attendanceHistory,
            grades: grades,
            metrics: metrics,
            ssmS: contacts.data.filter(o => o.role.courseRoleCode === "ssm" && o.contact.active),
            unsubmittedAcademicAssignmentList: unsubmittedAcademicAssignmentList
        })
    }

    calculateMetrics = (assignments, average) => {
        let grades = [];
        assignments.forEach((assignment) => {
            if (assignment.context.contextCode === assignmentContextTypes.ACADEMIC && assignment.assignment.required && assignment.submissionGrade) {
                grades.push(assignment.submissionGrade.grade)
            } else {
                return false
            }
        });

        const academicAssignments = assignments.filter((a) => a.context.contextCode === assignmentContextTypes.ACADEMIC);
        const careerAssignments = assignments.filter((a) => a.context.contextCode === assignmentContextTypes.CAREER);

        const academicRequiredForGraduationSubmitted = academicAssignments.filter((a) => {
            const isSubmittedIncomplete = a.submissionGrade && isGradeIncomplete(a.submissionGrade.grade);
            return a.assignment.requiredForGraduation && a.submission && !isSubmittedIncomplete
        }).length;

        const academicGeneralAssignmentsSubmitted = academicAssignments.filter((a) => {
            const isSubmittedIncomplete = a.submissionGrade && isGradeIncomplete(a.submissionGrade.grade);
            return !a.assignment.requiredForGraduation
                && a.assignment.required
                && a.submission
                && !isSubmittedIncomplete
        }).length;

        const academicIncompleteSubmissions = academicAssignments.filter((assignment) => {
            return assignment.assignment.required
                && assignment.submissionGrade
                && isGradeIncomplete(assignment.submissionGrade.grade)
        }).length;

        const academicRequiredOverdue = academicAssignments.filter((a) => {
            return a.assignment.requiredForGraduation
                && moment().isAfter(moment(a.assignment.effectiveDueDate))
                && (!a.submission || (a.submissionGrade && isGradeIncomplete(a.submissionGrade.grade)))
        }).length;

        const academicGeneralOverdue = academicAssignments.filter((a) => {
            return !a.assignment.requiredForGraduation
                && moment().isAfter(moment(a.assignment.effectiveDueDate))
                && a.assignment.required
                && (!a.submission || (a.submissionGrade && isGradeIncomplete(a.submissionGrade.grade)))
        }).length;

        const academicOptionalOverdue = academicAssignments.filter((a) => {
            return !a.assignment.requiredForGraduation
                && moment().isAfter(moment(a.assignment.effectiveDueDate))
                && !a.assignment.required
                && (!a.submission || (a.submissionGrade && isGradeIncomplete(a.submissionGrade.grade)))
        }).length;

        const academicOptionalAssignmentSubmitted = academicAssignments.filter((a) => {
            const isSubmittedIncomplete = a.submissionGrade && isGradeIncomplete(a.submissionGrade.grade);
            return !a.assignment.requiredForGraduation && !a.assignment.required
                && a.submission && !isSubmittedIncomplete
        }).length;


        const careerGeneralAssignmentsSubmitted = careerAssignments.filter((assignment) => {
            return assignment.assignment.required && assignment.submission
        }).length;

        const careerOptionalAssignmentSubmitted = careerAssignments.filter((assignment) => {
            return !assignment.assignment.required && assignment.submission
        }).length;

        const careerGeneralOverdue = careerAssignments.filter((assignment) => {
            return assignment.assignment.required
                && !assignment.submission && moment().isAfter(moment(assignment.assignment.effectiveDueDate))
        }).length;

        const careerOptionalOverdue = careerAssignments.filter((assignment) => {
            return !assignment.assignment.required
                && !assignment.submission && moment().isAfter(moment(assignment.assignment.effectiveDueDate))
        }).length;


        let careerRequired = careerAssignments.filter((assignment) => {
            return assignment.assignment.required
        }).length;

        let careerSubmitted = careerAssignments.filter((assignment) => {
            return assignment.assignment.required && assignment.submission
        }).length;

        let percentage = (careerSubmitted / careerRequired) * 100;

        return {
            academicAverage: average,
            academicRequiredOverdue: academicRequiredOverdue,
            academicGeneralOverdue: academicGeneralOverdue,
            academicOptionalOverdue: academicOptionalOverdue,
            careerGeneralOverdue: careerGeneralOverdue,
            careerOptionalOverdue: careerOptionalOverdue,
            careerCompletion: percentage > 0 ? percentage.toFixed() : 0,
            academicRequiredForGraduationSubmitted: academicRequiredForGraduationSubmitted,
            academicGeneralAssignmentsSubmitted: academicGeneralAssignmentsSubmitted,
            academicOptionalAssignmentSubmitted: academicOptionalAssignmentSubmitted,
            careerGeneralAssignmentsSubmitted: careerGeneralAssignmentsSubmitted,
            careerOptionalAssignmentSubmitted: careerOptionalAssignmentSubmitted,
            academicAssignments: academicAssignments.map((a)=>a.assignment),
            careerAssignments: careerAssignments.map((a)=>a.assignment),
            academicIncompleteSubmissions: academicIncompleteSubmissions,
        }
    }

    gradeVisualClass(grade) {
        if (grade === null || grade === undefined) {
            return ''
        }
        return classNames({
            'text-red': grade[0] === 'F' || grade[0] === 'I',
            'text-yellow': grade[0] === 'C' || grade[0] === 'D',
            'text-green': grade[0] === 'A' || grade[0] === 'B'
        })
    }

    minimumGradeRequired = (minimumScoreRequired) => {
        if (!!minimumScoreRequired) {
            let roundedScore = Math.round(minimumScoreRequired);
            let scale = [55, 62, 65, 70, 72, 75, 78, 82, 85, 88, 92, 95, 100];
            let grade = scale[0];

            for (let i = 0; i < scale.length; i++) {
                if (scale[i] >= roundedScore) {
                    grade = scale[i]
                    break;
                }
            }
            return minimumScoreRequiredToGrade(grade);
        } else {
            return "---"
        }
    }

    render() {
        const {error, student, pageLoaded, metrics, ssmS, remoteAttendanceRequests, unsubmittedAcademicAssignmentList} = this.state;
        const {activeRole, enrollmentInfo, match, graduationRequirements, user} = this.props;
        const showEditPictureBtn = (enrollmentInfo.id === parseInt(match.params.studentEnrollmentId,10));
        const {maxMissedGeneralAssignment, maxMissedRequiredAssignment} = graduationRequirements;
        const allowableMisses = maxMissedGeneralAssignment+maxMissedRequiredAssignment;
        const exhaustedAllowableGenAssignments = (maxMissedGeneralAssignment > 0 && maxMissedGeneralAssignment - metrics.academicGeneralOverdue <= 0 );
        const exhaustedAllowableReqAssignments = (maxMissedRequiredAssignment > 0 && maxMissedRequiredAssignment - metrics.academicRequiredOverdue <= 0);

        const minimumGradeRequired = this.minimumGradeRequired(graduationRequirements.minimumScoreRequired);

        if(isStudent(activeRole)){
            const unCachedUrl = user.profile.nexusAvatarUrl;
            student.nexusAvatarUrl = unCachedUrl// + `&cache=${new Date().valueOf()}`
        }

        return (
            <DocumentTitle
                title={`${!!student.firstName ? student.firstName : ''} ${!!student.lastName ? student.lastName : ''} Profile | Bootcamp Spot`}>
                {pageLoaded ?
                    <section className="page" data-component="student-detail">
                        {error ? (
                            <Alert type="error">
                                <p>{error}</p>
                            </Alert>
                        ) : (
                            <div className="row">
                                <div className="col-xs-12 col-gutter-lr text-right margin-t-3-xs margin-t-0-sm">
                                    {!isStudent(activeRole) &&
                                    <p><Link to="/students">View all Students</Link></p>
                                    }
                                </div>
                                <div className="col-xs-12 col-sm-3 text-center-xs text-left-sm">
                                    <ProfilePic user={student}/>
                                </div>
                                <div className="col-xs-12 col-sm-9 text-center-xs text-left-sm">
                                  <h1>{ (!!student.firstName && !!student.lastName) ? `${student.firstName} ${student.lastName}` : 'Student Profile' }</h1>
                                  <p>
                                    <Link to={`mailto:${student.email}`}>
                                      <Icon icon="envelope" ariaLabel={"envelope"}/>&nbsp;
                                      {student.email}
                                    </Link>
                                  </p>
                                  {(student.githubUserName) ? (
                                    <p>
                                      <Link to={`https://github.com/${student.githubUserName}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                      >
                                        <Icon icon="external-link-square-alt" ariaLabel="external-link" />&nbsp;
                                        github.com/{student.githubUserName}
                                      </Link>
                                      <p>(Link opens in new window)</p>
                                    </p>
                                  ) : ""}
                                    {showEditPictureBtn &&
                                      <p>
                                        <Link to={`/manage-profile/${this.props.match.params.studentEnrollmentId}`} style={{borderBottom: 'none'}}>
                                          <button className="btn-edit btn-small">Edit profile picture</button>
                                        </Link>
                                      </p>
                                    }
                                </div>

                                {isStudent(activeRole) &&
                                    <div className="col-xs-12">
                                            <Alert type={"info"}>
                                                In order to successfully complete the program, you must complete all Required Project Assignments. You can miss up to {allowableMisses} General Coursework Assignments Assignments.
                                                Any ‘Optional’ Coursework Assignments are not factored into course completion requirements.
                                            </Alert>
                                    </div>
                                }
                                <div className="col-xs-12 col-no-gutter">
                                    {
                                        <React.Fragment>
                                            <div className="col-xs-12">
                                                <h2 className={"margin-t-4"}>My Progress - Academic Assignments</h2>

                                                <div className="card">
                                                    <div className="row text-center around-xs">
                                                        {(graduationRequirements.minimumScoreRequired !== null && graduationRequirements.minimumScoreRequired !== undefined) &&
                                                            <div className="minimumGradeRequired col-xs-12 col-md-6 col-gutter-lr">
                                                                <p className="title">Minimum Grade Required: </p>
                                                                <p className="headline">{minimumGradeRequired}</p>
                                                            </div>
                                                        }
                                                        <div className="col-xs-12 col-md-6 col-gutter-lr">
                                                            <p className="title">Average Academic</p>
                                                            {metrics.academicAverage ? (
                                                                <p className={'headline ' + this.gradeVisualClass(metrics.academicAverage)}>
                                                                    { displayGrade(enrollmentInfo, metrics.academicAverage, metrics.academicAverageValue) }
                                                                </p>
                                                            ) : <p className='headline'>---</p>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <h3 className={"margin-t-3"}>Allowable Missed</h3>
                                                <div className="card">
                                                    <div className="row text-center around-xs">
                                                        <div className="col-xs-12 col-md-6 col-gutter-lr">
                                                            <p className="title">Allowable Missed Projects</p>
                                                            <p className="headline">{maxMissedRequiredAssignment}</p>
                                                        </div>

                                                        <div className="col-xs-12 col-md-6 col-gutter-lr">
                                                            <p className="title">Allowable Missed Homework Assignments</p>
                                                            <p className="headline">{maxMissedGeneralAssignment}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h3 className={"margin-t-3"}>Submission Details</h3>
                                                <h4 className={"margin-t-3"}>Projects</h4>

                                                <div className="card margin-b-3">
                                                    <div className="row text-center around-xs">
                                                        <div className="col-xs-3 col-gutter-lr">
                                                            <p className="title">Total Projects</p>
                                                            <p className="headline">{metrics.academicAssignments.filter((a)=>a.requiredForGraduation).length}</p>
                                                        </div>

                                                        <div className="col-xs-3 col-gutter-lr">
                                                            <p className="title">Projects Submitted</p>
                                                            <p className="headline">{metrics.academicRequiredForGraduationSubmitted}</p>
                                                        </div>

                                                        <div className="col-xs-3 col-gutter-lr">
                                                            <p className="title">Projects Unsubmitted</p>
                                                            <p className={
                                                                `headline ${exhaustedAllowableReqAssignments?'text-red':''}`
                                                            }>{metrics.academicRequiredOverdue}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h4 className={"margin-t-3"}>Homework Assignments</h4>
                                                <div className="card margin-b-3">
                                                    <div className="row text-center around-xs">
                                                        <div className="col-xs-3 col-gutter-lr">
                                                            <p className="title">Total Homework Assignments</p>
                                                            <p className="headline">{metrics.academicAssignments.filter((a)=> !a.requiredForGraduation && a.required).length}</p>
                                                        </div>

                                                        <div className="col-xs-3 col-gutter-lr">
                                                            <p className="title">Homework Assignments Submitted</p>
                                                            <p className="headline">{metrics.academicGeneralAssignmentsSubmitted}</p>
                                                        </div>

                                                        <div className="col-xs-3 col-gutter-lr">
                                                            <p className="title">Homework Assignments Unsubmitted</p>
                                                            <p className={
                                                                    `headline ${exhaustedAllowableGenAssignments?'text-red':''}`
                                                                }>{metrics.academicGeneralOverdue}
                                                            </p>
                                                        </div>

                                                        <div className="col-xs-3 col-gutter-lr">
                                                            <p className="title">Homeworkk Assignments Incomplete</p>
                                                            <p className="headline">{metrics.academicIncompleteSubmissions}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h4 className={"margin-t-3"}>Optional Assignments</h4>
                                                <div className="card margin-b-3">
                                                    <div className="row text-center around-xs">
                                                        <div className="col-xs-3 col-gutter-lr">
                                                            <p className="title">Total</p>
                                                            <p className="headline">{metrics.academicAssignments.filter((a)=>  !a.required).length}</p>
                                                        </div>

                                                        <div className="col-xs-3 col-gutter-lr">
                                                            <p className="title">Submitted</p>
                                                            <p className="headline">{metrics.academicOptionalAssignmentSubmitted}</p>
                                                        </div>
                                                        <div className="col-xs-3 col-gutter-lr">
                                                            <p className="title">Unsubmitted and Overdue</p>
                                                            <p className="headline">{metrics.academicOptionalOverdue}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                {isRole([roleTypes.ADMIN, roleTypes.SSM, roleTypes.INSTRUCTOR, roleTypes.TA], activeRole) &&
                                                    <React.Fragment>
                                                        <h4 className="margin-t-4 margin-b-0">Unsubmitted Assignments</h4>
                                                        {unsubmittedAcademicAssignmentList.length > 0 ?
                                                            <Table className="margin-b-3">
                                                            <THead>
                                                                <TR>
                                                                    <TH col="6">Assignment</TH>
                                                                    <TH col="2">Optional</TH>
                                                                    <TH col="4">Due date</TH>
                                                                </TR>
                                                            </THead>
                                                            <TBody>
                                                                {unsubmittedAcademicAssignmentList.map((item, index) => {
                                                                    const isOptional = !item.assignment.required && !item.assignment.requiredForGraduation;
                                                                    return (
                                                                    <TR key={index}>
                                                                        <TD col="6" className="text-bold"><Link
                                                                            to={`/coursework/${item.assignment.id}/show`}>{item.assignment.title}</Link></TD>
                                                                        <TH col="2">
                                                                            <span className="font-lg">
                                                                                <Icon
                                                                                    icon={(isOptional) ? "check" : "times"}
                                                                                    options="fa-fw"
                                                                                    ariaHidden="false"
                                                                                    ariaLabel={`${(isOptional) ? "Is" : "Is not"} optional`}
                                                                                />
                                                                            </span>
                                                                        </TH>
                                                                        <TD col="4">{getFormattedDateString(item.assignment.effectiveDueDate)}</TD>
                                                                    </TR>
                                                                    )
                                                                })
                                                                }
                                                            </TBody>
                                                        </Table> :
                                                            <Alert type="info" className="margin-t-2">
                                                                 No unsubmitted assignments
                                                            </Alert>
                                                        }
                                                    </React.Fragment>
                                                }
                                            </div>

                                            <AttendanceInfo title={"Attendance - Academic"}
                                                            attendanceHistory={this.state.attendanceHistory}
                                                            remoteAttendanceRequests={remoteAttendanceRequests}
                                                            topLevel={false}
                                                            showWarning={true}
                                                            ssmS={ssmS}/>

                                            <div className="col-xs-12">
                                                <h2>My Progress - Career Milestones</h2>
                                                <div className="card">
                                                    <div className="row text-center around-xs">


                                                        <div className="col-xs-12 col-sm-6 col-gutter-lr">
                                                            <p className="title">Submitted</p>
                                                            <p className="headline">{
                                                                metrics.careerGeneralAssignmentsSubmitted
                                                                + metrics.careerOptionalAssignmentSubmitted
                                                            }</p>
                                                        </div>

                                                        <div className="col-xs-12 col-sm-6 col-gutter-lr">
                                                            <p className="title">Unsubmitted</p>
                                                            <p className="headline">{
                                                                metrics.careerGeneralOverdue
                                                                + metrics.careerOptionalOverdue
                                                            }</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        }
                                </div>
                            </div>
                        )}
                    </section>
                    :
                    <div className="col-xs-12">
                        <div className="text-center"><Loading/></div>
                    </div>
                }
            </DocumentTitle>
        )
    }

}

export const STUDENTDETAIL = StudentDetail
export default connect(mapStateToProps)(StudentDetail);
