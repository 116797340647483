import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import {Flag} from 'flag';
import Icon from '../Icon';
import {userActions} from '../../actions';
import NotificationDropdownItem from '../../components/NotificationDropdownItem';
import { isStudent } from '../../helpers/roles';
import ProfilePic from '../../components/ProfilePic';
import {getPaymentPortalLink, setPaymentPortalCookie} from "../../helpers/utils";
import {
    fetchNotificationsDropdown, markAllAsRead,
    updateNotifications
} from "../../actions/notificationsActions";
import {isEmpty, get} from 'lodash';

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
};

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeNotifications: false,
            profileDropdownActive: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.active !== nextProps.active) {
            this.setState({navOpen: nextProps.active});
        }

        if (!isEmpty(nextProps.user.enrollmentInfo)) {
            if (this.props.user.enrollmentInfo !== nextProps.user.enrollmentInfo) {
                const enrollmentId = nextProps.user.enrollmentInfo.id;
                const payload = {enrollmentId: enrollmentId};
                this.props.dispatch(fetchNotificationsDropdown(payload));
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideDropdownClick, false);
    }

    handleMarkAllAsReadClick = async() => {
        try {
            const enrollmentInfo = this.props.user.enrollmentInfo;
            const enrollmentId = enrollmentInfo.id;

            const payload = {enrollmentId: enrollmentId};
            await markAllAsRead(payload);

            const {notificationList, notificationsDropdownList} = this.props.notifications;

            let handledNotificationList = notificationList;
            for (let i = 0; i < handledNotificationList.length; i++) {
                handledNotificationList[i].handled = true;
            }

            let handledLatest = notificationsDropdownList;
            for (let i = 0; i < handledLatest.length; i++) {
                handledLatest[i].handled = true;
            }
            this.props.dispatch(updateNotifications(handledNotificationList, handledLatest));
        } catch(err) {
            console.error(err)
        }
    }

    handleBellClick = () => {
        this.addHandleOutsideDropdownClick(!this.state.activeNotifications);
        this.toggleNotificationsDropdown();
    }

    handleBellEnterPress = ({ key }) => {
        if (key !== 'Enter') return;
        this.handleBellClick();
    }

    handleProfileClick = () => {
        this.addHandleOutsideDropdownClick(!this.state.profileDropdownActive);
        this.toggleProfileDropdown();
    }

    handleOutsideDropdownClick = (e) => {
        if (this.node && this.node.contains(e.target)) {
            return;
        }

        if (this.state.activeNotifications) {
            this.toggleNotificationsDropdown();
        }

        if (this.state.profileDropdownActive) {
            this.handleProfileClick();
        }
    }

    addHandleOutsideDropdownClick = (isActiveState) => {
        if (isActiveState) {
            document.addEventListener('click', this.handleOutsideDropdownClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideDropdownClick, false);
        }
    }

    toggleNotificationsDropdown = () => {
        const currentNotifications = this.state.activeNotifications;
        this.setState({
            activeNotifications: !currentNotifications,
            profileDropdownActive: false
        });
    };

    toggleProfileDropdown = () => {
        const profileDropdownActive = this.state.profileDropdownActive
        this.setState({
            profileDropdownActive: !profileDropdownActive,
            activeNotifications: false
        });
    }

    closeDropdowns = () => {
        this.setState({
            profileDropdownActive: false,
            activeNotifications: false
        })
        document.removeEventListener('click', this.handleOutsideDropdownClick, false);
    }

    logout = () => {
        if (this.state.activeNotifications) {
            this.handleBellClick()
        }
        const {dispatch} = this.props;
        dispatch(userActions.logout());
    }

    renderLogo = () => {
        let alt = 'Bootcamp Spot'
        let logoSrc = 'https://s3.amazonaws.com/bcs-media-dev/ui/bcs-logo.svg'
        let url = '/universities'
        if (this.props.user.enrollmentInfo) {
            logoSrc = this.props.user.enrollmentInfo.course.cohort.program.university.logoUrl
            alt = this.props.user.enrollmentInfo.course.cohort.program.university.name
            url = '/'
        }
        return (
            <span className="header-logo">
                <Link to={url} aria-label="Bootcamp Spot home page"><img src={logoSrc} alt={alt}/></Link>
            </span>
        )
    }

    renderPaymentPortalLink = () => {
        const url = getPaymentPortalLink();
        const universityId = this.props.user.enrollmentInfo.course.cohort.program.university.id;
        return (
            <a
                aria-label="Go to billing page. Link opens in a new window."
                href={`${url}/billing?ref=${universityId}`}
                role="menuitem"
                target="_blank" rel="noopener noreferrer"
                title="Billing"
                onClick={() => setPaymentPortalCookie(this.props.user.data.authToken)}
            >
                Billing
            </a>
        )
    }

    render() {
        const unreadNotifications = this.props.notifications.unreadNotifications;
        let notificationList = this.props.notifications.notificationsDropdownList;
        const {profile} = this.props.user;
        const { enrollmentInfo } = this.props.user;

        let activeRole = '';
        if (enrollmentInfo) {
            activeRole = this.props.user.enrollmentInfo.courseRole.courseRoleCode;
        }
        const {activeNotifications} = this.state;

        const list = notificationList.slice(0, 5).map((notification) =>
            <NotificationDropdownItem dispatch={this.props.dispatch} item={notification} key={notification.id.toString()}/>
        );
        
        const hideWebToCase = get(this.props, 'user.enrollmentInfo.course.cohort.cohortUiControl.hideWebToCase', false)
        if (this.props.includeNav) {
            return (
                <header
                    className={`bcs-header ${this.props.condenseNav ? 'expanded' : ''}`}
                    data-component="header"
                    ref={node => { this.node = node; }}
                >
                    <div className='header-back-logo-container'>
                        {this.renderLogo()}
                    </div> 

                    <nav className="header-nav">
                    <ul>
                        <li className={`header-notifications ${activeNotifications ? 'active' : ''}`}>
                            <Icon
                                icon="bell"
                                unread={unreadNotifications ? unreadNotifications : 0}
                                options="fa-fw notification-icon link-with-unread"
                                ariaHidden="false"
                                ariaExpanded={activeNotifications ? "true" : "false"}
                                onClickHandler={()=>this.handleBellClick()}
                                onKeyPress={e=>this.handleBellEnterPress(e)}
                                tabIndex="0"
                                ariaLabel={`Toggle notifications, ${unreadNotifications ? unreadNotifications : 0} new messages`}
                                role={"button"}
                            />
                            <ul className="notification-dropdown" aria-live={this.state.activeNotifications ? 'polite' : null}>
                                {list.length ? (
                                    <React.Fragment>
                                        <li className="notification-dropdown-title">
                                            <p className="headline-three">Notifications</p>
                                            <button className="btn-create btn-tertiary" tabIndex="0" onClick={(e) => this.handleMarkAllAsReadClick()}>Mark All as Read</button>
                                        </li>
                                        {list}
                                        <li className="notification-dropdown-see-all">
                                            <Link tabIndex="0" onClick={(e) => this.toggleNotificationsDropdown()} to="/notifications">See All</Link>
                                        </li>
                                    </React.Fragment>
                                ) : (
                                    <li>Currently No Notifications</li>
                                ) }
                            </ul>
                        </li>

                        <Flag name="billing.bcsBilling">
                            {!get(this.props,'user.enrollmentInfo.course.cohort.cohortUiControl.hideBilling',false) &&
                            !isEmpty(this.props.user.enrollmentInfo) ? (
                            <li className="hide-xs show-md">
                                {this.renderPaymentPortalLink()}
                            </li>
                            ) : null}
                        </Flag>
                        <Flag name="support.web2case">
                            {
                                !hideWebToCase && <li className="hide-xs show-md">
                                                    <Link to="/support" role="menuitem" aria-label="Course Support">Support</Link>
                                                 </li>

                            }
                        </Flag>
                        <li className="hide-xs show-md">
                            <Link
                                aria-label="Logout"
                                to="/"
                                onClick={()=>this.logout()}
                            >
                                Logout
                            </Link>
                        </li>
                        <li className={`header-profile${this.state.profileDropdownActive ? ' active' : ''}`}>
                            <Link to="#" onClick={()=> this.handleProfileClick()} aria-label="My Profile">
                                <ProfilePic user={profile}/>
                            </Link>
                            <ul className="profile-dropdown">
                                <li>{profile.firstName} {profile.lastName}</li>
                                { isStudent(activeRole) ? (
                                    <li>
                                        <Link to={`/students/${this.props.user.enrollmentInfo.id}`} role="menuitem" aria-label="View Profile" onClick={()=> this.closeDropdowns()}>View Profile</Link>
                                    </li>
                                ) : null }
                                {/* <li>
                                    <Link to={`/manage-profile/${this.props.user.data.userId}`} role="menuitem" aria-label="Manage Profile" onClick={()=> this.closeDropdowns()}>Manage Profile</Link>
                                </li> */}
                            </ul>
                        </li>
                    </ul>
                    </nav>
                </header>
            )
        } else {
            return (
                <header className="bcs-header margin-l-0" style={{position: 'relative'}} data-component="header">
                    <div className='header-back-logo-container'>
                        {this.renderLogo()}
                    </div>
                </header>
            )
        }
    }
};

export const HEADER = Header;
export default connect(mapStateToProps)(Header);
