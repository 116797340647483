export function generateNotificationAndAccouncementClassnames(isRead, type) {

	let options;

	isRead ?
		options = {
				notification: `${type}-read`,
				label: `${type} read`
		}
		:
		options = {
				notification: '',
				label: `unread ${type}`
		}
	return options;
};
