import {Component} from "react";
import DocumentTitle from "react-document-title";
import {connect} from 'react-redux';
import React from "react";
import {getRawPayload} from "../../actions/openDocsActions";
import OpenDocsApiResponse from "../../components/OpenDocsApiResponse";
import OpenDocsHeader from "../../components/OpenDocsHeader";
import Input from "../../components/Input";


const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps,
        openDocs: state.openDocs,
        authToken: state.user.data.authToken,
    }
};

class ApiDocsLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tryItUrl: ''
        };
    }

    componentWillReceiveProps(newProps) {
        if (typeof newProps.openDocs !== "undefined") {
            const newOpenDocs = newProps.openDocs;
            if (newOpenDocs && newOpenDocs[this.state.tryItUrl]) {
                this.setState({
                    payloadRsp: newOpenDocs[this.state.tryItUrl].payload,
                    headersRsp: newOpenDocs[this.state.tryItUrl].headers,
                    status: newOpenDocs[this.state.tryItUrl].status,
                });
            }
        }
    }

    handleChange(event) {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        const obj = {};
        obj[fieldName] = fieldValue;
        this.setState(obj)
    }

    handleOnClick() {
        const {dispatch, authToken} = this.props;
        const {email, password} = this.state;
        const ep = `login`;
        this.setState({
            tryItUrl: ep
        });

        dispatch(getRawPayload(ep, {email: email, password: password}, authToken));
    }

    render() {
        const {payloadRsp, headersRsp, status} = this.state;

        return (
            <DocumentTitle title="API Access - Login | Bootcamp Spot">
                <section>
                    <OpenDocsHeader
                        endpoint={"/api/instructor/v1/login"}
                        description={"Allows to get an authorization token with a set of credentials."}
                        type={"POST"}
                        exampleBody={{email: "test@email.com", password:"mysecretPassword"}}
                        exampleResponses={[
                            {
                                code: 200,
                                body:{
                                    "success": true,
                                    "errorCode": null,
                                    "resetToken": null,
                                    "authenticationInfo": {
                                        "userId": 13786,
                                        "firstLogin": false,
                                        "active": true,
                                        "authToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...J9"
                                    }
                                },
                                rows: 11,
                                description: "Successful response."
                            },{
                                code: 200,
                                body:{
                                    "success": false,
                                    "errorCode": "INVALID_CREDENTIALS",
                                    "resetToken": null,
                                    "authenticationInfo": null
                                },
                                rows: 6,
                                description: "Invalid credentials."
                            }
                        ]}
                    />

                    <div className="row">
                        <div className="col-xs-12">
                            <h1 className={"border-bottom"}>Try it out</h1>

                            <Input
                                label="email"
                                type="email"
                                id="email"
                                placeholder="<email>"
                                helper="Email"
                                aria-required={true}
                                onChange={this.handleChange.bind(this)}
                            />
                            <Input
                                label="password"
                                type="password"
                                id="password"
                                placeholder="<password>"
                                helper="Password"
                                aria-required={true}
                                onChange={this.handleChange.bind(this)}
                            />
                            <button onClick={this.handleOnClick.bind(this)}>Submit</button>
                        </div>
                    </div>


                    <OpenDocsApiResponse
                        headers={headersRsp}
                        payload={payloadRsp}
                        status={status}
                    />

                </section>
            </DocumentTitle>
        )
    }
};

export default connect(mapStateToProps)(ApiDocsLogin);
