import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import ReactGA from 'react-ga';
import {isEmpty} from 'lodash';

import Icon from '../../components/Icon';
import Alert from '../../components/Alert';
import Loading from '../../components/Loading'
import FormNavigationPrompt from '../../components/NavigationPrompt';

import {fetchSingleCoursework, updateSubmission, createSubmission} from '../../actions/courseworkActions';

import {assignmentContextTypes, availableCareerStatus, finalCareerUrlList} from '../../constants/dataTypes';
import {gradeColorsMap, isGradeIncomplete} from "../../constants/grade";

import {runCareerServiceUrlValidation, milestoneType2VarName, runCareerServiceMilestoneUrlValidation, hasDuplicateUrlsValidation} from './helper';

import CareerAssignment from './careerAssignment'
import StandardCourseworkSubmit from './standardCourseworkSubmit'
import {GITHUB} from "../../constants/careerConstants";
import { get } from "lodash";

const mapStateToProps = (state) => {
    return {
        userId: state.user.data.userId,
        enrollmentId: state.user.enrollmentInfo.id,
        enrollmentInfo: state.user.enrollmentInfo
    }
};

class CourseworkSubmit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignment: null,
            milestoneTypes: null,
            submissionLoading: true,
            urlList: [],
            successRedirect: false,
            errorRedirect: false,
            redirectUrl: null,
            singleUrl: '',
            singleUrlTitle: '',
            urlError: '',
            careerSubmissionTitles: finalCareerUrlList,
            courseworkNotes: '',
            submitError: '',
            buttonDisabled: false,
            milestoneUrls: [],
            submissionRequired: false,
        }
    }

    async componentDidMount() {
        const {match} = this.props;
        const assignmentId = parseInt(match.params.courseworkId, 10);
        let result = await fetchSingleCoursework(assignmentId);
        this.setState( () => {
            const milestoneTypes = get(result.data, 'milestoneTypes', []);
            const submissionRequired = (milestoneTypes.length > 0);
            const initialState = {
                assignment: result.data.assignment,
                milestoneTypes: milestoneTypes,
                urlList: result.data.assignment.submission ? result.data.assignment.submission.submissionUrlList : [],
                courseworkNotes: result.data.assignment.submission ? result.data.assignment.submission.notes : '',
                submissionLoading: false,
                milestoneUrls: [],
                submissionRequired: submissionRequired
            }
            if (submissionRequired) {
                for (const i in initialState.milestoneTypes) {
                    const name = initialState.milestoneTypes[i].name
                    const element = initialState.urlList.find( element => element.title === name)
                    const url = typeof element === 'undefined' ? '' : element.url
                    initialState.milestoneUrls.push({name: name, url: url, id: milestoneType2VarName(name)})
                }
            }
            return initialState
        })
    }

    validateCourseworkSubmission = (linkLabelChoices) => {
        const {milestoneUrls, assignment, urlList, submissionRequired} = this.state;
        if (assignment.context.contextCode !== "career" && isEmpty(urlList)) {
            return 'You must add at least one URL via the "Add URL" button before submitting';
        }
        if (assignment.context.contextCode === "career" && submissionRequired && isEmpty(milestoneUrls) && linkLabelChoices !== undefined && !isEmpty(linkLabelChoices)) {
            return 'This Milestone requires a URL submission/s to complete. Please attach your materials before submitting'
        }
        if (assignment.context.contextCode === "career" && submissionRequired) {
            let hasMilestoneForSubmission = false;
            for (let i in this.state.milestoneUrls) {
                const milestone = this.state.milestoneUrls[i]
                const milestoneUrl = milestone.url.trim();
                if (milestoneUrl.length > 0) {
                    hasMilestoneForSubmission = true;
                    const urlValidationResponse = runCareerServiceMilestoneUrlValidation(this.state.urlList, milestone.id, milestoneUrl);
                    if (urlValidationResponse != null) {
                        return urlValidationResponse;
                    }
                    if (hasDuplicateUrlsValidation(milestoneUrls, milestoneUrl)) {
                        return "Submissions may not contain duplicate. Please ensure each Milestone URL is unique"
                    }
                }
            }
            if (!hasMilestoneForSubmission) {
                return 'This Milestone requires a URL submission/s to complete. Please attach your materials before submitting';
            }
        }
        return null;
    }

    submitCoursework = async (event, linkLabelChoices) => {
        event.preventDefault();
        if (this.state.buttonDisabled) {
            return;
        }
        this.setState({buttonDisabled: true});

        const {milestoneUrls, assignment, courseworkNotes, urlList, submissionRequired} = this.state;
        const {enrollmentId} = this.props;
        let courseworkSubmitted = assignment.submission;

        if (courseworkSubmitted) {
            if (!window.confirm("It seems you already submitted this assignment, are you sure you want to edit your submission?")) {
                this.setState({buttonDisabled: false});
                return
            }
        }

        const errorMessage = this.validateCourseworkSubmission(linkLabelChoices);
        if (errorMessage != null) {
            window.alert(errorMessage)
            this.setState({buttonDisabled: false});
            return
        }

        // if career, redirectURL should be to /career-services
        let redirectUrl = "/coursework";
        if (assignment.context.contextCode === assignmentContextTypes.CAREER) {
            redirectUrl = "/career-services"
        }

        try {
            this.setState({submissionLoading: true});
            let submission = {
                id: assignment.submission ? assignment.submission.id : null,
                assignmentId: assignment.id,
                studentEnrollmentId: enrollmentId,
                notes: courseworkNotes
            };
            let submissionUrlList = [];
            if (assignment.context.contextCode === "career") {
                if (submissionRequired) {
                    for (let i in milestoneUrls) {
                        const milestone = milestoneUrls[i];
                        const milestoneUrl = milestone.url.trim();
                        if (milestoneUrl.length > 0) {
                            const element = urlList.find(element => element.title === milestone.name);
                            submissionUrlList.push({
                                id: typeof element !== 'undefined' ? element.id : null,
                                submissionId: assignment.submission ? assignment.submission.id : null,
                                url: milestoneUrl,
                                title: milestone.name
                            })
                        }
                    }
                }
            } else {
                submissionUrlList = urlList.map(url => (
                    {
                        id: url.id ? url.id : null,
                        submissionId: assignment.submission ? assignment.submission.id : null,
                        url: url.url,
                        title: url.title
                    }
                ));
            }

            if (courseworkSubmitted) {
                await updateSubmission(submission, submissionUrlList)
            } else {
                await createSubmission(submission, submissionUrlList)
            }

            ReactGA.event({
                category: 'Coursework',
                action: 'Submit Coursework',
            });
        } catch (error) {
            this.handleError(error, "There has been an error while saving your submission", redirectUrl);
            return;
        }

        try {
            const assignmentId = assignment.id;
            await fetchSingleCoursework(assignmentId);
        } catch (error) {
            this.handleError(error, "Your submission was saved, but there was an error while refreshing this page", redirectUrl);
            return;
        }

        this.setState({
            submissionLoading: false,
            successRedirect: true,
            errorRedirect: false,
            redirectUrl: redirectUrl
        })
    }

    handleError(error, localMessage, redirectUrl) {
        if (error.response && error.response.data.errorMessage) {
            this.setState({
                submitError: "Error: " + error.response.data.errorMessage,
                submissionLoading: false,
                errorRedirect: true,
                redirectUrl: redirectUrl
            })
        } else {
            this.setState({
                submitError: localMessage,
                submissionLoading: false,
                errorRedirect: true,
                successRedirect: false,
                redirectUrl: redirectUrl
            })
        }
    }

    handleUrlAddition = async (linkLabelChoices) => {
        const {urlList, singleUrl, singleUrlTitle, assignment} = this.state;

        const setUrlState = async () => {
            let newState = urlList;
            newState.push({url: singleUrl, title: singleUrlTitle})
            await this.setState({
                urlList: newState,
                singleUrl: '',
                singleUrlTitle: ''
            })
        };

        if (await !this.validUrl(this.state.urlList, singleUrlTitle, singleUrl)) {
            window.alert(this.state.urlError)
            this.setState({
                singleUrl: '',
                singleUrlTitle: ''
            })
            return false;
        } else {
            if (assignment.context.contextCode === "career" && linkLabelChoices !== undefined && !isEmpty(linkLabelChoices)) {
                if (singleUrlTitle.length === 0) {
                    window.alert('You must select a title for each URL submission')
                    return false;
                } else {
                    setUrlState()
                }
            } else {
                setUrlState()
            }
        }
        return true;
    };

    validUrl = (currentUrls = [], currentUrlTitle, currentUrl) => {
        const urlValidationResponse = runCareerServiceUrlValidation(currentUrls, currentUrlTitle, currentUrl);

        if (urlValidationResponse !== null) {
            this.setState({
                urlError: urlValidationResponse
            });
            return false;
        }

        return true;
    };

    handleChange = (event) => {
        const name = event.target.id
        const value = event.target.value
        this.setState(prevState => {
            const state = {...prevState}
            const prefix = 'milestoneUrl'
            if (name.startsWith(prefix)) {
                for (let i in prevState.milestoneUrls) {
                    if (name === prefix + prevState.milestoneUrls[i].id) {
                        prevState.milestoneUrls[i].url = value
                    }
                }
            } else {
                state[name] = value
            }
            return state
        })
    }

    goBack = () => {
        if (this.state.singleUrl || this.state.courseworkNotes) {
            if (window.confirm('Are you sure you want to cancel your submission and go back?')) {
                this.props.history.goBack();
            } else {
                return;
            }
        }
        this.props.history.goBack();
    }

    removeUrl = (event, url, index) => {
        event.preventDefault();
        //we want a copy so we don't mutate state directly
        let {urlList} = this.state;
        let urls = urlList;
        urls.splice(index, 1);
        this.setState({urlList: urls})
    }

    renderUrlList = () => {
        let {urlList} = this.state;

        if (urlList.length > 0) {
            return urlList.map((url, index) => {
                let icon;
                if (url.title === GITHUB) {
                    icon = (<Icon icon="github-square" brand={true}/>)
                } else if (url.url.includes('google')) {
                    icon = (<Icon icon="google-drive" brand={true}/>)
                } else {
                    icon = <Icon icon="clipboard-check"/>
                }
                if (url.url === '') {
                    return (<strong key={'no url'}><p>No Submitted URLs</p></strong>)
                }
                // FIX(10/10/2018): Change href to be set to url.url instead of url. Looks like we forgot to update this when we moved to object mode.
                return (
                    <li key={index}>
                        <a key={index} href={url.url} target="_blank" rel="noopener noreferrer">
                            {icon} {url.title ? url.title + ':' : ''} {url.url}<span role='button'
                                                                                     tabIndex={0}
                                                                                     htmlFor='remove url'
                                                                                     onKeyPress={(e) => {
                                                                                         if (e.key === 'Enter') this.removeUrl(e, url)
                                                                                     }} // per linter, keyPress handler must be present for any button role non-button
                                                                                     onClick={(e) => {
                                                                                         this.removeUrl(e, url, index)
                                                                                     }}
                                                                                     className="text-red margin-l-3"><Icon
                            icon="times"/>
                                                                        </span>

                        </a>
                    </li>
                )
            })
        } else {
            return (<strong><p>No Submitted URLs</p></strong>)
        }
    }

    render() {
        let {singleUrl, urlList, singleUrlTitle, assignment, milestoneTypes} = this.state;
        if (this.state.submissionLoading) {
            return (
                <Loading>Loading Coursework..</Loading>
            )
        }
        if (this.state.successRedirect) {
            return (
                <div>
                    <Alert type="success">
                        Your assignment has been submitted.<br/>
                    </Alert>
                    <Link to={this.state.redirectUrl} className="btn btn-edit btn-sm">Continue</Link>
                </div>
            )
        }

        if (this.state.errorRedirect) {
            return (
                <div>
                    <Alert type="error">
                        {this.state.submitError}.<br/>
                    </Alert>
                    <Link to={this.state.redirectUrl} className="btn btn-edit btn-sm">Continue</Link>
                </div>
            )
        }

        if (assignment) {
            let submittedOrGradedNotice = null;
            if (assignment.context.contextCode === assignmentContextTypes.ACADEMIC) {
                submittedOrGradedNotice = (assignment.submissionGrade && !isGradeIncomplete(assignment.submissionGrade)) ? (
                        <strong className="text-green text-bold margin-l-2">Graded</strong>)
                    : <strong
                        className={`text-${assignment.submission ? 'green' : 'red'}`}>{assignment.submission ? "Submitted" : "Not Submitted"}</strong>

            } else if (assignment.context.contextCode === assignmentContextTypes.CAREER) {
                assignment.submission && assignment.submission.careerFeedback ? submittedOrGradedNotice = <strong
                        className={`${gradeColorsMap.get(assignment.submission.careerFeedback.careerFeedbackStatusId)}`}>
                        {(assignment.submission.careerFeedback.careerFeedbackStatusId === 1) ? (
                            <span
                                className="text-yellow">{availableCareerStatus[0].label}</span>) : null}
                        {(assignment.submission.careerFeedback.careerFeedbackStatusId === 2) ? (
                            <span
                                className="text-green">{availableCareerStatus[1].label}</span>) : null}
                        {(assignment.submission.careerFeedback.careerFeedbackStatusId === 3) ? (
                            <span
                                className="text-red">{availableCareerStatus[2].label}</span>) : null}
                        {(assignment.submission.careerFeedback.careerFeedbackStatusId === 4) ? (
                            <span
                                className="text-yellow">{availableCareerStatus[3].label}</span>) : null}
                        {(assignment.submission.careerFeedback.careerFeedbackStatusId === 5) ? (
                            <span
                                className="text-green">{availableCareerStatus[4].label}</span>) : null}
                        {(assignment.submission.careerFeedback.careerFeedbackStatusId === 6) ? (
                            <span
                                className="text-green">{availableCareerStatus[5].label}</span>) : null}
                        {(assignment.submission.careerFeedback.careerFeedbackStatusId === 7) ? (
                            <span
                                className="text-red">{availableCareerStatus[6].label}</span>) : null}</strong>
                    : submittedOrGradedNotice = <span className="text-red"><strong>No Feedback</strong></span>
            }

            // Per academics, disallow submission/editing if the coursework is submitted and graded as anything other than I (incomplete). Per career, always allow submediting.
            const disallowSubmedit = (assignment.context.contextCode === assignmentContextTypes.CAREER) ? false : ((assignment.submissionGrade && !isGradeIncomplete(assignment.submissionGrade) && assignment.submission) === true)// true when submitted and graded but not I. False when any condition doesnt pass. Turn it into a boolean so it works straight into the disabled attr

            const submitButton = disallowSubmedit ?
                <button disabled='true' className={`btn-disabled grey`}>Submitted & Graded</button>
                : <button disabled={this.state.buttonDisabled}
                          className={`btn-primary ${this.state.submissionLoading ? 'btn-loading' : 'btn-submit'}`}
                          onClick={this.submitCoursework}>{assignment.context.contextCode === assignmentContextTypes.CAREER ? 'Submit Milestone' : 'Submit Your Coursework'}</button>

            const formInProgress = urlList.length > 0 || singleUrl.length > 0 // if we have any user input, mark the form in progress so we can alert user if they try and navigate

            // NOTE: DocumentTitle only likes receiving one component, so we wrap in React.Fragment
            if (assignment.context.contextCode === assignmentContextTypes.CAREER) {
                return (
                    <DocumentTitle title="Submit Coursework | Bootcamp Spot">
                        <React.Fragment>
                            <CareerAssignment coursework={assignment}
                                              milestoneTypes={milestoneTypes}
                                              submittedOrGradedNotice={submittedOrGradedNotice}
                                              disallowSubmedit={disallowSubmedit}
                                              courseworkNotes={this.state.courseworkNotes === null ? '' : this.state.courseworkNotes}
                                              goBack={this.goBack}
                                              handleChange={this.handleChange}
                                              submitCoursework={this.submitCoursework}
                                              submissionLoading={this.state.submissionLoading}
                                              buttonDisabled={this.state.buttonDisabled}
                                              milestoneUrls={this.state.milestoneUrls}
                                              submissionRequired={this.state.submissionRequired}
                            />
                        </React.Fragment>
                    </DocumentTitle>
                )
            } else {
                return (

                    <DocumentTitle title="Submit Coursework | Bootcamp Spot">
                        <React.Fragment>
                            <FormNavigationPrompt
                                message='Are you sure you want to navigate away from this page while you have a submission in progress?'
                                valueExists={formInProgress}/>
                            <StandardCourseworkSubmit coursework={assignment}
                                                      submittedOrGradedNotice={submittedOrGradedNotice}
                                                      disallowSubmedit={disallowSubmedit}
                                                      submitButton={submitButton}
                                                      singleUrl={singleUrl}
                                                      urlList={urlList}
                                                      courseworkNotes={this.state.courseworkNotes === null ? '' : this.state.courseworkNotes}
                                                      goBack={this.goBack}
                                                      handleChange={this.handleChange}
                                                      handleUrlAddition={this.handleUrlAddition}
                                                      renderUrlList={this.renderUrlList}
                                                      singleUrlTitle={singleUrlTitle}
                            />
                        </React.Fragment>
                    </DocumentTitle>
                )
            }
        } else {
            return (
                <Loading>Loading Coursework..</Loading>
            )
        }
    }
}

export const COURSEWORKSUBMIT = CourseworkSubmit;
export default connect(mapStateToProps)(CourseworkSubmit);
