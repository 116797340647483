import { axiosInit } from '../config/axiosConfig';
import { persistor } from '../store';

export const setUserLoggedIn = async (accessToken) => {
	if (accessToken !== null) {
		localStorage.removeItem('user');
		localStorage.setItem('user', accessToken);
		window.history.pushState({}, document.title, window.location.origin);
		axiosInit();
	}
}

export const isLoggedIn = () => {
	let accessToken = localStorage.getItem("user");
	if(accessToken) {
		axiosInit();
	}
	return !!accessToken;
}

export const logoutUser = (doReload=true) => {
	localStorage.removeItem('user');
    persistor.purge();

	if(doReload) {
		window.location.reload();
	}
}
