import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import BlankSlate from '../../components/BlankSlate';
import ReactGA from 'react-ga';

class Unauthorized extends Component {

    constructor(props)
    {
        super(props)
        ReactGA.event({
            category: 'Unauthorized',
            action: 'Unauthorized View'
        });
    }


    render()
    {
        return(
            <DocumentTitle title="Unauthorized | Bootcamp Spot">
                <section className="page" data-component="unauthorized">
                    <BlankSlate icon="times" header="You don't have permission!">
                        <p>It seems you don't have the necessary permissions to view this page/resource - if you feel like this is an error, please reach out to BCS support</p>
                    </BlankSlate>
                </section>
            </DocumentTitle>
        )
    }

}

export default Unauthorized;
