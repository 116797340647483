import React from 'react';
import { connect } from 'react-redux';
import moment from "moment/moment";
import { isAdmin, isSSM } from "../../helpers/roles";
import { toggleEndCourseSurvey } from "../../actions/dashboardActions";
import Alert from "../Alert"
import { get } from 'lodash'

const mapStateToProps = (state) => {
    return {
        enrollmentInfo: get(state, 'user.enrollmentInfo', null),
        error: get(state, 'user.errors.getEnrollmentInfoError', null),
    }
};

const NpsButtonContainer = (props) => {
    const { enrollmentInfo, error } = props;
    const { midCourseSurveySchedule, endCourseSurveySchedule } = enrollmentInfo;
    const endDate = moment(enrollmentInfo.course.endDate).format('dddd, MMMM DD, YYYY - h:mm a');
    const surveyDueDate = moment(enrollmentInfo.course.endDate).add(2, 'weeks').format('dddd, MMMM DD, YYYY - h:mm a');
    const endsContext = moment().diff(moment(enrollmentInfo.course.endDate)) < 0 ? 'will end' : 'ended';
    const isTodayEndDate = moment().format('L') === moment(enrollmentInfo.course.endDate).format('L');
    const isEndCourseSurveyEnabled = enrollmentInfo.course.endSurveyEnabled;

    const hasToBeVisible = () => {
        const { enrollmentInfo } = props;
        const activeRole = enrollmentInfo.courseRole.courseRoleCode;

        if (isSSM(activeRole) || isAdmin(activeRole)) {
            let diff = moment(enrollmentInfo.course.endDate).diff(moment(enrollmentInfo.course.startDate), 'days');
            let enableWeek = (diff / 7) / 2 + 1;
            let dateToEnable = moment(enrollmentInfo.course.startDate).add(enableWeek, 'weeks').startOf('isoWeek');
            let dateToDisable = moment(enrollmentInfo.course.endDate).add(2, 'weeks');
            return moment().isAfter(dateToEnable) && moment().isBefore(dateToDisable)
        } else {
            return false
        }
    }

    const endCourseButtonAction = () => {
        const { enrollmentInfo, dispatch } = props;
        dispatch(toggleEndCourseSurvey(enrollmentInfo));
    }

    return (
        <div className="card text-center">
            <h2>NPS Survey</h2>
            <div><span className="text-bold">MidPoint:</span> {get(midCourseSurveySchedule, 'dateFrom', false) ? moment(midCourseSurveySchedule.dateFrom).format('MMMM D, YYYY') : "TBD"}</div>
            <div><span className="text-bold">EndPoint:</span> {get(endCourseSurveySchedule, 'dateFrom', false) ? moment(endCourseSurveySchedule.dateFrom).format('MMMM D, YYYY') : "TBD"}</div>
            {hasToBeVisible() ? (
                <div className="margin-t-2">
                    <p>This class {endsContext} {isTodayEndDate ? 'today' : `on ${endDate}`} Local.</p>
                    <p>The NPS survey must be submitted by all students by {surveyDueDate} Local</p>

                    {error ? (
                       <Alert type="error">
                           <p>Something went wrong. Please refresh and try again.</p>
                       </Alert>
                    ) : isEndCourseSurveyEnabled ? (
                        <button className="btn-small btn-delete" onClick={() => endCourseButtonAction()}>Disable NPS Survey</button>
                    ) : (
                        <button className="btn-small btn-save" onClick={() => endCourseButtonAction()}>Enable NPS Survey </button>
                    )}
                </div>
            ) : null}
        </div>
    )

}

export const NpsBUTTONCONTAINER = NpsButtonContainer;
export default connect(mapStateToProps)(NpsButtonContainer);
