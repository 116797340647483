import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import BlankSlate from '../../components/BlankSlate';
import ReactGA from 'react-ga';

class PageNotFound extends Component {

    constructor(props)
    {
        super(props)
        ReactGA.event({
            category: 'Page Not Found',
            action: '404 Error',
        });
    }

    render()
    {
        return(
            <DocumentTitle title="Page Not Found | Bootcamp Spot">
                <section className="page" data-component="page-not-found">
                    <BlankSlate icon="exclamation-triangle" header="404 - Page Not Found">
                        <p>We can't seem to find that page your looking for. Try navigating around or reach out to support for more help.</p>
                    </BlankSlate>
                </section>
            </DocumentTitle>
        )
    }

}

export default PageNotFound;
