import React, { Component } from 'react';
import Alert from '../../../components/Alert';
import BaseSessionGridCard from './BaseSessionGridCard';
import {Link} from 'react-router-dom';

export class BaseSessionGrid extends Component {

    renderSessionCards = () => {
        const {sessions, dispatch} = this.props;

        if(sessions.currentWeekSessions.length > 0) {
            return sessions.currentWeekSessions.map( (session, index) => {
                    return <BaseSessionGridCard
                            session={session}
                            key={index}
                            dispatch={dispatch}
                            enrollmentInfo={this.props.user.enrollmentInfo} />
            })
        } else {
            return <div className="col-xs-12"><Alert type="warn">No sessions found for this week</Alert></div>
        }
    }

    render() {
        return (
            <div className="row card-group">
                <div className="col-xs-12 col-gutter col-md-8">
                    <h1>{this.props.listHeader || "This Week's Sessions"}</h1>
                </div>
                <div className="col-xs-12 col-md-4 col-gutter text-right">
                    <Link to={`/academicSyllabus`}>
                        <button className="btn-small btn-tertiary btn-create">View Session Syllabus</button>
                    </Link>
                </div>

                {this.renderSessionCards()}
            </div>
        )
    }
}

export default BaseSessionGrid;
