import React, { useEffect } from 'react';

import Loading from '../Loading'
import { axiosAuthRequiredHandler } from '../../config/axiosConfig'
import { debouncedGetAndValidateToken, notValidRenderer } from './SessionEnforcerHelper'

export const SessionEnforcer = (props) => {
    const { dispatch } = props
    const [axiosIsConfigured, setAxiosIsConfigured] = React.useState(false); // don't want to set axios more than once so we handle it in state
    if(!axiosIsConfigured){
        axiosAuthRequiredHandler(dispatch) // initialize the axios interceptor that handles any 401 response by dispatching logout.
        setAxiosIsConfigured(true)
    }

    const [isValid, setIsValid] = React.useState(null); // null is our initial case, still logging in


    useEffect(
        () => {
            let isUserValid = debouncedGetAndValidateToken(props)
            setIsValid(isUserValid)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.authToken, props.location, props.userActionTaken]
    )

    // handle initial case, if isValid is null, we're still loading
    if(isValid === null){ 
        return <Loading className="">Verifying your session...</Loading>
    }

    if(!isValid){ // if we never got a validation, we will auto-logout due to "initialstate" of null
       return notValidRenderer(dispatch)
    } else {
        return props.children
    }
   
}

export default SessionEnforcer;