import { fulfilled } from '../helpers/asyncStatusGenerator';

const initialState = {
    localTime: null,
    sectionTZ: null,
    sectionTime: null,
    timer: null
}

export const timeReducer = (state=initialState, action) => {
    switch(action.type) {
        case fulfilled('SET_GLOBAL_TIME'):
            return {
                ...state,
                localTime: action.payload.localTime,
                sectionTime: action.payload.sectionTime,
                sectionTZ: action.payload.sectionTZ
            }

        case fulfilled('SET_TIMER_ID'):
            return {
                ...state,
                timer: action.payload
            }
        default:
            return state;
    }
}
