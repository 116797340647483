import { gradeToValue } from '../constants/grade';
import {get} from "lodash";

export const averageMeanGrade = (gradeArray) => {
    let gradeValueSum = 0;
    gradeArray.forEach((grade) => gradeValueSum += gradeToValue[grade])

    const mean = Math.round(gradeValueSum/gradeArray.length);
    let nearestWholeNumberByFive = Math.round(mean/5) * 5;

    if (nearestWholeNumberByFive < 35) {
        nearestWholeNumberByFive = 35
    }

    const finalAverageGrade = Object.keys(gradeToValue).find((grade) => gradeToValue[grade] === nearestWholeNumberByFive);

    return finalAverageGrade;
}

export const averageModeGrade = (gradeArray) => {
  return gradeArray.reduce((a,b,i,arr)=>
     (arr.filter(v=>v===a).length>=arr.filter(v=>v===b).length?a:b),
    null)
}

export const displayGrade = (enrollmentInfo, gradeAsLetter, gradeAsPercentage) => {
    const programType = get(enrollmentInfo, 'course.cohort.program.programType.name', null);
    if (programType !== null && programType.toLowerCase().includes('netflix')) {
        return `${gradeAsPercentage}%`;
    }
    return gradeAsLetter;
}

export const convertLetterToPercent = (letter, defaultValue) => {
    return gradeToValue[letter] ? gradeToValue[letter] : defaultValue;
}
