import React, {Component} from 'react';
import Textarea from "../Textarea";


//FIXME: Refactor into a functional component, no need for a class.
class TextareaStep extends Component {
    commitStep = (e) => {
        let { value } = e.target
        value = value.toString().trim()
        this.props.onChange(this.props.step.step.id, value);
    }

    render() {
        const step = this.props.step.step;
        return (
            <li className="margin-b-3">

                <Textarea label={step.definition.text}
                          characterLimit={step.definition.ui.maxLength ? step.definition.ui.maxLength : 5000}
                          required={step.required}
                          id={(step.id).toString()}
                          onBlur={this.commitStep}/>
            </li>
        )
    }
}

export default TextareaStep;
