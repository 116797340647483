import React, { Component } from 'react';
import {connect} from 'react-redux';
import DocumentTitle from 'react-document-title'
import {Link, Redirect} from 'react-router-dom';

import { isEmpty } from 'lodash'

import Loading from '../../components/Loading'
import VideoDeleteButton from './deletePanoptoVideo'
import VideoEditButton from './editPanoptoVideo'
import Alert from '../../components/Alert'

import { isStudent } from '../../helpers/roles';
import { panoptoActions } from '../../actions/sessionActions'
import ReactGA from 'react-ga'
import { PanoptoVideoCreate } from "../PanoptoVideosCreate";

const mapStateToProps = (state) => {
    return{
        user: state.user,
        selectedSession: state.sessions.selected,
        authToken: state.user.data.authToken,
        sessionId: state.sessions.selected.session.session.id,
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode
    }
}

class PanoptoVideoModify extends Component {
    constructor(props){
        super(props)
        this.state = {
            calledApiLoading: false,
            videoDataLoading: false,
            videoData: null,
            modifiedVideoData: [],
            callingApiError: false,
            errorText: '',
            sessionId: null,
            lastVideoDeleted: false,
            editSuccess: false,
            editingInitialized: false
        }
    }

    async componentDidMount(){
        if(!isStudent(this.props.activeRole)){
            this.setState({
                videoDataLoading: true
            })
            const sessionId = this.props.sessionId
            try {
                const payload = {id : parseInt(sessionId, 10)};
                const videoData = await panoptoActions.getPanoptoSessionVideos(this.props.authToken, payload);

                // had a wierd race here, hence awaiting this particular setState
                await this.setState({
                    videoDataLoading: false,
                    videoData: videoData.data
                })
            } catch (error) {
                this.setState({
                    videoDataLoading: false,
                    callingApiError: true,
                    errorText: error
                })
            }
        }
    }

    editVideo = async (event, id) => {
        event.preventDefault()
        // FIXME: Make sure we're not accidentally relying on the typeConversion here and correct.
        //
        const modifiedVideoObject = this.state.modifiedVideoData.find((videoObj) => videoObj.id === id)

        // if our modified state hasnt populated we don't need to do anything yet. This occurs when user clicks edit without changing anything
        if(!modifiedVideoObject){
            return
        }
        // check URL validity and inform user
        if(!PanoptoVideoCreate.checkUrlValidity([modifiedVideoObject.url])) {
            window.alert("Sorry, that URL isn't valid or isn't hosted with panopto.com or zoom.us. Please try again, and remember to include the http/https.")
            return
        }
        if(window.confirm("Are you sure you want to edit this video?")){
            this.setState({calledApiLoading: true})
            const videoUrls = this.state.modifiedVideoData.map( (videoObj) => videoObj.url );
            const result = await panoptoActions.createPanoptoVideos(this.props.authToken, this.props.sessionId, videoUrls)
            const payload = {id : parseInt(this.props.sessionId, 10)};
            const newModifiedVideoData = await panoptoActions.getPanoptoSessionVideos(this.props.authToken, payload)
            if(result.error){
                ReactGA.event({
                    category: 'Panopto Video',
                    action: 'Video Edit Error',
                });
                this.setState({errorText: result.error, calledApiLoading: false})
            } else {
                ReactGA.event({
                    category: 'Panopto Video',
                    action: 'Video Edited',
                });
                this.setState({
                    videoDataLoading: false,
                    calledApiLoading: false,
                    modifiedVideoData: newModifiedVideoData.data,
                    editSuccess: true
                })
            }
        }
    }

    deleteVideo = async (event, id) => {
        event.preventDefault()
        const { videoData, modifiedVideoData } = this.state
        if(window.confirm("Are you sure you want to delete this video?")){
            // have to make sure if edit is in use that we remove the object with that ID from state as well
            let modifiedVideoStateDup = [];
            if(modifiedVideoData.length > 0){
                modifiedVideoStateDup = modifiedVideoData.slice().filter((videoObj, index) => {
                    return videoObj.id !== id
                })
            }
            let videoStateDup = videoData.slice().filter((videoObj, index) => {return videoObj.id !== id})
            this.setState({calledApiLoading: true})

            let excludedList = videoStateDup;
            const urls = excludedList.map( (vidObj)=> vidObj.url );
            let result = panoptoActions.createPanoptoVideos(this.props.authToken, this.props.sessionId, urls);

            if(result.error) {
                ReactGA.event({
                    category: 'Panopto Video',
                    action: 'Video Delete Error',
                });
                this.setState({
                    errorText: 'This video could not be deleted! Please try again or contact BCS-Support',
                    calledApiLoading: false
                })
            } else {
                ReactGA.event({
                    category: 'Panopto Video',
                    action: 'Video Deleted',
                });
                // if we deleted our last video, set a flag so that we can offer user navigation away
                if(videoStateDup.length === 0){
                    await this.setState({lastVideoDeleted: true})
                }
                this.setState({
                    videoData: videoStateDup,
                    modifiedVideoData: modifiedVideoStateDup,
                    calledApiLoading: false
                })
            }
        }
    }

    handleUrlChange = async (event) => {
        this.setState({editingInitialized: true})
        const videoID = parseInt(event.target.id, 10)
        const sessionId = parseInt(this.props.sessionId, 10)
        const newUrl = event.target.value
        const newVideoObj = {id: videoID, sessionId: sessionId, url: newUrl}
        // use modifiedVideoState if user has started changing URLs, otherwise use regular videoData
        // find the object to modify in our duped state, replace it with the newVideoObj
        const videoDataToModify = this.state.modifiedVideoData.length > 1 ? this.state.modifiedVideoData : this.state.videoData
        let newState = videoDataToModify.slice().map((videoObject)=>{
                    // FIXME figure out if we can switch to === from == (aka confirm the types of videoObj.id and id so we make sure that we're not accidentally relying on the typeconversion)
                                                        //
                                                        if(videoObject.id === videoID){
                                                            return newVideoObj
                                                        } else {
                                                            return videoObject
                                                        }
                                                    })
        await this.setState({
            modifiedVideoData: newState
        })
    }

    render(){
        if(isStudent(this.props.activeRole)){
            return <Redirect to='/unauthorized'/>
        }
        let { videoData, videoDataLoading, calledApiLoading, modifiedVideoData, callingApiError, lastVideoDeleted } = this.state
        // if we deleted the last video, offer user redirect button alert
        if(lastVideoDeleted){
            const sessionId = this.props.sessionId
            return(
                <Alert type="success">
                    You have deleted all the video URLs!<br/>
                    <Link to={`/sessions/${sessionId}`} className="btn btn-edit btn-sm">Continue to Session</Link>
                </Alert>
            )
        }

        if(callingApiError){
            return(<h3>Sorry, there was an error loading videos. Please try again or contact BCS-Support</h3>)
        }
        // spin loader if we have no data
        if(isEmpty(videoData) || videoDataLoading){
            return <Loading />
        }


        // map over either videoData(if user hasnt touched our URLs) or modifiedVideoData(if user has)
        const videoDataToDisplay = modifiedVideoData.length >= 1 ? modifiedVideoData : videoData
        const videoDisplay = videoDataToDisplay.map((videoObj, index) => {
                                return(
                                    <React.Fragment key={index}>
                                        <form>
                                            <div className='divider divide-horizontal'></div>
                                            <label htmlFor='URL-Input'> Session Video {`${index+1}`}: </label>
                                            <input type="text"
                                                value={videoObj.url}
                                                className="form"
                                                placeholder="Modify Video URL"
                                                onChange={this.handleUrlChange}
                                                id={videoObj.id}/>
                                            <VideoEditButton disabled={!this.state.editingInitialized}
                                                            id={videoObj.id}
                                                            editVideo={this.editVideo}
                                                            calledApiLoading={calledApiLoading}
                                                            videoID={videoObj.id}/>
                                            <VideoDeleteButton id={videoObj.id}
                                                            deleteVideo={this.deleteVideo}
                                                            calledApiLoading={calledApiLoading}
                                                            videoID={videoObj.id}/>
                                        </form>
                                     </React.Fragment>
                                )
                            })
        return(
            <DocumentTitle title="Modify Videos | Bootcamp Spot">
                <section className="page" data-component="panopto-video-modify">
                    <div className="row">
                        <div className="col-xs-12 col-md-8">
                            <h1>Edit or Delete Videos for {this.props.selectedSession.SessionName}, Unit {this.props.selectedSession.chapter}</h1>
                            <p>Our Academic policy requires that all videos be uploaded through Panopto or Zoom.</p>
                            {this.state.editSuccess ? <p className='text-green font-sm'>Video Successfully Edited</p> : ''}
                            {videoDisplay}
                        </div>
                    </div>
                </section>
            </DocumentTitle>
        )
    }
}

export default connect(mapStateToProps)(PanoptoVideoModify);
