import React, { Component } from 'react';
import {calendarWeekDays} from '../../constants/calendar';
import { withRouter } from 'react-router-dom';

class CalendarItem extends Component {
    renderEventList = () => {
        const {data, eventCssClassCallback, eventLinkCallback} = this.props;
        const navigationFunc = (e, href) => {
            e.preventDefault()
            this.props.history.replace(href)
        }
        
        let eventList = !data ? null : data.map((event, i) => {
            const title = event.title;
            const eventCssClass = eventCssClassCallback ? eventCssClassCallback(event) : null;
            const eventColor = eventCssClass ? "" : "#888888";
            const cursor = eventLinkCallback ? "pointer" : "auto";
            const href = this.getHref(event)

            return (
                <li key={i} style={{backgroundColor: eventColor, cursor: cursor}} className={eventCssClass}>
                    <a onClick={(e) => navigationFunc(e, href)} href={href} title={title}>{title}</a>
                </li>
            )
        })

        return (
            <ul className={'calendar-event-list'}>{eventList}</ul>
        )
    }

    getHref = (event) => {
        if (this.props.eventLinkCallback) {
            const url = this.props.eventLinkCallback(event);
            if (url) {
                return url
            } else {
                return '#'
            }
        }
    }

    renderDate = () => {
        const {date, dayOfWeek} = this.props;

        return (
            <time className="calendar-date">
                <strong>{date}</strong>
                <span>{calendarWeekDays[dayOfWeek] ? calendarWeekDays[dayOfWeek].substring(0, 3) : ''}</span>
            </time>
        )
    }

    render() {
        const {empty, data} = this.props;
        let className;
        if(!data || !data.length || data.length < 1 || empty) {
            className = 'hide-xs show-md';
        }
        return (
            <li className={className} role="cell">
               {this.renderDate()}
               {this.renderEventList()}

            </li>
        )
    }
}

export default withRouter(CalendarItem);
