import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import Loading from '../../components/Loading';
import Alert from '../../components/Alert';
import ProfilePic from '../../components/ProfilePic';
import {isStudent} from '../../helpers/roles';
import {fetchStudentProfiles} from "../../actions/studentActions";

const mapStateToProps = (state, ownProps) => {
    return {
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        courseId: state.user.enrollmentInfo.courseId,
        ...ownProps
    }
}

export class Students extends Component {

    constructor(props) {
        super(props);
        this.state = {
            students: [],
            orgStudents: [],
            loading: true,
            error: false
        }
    }

    async componentDidMount() {
        const {activeRole, courseId} = this.props;

        if (isStudent(activeRole)) {
            this.props.history.push('/unauthorized');
        } else {
            let payload = {
                courseId: courseId
            }
            try {
                let result = await fetchStudentProfiles(payload);
                this.setState({
                    students: result.data,
                    orgStudents: result.data,
                    loading: false
                })
            } catch(err) {
                console.error(err)
                this.setState({
                    error: `There was an error gathering the class list. If you feel like this is a mistake - contact BCS Support`,
                    loading: false
                })
            }
        }
    }

    render() {
        return (
            <DocumentTitle title="Student Profiles | Bootcamp Spot">
                <section className="page" data-component="students">
                    <div className="row card-group">
                        <div className="col-xs-12 col-gutter-lr">
                            <h1>Student Profiles</h1>
                        </div>
                        {this.state.loading ? (
                            <div className="col-xs-12 text-center"><Loading/></div>
                        ) : (
                            this.state.error ? (
                                <div className="col-xs-12">
                                    <Alert type="error">
                                        <p>{this.state.error}</p>
                                    </Alert>
                                </div>
                            ) : (
                                this.state.students.map((item, index) => {
                                    return (
                                        <div className="col-xs-12 col-sm-4 col-md-3" key={index}>
                                            <div className="card">
                                                <ProfilePic user={item.student} options="margin-b-3"/>
                                                <p className="text-center">
                                                    <Link to={`/students/${item.studentEnrollmentId}`}>
                                                        {item.student.firstName + ' ' + item.student.lastName}
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })
                            )
                        )}
                    </div>
                </section>
            </DocumentTitle>
        )
    }

}

export default connect(mapStateToProps)(Students);
