import {
    dashboardActionTypes, universalActionTypes
} from '../constants/actionTypes'
import {
    pending,
    rejected,
    fulfilled
} from '../helpers/asyncStatusGenerator';
import { convertNexusEventToCalendarEvent } from "../helpers/calendar";

const initialState = {
    dashboardLoading: true,
    dashboardCalendar: [],
    dashboardAnnouncements: {},
    dashboardStudentStats: {},
    upcomingSession: {},
    upcomingCareerSession: {},
    virtualClassroom: {},
    virtualClassroomCredentials: {},
    continuationContent: {},
    relatedInstructionalSessions: [],
    zoomMeetingEnabled: false,
}

export  const dashboardReducer = (state=initialState, action) => {
    switch (action.type) {
        case universalActionTypes.RESET_ENROLLMENT_INFO:
            return initialState;

        case pending(dashboardActionTypes.GET_DASHBOARD):
            return {
                ...state,
                dashboardLoading: true,
            };
        case fulfilled(dashboardActionTypes.GET_DASHBOARD):
            const {
                calendarEvents,
                announcementsResume,
                studentResume,
                upcomingAcademicSession,
                upcomingCareerSession,
                virtualClassroom,
                virtualClassroomCredentials,
                continuationContent,
            } = action.payload;
           return {
                ...state,
                dashboardLoading: false,
                dashboardCalendar: calendarEvents ? convertNexusEventToCalendarEvent(calendarEvents) : null,
                dashboardAnnouncements: announcementsResume ? announcementsResume : null,
                dashboardStudentStats: studentResume ? studentResume : null,
                upcomingSession: upcomingAcademicSession ? upcomingAcademicSession : null,
                upcomingCareerSession: upcomingCareerSession ? upcomingCareerSession : null,
                virtualClassroom: virtualClassroom ? virtualClassroom : null,
                virtualClassroomCredentials: virtualClassroomCredentials ? virtualClassroomCredentials : null,
                continuationContent: continuationContent ? continuationContent : null,
                relatedInstructionalSessions: action.payload.relatedInstructionalSessions ? action.payload.relatedInstructionalSessions : null,
                zoomMeetingEnabled: action.payload.zoomMeetingEnabled ? true : false
            };
        case rejected(dashboardActionTypes.GET_DASHBOARD):
            return {
                ...state,
                dashboardLoading: false
            };
        default:
            return state;
    }
}
