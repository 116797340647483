import React from 'react';

const getStatsTextColor = (id, value) => {
    const statsSpecs = {
        "paceOfClass": {
            textColorClass: (value < 3.0 || value > 4.11) ? 'text-error' : (value > 3.24 && value < 3.87) ? 'text-success' : '',
        },
        "applyLearningOutsideClass": {
            textColorClass: (value < 3.32) ? 'text-error' : (value > 3.56) ? 'text-success' : '',
        },
        "academicSupport": {
            textColorClass: (value < 3.8) ? 'text-error' : (value > 4.29) ? 'text-success' : '',
        },
        "instructorClarity": {
            textColorClass: (value < 4.02) ? 'text-error' : (value > 4.26) ? 'text-success' : '',
        },
        "instructorEngagement": {
            textColorClass: (value < 4.1) ? 'text-error' : (value > 4.34) ? 'text-success' : '',
        },
        "instructorKnowledge": {
            textColorClass: (value < 4.27) ? 'text-error' : (value > 4.51) ? 'text-success' : '',
        },
        "homeworkFeedbackRating": {
            textColorClass: (value < 4.27) ? 'text-error' : (value > 4.51) ? 'text-success' : '',
        }
    }

    return statsSpecs[id];
}

const textColorClass = (id, value) => getStatsTextColor(id, value) ? getStatsTextColor(id, value).textColorClass : '';

const WeeklyFeedbackReportStatLabel = (props) => {
    const {id, value} = props;
    return (
        <p id={id} className={`headline margin-b-0 ${textColorClass(id, value)}`}>{value}</p>
    )
}

export default WeeklyFeedbackReportStatLabel
