import React, {Component} from 'react';
import {connect} from 'react-redux';
import DocumentTitle from 'react-document-title';
import {fetchSessions} from '../../actions/sessionActions';
import BaseSessionGrid from '../../components/Sessions/SessionGrid/BaseSessionGrid';
import Loading from '../../components/Loading';
import { HashLink } from 'react-router-hash-link';

const mapStateToProps = (state, ownProps) => {
    return {
        enrollmentId: state.user.enrollmentInfo.id,
        sessions: state.sessions,
        user: state.user,
        sessionListLoading: state.sessions.sessionListLoading,
        ...ownProps
    }
}

export class Sessions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            calendarData: {},
        }
    }

    componentDidMount() {
        const {enrollmentId, dispatch} = this.props;
        dispatch(fetchSessions(enrollmentId));
    }

    render() {
        const {sessionListLoading} = this.props;

        return (
            <DocumentTitle title="Class Sessions | Bootcamp Spot">
                <section className="page" data-component="sessions">
                    {sessionListLoading === false ? (
                        <div>
                            <BaseSessionGrid {...this.props} />
                            <div className="row">
                                <div className="col-xs-12 col-gutter">
                                    <HashLink to={'/#coursecalendar'}>
                                        <button className="btn-small btn-tertiary btn-create">View Course Calendar</button>
                                    </HashLink>
                                </div>
                            </div>
                        </div>
                        ) : <Loading/>
                    }
                </section>
            </DocumentTitle>
        )
    }
}

export default connect(mapStateToProps)(Sessions);
