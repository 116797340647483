export const gradeIncomplete = "Incomplete";

export function isGradeIncomplete(grade){
    return grade === gradeIncomplete || grade === 'I'
}

export function getGradeName(grade){
    // test this coop
    return (grade !== "I" && grade) || gradeIncomplete;
}

export const gradeToValue = {
  'A+': 105,
  'A': 100,
  'A-': 95,
  'B+': 90,
  'B': 85,
  'B-': 80,
  'C+': 75,
  'C': 70,
  'C-': 65,
  'D+': 60,
  'D': 55,
  'D-': 50,
  'F+': 45,
  'F': 40,
  'F-': 35,
  [gradeIncomplete]:0,
  'I':0,
};


export const gradeStatuses = {
    all: 'all',
    graded: 'graded',
    ungraded: 'ungraded'
};

export const allBasicGrades = {
    all: 'all',
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    F: 'F',
    I: 'Incomplete'
};


export let gradeColorsMap = new Map();

gradeColorsMap.set("A+", "text-green");
gradeColorsMap.set("A", "text-green");
gradeColorsMap.set("A-", "text-green");
gradeColorsMap.set("B+", "text-green");
gradeColorsMap.set("B", "text-green");
gradeColorsMap.set("B-", "text-green");
gradeColorsMap.set("C+", "text-yellow");
gradeColorsMap.set("C", "text-yellow");
gradeColorsMap.set("C-", "text-yellow");
gradeColorsMap.set("D+", "text-orange");
gradeColorsMap.set("D", "text-orange");
gradeColorsMap.set("D-", "text-orange");
gradeColorsMap.set("E+", "text-red");
gradeColorsMap.set("E", "text-red");
gradeColorsMap.set("F+", "text-red");
gradeColorsMap.set("F", "text-red");
gradeColorsMap.set("F-", "text-red");
gradeColorsMap.set(gradeIncomplete, "text-red");
gradeColorsMap.set('I', "text-red");

gradeColorsMap.set(1, "text-yellow");
gradeColorsMap.set(2, "text-green");
gradeColorsMap.set(3, "text-red");
gradeColorsMap.set(4, "text-yellow");
gradeColorsMap.set(5, "text-green");
gradeColorsMap.set(6, "text-green");
gradeColorsMap.set(7, "text-red");

export const getPercentageColor = (percentage) => {
    if (percentage <= 25) {
        return 'text-red'
    } else if (percentage <= 50) {
        return 'text-orange'
    } else if (percentage <= 75) {
        return 'text-yellow'
    } else {
        return 'text-green'
    }
}

export const minimumScoreRequiredToGrade = (score) => {
    switch (score) {
        case 100:
            return "A+"
        case 95:
            return "A"
        case 92:
            return "A-"
        case 88:
            return "B+"
        case 85:
            return "B"
        case 82:
            return "B-"
        case 78:
            return "C+"
        case 75:
            return "C"
        case 72:
            return "C-"
        case 70:
            return "D+"
        case 65:
            return "D"
        case 62:
            return "D-"
        case 55:
            return "F"
        default:
            return "---"
    }
}

