// these constants are not used for sf-liveagent.js because of issues importing/requiring the window script (aka outisde of transpile)
export const sfUat = {
    orgId: '00D030000004e1W',
    webToCaseActionRoot: 'trilogyed--uat.my.salesforce.com',
    roleId: '00N4100000f2vfV',
    trilMail: '00N4100000f2vfL',
    sisenseDashboardUrl: '00N2M00000egqAo',
    sisenseCohort: '00N2M00000egqAj',
    sisenseClass: '00N2M00000egqAe',
    spokenTutorFirstName: '00N3f000000dijdEAA',
    spokenTutorLastName: '00N3f000000dijeEAA',
    pastDueOnHomework: '00N3f000000dijcEAA',
    classSession: '00N2M00000eseY0UAI',
    attendOfficeHours: '00N3f000000dijXEAQ',
    hoursOfStudy: '00N3f000000dijbEAA',
    currentTutorFirstName: '00N3f000000dijZEAQ',
    currentTutorLastName: '00N3f000000dijaEAA',
    availabilityDoesntMatch: '00N3f000000dijYEAQ',
    tutorChangeOtherReason: '00N3f000000dijgEAA',
    tutorResigned: '00N3f000000dijtEAA',
    tutorVacation: '00N3f000000dijuEAA',
    timeZone: '00N3f000000dijfEAA',
    tutorPDMonday: '00N3f000000dijiEAA',
    tutorPDTuesday: '00N3f000000dijmEAA',
    tutorPDWednesday: '00N3f000000dijnEAA',
    tutorPDThursday: '00N3f000000dijlEAA',
    tutorPDFriday: '00N3f000000dijhEAA',
    tutorPDSaturday: '00N3f000000dijjEAA',
    tutorPDSunday: '00N3f000000dijkEAA',
    tutorPTEarly: '00N3f000000dijoEAA',
    tutorPTEvening: '00N3f000000dijpEAA',
    tutorPTMidday: '00N3f000000dijqEAA',
    tutorPTMorning: '00N3f000000dijrEAA',
    tutorPTNight: '00N3f000000dijsEAA'
};


export const sfProd = {
    orgId: '00D410000006puJ',
    webToCaseActionRoot: 'webto.salesforce.com',
    roleId: '00N4100000f2vfV',
    trilMail: '00N4100000f2vfL',
    sisenseDashboardUrl: '00N2M00000egqAo',
    sisenseCohort: '00N2M00000egqAj',
    sisenseClass: '00N2M00000egqAe',
    spokenTutorFirstName: '00N3f000000dijdEAA',
    spokenTutorLastName: '00N3f000000dijeEAA',
    pastDueOnHomework: '00N3f000000dijcEAA',
    classSession: '00N2M00000eseY0UAI',
    attendOfficeHours: '00N3f000000dijXEAQ',
    hoursOfStudy: '00N3f000000dijbEAA',
    currentTutorFirstName: '00N3f000000dijZEAQ',
    currentTutorLastName: '00N3f000000dijaEAA',
    availabilityDoesntMatch: '00N3f000000dijYEAQ',
    tutorChangeOtherReason: '00N3f000000dijgEAA',
    tutorResigned: '00N3f000000dijtEAA',
    tutorVacation: '00N3f000000dijuEAA',
    timeZone: '00N3f000000dijfEAA',
    tutorPDMonday: '00N3f000000dijiEAA',
    tutorPDTuesday: '00N3f000000dijmEAA',
    tutorPDWednesday: '00N3f000000dijnEAA',
    tutorPDThursday: '00N3f000000dijlEAA',
    tutorPDFriday: '00N3f000000dijhEAA',
    tutorPDSaturday: '00N3f000000dijjEAA',
    tutorPDSunday: '00N3f000000dijkEAA',
    tutorPTEarly: '00N3f000000dijoEAA',
    tutorPTEvening: '00N3f000000dijpEAA',
    tutorPTMidday: '00N3f000000dijqEAA',
    tutorPTMorning: '00N3f000000dijrEAA',
    tutorPTNight: '00N3f000000dijsEAA'
};
