import {
    courseworkActionTypes,
    universalActionTypes,
} from '../constants/actionTypes'
import {
    pending,
    rejected,
    fulfilled
} from '../helpers/asyncStatusGenerator';

const initialState = {
    assignmentLoading: false,
    assignmentListLoading: false,
    nextDueAssignments: [],
    overdueAssignments: [],
    currentWeekCoursework: [],
    courseStudentsCount: null,
    calendarAssignments: [],
    courseworkLoadFailure: false
}

export const courseworkReducer = (state=initialState, action) => {
    switch (action.type) {
        case universalActionTypes.RESET_ENROLLMENT_INFO:
            return initialState;
        case pending(courseworkActionTypes.GET_ASSIGNMENTS):
            return {
                ...state,
                assignmentListLoading: true,
            };
        case fulfilled(courseworkActionTypes.GET_ASSIGNMENTS):
            return {
                ...state,
                assignmentListLoading: false,
                courseworkLoadFailure: false,
                currentWeekCoursework: action.payload.currentWeekAssignments ? action.payload.currentWeekAssignments : null,
                courseStudentsCount: action.payload.courseStudentsCount ? action.payload.courseStudentsCount : null,
                calendarAssignments: action.payload.calendarAssignments,
                nextDueAssignments: action.payload.nextDueAssignments ? action.payload.nextDueAssignments : null,
                overdueAssignments: action.payload.overdueAssignments ? action.payload.overdueAssignments : null,
            };
        case rejected(courseworkActionTypes.GET_ASSIGNMENTS):
            return {
                ...state,
                assignmentListLoading: false,
                courseworkLoadFailure: true
            };
        default:
            return state;
    }
}
