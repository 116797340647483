import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import {Link} from 'react-router-dom'
import BackButton from "../../components/BackButton";

class PrivacyPolicy extends Component {
    render() {
        return (
            <DocumentTitle title="Privacy Policy | edX Boot Camps">
                <section className="page padding-t-6" data-component="privacy-policy" style={{ fontFamily: 'Helvetica, Arial, sans-serif !important' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-6 col-gutter-lr">
                            <h1>Privacy Policy</h1>

                            <p>Effective Date: October 27, 2022</p>
                        </div>
                        <div className="col-xs-12 col-md-6 col-gutter-lr text-right">
                            {this.props.history.length ? (
                                <BackButton history={this.props.history} customText="Back to Bootcamp Spot"/>
                            ) : (
                                <Link to="/">Visit Bootcamp Spot</Link>
                            )}
                        </div>
                        <div className="col-xs-12">

                            <p>This “Privacy Policy” describes the privacy practices of edX Boot Camps LLC and our subsidiaries and affiliates (collectively, “Company”, “we”, “us”, or “our”) and the rights and choices available to individuals with respect to their information in connection with this website (the “Site”).</p>
                            <p>Company may provide additional or supplemental privacy policies to individuals for specific products or services that we offer. These supplemental privacy policies will govern how we may process the information in the context of the specific product or service.</p>
                            <p>We provide important information for individuals located in <a href="#california-section-header">California</a>, <a href="#europe-section-header">Europe</a>, and <a href="#australia-section-header">Australia</a> below.</p>

                            <h2>TABLE OF CONTENTS</h2>
                            <ul>
                                <li><a href="#personal-info-collected-section-header">Personal Information We Collect</a></li>
                                <li><a href="#cookies-section-header">Cookies and Similar Technologies</a></li>
                                <li><a href="#do-not-track-section-header">Do Not Track Signals</a></li>
                                <li><a href="#personal-info-use-section-header">How We Use Your Personal Information</a></li>
                                <li><a href="#personal-info-share-section-header">How We Share your Personal Information</a></li>
                                <li><a href="#choices-section-header">Your Choices</a></li>
                                <li><a href="#security-section-header">Security</a></li>
                                <li><a href="#intl-transfer-section-header">International Transfer</a></li>
                                <li><a href="#third-parties-section-header">Other Sites and Services</a></li>
                                <li><a href="#california-section-header">Notice for California Consumers</a></li>
                                <li><a href="#europe-section-header">Additional Information for Individuals in Europe</a></li>
                                <li><a href="#australia-section-header">Additional Information for Australian Users</a></li>
                                <li><a href="#changes-section-header">Changes to this Privacy Policy</a></li>
                                <li><a href="#contact-section-header">Contact Us</a></li>
                            </ul>

                            <h2 id="personal-info-collected-section-header">Personal Information We Collect</h2>
                            <h3>Information you give us</h3>
                            <p>Personal information that you may provide through the Site or otherwise communicate with us includes:
                                <ul>
                                    <li>Contact information, such as your first name, last name, mailing address, phone number, and email address</li>
                                    <li>Information about your education or professional experience and goals, such as coursework attempted or completed, degrees earned or in progress, places and length of employment, professional duties and accomplishments, and other information of the type that would be included on a resume or curriculum vitae; and information about your professional goals</li>
                                    <li>Feedback and correspondence, such as information you provide when you contact us with questions, feedback, or otherwise correspond with us online</li>
                                    <li>Payment information, such as the details of your method of payment for the boot camp; however, your payment card number is sent directly to our payment processor and we do not collect or retain such information ourselves</li>
                                    <li>Demographic information, such as your city, state, country, and postal code</li>
                                    <li>Usage information, such as information about how you use the Site and interact with us, including any information that you submit to us through the Site</li>
                                    <li>Marketing information, such as your preferences for receiving marketing communications, details about how you engage with marketing communications, and your responses to our marketing research</li>
                                    <li>Content you choose to upload through the Site, such as videos, audio, images, text, and other electronic documents, including any metadata contained in the content you upload</li>
                                    <li>Other information you may choose to provide, such as information about accommodations you may require and emergency contacts</li>
                                </ul>
                            </p>

                            <h3>Information we obtain from educational institutions</h3>
                            <p>We may obtain information about you from the educational institution with which you enrolled. For example, a college or university may share with us information that is relevant to your participation in our boot camp.</p>

                            <h3>Information we collect about individuals who interact with us in person</h3>
                            <p>In addition to the types of information described above, we may collect additional types of information when individuals interact with us in person, such as when individuals attend our informational sessions or events. This information may include photo and video recordings of our events, and information you may communicate to us in person.</p>

                            <h3>Information automatically collected</h3>
                            <p>When you access our Site, we, and our service providers may automatically log information about you, your computer or mobile device, and activity on our Site. The information that may be collected automatically includes your computer or mobile device operating system, browser type, screen resolution, IP address, the website you visited before browsing to our Site, general location information such as city, state or geographic area; and information about your use of and actions on our Site, such as pages you viewed, how long you spent on a page, navigation paths between pages, information about your activity on a page, access times, and length of access. Our service providers may collect this type of information over time and across third-party websites. This information is collected using cookies and similar technologies. Please refer to the Cookies and Similar Technologies section for more details.</p>

                            <h3>Sensitive Personal Information</h3>
                            <p>We ask that you not send us sensitive personal information (e.g., Social Security numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background or trade union membership), unless we explicitly request it from you. We ask that you not send us, and you not disclose, this type of information to us on the Site.</p>
                            <p>If you send or disclose any sensitive personal information to us when you use the Services, you must consent to our processing and use of such sensitive personal information in accordance with this Privacy Policy. If you do not consent to our processing and use of such sensitive personal information, you must not submit such sensitive personal information through our Site.</p>

                            <h2 id="cookies-section-header">Cookies and Similar Technologies</h2>
                            <h3>What are cookies?</h3>
                            <p>Cookies are small data files stored on your computer or mobile device by a website. Our Site may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer or mobile device until you delete them) to provide you with a more personal and interactive experience on our Site.</p>
                            <p>We use two broad categories of cookies: (1) first party cookies, served directly by us to your computer or mobile device, which we use to recognize your computer or mobile device when it revisits our Site; and (2) third party cookies, which are served by service providers or business partners on our Site, and can be used by such service providers or business partners to recognize your computer or mobile device when it visits other websites.</p>

                            <h3>Cookies we use</h3>
                            <p>Our Site uses the following types of cookies for the purposes set out below:</p>

                            <table style={{borderCollapse: "collapse"}}>
                                <thead>
                                <tr>
                                    <th style={{borderBottom: "1px solid #000000"}}>TYPE OF COOKIE</th>
                                    <th style={{borderBottom: "1px solid #000000"}}>PURPOSE</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{border: "1px solid #aaaaaa",padding: "5px"}}>Essential Cookies</td>
                                    <td style={{border: "1px solid #aaaaaa",padding: "5px"}}>These cookies are essential to provide you with services available through our Site and to enable you to use some of its features. Without these cookies, the services that you have asked for cannot be provided, and we only use these cookies to provide you with those services.</td>
                                </tr>
                                <tr>
                                    <td style={{border: "1px solid #aaaaaa",padding: "5px"}}>Functionality Cookies</td>
                                    <td style={{border: "1px solid #aaaaaa",padding: "5px"}}>These cookies allow our Site to remember choices you make when you use our Site. The purpose of these cookies is to provide you with a more personal experience and to avoid you having to re-select your preferences every time you visit our Site.</td>
                                </tr>
                                <tr>
                                    <td style={{border: "1px solid #aaaaaa",padding: "5px"}}>Analytics and Performance Cookies</td>
                                    <td style={{border: "1px solid #aaaaaa",padding: "5px"}}>These cookies collect information about traffic to our Site and about how individuals use our Site. The information gathered may include the types of information described above in the section titled “Information automatically collected.” We use this information to help operate our Site more efficiently, to gather broad demographic information, monitor the level of activity on our Site, and improve the Site.</td>
                                </tr>
                                </tbody>
                            </table>

                            <h3>Disabling cookies</h3>
                            <p>You can typically remove or reject cookies via your browser settings. In order to do this, follow the instructions provided by your browser (usually located within the “settings,” “help” “tools” or “edit” menus). Many browsers are set to accept cookies until you change your settings.</p>
                            <p>For further information about cookies, including how to see what cookies have been set on your computer or mobile device and how to manage and delete them, visit <a href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a>.</p>
                            <p>If you do not accept our cookies, you may experience some inconvenience in your use of our Site. For example, we may not be able to recognize your computer or mobile device and you may need to log in every time you visit our Site.</p>

                            <h3>Pixel tags</h3>
                            <p>In addition to cookies, we may use other technologies, such as pixel tags (which are also known as web beacons and clear GIFs) on our Site and in our HTML formatted emails to track the actions of users on our Site and interactions with our emails. Unlike cookies, which are stored on the hard drive of your computer or mobile device by a website, pixel tags are embedded invisibly on webpages or within HTML formatted emails. Pixel tags are used to demonstrate that a web page was accessed or that certain content was viewed, typically to measure the success of our marketing campaigns or engagement with our emails and to compile statistics about usage of the Site, so that we can manage our content more effectively.</p>

                            <h2 id="do-not-track-section-header">Do Not Track Signals</h2>
                            <p>Some Internet browsers may be configured to send "Do Not Track" signals to the online services that you visit. We currently do not currently respond to do not track signals. To find out more about "Do Not Track," please visit <a href="http://www.allaboutdnt.com/">www.allaboutdnt.com</a>.</p>

                            <h2 id="personal-info-use-section-header">How We Use Your Personal Information</h2>
                            <h3>To provide our Site</h3>
                            <p>If you use our Site, we use your personal information to:
                                <ul>
                                    <li>operate, maintain, administer and improve the Site and provide you our products and services</li>
                                    <li>present invoices and collect payment for our products and services</li>
                                    <li>provide information about our products and services</li>
                                    <li>process and manage your account you make through the Site, including requests to receive additional information, and to attend one of our information sessions or events</li>
                                    <li>better understand your needs and interests, including by conducting surveys</li>
                                    <li>personalize our content on the Site and in our communications with you</li>
                                    <li>provide support and maintenance for the Site</li>
                                    <li>communicate with you</li>
                                    <li>respond to your requests, questions and feedback</li>
                                </ul>
                            </p>

                            <h3>To comply with law</h3>
                            <p>We may use your personal information if we are authorized or required by applicable laws, lawful requests or legal process, such as to respond to subpoenas or requests from government authorities.</p>

                            <h3>With your consent</h3>
                            <p>Where required by law, we will request your consent to use your personal information. If we request your consent to use your personal information, you have the right to withdraw your consent any time in the manner indicated when we requested the consent or by <a href="#contact-section-header">contacting us</a>. If you have consented to receive marketing communications from one of our partner educational institutions, you may withdraw your consent by contacting the educational institution directly.</p>

                            <h3>To create aggregated or de-identified data for analytics</h3>
                            <p>We may perform analytics on aggregated or de-identified data that we derive from your personal information and other individuals whose personal information we collect. We use that aggregated or de-identified data for our lawful business purposes.</p>

                            <h3>For compliance, fraud prevention and safety</h3>
                            <p>We use your personal information as we believe necessary or appropriate to (a) enforce the terms and conditions that govern the Site; (b) protect our rights, privacy, safety or property, and/or that of you or others; and (c) protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.</p>

                            <h2 id="personal-info-share-section-header">How We Share your Personal Information</h2>
                            <h3>Affiliates</h3>
                            <p>We may disclose your personal information within the Company group of companies, including to our subsidiaries and corporate affiliates (our “Affiliates”), for purposes consistent with this Privacy Policy and any applicable laws.</p>

                            <h3>Educational Institutions, Commercial Partners, and Potential Employers</h3>
                            <p>We may disclose your personal information to the educational institution or commercial partner for the boot camp in which you are enrolled, either on an individual basis or in an aggregate form. We may also share your personal information with potential employers if you have expressed interest in jobs or internships through the Site. At your direction, we may provide these potential employers your contact information, information about your education or professional experience, marketing information, your interest in certain courses or fields of study and other information you request that we share with these parties, so that the employer can contact you about potential employment, or as otherwise may be disclosed to you. We do not control the partners’ or potential employers’ use of your personal information.</p>

                            <h3>Service providers</h3>
                            <p>We may employ third party companies and individuals to administer and provide services on our behalf (such as customer support, hosting, email delivery, and database management services). These third parties may use your information only as directed by Company and in a manner consistent with this Privacy Policy, and are prohibited from using or disclosing your information for any other purpose.</p>
                            <p>In addition, Company uses a third-party payments processor to process payments made to us – currently, Stripe. Stripe may use personal information it collects when processing our payments as directed by us.</p>

                            <h3>Professional advisors</h3>
                            <p>We may disclose your personal information to professional advisors, such as lawyers, bankers, auditors and insurers, where necessary in the course of the professional services that they render to us.</p>

                            <h3>Compliance with Laws and Law Enforcement; Protection and Safety</h3>
                            <p>Company may disclose information about you to government or law enforcement officials or private parties as required by law, and disclose and use such information as we believe necessary or appropriate to (a) comply with applicable laws and lawful requests and legal process, such as to respond to subpoenas or requests from government authorities; (b) enforce the terms and conditions that govern the Site; (d) protect our rights, privacy, safety or property, and/or that of you or others; and (e) protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.</p>

                            <h3>Business Transfers</h3>
                            <p>Company may sell, transfer or otherwise share some or all of its business or assets, including your personal information, in connection with a business transaction (or potential business transaction) such as a merger, consolidation, acquisition, reorganization or sale of assets or in the event of bankruptcy, in which case we will make reasonable efforts to require the recipient to honor this Privacy Policy.</p>

                            <h2 id="choices-section-header">Your Choices</h2>
                            <p>In this section, we describe the rights and choices available to all users. Users who are located within Member States of the European Union, countries in the <a href="http://ec.europa.eu/eurostat/statistics-explained/index.php/Glossary:European_Economic_Area_(EEA)">European Economic Area</a>, and the United Kingdom (collectively, “Europe” or “European”) or who are residents of California may read additional information about their rights below.</p>

                            <h3>Access, Update, or Correct Your Information</h3>
                            <p>You may also <a href="#contact-section-header">contact us</a> as described below to access, update or correct the personal information that Company maintains about you, or if you have additional requests or questions. You can submit these requests by email to <a href="mailto:privacy@trilogyed.com">privacy@trilogyed.com</a>. We may request specific information from you to help us confirm your identity and process your request. Applicable law may require or permit us to decline your request. If we decline your request, we will tell you why, subject to legal restrictions.</p>

                            <h3>Choosing not to share your personal information</h3>
                            <p>Where we are required by law to collect your personal information, or where we need your personal information in order to provide services or the Site to you, if you do not provide this information when requested (or you later ask to delete it), we may not be able to provide you with our services. We will tell you what information you must provide to receive the services by designating it as required in or on the Site or through other appropriate means.</p>

                            <h2 id="security-section-header">Security</h2>
                            <p>The security of your personal information is important to us. We take a number of organizational, technical and physical measures designed to protect the personal information we collect, both during transmission and once we receive it. However, no security safeguards are 100% secure and we cannot guarantee the security of your information.</p>

                            <h3>Children</h3>
                            <p>Our Site is not directed to children under 13. If a parent or guardian becomes aware that their child has provided us with information without their consent, they should <a href="#contact-section-header">contact us</a>. We will delete such information from our files as soon as reasonably practicable.</p>

                            <h2 id="intl-transfer-section-header">International Transfer</h2>
                            <p>Company is headquartered in the United States and has service providers in other countries, your personal information may be transferred to the United States or other locations outside of your state, province, country or other governmental jurisdiction.</p>
                            <p>European Union and UK users should read the important information provided here about transfer of personal information outside of the European Economic Area or UK.</p>

                            <h2 id="third-parties-section-header">Other Sites and Services</h2>
                            <p>The Site may contain links to other websites and services, including educational institutions that offer or use our educational material. These links are not an endorsement, authorization or representation that we are affiliated with that third party. We do not exercise control over third party websites or services and are not responsible for their actions. Other websites and services may follow different rules regarding the use or disclosure of the personal information you submit to them. We encourage you to read the privacy policies of the other websites you visit and services you use.</p>

                            <h2 id="california-section-header">Notice for California Consumers</h2>
                            <h3>Categories of Personal Information that We Collect</h3>
                            <p>As required by the California Consumer Privacy Act (“CCPA”), below are the categories of personal information about California consumers that the Company collects and discloses for a business purpose. We do not sell Personal Information collected through this Site.</p>
                            <p>We collect and disclose for a business purpose the following categories of personal information:
                                <ul>
                                    <li><b>Contact Information and Identifiers</b>, including real name, alias, postal address, unique personal identifier, online identifier, IP address, email address, phone number, account name, or other similar identifiers.</li>
                                    <li><b>Customer Records</b>, including paper and electronic customer records containing personal information, such as name, signature, address, telephone number, education, employment, employment history, financial information relevant to your payments, or medical information relevant to your participation in the Boot Camp.</li>
                                    <li><b>Usage Data</b>, including internet or other electronic network activity information, such as browsing history, search history, and information regarding a consumer’s interaction with an internet website, application, or advertisement.</li>
                                    <li><b>Audio, electronic, visual, thermal, olfactory or similar information</b>, including audio/video recordings during class sessions or voice recordings when you call us.</li>
                                    <li><b>Biometric Information</b>, including facial recognition used for identity verification, where required by the School.</li>
                                    <li><b>Employment History</b>, including professional or employment-related information.</li>
                                    <li><b>Education Information</b>, including information about education history or background.</li>
                                    <li><b>Protected Classifications</b>, including characteristics of protected classifications under California or federal law such as race, color, sex, age, religion, national origin, disability, and citizenship status.</li>
                                </ul>
                            </p>

                            <h3>Categories of Sources of Personal Information</h3>
                            <p>The categories of sources of personal information we collect are described in the section Personal Information We Collect above.</p>

                            <h3>Purposes for Collecting Personal Information</h3>
                            <p>We collect the personal information identified above to communicate with you, for advertising and marketing purposes, to provide and improve our services, and other purposes set forth in How We Use Your Personal Information above.</p>

                            <h3>Categories of Third Parties with Which Personal Information is Shared</h3>
                            <p>We share personal information identified above with the following categories of third parties:
                                <ul>
                                    <li>Our educational partners,</li>
                                    <li>Our Affiliates,</li>
                                    <li>Third-party providers that serve business, professional or technical support functions,</li>
                                    <li>Potential employers (as directed by you), or</li>
                                    <li>Third parties for legal matters or safety purposes.</li>
                                </ul>
                            </p>

                            <h3>California Consumer Rights</h3>
                            <p>California law gives California consumers the right to make the following requests:
                                <ul>
                                    <li>The right to opt-out of sale of your personal information. We do not sell personal information collected through this Site.</li>
                                    <li>The right to request a copy of the personal information that we have collected about you in the prior 12 months.</li>
                                    <li>The right to request deletion of the personal information that we have collected from you, subject to certain exemptions (for example, where the information is used by us to detect security incidents, debugging or to comply with a legal obligation).</li>
                                    <li>The right to request more details about the personal information we collect and how and why we use and share it, including the categories of personal information we have collected about you, the categories of sources for personal information we collect, the business or commercial purposes for collecting personal information, and the categories of third parties with which we share personal information.</li>
                                </ul>
                            </p>

                            <p>You can submit a copy, deletion, or right-to-know request online by filling out Company’s <a href="https://2u-datarequest.atlassian.net/servicedesk/customer/portal/3/group/7/create/39">Data Request Form</a> or by contacting us at 1-888-213-3391.</p>

                            <p>The CCPA prohibits discrimination against California consumers for exercising their rights under the CCPA and imposes requirements on any financial incentives offered to California consumers related to their personal information, unless the different prices, rates, or quality of goods or services are reasonably related to the value of the consumer’s data.</p>

                            <h2 id="europe-section-header">Additional Information for Individuals in Europe</h2>
                            <p>The information provided in this section is provided for individuals located in Europe or the UK.</p>

                            <h3>Personal information</h3>
                            <p>References to “personal information” in this Privacy Policy are equivalent to “personal data” governed by European or UK data protection legislation.</p>

                            <h3>Legal bases for processing</h3>
                            <p>We use your personal information only as permitted by law. We are required to inform you of the legal bases of our processing of your personal information, which are described in the table below. If you have questions about the legal basis of how we process your personal information, contact us at <a href="mailto:privacy@trilogyed.com">privacy@trilogyed.com</a>.</p>

                            <p><i>Details regarding each processing purpose listed below are provided in the section above titled “<a href="#personal-info-use-section-header">How We Use Your Personal Information</a>”.</i></p>

                            <table  style={{borderCollapse: "collapse"}}>
                                <thead>
                                <tr>
                                    <th style={{borderBottom: "1px solid #000000"}}>PROCESSING PURPOSE</th>
                                    <th style={{borderBottom: "1px solid #000000"}}>LEGAL BASIS</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{border: "1px solid #aaaaaa",padding: "5px"}}>To provide our Site</td>
                                    <td style={{border: "1px solid #aaaaaa",padding: "5px"}}>Processing is necessary to perform the contract governing our provision of the Services or to take steps that you request prior to signing up for the Services.</td>
                                </tr>
                                <tr>
                                    <td style={{border: "1px solid #aaaaaa",padding: "5px"}}>To communicate with you<br/>To create anonymous data for analytics<br/>To communicate with you<br/>To create anonymous data for analytics<br/>For compliance, fraud prevention and safety</td>
                                    <td style={{border: "1px solid #aaaaaa",padding: "5px"}}>These processing activities constitute our legitimate interests. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal information for our legitimate interests. We do not use your personal information for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law).</td>
                                </tr>
                                <tr>
                                    <td style={{border: "1px solid #aaaaaa",padding: "5px"}}>To comply with law</td>
                                    <td style={{border: "1px solid #aaaaaa",padding: "5px"}}>Processing is necessary to comply with our legal obligations</td>
                                </tr>
                                <tr>
                                    <td style={{border: "1px solid #aaaaaa",padding: "5px"}}>With your consent</td>
                                    <td style={{border: "1px solid #aaaaaa",padding: "5px"}}>Processing is based on your consent. Where we rely on your consent you have the right to withdraw it anytime in the manner indicated in the Service or by contacting us.</td>
                                </tr>
                                </tbody>
                            </table>

                            <h3>Use for new purposes</h3>
                            <p>We may use your personal information for reasons not described in this Privacy Policy where permitted by law and the reason is compatible with the purpose for which we collected it. If we need to use your personal information for an unrelated purpose, we will notify you and explain the applicable legal basis.</p>

                            <h3>Retention</h3>
                            <p>We will only retain your personal information for as long as necessary to fulfil the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>

                            <p>To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>

                            <p>When we no longer require the personal information we have otherwise collected about you, we will dissociate such information from the information attached to your content. In some circumstances we may aggregate or de-identify your personal information, in which case we may use this information indefinitely without further notice to you.</p>

                            <h3>Your rights</h3>
                            <p>European data and UK protection laws give European Union and UK users certain rights regarding their personal information. If you are located within the European Union or the UK, you may ask us to take the following actions in relation to your personal information that we hold:
                                <ul>
                                    <li>Opt-out. Stop sending you direct marketing communications. You may continue to receive service-related and other non-marketing emails.</li>
                                    <li>Access. Provide you with information about our processing of your personal information and give you access to your personal information.</li>
                                    <li>Correct. Update or correct inaccuracies in your personal information.</li>
                                    <li>Delete. Delete your personal information.</li>
                                    <li>Transfer. Transfer a machine-readable copy of your personal information to you or a third party of your choice.</li>
                                    <li>Restrict. Restrict the processing of your personal information.</li>
                                    <li>Object. Object to our reliance on our legitimate interests as the basis of our processing of your personal information.</li>
                                </ul>
                                You can submit these requests by email to privacy@trilogyed.com. We may request specific information from you to help us confirm your identity and process your request. Applicable law may require or permit us to decline your request. If we decline your request, we will tell you why, subject to legal restrictions. If you would like to submit a complaint about our use of your personal information or response to your requests regarding your personal information, you may contact us or submit a complaint to the data protection regulator in your jurisdiction. <a href="https://edpb.europa.eu/about-edpb/board/members_en">Find your data protection regulator</a>.</p>

                            <h3>Cross-Border Data Transfer</h3>
                            <p>Whenever we transfer your personal information out of Europe or the UK to countries not deemed by the European Commission to provide an adequate level of personal information protection, the transfer will be based:
                                <ul>
                                    <li>Pursuant to the recipient’s compliance with standard contractual clauses or Binding Corporate Rules;</li>
                                    <li>Pursuant to the consent of the individual to whom the personal information pertains; or</li>
                                    <li>As otherwise permitted by applicable European or UK requirements.</li>
                                </ul>
                                You may contact us if you want further information on the specific mechanism used by us when transferring your personal information out of Europe or the UK.</p>

                            <h2 id="australia-section-header">Additional Information for Australian Users</h2>
                            <p>References to “personal information” in this Privacy Policy are equivalent to “Personal Information” as defined in the <i>Privacy Act 1988</i> (Cth) (Privacy Act) and includes information or an opinion, whether true or not, and whether recorded in material form or not, about an identified individual, or an individual who is reasonably identifiable. It includes personal information such as your name, email address, home or other physical address, and phone number and which is not otherwise publicly available. This may include sensitive information, which is information or an opinion about an individual's race or ethnic origin. Personal Information may include other types of information depending on the definition of that applies in your physical location. Only the legal definition of Personal Information that applies in your location will apply to you under this Privacy Policy.</p>

                            <p>“Education Records” includes those records that contain information directly related to a student and which are maintained by an educational agency or institution or by a party acting for the agency or institution. For the purposes of this Privacy Policy, “Education Records” includes name, address, email, cell phone, date of birth, race, gender, previous salary information, current industry of employment, enrollment dates, classes taken, attendance, homework completion rate, grades, evaluations, pre-work, participation in career services in general as well as specific events attended, survey data, your portfolio/projects, feedback provided regarding the services we provide or the Site, your instructors, teaching assistants and career services personnel.</p>

                            <h3>Governing Law</h3>
                            <p>By choosing to use the Site or otherwise providing personal information to us, you agree that any dispute over privacy or the terms contained in this Privacy Policy will be governed by the Privacy Act and the Australian Privacy Principles (“APPs”), and the adjudication of any disputes arising in connection with the Site will be in accordance with the Terms of Use posted on www.bootcampspot.com.</p>

                            <p>The Site is based in the United States. Any data or Personal Information provided to the Site will be processed and stored in the United States and may be accessed from our offices in the United States, Canada, U.K., Ireland and Mexico where our servers are located. By using the Site, you expressly consent to the collection, retention, use, disclosure and transfer, including cross-border disclosure of your Personal Information to the United States, Canada, U.K., Ireland and Mexico in accordance with this Privacy Policy, regardless of your physical location, and understand the Site can't ensure that overseas recipients of your Personal Information and/or Education Records will comply with the APPs.</p>

                            <h3>Complaints</h3>
                            <p>If you would like to make a complaint about our handling of your Personal Information or have a query or concern about our handling of your Personal Information, please contact <a href="mailto:privacy@trilogyed.com">privacy@trilogyed.com</a>.</p>

                            <p>We may ask you to put your complaint in writing. We will investigate and respond to your complaint as soon as is practicable after we receive it.</p>

                            <p>If you are not satisfied with our response to your complaint, you can refer it to the Office of the Australian Information Commissioner (OAIC) (<a href="https://www.oaic.gov.au">https://www.oaic.gov.au</a>). The OAIC has the power to investigate the matter and make a determination.</p>

                            <h3>Site Collection Notice</h3>
                            <ol>
                                <li>We collect your Personal Information and Education Records when you use the Site. The primary purpose of collecting this information is to provide the Site, and perform necessary associated administrative activities, which will enable you to use the Site. We will also use your Personal Information to administer our relationship with you (including determining your eligibility for our services and responding to your queries), identify you and your account, provide and improve our services, communicate with you, for internal business purposes, and to comply with our legal, regulatory and other obligations.</li>
                                <li>We may disclose your Personal Information and Education Records for educational and administrative and support purposes, such as facilitating access to courses, to business partners, potential employers, our education partners, and to comply with our legal obligations.</li>
                                <li>If you do not provide some or all of your Personal Information, we may not be able to provide you with some or all of our services.</li>
                                <li>We may store your Personal Information and Education Records on secure servers located in the United States, and may access your Personal Information and Education Records from elsewhere.</li>
                                <li>The Privacy Policy, located above, sets out how you may seek access to and correct your Personal Information which the Company has collected and holds.</li>
                                <li>The Privacy Policy also sets out how you can make a complaint about a breach of the APPs and how the complaint will be handled by us.</li>
                            </ol>

                            <h2 id="changes-section-header">Changes to this Privacy Policy</h2>
                            <p>We reserve the right to modify this Privacy Policy at any time. We encourage you to periodically review this page for the latest information on our privacy practices. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on the Site. We may (and, where required by law, will) also provide notification of changes in another way that we believe is reasonably likely to reach you, such as via e-mail (if you have an account where we have your contact information) or another manner through the Site.</p>

                            <p>Any modifications to this Privacy Policy will be effective upon our posting of the new terms and/or upon implementation of the new changes on the Site (or as otherwise indicated at the time of posting). In all cases, your continued use of the Site after the posting of any modified Privacy Policy indicates your acceptance of the terms of the modified Privacy Policy.</p>

                            <h2 id="contact-section-header">Questions or Concerns About this Privacy Policy</h2>
                            <p>You may direct any questions or concerns regarding this Privacy Policy to us at <a href="mailto:privacy@trilogyed.com">privacy@trilogyed.com</a>. You can also contact our EU representative (identified below). We review this Privacy Policy from time to time and reserve the right to make changes to this Privacy Policy at any time. If we do make material changes, we will give notice via the Website or by otherwise notifying you.</p>


                            <p>You also have the right to file a complaint with the data protection regulator in the country where our “data controller” is located, where our EU representative is located (if you live in the EU), or the country where you live (e.g., if you live in Europe, the European Data Protection Authority where you live, or if you live in South Africa, the information regulator). A list of edX Boot Camps data controllers can be found <a href="https://2u.com/eudatacontrollers/">here</a>.</p>

                            <p>If you reside in the EU, you can contact our EU representative at DataRep, 12 Northbrook Road, Dublin, D06 E8W5, Ireland, datarequest@datarep.com. If you reside in the UK, you can contact our UK representative at 2U Group (UK) Ltd, c/o Skadden, Arps, Slate, Meagher & Flom (UK) LLP, 40 Bank Street Canary Wharf, London, United Kingdom, E14 5DS, +44 20 3457 5774.</p>

                            <p>To make a request to delete or access personal data, or for more information about the personal data edX Boot Camps has about you, use edX Boot Camps’ <a href="https://2u-datarequest.atlassian.net/servicedesk/customer/portal/3/group/7/create/39">Data Request Form</a>.</p>
                        </div>
                    </div>
                </section>
            </DocumentTitle>
        )
    }

}

export default PrivacyPolicy;
