import axios from 'axios';
import {jsonContentTypeHeader} from "../constants/axios";

export const getWeeklyFeedbackData = async (enrollmentId, courseId, week = null) => {
    try {
        let payload = {
            enrollmentId: enrollmentId,
            courseId: courseId,
            week: week,
        };
        let request = await axios.post("/broker/weeklyFeedbackReport", payload, {headers: jsonContentTypeHeader});

        return {chart: request.data.AverageData, details: request.data.DetailedData, comments: request.data.Comments};
    } catch (err) {
        return err;
    }
};

export const feedbackChartOptions = () => {
    return {
        legend: {
            position: 'bottom'
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero:false,
                max: 5,
                stepSize: .25
              }
            }
          ]
        }
      }
};

export const formatDataForChart = (chartData) => {

    let chartLabels = [];
    let AcademicSupportData = [];
    let OverallSatisfaction = [];
    let InstructorClarityData = [];
    let InstructorEngagementData= [];
    let InstructorKnowledgeData = [];
    let HomeworkFeedbackRatingData = [];
    let ApplyLearningOutsideClassData = [];
    let ClassPace = [];

    //FIXME: refactor the map to an each and figure out if we can delete HoursSpentOutsideOfClassData and its references (currently commented out)
    chartData
      .sort((a, b) =>  a.week - b.week)
      // eslint-disable-next-line
      .map((item,index)=>{
          chartLabels.push(`Week ${item.week}`);
          AcademicSupportData.push(item.academicSupport);
          OverallSatisfaction.push(item.classOverall);
          InstructorClarityData.push(item.instructorClarity);
          InstructorEngagementData.push(item.instructorEngagement);
          InstructorKnowledgeData.push(item.instructorKnowledge);
          HomeworkFeedbackRatingData.push(item.homeworkFeedbackRating);
          ApplyLearningOutsideClassData.push(item.applyLearningOutsideClass);
          ClassPace.push(item.paceOfClass);
    });

    const data = {
        labels: chartLabels,
        datasets: [
          {
            label: 'Academic Support',
            fill: false,
            data: AcademicSupportData,
            backgroundColor: '#332288',
            borderColor: '#332288'
          },
          {
            label: 'Overall Satisfaction',
            fill: false,
            data: OverallSatisfaction,
            backgroundColor: '#88ccee',
            borderColor: '#88ccee'
          },
          {
            label: 'Instructor Clarity',
            fill: false,
            data: InstructorClarityData,
            backgroundColor: '#44aa99',
            borderColor: '#44aa99'
          },
          {
            label: 'Instructor Engagement',
            fill: false,
            data: InstructorEngagementData,
            backgroundColor: '#117733',
            borderColor: '#117733'
          },
          {
            label: 'Instructor Knowledge',
            fill: false,
            data: InstructorKnowledgeData,
            backgroundColor: '#999933',
            borderColor: '#999933'
          },
         {
            label: 'Homework Feedback Rating',
            fill: false,
            data: HomeworkFeedbackRatingData,
            backgroundColor: '#882255',
            borderColor: '#882255'
         },
          {
            label: 'Apply Learning Outside Class',
            fill: false,
            data: ApplyLearningOutsideClassData,
            backgroundColor: '#ddcc77',
            borderColor: '#ddcc77'
          },
          {
            label: 'Class Pace',
            fill: false,
            data: ClassPace,
            backgroundColor: '#aa4499',
            borderColor: '#aa4499'
          }
        ]
      };

    return data;
};

export const submissionsChartOptions = (chartData) => {
    let NumberOfSubmissions = [];
    chartData.forEach((item)=> {
        NumberOfSubmissions.push(item.feedbackCount);
    });
    let min = Math.min(...NumberOfSubmissions);
    let max = Math.max(...NumberOfSubmissions);
    return {
        legend: {
            position: 'bottom'
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero:false,
                        suggestedMax: max + 1,
                        suggestedMin: min - 1,
                        stepSize: 1
                    }
                }
            ]
        }
    }
};

export const formatDataForSubmissionsChart = (chartData) => {

    let chartLabels = [];
    let NumberOfSubmissions = [];

    //FIXME: refactor the map to an each and figure out if we can delete HoursSpentOutsideOfClassData and its references (currently commented out)
    chartData
        .sort((a, b) =>  a.week - b.week)
        // eslint-disable-next-line
        .map((item,index)=>{
            chartLabels.push(`Week ${item.week}`);
            NumberOfSubmissions.push(item.feedbackCount);
        });

    const data = {
        labels: chartLabels,
        datasets: [
            {
                label: 'Total Submissions',
                fill: false,
                data: NumberOfSubmissions,
                backgroundColor: '#533e6d',
                borderColor: '#533E6D'
            }
        ]
    };

    return data;
};


export const outsideTimeSpentChartOptions = () => {
    return {
        legend: {
            position: 'bottom'
        },
        scales: {
            yAxes: [
                {scaleLabel: {
                        display: true,
                        labelString: '% Submissions'
                    },
                    ticks: {
                        beginAtZero:true,
                        max: 100,
                        stepSize: 10
                    },
                }
            ]
        }
    }
};

export const formatDataForOutsideTimeSpentChart = (chartData) => {

    let chartLabels = [];
    let option1 = [];
    let option2 = [];
    let option3 = [];
    let option4 = [];
    let option5= [];

    //FIXME: refactor the map to an each and figure out if we can delete HoursSpentOutsideOfClassData and its references (currently commented out)
    chartData
        .sort((a, b) =>  a.week - b.week)
        // eslint-disable-next-line
        .forEach((item)=>{
            chartLabels.push(`Week ${item.week}`);
            option1.push(item.OutsideTimeSpent.option1Percentage);
            option2.push(item.OutsideTimeSpent.option2Percentage);
            option3.push(item.OutsideTimeSpent.option3Percentage);
            option4.push(item.OutsideTimeSpent.option4Percentage);
            option5.push(item.OutsideTimeSpent.option5Percentage);
        });

    const data = {
        labels: chartLabels,
        datasets: [
            {
                label: '0-5 hrs',
                fill: false,
                data: option1,
                backgroundColor: '#332288',
                borderColor: '#332288'
            },
            {
                label: '6-10 hrs',
                fill: false,
                data: option2,
                backgroundColor: '#88ccee',
                borderColor: '#88ccee'
            },
            {
                label: '11-15 hrs',
                fill: false,
                data: option3,
                backgroundColor: '#117733',
                borderColor: '#117733'
            },
            {
                label: '16-20 hrs',
                fill: false,
                data: option4,
                backgroundColor: '#ddcc77',
                borderColor: '#ddcc77'
            },
            {
                label: '+20 hrs',
                fill: false,
                data: option5,
                backgroundColor: '#aa4499',
                borderColor: '#aa4499'
            }
        ]
    };

    return data;
};
