import React, {Component} from 'react';
import {connect} from 'react-redux'
import { HashLink } from 'react-router-hash-link';
import StudentCoursework from './studentCoursework'
import InstructionalCoursework from './instructionalCoursework'
import Loading from '../../components/Loading'
import Alert from '../../components/Alert'


import {isStudent} from '../../helpers/roles'
import {courseworkActions} from '../../actions/courseworkActions';

const mapStateToProps = (state) => {
    return {
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        enrollmentId: state.user.enrollmentInfo.id,
        coursework: state.coursework,
        enrollmentInfo: state.user.enrollmentInfo
    }
}

class Coursework extends Component {
    componentDidMount() {
        this.fetchCourseworks()
    }

    fetchCourseworks = () => {
        const {enrollmentId, dispatch} = this.props;
        dispatch(courseworkActions.fetchCourseworks(enrollmentId));
    }

    render() {
        const {activeRole, coursework} = this.props;
        if(coursework.courseworkLoadFailure){
            return (<Alert type='error'>
                        We're sorry, an error occurred while loading. Please click <button onClick={this.fetchCourseworks()}>here</button> to try again!
                    </Alert>
                    );
        }
        
        return (<div>
                {coursework.assignmentListLoading === true ? <Loading/> :
                    <div>
                        {isStudent(activeRole) ? <StudentCoursework/> : <InstructionalCoursework/>}
                        <HashLink to={'/#coursecalendar'}>
                            <button className="btn-small btn-tertiary btn-create">View Course Calendar</button>
                        </HashLink>
                    </div>
                }
            </div>
        )
    }

}

export const COURSEWORK = Coursework; 
export default connect(mapStateToProps)(Coursework)
