import React from 'react';

const Tag = (props) => {
    const value = props.value;
    return props.value ? (
        <span className="bcs-filter-tags-container">
            <span className="bcs-filter-tags-tag"> {value} </span>
            <span
                className="bcs-filter-tags-delete"
                onClick={() => props.onRemove(value)}
                onKeyDown={() => props.onRemove(value)}
                role="link"
                tabIndex="0"
            > x </span>
        </span>
    ) : null;
}

export default Tag;
