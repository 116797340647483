import React from 'react';
import PropTypes from 'prop-types';

const Alert = (props) => {
    const {type, center} = props
    return (
        <div
            className={`context-${type} margin-b-3${center ? " text-center" : ""}`}
            role="alert"
            data-component="alert"
            aria-live={(type === 'error' || type === 'warn') ? 'assertive' : 'polite'}
        >
            {props.children}
        </div>
    )
}

Alert.propTypes = {
    type: PropTypes.oneOf(['error','success','warn', 'info']),
}

export default Alert