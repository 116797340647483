import React from 'react';
import PropTypes from 'prop-types';

const renderHeading = (heading) => {
    const headingEl = heading !== '' ? (
        // td-title will only show on md screens and up
        <div className="td-title">{heading}</div>
    ) : null
    return headingEl
}

const TD = (props) => {

    // If no col prop is provided the cell will auto size itself to fit the row (md screens up)
    const col = props.col !== '' ? ` col-md-${props.col}` : ' col-md'

    return (
        <td className={props.className + ' col-xs-12' + col} style={props.style}>
            {renderHeading(props.heading)}
            {props.children}
        </td>
    )
}

TD.propTypes = {
    className: PropTypes.string,
    col: PropTypes.string,
    heading: PropTypes.string,
    style: PropTypes.object
}

TD.defaultProps = {
    className: '',
    col: '',
    heading: '',
    style: {}
}

export default TD