import React from 'react';
import Icon from '../Icon';

const Loading = (props) => (
    <div className="text-center" role="status" aria-busy="true">
        <Icon icon="circle-notch" options="fa-spin fa-2x fa-fw" />
        {props.children ? (
            <div role="alert" aria-busy="true">{props.children}</div>
        ) : null}
    </div>
)


export default Loading
