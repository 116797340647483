import React, {Component} from 'react';

class ShortTextStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value : ""
        }

    }

    handleChange = (e) => {
        this.setState({
            value: e.target.value
        });
    }

    commitStep = (e) => {
        let { value } = e.target
        value = value.toString().trim()
        this.props.onChange(this.props.step.step.id, value);
    }

    render() {
        const { id, required, definition } = this.props.step.step;
        let charLeftComponent = null;
        if (definition.ui.maxLength){
            const charLeft = definition.ui.maxLength - this.state.value.length;
            charLeftComponent = <p style={charLeft < 5 ? {width: '100%', color: "red"} : {width: '100%'}}
               id={id + "CharacterLimit"}
               className="text-right label">
                {charLeft} characters left
            </p>
        }
        
        return (
            <li className="margin-b-3">
                <label htmlFor={id} aria-required={required ? "true" : "false"}>{definition.text}{required ? ' *' : null}</label>
                <input id={id} type="text" name={id} onBlur={this.commitStep} onChange={this.handleChange}
                       maxLength={definition.ui.maxLength ? definition.ui.maxLength : null}
                />
                {charLeftComponent}
            </li>
        )
    }
}

export default ShortTextStep;
