import React from 'react';
import {Route, Redirect} from 'react-router-dom';

export const PrivateRoute = ({component: Component, ...rest}) => {
    const state = rest.store.getState();
    //TODO put in UX for user's session not being valid

    return (
        
            <Route {...rest} render={props => {
                const routeLockPathExists = state.routeLock && state.routeLock.data && state.routeLock.data.path // will be the value of the path(aka truthy) if it exists
                const denyAccess = !(routeLockPathExists == null) && (window.location.pathname !== routeLockPathExists)
                const noUserRedirectToLogin = !localStorage.getItem('user')
                const renderCurrentComponent = (localStorage.getItem('user') && !denyAccess)

                return (
                    noUserRedirectToLogin ? <Redirect to={{pathname: '/login', state: {from: props.location}}}/> :
                    renderCurrentComponent
                    ? <Component {...props}/> 
                    : <Redirect to={{pathname: state.routeLock.data.path}}/>
                )
            }
            }/>
        
    )
}

export default (PrivateRoute);
