import {Component} from "react";
import DocumentTitle from "react-document-title";
import {connect} from 'react-redux';
import React from "react";
import GraphiQL from "graphiql";
import "./graphiql.css";
import {Table, TBody, TD, TH, THead, TR} from "../../components/Table";

const mapStateToProps = (state) => {
    return {
        authToken: state.user.data.authToken,
    }
};

class ApiDocsGraphQL extends Component {
    state = {
        query: ""
    }

    updateQuery = (query) => {
        this.setState({query})
    }
    render() {
        const {authToken} = this.props;
        const headers = [
            {name: "Content-Type", value: "application/json", description: ""},
            {name: "authToken", value: "<valid token>", description: "The token generated via Login query"}
        ];

        const {query} = this.state
        
        return (
            <DocumentTitle title="API Access - GraphQL | Bootcamp Spot">
                <section>
                    <div className="row">
                        <div className="col-xs-12">
                            <h1 className={"border-bottom"}>/api/instructor/graphql/v1/query</h1>
                            Allows to access the instructor API data via a GraphQL interface.

                            <React.Fragment>
                                <h3>Headers</h3>
                                <div className="margin-b-3">
                                    <Table className="bcs-table">
                                        <THead><TR><TH>Key</TH><TH>Value</TH><TH>Description</TH></TR></THead>
                                        <TBody>
                                            {headers.map((h, i) => (
                                                <TR key={i}>
                                                    <TD>{h.name}</TD>
                                                    <TD>{h.value}</TD>
                                                    <TD>{h.description}</TD>
                                                </TR>
                                            ))}
                                        </TBody>
                                    </Table>
                                </div>
                            </React.Fragment>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12">
                            <h1 className={"border-bottom"}>Try it out</h1>
                                Use the documentation explorer at the right to browse the available queries and types.
                                <br/>
                                For convenience this live GraphQL client already set's the authToken in the header.
                                <br/>
                            <br/>
                            <div style={{height: '80vh'}}>
                                <GraphiQL
                                    onEditQuery={(queryString) => {this.updateQuery(queryString)}}
                                    query={query || 
                                        `# Welcome to the instructor GraphQL API
#
# Type queries into this side of the screen, and you will see intelligent
# typeaheads aware of the instructor API type schema and live syntax and
# validation errors highlighted within the text.
#
# GraphQL queries typically start with a "{" character. Lines that starts
# with a # are ignored.
#
# An example GraphQL query might look like:
#   {
#     me {
#       enrollments {
#         enrollment {
#           id
#           active
#         }
#       }
#     }
#   }
#
# Keyboard shortcuts:
#  Prettify Query:  Shift-Ctrl-P (or press the prettify button above)
#     Merge Query:  Shift-Ctrl-M (or press the merge button above)
#       Run Query:  Ctrl-Enter (or press the play button above)
#   Auto Complete:  Ctrl-Space (or just start typing)
#
`
                                        }
                                    fetcher={(graphQLParams) => {
                                        return fetch('/api/instructor/graphql/v1/query', {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'authToken': authToken
                                            },
                                            body: JSON.stringify(graphQLParams),
                                        }).then(response => response.json());
                                    }}
                                >
                                    <GraphiQL.Logo>
                                        GraphQL Instructor API
                                    </GraphiQL.Logo>
                                </GraphiQL>
                            </div>
                        </div>
                    </div>
                </section>
            </DocumentTitle>
        );
    }
}

export default connect(mapStateToProps)(ApiDocsGraphQL);
