import { useState } from 'react';

export const useInput = (initialValue, inputOptions, callBack) => {
    const [state, setState] = useState(initialValue);
    const handleChange = ({ target: { value } }) => {
        if (callBack) callBack(value);
        setState(value);
    };
    const input = {
        ...inputOptions,
        onChange: handleChange,
        value: state,
    };
    return [input, state, setState];
};
