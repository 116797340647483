export const ytConfig = {
    YT_API_URL: 'https://www.googleapis.com/youtube/v3',
    YT_API_KEY: 'AIzaSyBBW4HqtOB8x56IsgTb_E60SM_3xZd-v4s',
    YT_ALL_PLAYLIST: 'PLgJ8UgkiorClwoHB8vzfOFp48xk6Hmw1X',
    YT_FSF_PLAYLIST: 'PLgJ8UgkiorCkpw8AYBaU68TF_TRTiO1KS',
    YT_DATAVIZ_PLAYLIST: 'PLgJ8UgkiorCkvxz_udetNV0sx_SMRdB36',
    YT_UIUX_PLAYLIST: 'PLgJ8UgkiorCms1z3IC85iAOrK9LIBMmBe',
    YT_CAREER_PLAYLIST: 'PLgJ8UgkiorCmz3Q869uZUVnPS_OM9w4sI',
    YT_SUPPORT_STUDENT: "PLgJ8UgkiorCn_gY0c8EsZw9na-Vmw-OdL",
    YT_SUPPORT_INSTRUCTOR: "PLgJ8UgkiorCnqZzuQsxI9kinmHGIFVZxm",
    YT_SUPPORT_CAREER: "PLgJ8UgkiorCnWoaIZALezbIuSk4Hhpn8y",
    TY_SUPPORT_ADMINSSM: "PLgJ8UgkiorCn0JMLgDg_BRZGwCpNuf--e"
}
