import React, {Component} from 'react';
import DocumentTitle from 'react-document-title';
import {connect} from 'react-redux';

import AskBCS from "../../components/AskBCS";
import Alert from '../../components/Alert';
import SingleCoursework from './singleCoursework';
import {roleTypes} from '../../constants/dataTypes'

const mapStateToProps = (state) => {
    return {
        nextDueAssignments: state.coursework.nextDueAssignments,
        currentWeek: state.sessions.thisWeek,
        overdueAssignments: state.coursework.overdueAssignments
    }
}

class StudentCoursework extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseworkView: 'month',
        }
    }

    generateDueCourseworkMarkup = () => {
        let nextDueAssignmentList = this.props.nextDueAssignments.map((assignment) => {
            return <div className="col-xs-12" key={assignment.id}>
                <SingleCoursework
                    userRole={roleTypes.STUDENT}
                    coursework={assignment}
                    overdue={false}
                />
            </div>
        })
        return (
            <div className="row">
                {this.props.nextDueAssignments.length > 0 ? (
                    nextDueAssignmentList
                ) : null}
            </div>
        )
    }

    generateOverdueCourseworkMarkup = () => {
        return (
            <div className="col-xs-12">
                <Alert type="warn">
                    <p id="overdue" className="text-warn">Overdue Assignments</p>
                    <div className="row card-group">
                        {this.props.overdueAssignments.map((singleCoursework) => {
                            return <div className="col-xs-12" key={singleCoursework.id}>
                                        <SingleCoursework
                                            userRole={roleTypes.STUDENT}
                                            overdue={true}
                                            coursework={singleCoursework}
                                        />
                                    </div>
                        })}
                    </div>
                </Alert>
            </div>
        )
    }

    renderOverDueAlert = (overdueAssignments) => {
        // const {overdueAssignments } = this.props

        return (
            <div className="col-xs-12">
                <Alert type="warn">
                    <p>You have {overdueAssignments.length} overdue coursework item{ overdueAssignments.length > 1 ? 's' : null}. Please see below!</p>
                    <a href="#overdue">View Overdue Coursework Here</a>{' '}
                    <AskBCS
                        onDeepLinkAvailable={({ deepLink, askBcsIcon, dataBcsProp }) => (
                            <span className="padding-l-2" {...dataBcsProp}>
                                <a href={deepLink} target="_blank" rel="noopener noreferrer" className="text-underline">
                                    <img alt="AskBCS Learning Assistant icon" src={askBcsIcon} className="ask-bcs-icon"/>Need help? Ask a Learning Assistant on Slack
                                </a>
                            </span>
                        )}/>
                </Alert>
            </div>
        )
    }

    render() {
        const {overdueAssignments, nextDueAssignments} = this.props

        const areOverdueAssignements = overdueAssignments && overdueAssignments.length > 0
        const areNextDueAssignments = nextDueAssignments && nextDueAssignments.length > 0

        const overDueAlert = areOverdueAssignements ? this.renderOverDueAlert(overdueAssignments) : null
        const nextAssignmentsMarkup = areNextDueAssignments ? this.generateDueCourseworkMarkup() : [];
        const overdueAssignmentsMarkup = areOverdueAssignements ? this.generateOverdueCourseworkMarkup() : [];

        return (
            <DocumentTitle title="Coursework | Bootcamp Spot">
                <section className="page" data-component="coursework">
                    <div className="row">
                        {this.props.overdueAssignments !== null ? overDueAlert : ''}
                        <div className="col-xs-12 col-md-8 col-gutter-lr">
                            <h1>Coursework</h1>
                        </div>
                        {nextAssignmentsMarkup}
                        {overdueAssignmentsMarkup}
                    </div>
                </section>
            </DocumentTitle>
        )
    }
}

export const STUDENTCOURSEWORK = StudentCoursework;
export default connect(mapStateToProps)(StudentCoursework)


