import React from 'react'
import PropTypes from 'prop-types';

import Icon from '../Icon'

export const FlagWithText = (props) => {
    return (
        <div className="plagiarism-flag">
            <Icon icon="flag-alt" solid={true} ariaHidden="false" options="margin-r-2"></Icon>
            {props.text ? props.text : ""}
        </div>
    )
}

FlagWithText.propTypes = {
   text: PropTypes.string.isRequired
}

FlagWithText.defaultProps = {
    text: ""
}
