export const SurveyName = {
    PreCourseSurvey: 'pre-course',
    MidCourseSurvey: 'mid-course',
    EndCourseSurvey: 'end-course',
    WeeklySurvey: 'weekly-feedback',
    DataCsCurriculumSurvey: "data-cs-curriculum",
	PtFsfCsCurriculumSurvey: "pt-fsf-cs-curriculum",
	UiUxCsCurriculumSurvey: "uiux-cs-curriculum",
	FtFsfCsCurriculumSurvey:  "ft-fsf-cs-curriculum",
    Agreement: "terms-agreement",
    EnterprisePreCourseSurvey: 'enterprise-pre-course',
    EnterpriseMidCourseSurvey: 'enterprise-mid-course',
    EnterpriseEndCourseSurvey: 'enterprise-end-course',
    CsPreferences: 'cs-preferences'
}
