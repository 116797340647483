import React, { Component } from "react";
import Loading from "../../components/Loading";
import { Link } from "react-router-dom";

import MarkdownRender from "../../components/MarkdownRenderer/markdownRenderer";
import GradeDetail from "../GradeDetail";

import { assignmentContextTypes } from "../../constants/dataTypes";
import { getFormattedDateString } from "../../helpers/dateTime";
import { isEmpty } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import CommentManager from "../../components/CommentManager";
import DisabledComments from "../../components/Message/DisabledComments";

function mapStateToProps(state, ownProps) {
    return {
        userId: state.user.data.userId,
        role: state.user.enrollmentInfo.courseRole.name,
        enrollmentId: state.user.enrollmentInfo.id,
        ...ownProps,
    };
}

class StudentCourseworkDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            instructionalCourseworkDetails: [],
            courseworkLoading: false,
            isExtendingDueDate: false,
            dueDateExtension: "0",
            dueDateExtensionSuccess: "",
            dueDateExtensionError: "",
        };
    }

    refreshAssignment = () => {
        this.props.refreshAssignment();
    };

    render() {
        let { selectedAssignment, userId } = this.props;

        if (!selectedAssignment && isEmpty(selectedAssignment)) {
            return <Loading />;
        }
        let dueOrSubmittedNotice = "";
        if (!isEmpty(selectedAssignment.assignment)) {
            const effectiveDueDate =
                selectedAssignment.assignment.effectiveDueDate;
            // career doesn't want due dates displayed anymore.
            const due =
                selectedAssignment.assignment.context.contextCode ===
                assignmentContextTypes.CAREER ? null : (
                    <p>
                        <strong>
                            {selectedAssignment.assignment.bufferDays > 0
                                ? "Extended Due Date: "
                                : "Due: "}
                        </strong>
                        {getFormattedDateString(effectiveDueDate)} Local
                    </p>
                );
            const submitted = selectedAssignment.assignment.submission ? (
                <p className="font-sm text-green">
                    Submitted:{" "}
                    <strong>
                        {moment(
                            selectedAssignment.assignment.submission.date
                        ).format("dddd, MMMM Do YYYY")}
                    </strong>
                </p>
            ) : null;
            dueOrSubmittedNotice = selectedAssignment.assignment.submission
                ? submitted
                : due;
        }
        // New addition here - since we don't allow editing/submission once the coursework is submitted & graded with a grade thats not I, disable the submit button.
        let enabledSubmitOrUpdateButton = (
            <Link
                to={`/coursework/${selectedAssignment.assignment.id}/submit`}
                className="btn btn-submit margin-r-0"
            >
                {`${
                    selectedAssignment.assignment.submission
                        ? "Update"
                        : "Submit"
                }`}{" "}
                Coursework
            </Link>
        );
        if (
            selectedAssignment.assignment.context.contextCode ===
            assignmentContextTypes.CAREER
        ) {
            // except for career, who wants resubmission whenever
            enabledSubmitOrUpdateButton = (
                <Link
                    to={`/coursework/${selectedAssignment.assignment.id}/submit`}
                    className="btn btn-submit margin-r-0"
                >
                    Submit Milestone
                </Link>
            );
        }
        const submitOrUpdateButton = enabledSubmitOrUpdateButton;
        return (
            <div className="session-detail-info">
                <div className="row">
                    <div className="col-xs-12 col-md-6 col-lg-8">
                        <h1>{selectedAssignment.assignment.title}</h1>
                        {selectedAssignment.assignment.context.contextCode ===
                        assignmentContextTypes.CAREER ? (
                            <p className="font-sm">
                                <em>
                                    This Milestone is <strong>Required</strong>{" "}
                                    if you are seeking career assistance.
                                </em>
                            </p>
                        ) : (
                            <p className="font-sm">
                                <em>
                                    This Assignment is{" "}
                                    {selectedAssignment.assignment.required ? (
                                        selectedAssignment.assignment
                                            .requiredForGraduation ? (
                                            <strong>
                                                Required for Graduation
                                            </strong>
                                        ) : (
                                            <strong>Required</strong>
                                        )
                                    ) : (
                                        <strong>Not Required</strong>
                                    )}
                                </em>
                            </p>
                        )}
                        {dueOrSubmittedNotice}
                        {selectedAssignment.assignment.assignmentHeader && (
                            <p>
                                {
                                    selectedAssignment.assignment
                                        .assignmentHeader.header
                                }
                            </p>
                        )}
                        {selectedAssignment.assignment.assignmentContent && (
                            <MarkdownRender
                                text={
                                    selectedAssignment.assignment
                                        .assignmentContent.content
                                }
                            />
                        )}
                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-4 first-xs last-md text-right">
                        <p>
                            <Link to="/coursework">View all Coursework</Link>
                        </p>
                        {selectedAssignment.assignment.submission ? (
                            <p>
                                Times Resubmitted:{" "}
                                {
                                    selectedAssignment.assignment
                                        .resubmissionCount
                                }
                            </p>
                        ) : null}
                        {submitOrUpdateButton}
                    </div>
                </div>
                <div className="row feedback-section">
                    {selectedAssignment.assignment.context.contextCode !==
                    assignmentContextTypes.CAREER ? (
                        <DisabledComments />
                    ) : null}
                    <GradeDetail
                        assignment={selectedAssignment.assignment}
                        submission={selectedAssignment.assignment.submission}
                        userId={userId}
                    />
                    {/* We want to render comments but not provide comment CRUD functionality */}
                    {selectedAssignment.assignment.submission ? (
                        <div className="col-xs-12 padding-t-0">
                            <CommentManager
                                career={
                                    selectedAssignment.assignment.context.id ===
                                    2
                                }
                                grade={
                                    selectedAssignment.assignment.submission
                                        .submissionGrade
                                }
                                isStudent={true}
                                submission={
                                    selectedAssignment.assignment.submission
                                }
                                refresh={this.refreshAssignment}
                                assignmentId={selectedAssignment.assignment.id}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(StudentCourseworkDetail);
