import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

// ExternalLink IS A REUSEABLE COMPONENT. Please make sure that you pass the right props in or unexpected behavior will occur. 

const ExternalLink = (props) => (
    <a href={props.to}
        target="_blank"
        rel="noopener noreferrer"
        aria-describedby="link goes to external site, and opens in a new window"
        title={ props.title ? props.title : null }
    >
        <Icon icon="external-link-alt"/>{' '}
        {props.text}
    </a>
)

ExternalLink.propTypes = {
    to: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string
}

ExternalLink.defaultProps = {
    
}

export default ExternalLink