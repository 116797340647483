import axios from 'axios';
import { userActionTypes } from '../constants/actionTypes';
import { pending, rejected, fulfilled } from '../helpers/asyncActionGenerator';
import { setUserLoggedIn, logoutUser } from '../helpers/auth';
import { getCourseEnrollments } from "./enrollmentsActions";

export const userActions = {
    login,
    logout,
    forgotPassword,
    resetPassword,
    updateUserProfile,
    validateNexusUser,
    validateUserNexusSession,
    generateMFACode,
    getUniversities,
    getCohorts,
    getEnrollments,
    getEnrollmentInfo,
    getInfo,
    resetUserLoginAndErrorState,
    cacheBustNexusAvatarUrl,
    resetCohorts
};

function login(email, password, mfaCode) {
    const {
        USER_LOGIN,
        USER_ACTIVE_FLAG,
        USER_GET_DATA,
        USER_GET_INFO,
        USER_GET_UNIVERSITIES,
        USER_MFA_ERROR
    } = userActionTypes;

    return async dispatch => {
        dispatch(pending(USER_LOGIN, email));
        dispatch(pending(USER_ACTIVE_FLAG, email));
        dispatch(rejected(USER_MFA_ERROR, false));

        try {
            let loginResult = await axios.post("/broker/login", {
                email: email,
                ...(mfaCode ? { mfaCode } : {}),
                password: password
            });
            
            if (loginResult.data.authToken) {
                let myInfoCall = await axios.get("/broker/myInfo?authToken=" + loginResult.data.authToken);

                dispatch(pending(USER_GET_UNIVERSITIES));
                let myUniversitiesCall = await axios.get("/broker/myUniversities?authToken=" + loginResult.data.authToken);
                dispatch(fulfilled(USER_GET_UNIVERSITIES, myUniversitiesCall.data));
                
                dispatch(fulfilled(USER_GET_INFO, myInfoCall.data));
                
                setUserLoggedIn(loginResult.data.authToken);
                dispatch(fulfilled(USER_LOGIN, loginResult.data));

                if (loginResult.data.active) {
                    dispatch(fulfilled(USER_ACTIVE_FLAG, loginResult.data.active));
                }
            }
        } catch (error) {
            if(error.response){
                const { data, headers } = error.response
                if ( headers['content-type'] &&  headers['content-type'] === "Application/json" ) {
                    if (data.error === "MUST_RESET_PASSWORD") {
                        const payload = { userAccount: { username: email } };
                        dispatch(fulfilled(USER_LOGIN, data));
                        dispatch(fulfilled(USER_GET_DATA, payload));
                        dispatch(fulfilled(USER_ACTIVE_FLAG, null));
                    }else if (data.error === "MUST_RESET_PASSWORD_TOKEN_EXPIRED") {
                        dispatch(fulfilled(USER_LOGIN, data));
                        dispatch(rejected(USER_ACTIVE_FLAG, null));
                    }else if (data.error === "INVALID_CREDENTIALS") {
                        dispatch(rejected(USER_LOGIN, 'Your username or password is incorrect, please try logging in again. You can always reset your password if needed.'));
                        dispatch(rejected(USER_ACTIVE_FLAG, null));
                    }else if (data.error === "ACCOUNT_LOCKED") {
                        dispatch(rejected(USER_LOGIN, 'Your account has been locked after too many failed logins attempts. Please reset your password to unlock your account.'));
                        dispatch(rejected(USER_ACTIVE_FLAG, null));
                    }else if (data.error === "MFA_CODE_REQUIRED") {
                        dispatch(rejected(USER_MFA_ERROR, true));
                        dispatch(rejected(USER_LOGIN, 'The MFA Code is required, please complete the field'));
                        dispatch(rejected(USER_ACTIVE_FLAG, null));
                    }else if (["MFA_CODE_INVALID", "MFA_CODE_ALREADY_USED"].includes(data.error)) {
                        dispatch(rejected(USER_MFA_ERROR, true));
                        dispatch(rejected(USER_LOGIN, 'The MFA code is invalid, please try again'));
                        dispatch(rejected(USER_ACTIVE_FLAG, null));
                    }
                }
            } else {
                dispatch(rejected(USER_LOGIN, 'An unexpected error occurred, please try again or contact support'));
                dispatch(rejected(USER_ACTIVE_FLAG, null));
            }
        }
    }
}

function generateMFACode(payload) {
    const { USER_GENERATE_MFA } = userActionTypes;
    const { email } = payload;
    return async dispatch => {
        try {
            dispatch(pending(USER_GENERATE_MFA, true));
            const { data } = await axios.post("/broker/mfaGenerateSecret", { email });
            dispatch(fulfilled(USER_GENERATE_MFA, data));
        } catch (error) {
            dispatch(rejected(USER_GENERATE_MFA, true));
        }
    };
}

function validateNexusUser(token) {
    const { USER_LOGIN, USER_ACTIVE_FLAG, USER_GET_DATA } = userActionTypes;
    const config = {
        authToken: token,
        'Access-Control-Allow-Origin': '*'
    }
    return async dispatch => {
        // have to use the jobtrack route in order to get authenticationInfo for USER_LOGIN action
        let validated;
        try {
            validated = await axios.post(`/broker/jobtrackValidateToken?authToken=${token}`, {}, config)
        } catch (error) {
            dispatch(rejected(USER_LOGIN, '' + error));
            dispatch(rejected(USER_ACTIVE_FLAG, null));
        }
        if (validated && validated.data && validated.data.success) {
            try {
                let meCall = await axios.get("/broker/me?authToken=" + token);
                dispatch(fulfilled(USER_GET_DATA, meCall.data));
                dispatch(getCourseEnrollments(meCall.data.enrollments));

                setUserLoggedIn(token);
                dispatch(fulfilled(USER_LOGIN, validated.data.authenticationInfo));
                if (validated.status === 200) {
                    dispatch(fulfilled(USER_ACTIVE_FLAG, validated.data.active));
                }
            } catch (error) {
                dispatch(rejected(USER_LOGIN, '' + error));
                dispatch(rejected(USER_ACTIVE_FLAG, null));
            }
        }
    }

}

async function validateUserNexusSession(token) {
    let validated;
    try {
        const res = await axios.get(`/broker/validateToken`)
        if(res && res.status && res.status === 200){
            validated = true
        } else {
            validated = false
        }
    } catch (error) {
        validated = false
    }

    return validated
}

function updateUserProfile(userProfile){
    const {USER_GET_DATA} = userActionTypes;
    userProfile.nexusAvatarUrl += `&cache=${new Date().valueOf()}`;
    return async dispatch => {
        dispatch(fulfilled(USER_GET_DATA, { userAccount: userProfile }));
    }
}

function cacheBustNexusAvatarUrl(){
    const {USER_CACHEBUST_AVATAR_URL} = userActionTypes
    const cacheBustString = `&cache=${new Date().valueOf()}`;
    return async dispatch => {
        dispatch(fulfilled(USER_CACHEBUST_AVATAR_URL, { cacheBustString: cacheBustString }));
    }
}

function getInfo(authToken){
    const {USER_GET_INFO} = userActionTypes;
    return async dispatch => {
        dispatch(pending(USER_GET_INFO));
        try {
            let res = await axios.get("/broker/myInfo?authToken=" + authToken);
            if (res.status === 200) {
                res.data.userInfo.nexusAvatarUrl += `&cache=${new Date().valueOf()}`;
                dispatch(fulfilled(USER_GET_INFO, res.data));
            } else {
                dispatch(rejected(USER_GET_INFO, res.data.errorCode));
            }
        } catch (err) {
            dispatch(rejected(USER_GET_INFO, err));
        }
    }
}

function getUniversities(authToken){
    const {USER_GET_UNIVERSITIES} = userActionTypes;
    return async dispatch => {
        dispatch(pending(USER_GET_UNIVERSITIES));
        try {
            let res = await axios.get("/broker/myUniversities?authToken=" + authToken);
            if (res.status === 200) {
                dispatch(fulfilled(USER_GET_UNIVERSITIES, res.data));
            } else {
                dispatch(rejected(USER_GET_UNIVERSITIES, res.data.errorCode));
            }
        } catch (err) {
            dispatch(rejected(USER_GET_UNIVERSITIES, err));
        }
    }
}

function getCohorts(universityId, authToken){
    const {USER_GET_COHORTS} = userActionTypes;
    return async dispatch => {
        dispatch(pending(USER_GET_COHORTS));
        try {
            let payload = {
                universityId: universityId
            };
            let res = await axios.post("/broker/myUniversityCohorts", payload, { headers: {'Content-Type': 'application/json', 'authToken': authToken}});
            if (res.status === 200) {
                dispatch(fulfilled(USER_GET_COHORTS, res.data));
            } else {
                dispatch(rejected(USER_GET_COHORTS, res.data.errorCode));
            }
        } catch (err) {
            dispatch(rejected(USER_GET_COHORTS, err));
        }
    }
}

function resetCohorts() {
  return { type: userActionTypes.USER_RESET_COHORTS }
}

function getEnrollments(cohortId, authToken){
    const {USER_GET_ENROLLMENTS} = userActionTypes;
    return async dispatch => {
        dispatch(pending(USER_GET_ENROLLMENTS));
        try {
            let payload = {
                cohortId: cohortId
            };
            let res = await axios.post("/broker/myCohortEnrollments", payload, { headers: {'Content-Type': 'application/json', 'authToken': authToken}});
            if (res.status === 200) {
                dispatch(fulfilled(USER_GET_ENROLLMENTS, res.data));
            } else {
                dispatch(rejected(USER_GET_ENROLLMENTS, res.data.errorCode));
            }
        } catch (err) {
            dispatch(rejected(USER_GET_ENROLLMENTS, err));
        }
    }
}

function getEnrollmentInfo(enrollmentId, authToken){
    const {USER_GET_ENROLLMENT_INFO} = userActionTypes;
    return async dispatch => {
        dispatch(pending(USER_GET_ENROLLMENT_INFO));
        try {
            let payload = {
                enrollmentId: enrollmentId
            };
            let res = await axios.post("/broker/enrollmentInfo", payload, { headers: {'Content-Type': 'application/json', 'authToken': authToken}});
            if (res.status === 200) {
                dispatch(fulfilled(USER_GET_ENROLLMENT_INFO, res.data));
            } else {
                dispatch(rejected(USER_GET_ENROLLMENT_INFO, res.data.errorCode));
            }
        } catch (err) {
            dispatch(rejected(USER_GET_ENROLLMENT_INFO, err));
        }
    }
}

function logout() {
    return () => {
        logoutUser();
    }
}

function forgotPassword(email, resetUrl) {
    const { USER_FORGOT_PASSWORD_REQUEST } = userActionTypes;
    return async function (dispatch) {
        dispatch(pending(USER_FORGOT_PASSWORD_REQUEST));
        try {
            let payload = {
                username: email,
                resetUrl: resetUrl
            };
            let res = await axios.post("/broker/requestPasswordReset", payload);
            if (res.data.success) {
                dispatch(fulfilled(USER_FORGOT_PASSWORD_REQUEST, res.data));
            } else {
                dispatch(rejected(USER_FORGOT_PASSWORD_REQUEST, res.data.errorCode));
            }
        } catch (err) {
            dispatch(rejected(USER_FORGOT_PASSWORD_REQUEST, err));
        }
    }
}

function resetUserLoginAndErrorState(){
    return async dispatch => {
        return dispatch({type: userActionTypes.USER_CLEAR_ERROR})
    }
}

function resetPassword(payload) {
    const { USER_RESET_PASSWORD } = userActionTypes;
    return async dispatch => {
        dispatch(pending(USER_RESET_PASSWORD));
        try {
            const res = await axios.post("/broker/resetPassword", payload);
            if (res.data.success) {
                dispatch(fulfilled(USER_RESET_PASSWORD, res.data));
            } else {
                dispatch(rejected(USER_RESET_PASSWORD, res.data.errorCode));
            }
        } catch (err) {
            dispatch(rejected(USER_RESET_PASSWORD, err));
        }
    }
}