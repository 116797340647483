import React from 'react'
import { Prompt } from 'react-router-dom'
import PropTypes from 'prop-types';

const defaultMessage = 'Are you sure you want to navigate to another page with unsaved changes?'

const NavigationPrompt =  (props) => {
    const { valueExists, message = defaultMessage } = props
    return (
            <Prompt
              when={valueExists}
              message={message}
            />
    )
}

NavigationPrompt.propTypes = {
  message: PropTypes.string,
  valueExists: PropTypes.bool
}

export default NavigationPrompt


// EXAMPLE USE
// const formInProgress = urlList.length > 0 || singleUrl.length > 0 // if we have any user input, mark the form in progress so we can alert user if they try and navigate
/* <FormNavigationPrompt message='Are you sure you want to navigate away from this page while you have a submission in progress?' valueExists={formInProgress} /> */