import axios from 'axios'
import {jsonContentTypeHeader} from "../constants/axios";
import get from "lodash/get";

export async function handleZoomMeeting(virtualClassroomCardObject) {
    const userAccountId = get(virtualClassroomCardObject, 'props.userId', null);
    const sessionId = get(virtualClassroomCardObject, 'props.upcomingSession.session.id', null);
    const joinSessions = get(virtualClassroomCardObject, 'state.joinSession', []);
    const message = "An error occured while trying to start meeting. Try again later.";
    try {
        if (userAccountId && sessionId ) {
            let payload = {
                userAccountId: userAccountId,
                sessionId: sessionId,
                joinSessions: joinSessions
            };
            virtualClassroomCardObject.setState({zoomLoading: true});
            let result = await axios.post("/broker/prepareJoinMeetingUrl", payload, {headers: jsonContentTypeHeader});
            virtualClassroomCardObject.setState({zoomMeetingEnabled: true, errorMessage: null, zoomLoading: false});
            window.open(result.data.meetingUrl, '_blank');
        } else {
            virtualClassroomCardObject.setState({ errorMessage: message});
            console.error(`Invalid payload: userAccountId ${userAccountId}, sessionId: ${sessionId}, joinSessions: ${joinSessions}`);
        }
    }
    catch (err) {
        virtualClassroomCardObject.setState({ errorMessage: message, zoomLoading: false});
        console.error(err.response && err.response.data ? err.response.data : err);
    }
}

export async function handleEndZoomMeeting(virtualClassroomCardObject) {
    if ( window.confirm('Are you sure you want to end the ongoing meeting?') ) {
        const userAccountId = get(virtualClassroomCardObject, 'props.userId', null);
        const sessionId = get(virtualClassroomCardObject, 'props.upcomingSession.session.id', null);
        const message = "An error occured while trying to end the meeting. Try again later.";
        try {
            if (userAccountId && sessionId) {
                let payload = {
                    userAccountId: userAccountId,
                    sessionId: sessionId
                };
                virtualClassroomCardObject.setState({zoomLoading: true});
                await axios.post("/broker/endMeeting", payload, {headers: jsonContentTypeHeader});
                virtualClassroomCardObject.setState({zoomMeetingEnabled: false, errorMessage: null, warnMessage: null,  zoomLoading: false});
            } else {
                virtualClassroomCardObject.setState({ errorMessage: message});
                console.error(`Invalid payload: userAccountId ${userAccountId}, sessionId: ${sessionId}`);
            }
        }
        catch (err) {
            virtualClassroomCardObject.setState({ errorMessage: message, zoomLoading: false});
            console.error(err.response && err.response.data ? err.response.data : err);
        }
    }
}

export async function handleRefresh(virtualClassroomCardObject) {
    const sessionId = get(virtualClassroomCardObject, 'props.upcomingSession.session.id', null);
    const message = "There was a problem checking for the zoom classroom status. We are trying to re-connect.";
    try {
        if (sessionId !== null) {
            let payload = { sessionId: sessionId};
            const result = await axios.post("/broker/hasZoomMeetingForSession", payload, {headers: jsonContentTypeHeader});
            virtualClassroomCardObject.setState({zoomMeetingEnabled: result.data, warnMessage: null, errorMessage: null});
        } else {
            virtualClassroomCardObject.setState({ errorMessage: message});
            console.error(`Invalid payload: sessionId: ${sessionId}`);
        }
        virtualClassroomCardObject.scheduleRefresh();
    } catch (err) {
        virtualClassroomCardObject.scheduleRefresh();
        virtualClassroomCardObject.setState({ warnMessage: message});
        console.error(err.response && err.response.data ? err.response.data : err);
    }
}
