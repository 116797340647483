import {Component} from "react";
import DocumentTitle from "react-document-title";
import {connect} from 'react-redux';
import React from "react";
import {getRawPayload} from "../../actions/openDocsActions";
import OpenDocsApiResponse from "../../components/OpenDocsApiResponse";
import OpenDocsHeader from "../../components/OpenDocsHeader";
import Input from "../../components/Input";


const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps,
        openDocs: state.openDocs,
        authToken: state.user.data.authToken,
    }
};

class ApiDocsGrades extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tryItUrl: ''
        };
    }

    componentWillReceiveProps(newProps){
        if(typeof newProps.openDocs !== "undefined"){
            const newOpenDocs = newProps.openDocs;
            if (newOpenDocs && newOpenDocs[this.state.tryItUrl]) {
                this.setState({
                    payloadRsp: newOpenDocs[this.state.tryItUrl].payload,
                    headersRsp: newOpenDocs[this.state.tryItUrl].headers,
                    status: newOpenDocs[this.state.tryItUrl].status,
                });
            }
        }
    }

    handleChange(event) {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        const obj = {};
        obj[fieldName] = fieldValue;
        this.setState(obj)
    }

    handleOnClick() {
        const {dispatch, authToken} = this.props;
        const ep = `grades`;

        this.setState({
            tryItUrl: ep
        });

        dispatch(getRawPayload(ep, {courseId: parseInt(this.state.courseId, 10)}, authToken));
    }

    render() {
        const {payloadRsp, headersRsp, status} = this.state;

        return (
            <DocumentTitle title="API Access - Grades | Bootcamp Spot">
                <section>
                    <div className="row">
                        <div className="col-xs-12">
                            <OpenDocsHeader
                                endpoint={"/api/instructor/v1/grades"}
                                description={"Lists homework submission grades of all students in a course, for all assignments."}
                                type={"POST"}
                                headers={[{name: "Content-Type", value: "application/json", description: ""},
                                    {name: "authToken", value: "<valid token>", description: "The token generated via /login"}]}
                                exampleBody={{courseId: 1}}
                                exampleResponses={[
                                    {
                                        code: 200,
                                        body:[
                                            {
                                                assignmentTitle: "0: Web Development Prework",
                                                studentName: "Alex Ichikawa",
                                                submitted: false,
                                                grade: null
                                            }, {
                                                assignmentTitle: "0: Web Development Prework",
                                                studentName: "Alexander Netter",
                                                submitted: false,
                                                grade: null
                                            }, {
                                                assignmentTitle: "0: Web Development Prework",
                                                studentName: "Alphonse Le",
                                                submitted: false,
                                                grade: null
                                            }],
                                        rows: 10,
                                        description: "Successful request with data."
                                    },
                                    {
                                        code: 401,
                                        body: null,
                                        rows: 1,
                                        description: "A response with a 401 Unauthorized status means that the logged in user does not have access to the specified course."
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <h1 className={"border-bottom"}>Try it out</h1>

                            <Input
                                label="courseId"
                                type="number"
                                id="courseId"
                                placeholder="<courseId>"
                                helper="Fetch information for this course Id"
                                aria-required={true}
                                onChange={this.handleChange.bind(this)}
                            />
                            <button onClick={this.handleOnClick.bind(this)}>Submit</button>
                        </div>
                    </div>

                    <OpenDocsApiResponse
                        headers={headersRsp}
                        payload={payloadRsp}
                        status={status}
                    />
                </section>
            </DocumentTitle>
        )
    }
};

export default connect(mapStateToProps)(ApiDocsGrades);
