import React, {Component} from 'react'
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import rehypeRaw from 'rehype-raw';

class renderMarkdown extends Component {
    constructor(props){
        super(props);
        this.state = {
            markdownFailure: false
        }
    }
    componentDidCatch(){
        this.setState({
            markdownFailure: true
        })
    }

    render(){
        const { markdownFailure } = this.state;
        if(markdownFailure){
            return(
                <div className="bcs-markdown">
                    <p>We're Sorry, there was an error in the markdown provided for this comment(EC1)</p>
                </div>
            )
        }

        let {text, isPcCase} = this.props;
        let escapeHtml = !isPcCase; // we want to escape the HTML on everything except PC cases, since they are HTML generated by Salesforce.
        if(!text){
            return (
                <div className="bcs-markdown">
                    <p>We're Sorry, there was an error in the markdown provided for this comment(EC2)</p>
                </div>
            )
        }

        return(
            <div className="bcs-markdown">
                <ReactMarkdown rehypePlugins={[rehypeRaw]} skipHtml={escapeHtml} children={text}/>
            </div>
        )

    }
}

renderMarkdown.propTypes = {
    text: PropTypes.string,
    isPcCase: PropTypes.bool.isRequired
};

renderMarkdown.defaultProps = {
    text: "",
    isPcCase: false
};

export default renderMarkdown;
