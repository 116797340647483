import {fulfilled, pending, rejected} from "../helpers/asyncActionGenerator";
import axios from "axios/index";
import {notificationsActionTypes} from "../constants/actionTypes";
import {getNotificationsUnreadCount} from "../helpers/notifications";
import {jsonContentTypeHeader} from "../constants/axios";

export function fetchNotifications(payload) {
    return async (dispatch) => {
        dispatch(pending(notificationsActionTypes.FETCH_NOTIFICATIONS));
        try {
            let axiosResult = await axios.post("/broker/notifications", payload, {headers: jsonContentTypeHeader});

            const notificationsUnreadCount = getNotificationsUnreadCount(axiosResult.data.notifications);

            let data = {
                notificationList: axiosResult.data.notifications,
                notificationsUnread: notificationsUnreadCount
            }
            return dispatch(fulfilled(notificationsActionTypes.FETCH_NOTIFICATIONS, data));
        } catch (error) {
            return dispatch(rejected(notificationsActionTypes.FETCH_NOTIFICATIONS, error));
        }
    }
}

export function updateNotifications(notificationList, latest) {
    return async (dispatch) => {
        dispatch(pending(notificationsActionTypes.NOTIFICATIONS_UPDATE));
        try {
            const notificationsUnreadCount = getNotificationsUnreadCount(notificationList);

            let data = {
                notificationList: notificationList,
                notificationsUnread: notificationsUnreadCount,
                latest: latest
            }
            return dispatch(fulfilled(notificationsActionTypes.NOTIFICATIONS_UPDATE, data));
        } catch (error) {
            return dispatch(rejected(notificationsActionTypes.NOTIFICATIONS_UPDATE, error));
        }
    }
}

export function notificationsChangeFilter(notificationsFilter, notificationFilteredList) {
    return {
        type: notificationsActionTypes.NOTIFICATIONS_CHANGE_FILTER,
        payload: {
            notificationsFilter,
            notificationFilteredList
        }
    }
}

export async function markAllAsRead(payload) {
    await axios.post("/broker/handleNotificationForEnrollment", payload, {headers: jsonContentTypeHeader});
}

export function handleNotification(payload) {
    axios.post("/broker/handleNotification", payload, {headers: {'Content-Type': 'application/json',}});
}

export function fetchNotificationsDropdown(payload) {
    return async (dispatch) => {
        let axiosResult = await axios.post("/broker/latestNotifications", payload, {headers: jsonContentTypeHeader});
        let data = axiosResult.data.notificationsResume;
        return dispatch(fulfilled(notificationsActionTypes.FETCH_NOTIFICATIONS_DROPDOWN, data));
    }
}
