import moment from 'moment';
import {sessionCheckInTypes} from '../constants/checkIn';
import Cookies from "js-cookie";


export function isProduction() {
    const url = window.location.hostname;
    return (url === "bootcampspot.com" || url === "www.bootcampspot.com")
}

export function isStaging() {
    const url = window.location.hostname;
    return (url === "bcs-next-b.staging.bootcampspot.com" || url === "www.bcs-next-b.staging.bootcampspot.com" || url.includes("netlify"))
}

export const isIOS = () => {
    return (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) /*from https://stackoverflow.com*/
}

export const isSafari = () => {
    return (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) /*from https://stackoverflow.com*/
}

export const isCheckInAvailable = (startTime, endTime) => {
    const sessionEndTime = moment(endTime);
    const checKInStart = moment(startTime).subtract(45, 'minutes');
    const windowEnded  = moment(startTime).add(45, 'minutes');
    const now = moment();

    if(now.isBetween(checKInStart, moment.min([windowEnded, sessionEndTime]))) {
        return sessionCheckInTypes.CHECK_IN_TIME;
    } else if(now.isAfter(windowEnded) || now.isAfter(sessionEndTime)) {
        return sessionCheckInTypes.CHECK_IN_ENDED;
    } else {
        return sessionCheckInTypes.CHECK_IN_TOO_EARLY;
    }
}

export const getCalendarMonth = (yearMonth) => {
    let calendarDate = yearMonth ? moment(yearMonth) : moment();
    return {
        month: calendarDate.get('month'),
        daysInMonth: calendarDate.daysInMonth(),
        startDay: calendarDate.date(1).day(),
        weeksInMonth: getWeeksInMonth(yearMonth)
    }
}

export const getWeeksInMonth = (month) => {
    let currentMonth = month ? moment(month) : moment();
    let startOfMonth = currentMonth.startOf('month').week();
    let endOfMonth = currentMonth.endOf('month').week();

    //added to account for december wrapping to new year
    let weeksInYear = moment().weeksInYear();
    if( startOfMonth > endOfMonth) {
        endOfMonth = weeksInYear + endOfMonth;
    }
    return (endOfMonth - startOfMonth);
}

export function getPaymentPortalLink() {
    if (window.location.hostname === "localhost") {
        return "http://localhost:3001" // can be changed to temporary local development url
    } else if (window.location.hostname === "bcs.local.com") {
        return 'http://pay.local.com:3001'
    } else if (isProduction()) {
        return 'https://pay.bootcampspot.com'
    } else {
        return 'https://pay-test.bootcampspot.com'
    }
}

export function getPaymentPortalDomain() {
    if (window.location.hostname === "localhost") {
        return "localhost"
    } else if (window.location.hostname === "bcs.local.com") {
        return 'local.com'
    } else {
        return 'bootcampspot.com'
    }
}

export function setPaymentPortalCookie(authToken) {
    const paymentPortalDomain = getPaymentPortalDomain()
    Cookies.set('pp_nexus_token', authToken, {domain: paymentPortalDomain, path: '/'})
}

