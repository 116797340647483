export const placeholderProfilePic = 'https://s3.amazonaws.com/bcs-media-dev/ui/profile-placeholder.png';

export const getProfilePicAlt = user => {
    let imageAlt = '';

    if (user.studentName) {
        imageAlt = `${user.studentName} Profile`
    } else if (user.firstName && user.lastName) {
        imageAlt = `${user.firstName} ${user.lastName} Profile`
    } else if (user.FirstName && user.LastName) {
        imageAlt = `${user.FirstName} ${user.LastName} Profile`
    } else {
        imageAlt = 'User Profile'
    }
    return imageAlt;
};

export const getProfilePicSrc = (user) => {
    let imageSrc = placeholderProfilePic;

    if (!!user.nexusAvatarUrl) {
        imageSrc = user.nexusAvatarUrl;
    }
    else if (!!user.avatarUrl) {
        imageSrc = user.avatarUrl;
    }
    else if (!!user.githubUserName) {
        imageSrc = `https://github.com/${user.githubUserName}.png`;
    }

    return imageSrc;
};
