export const priorities = ["High", "Medium", "Low"];

export const roles = [
  'Student',
  'TA',
  'Instructor',
  'Career Director',
  'Student Success Manager',
];

export const categories = [{
    category: "Azure",
    subCategory: "azure",
},{
    category: "My Schedule",
    subCategory: 'schedule'
}, {
    category: "BCS Tech Support",
    subCategory: "techSupport"
}, {
    category: "Tuition & Financing",
    subCategory: "tuitionAndFinancing"
} , {
    category: "Facilities and Parking",
    subCategory: "facilitiesAndParking"
    }, {
        category: "Graduation Eligibility",
    }, {
        category: "Course Status",
        subCategory: "courseStatus"
    }, {
    category: "Tutor Request",
    subCategory: "tutorRequest"
}, {
    category: "Attendance",
    subCategory: "attendance"
}, {
    category: "Homework",
    subCategory: "homework"
}, {
    category: "Human Resources",
    subCategory: "humanResources"
}, {
    category: "Gitlab / Github Tech Support",
    subCategory: "gitTechSupport"
}, {
    category: "Career Services",
    subCategory: "career"
}, {
    category: "Calendar",
    subCategory: "calendar"
}, {
    category: "Onboarding",
    subCategory: "onboarding"
}, 
{
    category: "Login Issues",
    subCategory: "login"
}
];

export const categoryInfo = {
    azure: [{
        subCategory: "Azure Admin Access",
        exampleQuestion: "",
        priority: "Low",
    },{
        subCategory: "Azure Account Access",
        exampleQuestion: "",
        priority: "Low",
        }],
    onboarding: [{
        subCategory: "Zoom",
        exampleQuestion: "",
        priority: "Low",
    },{
        subCategory: "Google - Did not receive an email address",
        exampleQuestion: "",
        priority: "Low",
    },{
        subCategory: "Google Require access to Google Drive resources",
        exampleQuestion: "",
        priority: "Low",
    }],
    schedule : [{
        subCategory: "Find my syllabus",
        exampleQuestion: "Where can I find a syllabus?",
        priority: "High"
    }, {
        subCategory: "Find my class schedule and holidays",
        exampleQuestion: "Which days do we not have class due to a holiday?",
        priority: "Low"
    }],
    techSupport: [{
        subCategory: "Incorrect assignment dates",
        exampleQuestion: "",
        priority: "Low"
    }, {
        subCategory: "Recordings unavailable",
        exampleQuestion: "",
        priority: "Low"
    }, {
        subCategory: "Change email address",
        exampleQuestion: "",
        priority: "Low"
    }, {
        subCategory: "BCS Blank Missing Information",
        exampleQuestion: "",
        priority: "Low"
    }, {
        subCategory: "BCS not set up/Cannot access",
        exampleQuestion: "",
        priority: "Low"
    }, {
        subCategory: "Unable to access AskBCS",
        exampleQuestion: "",
        priority: "Low"
    }],
    tuitionAndFinancing: [{
        subCategory: "Add or edit a tuition payment method",
        exampleQuestion: "How do I change the credit card on my payment plan?",
        priority: "High"
    },{
        subCategory: "Tax or 1098-T information",
        exampleQuestion: "I need a 1098-T form",
        priority: "Low"
    }],
    facilitiesAndParking: [{
        subCategory: "Facilities and parking information",
        exampleQuestion: "Where can I park near the classroom?",
            priority: "Low"
    }],
    courseStatus: [
        {
        subCategory: "My career services support",
        exampleQuestion: "How should I prepare for an interview?",
        priority: "High"
    }, {
        subCategory: "My career services eligibility",
        exampleQuestion: "Am I eligible for Career services?",
        priority: "High"
    }, {
        subCategory: "My career services feedback",
        exampleQuestion: "Why did I not get feedback on my career services assignment?",
        priority: "Low"
    }, {
        subCategory: "My career services progress",
        exampleQuestion: "What is my attendance and homework rates for career services?",
        priority: "Low"
    }
    ],
    tutorRequest: [{
        subCategory: "Request a Tutor",
        exampleQuestion: "",
        priority: "High"
    }, {
        subCategory: "Tutor Change (Reassignment) Request",
        exampleQuestion: "",
        priority: "High"
    }],
    attendance: [{
        subCategory: "Check in not saved/incorrectly saved",
        exampleQuestion: "",
        priority: "Low"
    }, {
        subCategory: "Incorrect attendance Log",
        exampleQuestion: "",
        priority: "Low"
    }, {
        subCategory: "Absence request",
        exampleQuestion: "",
        priority: "Low"
    }, {
        subCategory: "Adjust/edit",
        exampleQuestion: "",
        priority: "Low"
        },
    {
        subCategory: "Attendance Access",
        exampleQuestion: "",
        priority: "Low",
        },
    {
        subCategory: "View my attendance records",
        exampleQuestion: "",
        priority: "Low",
        },
    ],
    homework: [{
        subCategory: "Incorrect submission times",
        exampleQuestion: "",
        priority: "Low"
    }, {
        subCategory: "Wrong due dates",
        exampleQuestion: "",
        priority: "Low"
    }, {
        subCategory: "Was it successfully submitted",
        exampleQuestion: "",
        priority: "Low"
        },{
            subCategory: "Homework Access",
            exampleQuestion: "",
            priority: "Low",
        },
        {
            subCategory: "View my homework records",
            exampleQuestion: "",
            priority: "Low"
        },
        {
            subCategory: "Other",
            exampleQuestion: "",
            priority: "Low",
        },  
    ],
    humanResources: [{
          subCategory: "Require help with I9",
          exampleQuestion: "",
          priority: "Low"
        }, {
          subCategory: "Require help with HRIS access",
          exampleQuestion: "",
          priority: "Low"
        }, {
          subCategory: "Payroll Issue",
          exampleQuestion: "",
          priority: "Low"
        }, {
          subCategory: "Bamboo (IRH)",
          exampleQuestion: "",
          priority: "Low",
        }, {
          subCategory: "Other",
          exampleQuestion: "",
          priority: "Low"
        },
    ],
    gitTechSupport: [{
            subCategory: "Incorrect username (Gitlab Access)",
        exampleQuestion: "",
        priority: "Low"
    }, {
        subCategory: "Update/change username",
        exampleQuestion: "",
        priority: "Low"
    }],
    career: [{
        subCategory: "Milestones",
        exampleQuestion: "",
        priority: "Low"
    }, {
        subCategory: "Events",
        exampleQuestion: "",
            priority: "Low",
        }, {
            subCategory: "View my career services support",
            exampleQuestion: "",
            priority: "Low",
        }, {
            subCategory: "View my career services eligibility",
        exampleQuestion: "",
            priority: "Low",
        }, {
            subCategory: "View my career services feedback",
            exampleQuestion: "",
            priority: "Low",
        },
        {
            subCategory: "Career services progress",
        exampleQuestion: "",
            priority: "Low",
        }, {
            subCategory: "Other",
            exampleQuestion: "",
            priority: "Low",
        
    }],
    calendar: [{
        subCategory: "Assignment dates",
        exampleQuestion: "",
        priority: "Low"
    }, {
        subCategory: "Class dates",
        exampleQuestion: "",
        priority: "Low"
    }, {
            subCategory: "Wrong due dates",
            exampleQuestion: "",
            priority: "Low",
    }],
    login: [{
        subCategory: "Blank screen when I log in",
        exampleQuestion: "",
        priority: "Low"
    }, {
        subCategory: "Cannot log in",
        exampleQuestion: "",
        priority: "Low"
    }],
    ask: [
        {
          subCategory: "All AskBCS Learning Assistant issues",
          exampleQuestion: "",
            priority: "Low"
        }
    ]
}
