import {Component} from "react";
import DocumentTitle from "react-document-title";
import {connect} from 'react-redux';
import React from "react";
import {Link} from 'react-router-dom';

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps,
        openDocs: state.openDocs,
        apiKey: state.user.data.authToken,
    }
};

class ApiDocs extends Component {
    render() {
        return (
            <DocumentTitle title="API Access | Bootcamp Spot">
                <section>
                    <div className="row">
                        <div className="col-xs-12">
                            <h1>API Access</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <p>By using the Instructor API - you agree to our <Link to="/instructor-api-docs/policy">BootcampSpot API Policy</Link></p>

                            <p>With your login credentials you can potentially access all the following endpoints with base URL <div>https://bootcampspot.com/api/instructor/v1</div></p>

                            <ul className="bulleted">
                                <li><Link to="/instructor-api-docs/login">Login (/login)</Link> Allows to get an authorization token with a set of credentials.</li>
                                <li><Link to="/instructor-api-docs/me">Me (/me)</Link> Provides detailed profile and authorization information for the caller.</li>
                                <li><Link to="/instructor-api-docs/attendance">Attendance (/attendance)</Link> Lists attendance status of all students in a course, for all sessions.</li>
                                <li><Link to="/instructor-api-docs/grades">Grades (/grades)</Link> Lists homework submission grades of all students in a course, for all assignments.</li>
                                <li><Link to="/instructor-api-docs/sessions">Sessions (/sessions)</Link> Lists all sessions.</li>
                                <li><Link to="/instructor-api-docs/sessionDetail">Session Detail (/sessionDetail)</Link> Lists details for the specified session.</li>
                                <li><Link to="/instructor-api-docs/assignments">Assignments (/assignments)</Link> Lists all assignments.</li>
                                <li><Link to="/instructor-api-docs/assignmentDetail">Assignment Detail (/assignmentDetail)</Link> Lists details on a specified assignment.</li>
                                <li><Link to="/instructor-api-docs/weeklyFeedback">Weekly Feedback (/weeklyFeedback)</Link> Lists details on weekly feedback for a course.</li>
                            </ul>

                            <p>
                                There's also a <b>GraphQL</b> interface to access the same or more data.<br/>
                                You can read more about it <Link to="/instructor-api-docs/graphql">here</Link>.
                            </p>
                        </div>
                    </div>
                </section>
            </DocumentTitle>
        )
    }
}

export default connect(mapStateToProps)(ApiDocs);
