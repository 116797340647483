import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Icon from '../../components/Icon';
import {
    deleteAnnouncement, updateAnnouncements, markAnnouncementRead, fetchAnnouncements
} from "../../actions/announcementsActions";
import ReactGA from 'react-ga';
import MarkdownRenderer from '../MarkdownRenderer/markdownRenderer';
import Loading from '../Loading';
import { getFormattedDateString } from '../../helpers/dateTime';


const mapStateToProps = (state) => {
    return {
        ...state,
    }
};

class AnnouncementCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notificationLoading: false
        }
    }

    handleAnnouncementClick = async (id, read) => {
        try {

            this.setState({notificationLoading: true})

            ReactGA.event({
                category: 'Announcement',
                action: 'Click Detail',
            });

            if (!read) {
                const { enrollmentInfo } = this.props.user;
                const enrollmentInfoId = enrollmentInfo.id;

                let payload = {courseAnnouncementId: id, enrollmentId: enrollmentInfoId};


                await markAnnouncementRead(payload)

                let handledAnnouncementList = this.props.announcements.announcementList;
                for (let i = 0; i < handledAnnouncementList.length; i++) {
                    if (handledAnnouncementList[i].id === id)
                        handledAnnouncementList[i].handled = true;
                }

                this.props.dispatch(updateAnnouncements(handledAnnouncementList));
            }
        } catch (err) {
            console.error(err)
        }
    }

    deleteAnnouncement = async (id) => {
        try {
            if (window.confirm('Are you sure that you want to delete this announcement?')) {
                const enrollmentInfo = this.props.user.enrollmentInfo;
                const enrollmentInfoId = enrollmentInfo.id;

                let deletePayload = {id: id};
                await deleteAnnouncement(deletePayload);

                let payload = {enrollmentId: enrollmentInfoId};
                this.props.dispatch(fetchAnnouncements(payload));
            } else {
                return false;
            }
        } catch (err) {
            console.error(err)
        }
    }

    editAnnouncement = (id) => {
        this.props.history.push('/announcement/' + id);
    }

    capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    render() {
        const announcement = this.props.item;
        let date = new Date(announcement.activationDate);
        let context = this.capitalizeFirstLetter(announcement.contextCode)

        const enrollmentId = this.props.user.enrollmentInfo.id;
        const firstName = announcement.creatorUserAccount.firstName;
        const lastName = announcement.creatorUserAccount.lastName;
        const {notificationLoading} = this.state;

        return (
            <div className="row margin-b-2">
                <div className="col-xs-12 col-no-gutter">
                    <div className={`card${announcement.read ? ' notification-read' : ''}`}>
                        <div className="card-content">
                            <h3>
                                {announcement.title}
                            </h3>
                            <p>{context} Announcement</p>
                            <MarkdownRenderer text={announcement.body}/>

                            <ul className="margin-b-3">
                                <li>
                                    <strong>Author:</strong>
                                    <span> {firstName} {lastName}</span>
                                </li>
                                <li>
                                    <strong>Created Date:</strong>
                                    <span> {getFormattedDateString(date)}</span>
                                </li>
                            </ul>

                            <div>
                                {announcement.staffEnrollmentId === enrollmentId && <button className="btn-small btn-edit"
                                                                           onClick={() => this.editAnnouncement(announcement.id)}>Edit</button>}
                                {announcement.staffEnrollmentId === enrollmentId &&
                                <button className="btn-small btn-delete btn-tertiary margin-r-0"
                                        onClick={() => this.deleteAnnouncement(announcement.id)}>Delete</button>}
                            </div>
                        </div>
                        <div className="card-footer">
                            <ul>
                                <li>
                                    {notificationLoading ? <Loading/> :
                                        <Link onClick={() => this.handleAnnouncementClick(announcement.id, announcement.read)} to={`/announcement/${announcement.id}/show`}>
                                            <Icon icon="bookmark margin-r-2"/>
                                            View announcement
                                        </Link>
                                    }
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(AnnouncementCard));
