import axios from "axios/index";

export const fetchStudentProfile = async(payload) => {
    let axiosResult = await axios.post("/broker/studentProfile", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

export const fetchStudentProfiles = async(payload) => {
    let axiosResult = await axios.post("/broker/studentProfiles", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

export const updateStudentProfile = async(payload) => {
  let axiosResult = await axios.post("/broker/updateStudentProfileInfo", payload, {headers: {'Content-Type': 'application/json',}});
  return axiosResult;
}

export const fetchStudentAvatar = async(payload) => {
  let axiosResult = await axios.post(`/broker/studentAvatar`, payload, {headers: {'Content-Type': 'application/json',}});
  return axiosResult;
}
