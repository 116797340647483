import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
}

class CareerServicesCurriculumSuccess extends Component {
    comebackToWall = () => {
        this.props.history.replace('/')
    }

    render() {
        return (
            <div className="context-success">
                <p>Thank you for providing your responses.</p>
                <button className="btn-small" onClick={this.comebackToWall}>Continue</button>
            </div>
        )
    }
}

export const CAREERSERVICESCURRICULUMSUCCESS = CareerServicesCurriculumSuccess;
export default withRouter(connect(mapStateToProps)(CareerServicesCurriculumSuccess));