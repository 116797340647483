import React from 'react';
import moment from 'moment';
import Icon from '../../../components/Icon';
import {Table, TBody, THead, TR, TD, TH} from '../../../components/Table';
import {getAttendanceStatusText, getRemoteRequestStatusText} from "../../../helpers/common";

const tableHeadings = [
    'Student',
    'Class & Session',
    'Reason',
    'Approval'
]

const AdminTable = (props) => (
    <Table>
        <THead>
            <TR>
                <TH>{tableHeadings[0]}</TH>
                <TH>{tableHeadings[1]}</TH>
                <TH>{tableHeadings[2]}</TH>
                <TH>{tableHeadings[3]}</TH>
            </TR>
        </THead>
        <TBody>
            {props.filteredRemoteAttendanceRequests.map((item, index) => {
                let requestStatus = getRemoteRequestStatusText(item);
                return (
                    <TR key={index} data-component="absence-request-student-row" className="font-xs">
                        <TD heading={tableHeadings[0]}>
                            <strong>{item.student.firstName} {item.student.lastName}</strong>
                            <br/>
                            <em className="font-xs">Submitted:</em> <span className="font-xs">{moment(item.remoteAttendanceRequest.date).format('MMMM D, YYYY')}</span>
                            <br/>
                            <span className="font-xs">{item.university.name}</span>
                        </TD>
                        <TD heading={tableHeadings[1]}>
                            {item.course.name}<br/>
                            <strong>{item.session.name}</strong> <br/> {moment(item.session.startTime).format('MMMM D, YYYY')}
                        </TD>
                        <TD heading={tableHeadings[2]}>{getAttendanceStatusText(item)}</TD>
                        {requestStatus !== 'Pending' ? (
                            <TD heading={tableHeadings[3]}>
                                {(requestStatus === "Approved") && <strong className="text-green">Approved</strong> }
                                {(requestStatus === "Denied") && <strong className="text-red">Denied</strong> }
                            </TD>
                        ) : (
                            <TD heading={tableHeadings[3]}>
                                {props.working ? (
                                    <Icon icon="circle-o-notch" options="fa-spin" />
                                ) : (
                                    <React.Fragment>
                                        <button className="btn-save btn-secondary" onClick={(e)=>{props.handleApprove(e, item)}}>Approve</button>
                                        <button className="btn-delete btn-tertiary" onClick={(e)=>{props.handleDeny(e, item)}}>Deny</button>
                                    </React.Fragment>
                                )}
                            </TD>
                        )}
                    </TR>
                )
            })}
        </TBody>
    </Table>
)


export default AdminTable
