/* eslint-disable */
// FIXME: This file to be refactored for accessibility by Drew

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Icon from '../../components/Icon';
import {getNotificationClickUrl, parseNotificationMessage} from '../../constants/notifications';
import {getGradeName, gradeColorsMap} from "../../constants/grade";
import { notificationSpecs } from "../../constants/notifications";
import ReactGA from 'react-ga';
import { generateNotificationAndAccouncementClassnames } from '../../helpers/accessibility';
import {updateNotifications, handleNotification} from "../../actions/notificationsActions";

const mapStateToProps = (state) => {
    return {
        ...state
    }
};

class NotificationDropdownItem extends Component {

    handleNotificationClick = async (notification) => {
        try {
            ReactGA.event({
                category: 'Notifications',
                action: 'Click (Dropdown)',
            });
            const userId= this.props.user.data.userId;

            let payload = {notificationId: notification.id};
            await handleNotification(payload);

            let handledNotificationList = this.props.notifications.notificationList;
            for (let i = 0; i < handledNotificationList.length; i++) {
                if (handledNotificationList[i].id === notification.id)
                handledNotificationList[i].handled = true;
            }

            let handledLatest = this.props.notifications.notificationsDropdownList;
            for (let i = 0; i < handledLatest.length; i++) {
                if (handledLatest[i].id === notification.id)
                    handledLatest[i].handled = true;
            }

            this.props.dispatch(updateNotifications(handledNotificationList, handledLatest));

            const activeRole = this.props.user.enrollmentInfo.courseRole.courseRoleCode;
            const universityId = this.props.user.enrollmentInfo.course.cohort.program.university.id;
            let notificationClickUrl = getNotificationClickUrl(notification, activeRole, userId, universityId);

            if (notificationClickUrl.startsWith("http")) {
                window.location = notificationClickUrl;
                this.setState({notificationLoading: false})
            } else {
                this.props.history.push(notificationClickUrl)
            }
        } catch (err) {
            console.error(err)
        }
    }

    render() {
        const notification = this.props.item;
        const currentNotification = notificationSpecs(notification);

        let classNames = generateNotificationAndAccouncementClassnames(notification.handled, "notification");
        const message = parseNotificationMessage(notification);

        return (
            <li className={classNames.notification} aria-label={classNames.label}>
                <a tabIndex="0" onClick={(e) => this.handleNotificationClick(notification)}>
                    <p>
                        { notification.type === 'homeworkGraded' && notification.content.grade ? (
                            <strong className={gradeColorsMap.get(notification.content.grade) + ' text-extra-bold margin-r-2'}>{getGradeName(notification.content.grade)}</strong>
                        ) : currentNotification ? (
                            <Icon icon={currentNotification.titleIcon.type}
                                  options={`fa-fw text-${currentNotification.titleIcon.color}`}
                                  ariaHidden="false"
                                  ariaLabel={classNames.notification !== "" ? "task complete": null}
                                  role='img'/>
                        ) : (
                            <Icon icon={'exclamation'} options={`text-primary`} role='img'/>
                        )}

                        <span className="notification-title">
                            { currentNotification ? (<strong>{currentNotification.contentPre}</strong>) : '' }
                            {message}
                        </span>
                    </p>
                </a>
            </li>
        )
    }
}

export default withRouter(connect(mapStateToProps)(NotificationDropdownItem));
