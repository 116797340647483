import React from 'react';
import PropTypes from 'prop-types';
import TBody from './TBody';
import THead from './THead';
import TR from './TR';
import TD from './TD';
import TH from './TH';

const Table = (props) => {

    return (
        <table className={`bcs-table ${props.className}`}>
            {props.children}
        </table>
    )
}

Table.propTypes = {
    className: PropTypes.string
}

Table.defaultProps = {
    className: ''
}

export { Table, TBody, THead, TR, TD, TH }


// Example:

// import {Table, TBody, THead, TR, TD, TH} from '../../components/Table';

// <Table>
//     <THead>
//         <TR>
//             <TH col="4">col 1</TH>
//             <TH col="4">col 2</TH>
//             <TH col="4">col 3</TH>
//         </TR>
//     </THead>
//     <TBody>
//         <TR role="button">
//             <TD col="4" heading="col 1">lorem</TD>
//             <TD col="4" heading="col 2">ipsum</TD>
//             <TD col="4" heading="col 3">dolor</TD>
//         </TR>
//         <TR>
//             <TD col="4" heading="col 1">lorem</TD>
//             <TD col="4" heading="col 2">ipsum</TD>
//             <TD col="4" heading="col 3">dolor</TD>
//         </TR>
//     </TBody>
// </Table>





// Example With Headings Const:

// import {Table, TBody, THead, TR, TD, TH} from '../../components/Table';

// const tableHeadings = [
//     'col 1',
//     'col 2',
//     'col 3'
// ]

// <Table>
//     <THead>
//         <TR>
//             <TH col="4">{tableHeadings[0]}</TH>
//             <TH col="4">{tableHeadings[1]}</TH>
//             <TH col="4">{tableHeadings[2]}</TH>
//         </TR>
//     </THead>
//     <TBody>
//         <TR role="button">
//             <TD col="4" heading={tableHeadings[0]}>lorem</TD>
//             <TD col="4" heading={tableHeadings[1]}>ipsum</TD>
//             <TD col="4" heading={tableHeadings[2]}>dolor</TD>
//         </TR>
//         <TR>
//             <TD col="4" heading={tableHeadings[0]}>lorem</TD>
//             <TD col="4" heading={tableHeadings[1]}>ipsum</TD>
//             <TD col="4" heading={tableHeadings[2]}>dolor</TD>
//         </TR>
//     </TBody>
// </Table>