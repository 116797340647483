import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';

class Textarea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            charsLeft: this.props.characterLimit
        }
    }

    handleChange = (e) => {
        const input = e.target.value;
        const maxLength = this.props.characterLimit
        this.setState({
            charsLeft: maxLength - input.length
        });
    }

    renderTextarea = () => {
        const {
            id,
            placeholder,
            required,
            onBlur,
            disabled,
            loading,
            error,
            success,
            characterLimit
        } = this.props;

        const textareaProps = {
            id,
            placeholder,
            required,
            disabled,
            onBlur
        }

        textareaProps.name = id;

        return (
            <TextareaAutosize
                {...textareaProps}
                className={
                    (characterLimit > 0 ? 'margin-b-0' : '') + (
                            disabled ? ' input-disabled' :
                                loading ? ' input-loading' :
                                    error ? ' input-error' :
                                        success ? ' input-success' :
                                            ''
                    )
                }
                aria-describedby={id + "CharacterLimit"}
                aria-controls={id + "CharacterLimit"}
                maxLength={characterLimit ? characterLimit : null}
                aria-required={required ? "true" : "false"}
                onChange={this.handleChange}
            />
        )
    }

    renderLabelText = () => {
        const {label, required} = this.props;
        return `${label}${required ? ' *' : ''}`
    }

    render() {
        const {id, characterLimit} = this.props;
        return (
            <React.Fragment>
                <label htmlFor={id}>{this.renderLabelText()}</label>
                {this.renderTextarea()}
                {characterLimit &&
                    <p style={this.state.charsLeft < 5 ? {width: '100%', color: "red"} : {width: '100%'}}
                       id={id + "CharacterLimit"}
                       className="text-right label">
                            {this.state.charsLeft} characters left
                    </p>
                }
            </React.Fragment>
        )
    }
}

Textarea.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    onBlur: PropTypes.func,
    pattern: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    success: PropTypes.bool,
    characterLimit: PropTypes.number,
}

Textarea.defaultProps = {
    label: '',
    id: '',
    required: false,
    disabled: false,
    loading: false,
    error: false,
    success: false
}

export default Textarea


/*
Mock textarea handler callback function:

handleBlur = (event) => {
    this.setState({something: event.target.value});
}

Mock textarea configuration:

<Textarea
    label="Some Label"
    id="someId"
    characterLimit={40}
    required={true}
    onBlur={this.handleBlur}
/>
*/
