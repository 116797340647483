import BCS from './pages/BCS';
import { config } from './config/datadog';
import { ConnectedFlagsProvider } from 'flag';
import ErrorCatcher from './components/ErrorCatcher'
import ForgotPassword from './pages/ForgotPassword';
import Loading from './components/Loading'
import Login from './pages/Login';
import GoogleAnalytics from './components/GoogleAnalytics';
import MFASecretGenerator from './pages/MFASecretGenerator';
import { PersistGate } from 'redux-persist/lib/integration/react';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { PrivateRoute } from './components/PrivateRoute';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import ResetPassword from './pages/ResetPassword';
import TermsAndConditions from './pages/TermsAndConditions';
import TermsAndConditionsGerman from './pages/TermsAndConditionsGerman';
import { datadogRum } from '@datadog/browser-rum';
import { isProduction } from './helpers/utils';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { persistor, store } from './store';
import './sass/bcs.css';

if (isProduction()) {
    datadogRum.init(config);
}

const BcsApp = () => (
    <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
            <ConnectedFlagsProvider>
                <BrowserRouter>
                    <ErrorCatcher>
                        <GoogleAnalytics />
                        <Switch>
                            <Route exact path='/login' component={Login} />
                            <Route exact path='/forgot-password' component={ForgotPassword} />
                            <Route exact path='/mfa-generate-secret' component={MFASecretGenerator} />
                            <Route exact path='/reset-password' component={ResetPassword} />
                            <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
                            <Route exact path='/terms-and-conditions/german' component={TermsAndConditionsGerman} />
                            <Route exact path='/privacy-policy' component={PrivacyPolicy} />

                            {/* Because of how React Router 4 likes to 'stack' it's routers,
                                        we have a div app container here that contains all the app routes */}
                            <PrivateRoute
                                path='/'
                                component={BCS}
                                store={store}
                            />
                        </Switch>
                    </ErrorCatcher>
                </BrowserRouter>
            </ConnectedFlagsProvider>
        </PersistGate>
    </Provider>
);

// export for tests
export const BCSAPP = BcsApp
export default BcsApp;

// dont run the reactdom render in test mode?
if (!(navigator.userAgent.includes("Node.js") || navigator.userAgent.includes("jsdom"))) {
    ReactDOM.render(<BcsApp />, document.getElementById('root'));
}
