import React from "react";
import {Link} from "react-router-dom";

const Footer = (props) => (
    <footer data-component="footer" className="text-center bcs-footer">
        <ul>
            <li>Terms of Use: <Link to="/terms-and-conditions">English</Link>, <Link to="/terms-and-conditions/german">German</Link> | <Link to="/privacy-policy">Privacy Policy</Link></li>
            {/* <li>copyright &copy; Trilogy Education Services, {(new Date()).getFullYear()}</li> */}
            <li><Link to="/"><img src="https://s3.amazonaws.com/bcs-media-dev/ui/bcs-logo.svg" alt="Bootcamp Spot home page" className="footer-logo"/></Link></li>
        </ul>
    </footer>
);

export default Footer;
