import {
  enrollmentActionTypes,
  universalActionTypes,
} from '../constants/actionTypes'

export function resetEnrollmentInfo() {
  return { type: universalActionTypes.RESET_ENROLLMENT_INFO }
}

export function getCourseEnrollments(payload) {
    return {
        type: enrollmentActionTypes.GET_COURSE_ENROLLMENTS,
        payload
    }
}

export function setActiveEnrollment(payload) {
    return {
        type: enrollmentActionTypes.SET_ACTIVE_ENROLLMENT,
        payload
    }
}

export function patchEnrollmentList(payload) {
    return {
        type: enrollmentActionTypes.REPLACE_ENROLLMENT,
        payload
    }
}
