import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Icon from '../../../components/Icon';
import moment from 'moment';
import {getMonthString, getDayNumber, getDayString, getFormattedTime} from '../../../helpers/dateTime';
import {BaseSessionGridCard} from './BaseSessionGridCard';
import {sessionCheckIn} from '../../../actions/sessionActions';
import {isCheckInAvailable} from '../../../helpers/utils';
import {sessionCheckInTypes} from '../../../constants/checkIn';
import {sessionContextTypes} from '../../../constants/dataTypes';
import Loading from "../../../components/Loading/index";
import {isStudent} from '../../../helpers/roles';
import {getAttendanceStatusText} from "../../../helpers/common";
import {attendanceStatusSpecs} from "../../../constants/attendance";
import get from "lodash/get";

const mapStateToProps = (state) => {
    return {
        activeRole: get(state,'user.enrollmentInfo.courseRole.courseRoleCode',false),
        userId: state.user.data.userId,
        hideRemoteAttendance: get(state,'user.enrollmentInfo.course.cohort.cohortUiControl.hideRemoteAttendance',true),
        enrollmentId: get(state,'user.enrollmentInfo.id',false),
        enrollmentInfo: get(state,'user.enrollmentInfo',false)
    }
};

class UpcomingSessionGridCard extends BaseSessionGridCard {

    constructor(props) {
        super(props);
        this.state = {
            attendance: null
        }
    }

    classAttendanceHandler = async (e) => {
        e.preventDefault();
        const sessionId = this.props.sessionData.session.id;

        await sessionCheckIn(sessionId);
        this.props.refresh();
    }

    renderCard = () => {
        const {sessionData, activeRole, cardStyle} = this.props;

        if (!sessionData.session || !moment(sessionData.session.startTime, 'HH:mm a').isValid()) {
            return (
                <div className="card-with-title card-skinny">
                    <div className="card-content">
                        <Loading/>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="card-with-title card-skinny" style={cardStyle}>
                    <div className="card-title">
                        <h2>Upcoming Session</h2>
                        <h4>{getDayString(sessionData.session.startTime)}</h4>
                        <p className="card-title-month">{getMonthString(sessionData.session.startTime)} {getDayNumber(sessionData.session.startTime, true)}</p>
                        <p className="card-title-time">{getFormattedTime(sessionData.session.startTime)} - {getFormattedTime(sessionData.session.endTime)} Local</p>
                    </div>
                    <div className="card-content">
                        <h3>{sessionData.session.chapter} - {sessionData.session.name}</h3>
                    </div>
                    <div className="card-footer">
                        <ul>
                            <li>
                                <Link to={`/sessions/${sessionData.session.id}`}><Icon icon="copy"/> Session Details</Link>
                            </li>
                            {(isStudent(activeRole) &&
                                sessionData.context.contextCode !== sessionContextTypes.CAREER &&
                                moment().isBefore(moment(sessionData.session.endTime)) && !sessionData.remoteAttendanceApproval && !sessionData.remoteAttendanceDenial) &&
                                getAttendanceStatusText(sessionData) !== "Remote" &&
                                !this.props.hideRemoteAttendance &&
                                    <li className="remoteRequestLink">
                                        <Link to={`/attendance/remote-attendance-request/${sessionData.session.id}`}><Icon icon="sign-out-alt"/> Request Remote Attendance</Link>
                                    </li>
                            }
                            {(isStudent(activeRole)
                            && sessionData.context.contextCode !== sessionContextTypes.CAREER) &&
                                this.showCheckInLink()
                            }
                        </ul>
                    </div>
                </div>
            )
        }

    }

    showCheckInLink = () => {
        const {sessionData} = this.props;

        let checkInStatus = sessionData.session.startTime !== undefined ? isCheckInAvailable(sessionData.session.startTime, sessionData.session.endTime) : null;

        const attendance = getAttendanceStatusText(sessionData);
        const currentAttendanceStatus = attendanceStatusSpecs(attendance);

        if (sessionData.remoteAttendanceRequest
            || checkInStatus === sessionCheckInTypes.CHECK_IN_ENDED
            || (checkInStatus === sessionCheckInTypes.CHECK_IN_TIME && attendance !== "None")
            || attendance === "Remote") {
            return (
                <li>{currentAttendanceStatus.text}</li>
            )
        } else if (checkInStatus === sessionCheckInTypes.CHECK_IN_TOO_EARLY) {
            return (
                <li>Check In begins 45 minutes before Class</li>
            )
        } else {
            return (
                <li>
                    <Link to={`/attendance/check-in/${sessionData.session.id}`} onClick={this.classAttendanceHandler}>
                        <Icon icon="calendar-check"/> Check In To Class
                    </Link>
                </li>
            )
        }
    }


    render() {
        return (
            this.renderCard()
        )
    }
}

export const UPCOMINGSESSIONGRIDCARD  = UpcomingSessionGridCard;
export default connect(mapStateToProps)(UpcomingSessionGridCard);
