import React, { Component } from 'react';
import { Route,  Switch } from 'react-router-dom';
import IdleTimer from 'react-idle-timer'

import { AskBCSProvider } from "../../components/AskBCS";
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import LiveChat from '../../components/LiveChat';
import CourseSelectTabs from '../../components/CourseSelectTabs';

import Dashboard from '../Dashboard';
import Universities from '../Universities';
import Cohorts from '../Cohorts';
import Classes from '../Classes';
import Courses from '../Courses';
import Feedback from '../Feedback';
import NPSForm from '../NPSForm';
import Sessions from '../Sessions';
import SessionDetail from '../SessionDetail';
import SessionEdit from '../SessionEdit';
import SessionCreate from '../SessionCreate';
import PanoptoVideosCreate from '../PanoptoVideosCreate'
import PanoptoVideosEdit from '../PanoptoVideosEdit'
import PanoptoVideoView from '../PanoptoVideoView'
import Coursework from '../Coursework';
import CourseworkSubmit from '../CourseworkSubmit';
import CourseworkShow from '../CourseworkDetail'
import Grades from '../Grades';
import GradeDetail from '../GradeDetail';
import GradeAssignment from '../GradeAssignment';
import Gradebook from '../Gradebook';
import Attendance from '../Attendance';
import RemoteAttendanceRequest from '../RemoteAttendanceRequest';
import RemoteAttendanceRequestManager from '../RemoteAttendanceRequestManager';
import RemoteAttendanceRequestManagerTab from '../RemoteAttendanceRequestManagerTab';
import Videos from '../Videos';
import VideoWatch from '../VideoWatch';
import VideoOnboarding from '../VideoOnboarding';
import Contacts from '../Contacts';
import Students from '../Students';
import StudentDetail from '../StudentDetail';
import CareerServices from '../CareerServices';
import PageNotFound from '../PageNotFound';
import AnnouncementForm from '../AnnouncementForm';
import AnnouncementDetail from '../AnnouncementDetail';
import Unauthorized from '../Unauthorized';
import WeeklyFeedbackReport from '../WeeklyFeedbackReport';
import WebToCase from '../WebToCase';
import CaseSuccess from '../CaseSuccess';
import WeeklySuccess from '../WeeklySuccess';
import NpsSuccess from '../NpsSuccess';
import Agreement from "../Agreement";
import AgreementSuccess from "../AgreementSuccess";
import CareerServicesCurriculum from '../CareerServicesCurriculum'
import CareerServicesCurriculumSuccess from '../CareerServicesCurriculumSuccess'
import ProfileManage from '../ProfileManage'
import CareerSurvey from '../CareerSurvey'
import CareerSurveySuccess from '../CareerSurveySuccess'
import ApiDocs from '../ApiDocs'
import ApiDocsLogin from '../ApiDocsLogin'
import ApiDocsAttendance from '../ApiDocsAttendance'
import ApiDocsGrades from '../ApiDocsGrades'
import ApiDocsMe from '../ApiDocsMe'
import ApiDocsAssignments from '../ApiDocsAssignments'
import ApiDocsAssignmentDetail from '../ApiDocsAssignmentDetail'
import ApiDocsSessions from '../ApiDocsSessions'
import ApiDocsSessionDetail from '../ApiDocsSessionDetail'
import ApiDocsWeeklyFeedback from '../ApiDocsWeeklyFeedback'
import ApiDocsPolicy from '../ApiDocsPolicy'
import ApiDocsGraphQL from '../ApiDocsGraphQL'
import Notifications from '../Notifications';

import EnrollmentEnforcer from '../../components/EnrollmentEnforcer'
import SessionEnforcer from '../../components/SessionEnforcer'

import { connect } from 'react-redux';
import {isLoggedIn} from '../../helpers/auth'

import {userActions} from '../../actions/userActions'
import {condenseNav} from '../../actions/navActions'
import PreCourseSurvey from '../PreCourseSurvey';
import PreCourseSuccess from '../PreCourseSuccess';
import SessionSyllabus from '../SessionSyllabus';
import Announcements from '../Announcements';
import PrivacyPolicyUpdatedBanner from "../../components/PrivacyPolicyUpdatedBanner";

const mapStateToProps = (state) => {
    return {
        enrollmentInfo: state.user.enrollmentInfo,
        authToken: state.user.data.authToken,
        profile: state.user.profile,
        enrollments: state.enrollments,
        condenseNav: state.navState.condenseNav
    }
}

class BCS extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active: false,
            includeNav: true,
            userActionTaken: null,
            userActive: true,

        }

        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
    }

    componentWillReceiveProps() {
        this.setState({active: false});
    }

    componentWillMount() {
        if(!isLoggedIn()) {
            this.props.dispatch(userActions.logout());
        }
    }

    removeNav = () => {
        this.setState({ includeNav: false });
    }

    includeNav = () => {
        this.setState({ includeNav: true });
    }

    toggleNav = () => {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
    }

    handleCondenseNav = () => {
        const {dispatch} = this.props;
        dispatch(condenseNav());
    }

    handleCondenseNavKey = (e) => {
        if (e.keyCode === 13) {
            this.handleCondenseNav()
        }
    }

    toggleNavKey = (e) => {
        if (e.keyCode === 13) {
            this.toggleNav()
        }
    };

    _onAction(e) {
        // console.log('user did something', e)
        this.setState({
            userActionTaken: e
        })
      }

    _onActive(e) {
        // console.log('user is active', e)
        this.setState({
            userActive: true
        })
        // console.log('time remaining', this.idleTimer.getRemainingTime())
    }

    _onIdle(e) {
        // console.log('user is idle', e)
        this.setState({
            userActive: false
        })
        // console.log('last active', this.idleTimer.getLastActiveTime())
    }

    render() {

        const currentPath = this.props.location.pathname;
        return (
            <React.Fragment>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={1000 * 60 * 5}
                />
                <SessionEnforcer {...this.props} userActionTaken={this.state.userActionTaken}>
                    <EnrollmentEnforcer {...this.props}>
                        <AskBCSProvider>
                            {this.state.includeNav ? (
                                <React.Fragment>
                                    <div
                                        className={`nav-toggle hide-md ${this.props.condenseNav ? 'condensed' : ''}`}
                                        title={this.state.active ? 'Show navigation menu' : 'Hide navigation menu'}
                                        role="button"
                                        tabIndex="0"
                                        aria-expanded={this.state.active}
                                        aria-label={this.state.active ? 'Show navigation menu' : 'Hide navigation menu'}
                                        onClick={(e)=>this.toggleNav()}
                                        onKeyDown={(e)=>this.toggleNavKey(e)}
                                    >
                                        <div className={`hide-md nav-toggle-open ${ this.state.active ? 'open' : '' }`}>
                                            <span></span><span></span><span></span><span></span>
                                        </div>
                                    </div>
                                    <div
                                        className={`nav-toggle hide-xs show-md ${this.props.condenseNav ? 'condensed' : ''}`}
                                        onClick={() => this.handleCondenseNav()}
                                        onKeyDown={(e) => this.handleCondenseNavKey(e)}
                                        title={this.props.condenseNav ? 'Expand navigation menu' : 'Condense navigation menu'}
                                        role="button"
                                        tabIndex="0"
                                        aria-expanded={this.props.condenseNav}
                                        aria-label={this.props.condenseNav ? 'Expand navigation menu' : 'Condense navigation menu'}
                                    >
                                        <div className={`nav-toggle-open ${ this.state.active ? 'open' : '' }`}>
                                            <span></span><span></span><span></span><span></span>
                                        </div>
                                    </div>
                                    <Navigation {...this.props} active={this.state.active} condenseNav={this.props.condenseNav}/>
                                </React.Fragment>
                            ) : null }
                                <Header {...this.props}
                                    active={this.state.active}
                                    toggleNav={this.toggleNav}
                                    condenseNav={this.props.condenseNav}
                                    includeNav={this.state.includeNav}
                                />
                                <main
                                    className={`bcs-app-content ${this.props.condenseNav ? 'expanded' : ''}`}
                                    style={this.state.includeNav ? null : {'margin': 0}}
                                    data-component="bcs"
                                    id="main-content"
                                >
                                    <PrivacyPolicyUpdatedBanner/>
                                    { currentPath.includes('universities') || currentPath.includes('remote-attendance-requests/all') ? (
                                        <CourseSelectTabs {...this.props}/>
                                    ) : null }
                                    <div className="bcs-app-content-inner">
                                        <Switch>
                                            <Route exact path='/' render={(props) => (
                                                <Dashboard {...props} includeNav={this.includeNav}/>
                                            )}/>
                                            <Route exact path='/precourse' render={(props) => (
                                                <PreCourseSurvey {...props} removeNav={this.removeNav}/>
                                            )}/>
                                            <Route exact path='/universities' render={(props) => (
                                                <Universities {...props} removeNav={this.removeNav}/>
                                            )}/>
                                            <Route exact path='/universities/:universityName' render={(props) => (
                                                <Cohorts {...props} removeNav={this.removeNav}/>
                                            )}/>
                                            <Route exact path='/universities/:universityName/cohorts/:cohortName' render={(props) => (
                                                <Classes {...props} removeNav={this.removeNav}/>
                                            )}/>
                                            <Route exact path='/courses' render={(props) => (
                                                <Courses {...props} removeNav={this.removeNav}/>
                                            )}/>
                                            <Route exact path='/' component={Dashboard} />

                                            <Route path='/feedback/:week' render={(props)=>(
                                                <Feedback {...props} removeNav={this.removeNav} />
                                            )}/>

                                            <Route path='/agreement' render={(props)=>(
                                                <Agreement {...props} removeNav={this.removeNav} />
                                            )}/>

                                            <Route path='/nps/:course/:type' render={(props)=>(
                                                <NPSForm {...props} removeNav={this.removeNav} />
                                            )}/>



                                            <Route exact path='/videos/onboarding' render={(props)=>(
                                                <VideoOnboarding {...props} removeNav={this.removeNav} includeNav={this.includeNav} />
                                            )}/>
                                            <Route exact path='/videos/onboarding/watch/:videoId' render={(props)=>(
                                                <VideoWatch {...props} removeNav={this.removeNav} includeNav={this.includeNav} />
                                            )}/>
                                            <Route exact path='/remote-attendance-requests/all' render={props => (
                                                <RemoteAttendanceRequestManagerTab {...props} showAll={true} removeNav={this.removeNav}/>
                                            )} />
                                            <Route path='/notifications' component={Notifications} />
                                            <Route path='/weekly-feedback-report' component={WeeklyFeedbackReport} />
                                            <Route exact path='/instructor-api-docs' component={ApiDocs} />
                                            <Route path='/instructor-api-docs/login' component={ApiDocsLogin} />
                                            <Route path='/instructor-api-docs/me' component={ApiDocsMe} />
                                            <Route path='/instructor-api-docs/attendance' component={ApiDocsAttendance} />
                                            <Route path='/instructor-api-docs/grades' component={ApiDocsGrades} />
                                            <Route path='/instructor-api-docs/assignments' component={ApiDocsAssignments} />
                                            <Route path='/instructor-api-docs/assignmentDetail' component={ApiDocsAssignmentDetail} />
                                            <Route path='/instructor-api-docs/sessions' component={ApiDocsSessions} />
                                            <Route path='/instructor-api-docs/sessionDetail' component={ApiDocsSessionDetail} />
                                            <Route path='/instructor-api-docs/weeklyFeedback' component={ApiDocsWeeklyFeedback} />
                                            <Route path='/instructor-api-docs/policy' component={ApiDocsPolicy} />
                                            <Route path='/instructor-api-docs/graphql' component={ApiDocsGraphQL} />
                                            <Route exact path='/announcement/:announcementId/show' component={AnnouncementDetail} />
                                            <Route exact path='/announcement/:announcementId' component={AnnouncementForm} />
                                            <Route exact path='/sessions' component={Sessions} {...this.props} />
                                            <Route exact path='/sessions/create' component={SessionCreate} />
                                            <Route exact path='/sessions/:sessionId' component={SessionDetail} />
                                            <Route exact path='/sessions/:sessionId/edit' component={SessionEdit} />
                                            <Route exact path='/sessions/:sessionId/videos/:videoId' component={PanoptoVideoView} />
                                            <Route exact path='/sessions/:sessionId/video/create' component={PanoptoVideosCreate} />
                                            <Route exact path='/sessions/:sessionId/video/update' component={PanoptoVideosEdit} />
                                            <Route exact path='/academicSyllabus' component={SessionSyllabus}/>
                                            <Route exact path='/coursework' component={Coursework} />
                                            <Route exact path='/coursework/:courseworkId/show' component={CourseworkShow} />
                                            <Route path='/coursework/:courseworkId/submit' component={CourseworkSubmit} />
                                            <Route exact path='/grades' component={Grades} />
                                            <Route path="/career-services" component={CareerServices} />
                                            <Route path='/grades/:assignmentId' component={GradeDetail} />
                                            <Route exact path='/gradebook' component={Gradebook} />
                                            <Route exact path='/gradebook/assignment/:assignmentId' component={Gradebook} />
                                            <Route path='/gradebook/:assignmentId/grade/:userId' component={GradeAssignment} />
                                            <Route exact path='/attendance' component={Attendance} />
                                            <Route path='/attendance/remote-attendance-request/:sessionId' component={RemoteAttendanceRequest} />
                                            <Route exact path='/remote-attendance-requests' component={RemoteAttendanceRequestManager} />
                                            <Route exact path='/videos' component={Videos} />
                                            <Route exact path='/students' component={Students} />
                                            <Route path='/students/:studentEnrollmentId' component={StudentDetail} />

                                            <Route path='/manage-profile/:studentId' component={ProfileManage} />

                                            <Route path='/videos/watch/:videoId' component={VideoWatch} />
                                            <Route path='/class-contacts' component={Contacts} />
                                            <Route path='/unauthorized' component={Unauthorized} />
                                            <Route path='/support' render={(props)=>(
                                                <WebToCase {...props} removeNav={this.removeNav}/>
                                            )}/>
                                             <Route path='/casesuccess' render={(props)=>(
                                                <CaseSuccess {...props} removeNav={this.removeNav}/>
                                            )}/>
                                            <Route path='/weeklysuccess' component={WeeklySuccess}/>
                                            <Route path='/agreementsuccess' component={AgreementSuccess}/>

                                            <Route path='/cscurriculum' render={(props)=>(
                                                <CareerServicesCurriculum {...props} removeNav={this.removeNav} />
                                            )}/>
                                            <Route path='/cscurriculumsuccess' component={CareerServicesCurriculumSuccess}/>

                                            <Route path='/careerSurvey' render={(props)=>(
                                                <CareerSurvey {...props} removeNav={this.removeNav} />
                                            )}/>
                                            <Route path='/careerSuccess' render={(props)=>(
                                                <CareerSurveySuccess {...props} removeNav={this.removeNav} />
                                            )}/>

                                            <Route path='/npssuccess/:type' render={(props)=>(
                                                <NpsSuccess {...props} removeNav={this.removeNav} />
                                            )}/>

                                            <Route path='/precoursesuccess' component={PreCourseSuccess}/>
                                            <Route path='/announcements' component={Announcements} />

                                            <Route component={PageNotFound} />
                                        </Switch>
                                    </div>
                                <Footer {...this.props} />
                            </main>
                            <LiveChat {...this.props}/>
                    </AskBCSProvider>
                </EnrollmentEnforcer>
            </SessionEnforcer>
        </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(BCS);
