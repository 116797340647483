import React from "react";
import ExternalButtonLink from "../ExternalButtonLink";

const ContinuationContentCard = (props) => {
    const {continuationContent} = props;
    return (
        <div className="card text-center">
            <div className="text-center">
                <h3>Continuation Courses Content</h3>
                <div>Continue your learning after the boot camp with optional, self-paced, online Continuation Courses</div>
                <div className="margin-t-3">
                    <iframe src="https://fast.wistia.net/embed/iframe/b27ajhyect?seo=false" title="Continuation Courses Intro Video" allow="fullscreen"
                            allowtransparency="true" frameBorder="0" scrolling="no" className="wistia_embed"
                            name="wistia_embed" allowFullScreen msallowfullscreen="true" width="100%" height="288"
                            style={{'maxWidth':512, 'margin':'auto'}}>
                    </iframe>
                </div>
                <ExternalButtonLink to={continuationContent.link} text={'View Continuation Courses'} className={'btn-primary margin-0 margin-t-3 margin-b-1'}/>
            </div>
        </div>
    )
}

export default ContinuationContentCard;
