import {announcementsActionTypes} from "../constants/actionTypes";
import axios from "axios/index";
import {pending, rejected, fulfilled} from '../helpers/asyncActionGenerator';
import {getAnnouncementsUnreadCount} from "../helpers/notifications";
import {jsonContentTypeHeader} from "../constants/axios";

export function fetchAnnouncements(payload) {
    return async (dispatch) => {
        dispatch(pending(announcementsActionTypes.FETCH_ANNOUNCEMENTS));
        try {
            let axiosResult = await axios.post("/broker/announcements", payload, {headers: jsonContentTypeHeader});

            const announcementUnreadCount = getAnnouncementsUnreadCount(axiosResult.data.announcements);

            let data = {
                announcementList: axiosResult.data.announcements,
                announcementsUnread: announcementUnreadCount
            }
            return dispatch(fulfilled(announcementsActionTypes.FETCH_ANNOUNCEMENTS, data));
        } catch (error) {
            return dispatch(rejected(announcementsActionTypes.FETCH_ANNOUNCEMENTS, error));
        }
    }
}

export function updateAnnouncements(announcementList, latest) {
    return async (dispatch) => {
        dispatch(pending(announcementsActionTypes.ANNOUNCEMENTS_UPDATE_LIST));
        try {
            const announcementsUnreadCount = getAnnouncementsUnreadCount(announcementList);

            let data = {
                announcementList: announcementList,
                announcementsUnread: announcementsUnreadCount,
                latest: latest
            }
            return dispatch(fulfilled(announcementsActionTypes.ANNOUNCEMENTS_UPDATE_LIST, data));
        } catch (error) {
            return dispatch(rejected(announcementsActionTypes.ANNOUNCEMENTS_UPDATE_LIST, error));
        }
    }
}

export function announcementsChangeFilter(announcementsFilter, announcementFilteredList) {
    return {
        type: announcementsActionTypes.ANNOUNCEMENTS_CHANGE_FILTER,
        payload: {
            announcementsFilter,
            announcementFilteredList
        }
    }
}

export async function createAnnouncement(payload) {
    await axios.post("/broker/createCourseAnnouncement", payload, {headers: jsonContentTypeHeader});
}

export async function updateAnnouncement(payload) {
    await axios.post("/broker/updateCourseAnnouncement", payload, {headers: jsonContentTypeHeader});
}

export async function markAnnouncementRead(payload) {
    await axios.post("/broker/markCourseAnnouncementRead", payload, {headers: jsonContentTypeHeader});
}

export async function deleteAnnouncement(payload) {
    await axios.post("/broker/deleteCourseAnnouncement", payload, {headers: jsonContentTypeHeader});
}
