import React, {Component} from 'react';
import {connect} from 'react-redux';
import DocumentTitle from 'react-document-title';
import {Redirect} from 'react-router-dom'

import Alert from '../../components/Alert';
import TimePicker from '../../components/TimePicker';

import {createCareerSession} from '../../actions/sessionActions';

import {sessionCreateValidation} from '../../constants/validationMessages';
import {roleTypes} from '../../constants/dataTypes';
import {isRole} from '../../helpers/roles';

import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import ReactGA from 'react-ga'
import FormNavigationPrompt from '../../components/NavigationPrompt'


let classNames = require('classnames');

const mapStateToProps = (state, ownProps) => {
    return {
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        courseId: state.user.enrollmentInfo.courseId,
        ...ownProps
    }
}

export class SessionCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sessionDescription: '',
            sessionShortDescription: '',
            errors: [],
            creating: false,
            created: false,
            startDate: null,
            formTouched: false
        }

        this.requiredFields = [
            'sessionName',
            'chapter',
            'sessionDate',
            'sessionStartTime',
            'sessionEndTime',
            'sessionStartTime_meridian',
            'sessionEndTime_meridian'
        ];

        this.validationMessages = sessionCreateValidation;
    }

    handleChange = (event) => {
        // just don't name any fieldnames "formTouched" please
        let fieldName = event.target.name;
        let fieldValue = event.target.value;
        let obj = {formTouched: true};
        obj[fieldName] = fieldValue;
        this.setState(obj)
    }

    handleDateChange = (date) => {
        this.setState({
            startDate: date,
            sessionDate: moment(date).format('MM-DD-YYYY'),
            formTouched: true
        });
    }

    submitSession = async () => {
        const {courseId} = this.props;
        this.setState({creating: true, errors: []});

        if (this.validate()) {
            let start = `${this.state.sessionStartTime} ${this.state.sessionStartTime_meridian}`;
            let end = `${this.state.sessionEndTime} ${this.state.sessionEndTime_meridian}`;
            let startTime = moment(start, 'HH:mm A');
            let endTime = moment(end, 'HH:mm A');

            let date = moment(this.state.sessionDate, 'MM-DD-YYYY');

            let startDateTime = date.set({
                hour: startTime.get('hour'),
                minute: startTime.get('minute'),
                second: startTime.get('second')
            });
            let formattedStartDateTime = moment(startDateTime).format()

            let endDateTime = date.set({
                hour: endTime.get('hour'),
                minute: endTime.get('minute'),
                second: endTime.get('second')
            });
            let formattedEndDateTime = moment(endDateTime).format()

            let payload = {
                name: this.state.sessionName,
                shortDescription: this.state.sessionShortDescription,
                longDescription: this.state.sessionDescription,
                courseId: courseId,
                startTime: formattedStartDateTime,
                endTime: formattedEndDateTime,
                chapter: this.state.chapter
            };
            try {
                await createCareerSession(payload);
                /* DISPATCH REFRESH */
                this.setState({created: true, formTouched: false})
                ReactGA.event({
                    category: 'Career Session',
                    action: 'Session Created',
                });
            } catch (e) {
                ReactGA.event({
                    category: 'Career Session',
                    action: 'Session Create Error',
                });
                this.setState({errors: [e.message]});
            }
        } else {
            this.setState({creating: false});
        }
    }

    validate() {
        const errors = [];

        //FIXME: refactor to an each loop, map is not needed here
        //  
        this.requiredFields.forEach((item) => {
            if (this.state[item] === undefined || !this.state[item].length) {
                errors.push(this.validationMessages[item]);
            }
        });

        //we've made it through the first round so we know that the requireds are present
        //so we can do some other checking
        if (!errors.length) {
            let startTime = moment(`${this.state.sessionStartTime}${this.state.sessionStartTime_meridian}`, 'h:mmA');
            let endTime = moment(`${this.state.sessionEndTime}${this.state.sessionEndTime_meridian}`, 'h:mmA');
            if (endTime.isBefore(startTime)) {
                errors.push('Session End Time must be AFTER Session Start Time');
            }
        }

        //do the actual return of true/false for validity
        if (errors.length) {
            this.setState({errors: errors});
            window.scrollTo(0, 0);
            return false;
        } else {
            return true;
        }

    }


    render() {
        if (isRole([roleTypes.STUDENT, roleTypes.INSTRUCTOR, roleTypes.TA], this.props.activeRole)) {
            return <Redirect to='/unauthorized'/>
        }

        const buttonClasses = classNames({
            'btn': true,
            'btn-create': true,
            'btn-loading': this.state.creating
        });
        const { formTouched } = this.state
        return (
            <DocumentTitle title="Create Session | Bootcamp Spot">
                <section className="page" data-component="session-create">
                    <FormNavigationPrompt message='Are you sure you want to navigate away from this page while you have a session in progress?' valueExists={formTouched} /> 
                    <div className="row">
                        <div className="col-xs-12 col-md-8">
                            <h1>Create Career Session</h1>
                            {this.state.errors.length ? (
                                <Alert type="error">
                                    <p>Please correct the following errors:</p>
                                    <ul>
                                        {this.state.errors.map((error, index) => {
                                            return (
                                                <li key={index}>{error}</li>
                                            )
                                        })}
                                    </ul>
                                </Alert>
                            ) : null}
                            {this.state.created ? (
                                <Alert type="success">
                                    Your career session has been created.<br/>
                                    <button className="btn btn-edit btn-sm" onClick={() => {
                                        this.props.history.go(-1)
                                    }}>Continue</button>
                                </Alert>
                            ) : (
                                <div id="create-session-form">
                                    <div className="field">
                                        <label htmlFor="sessionName">Session Title<span
                                            className="text-red">*</span></label>
                                        <input name="sessionName" id="sessionName" type="text" aria-required="true"
                                               onChange={this.handleChange}/>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-md-6" style={{paddingLeft: 0}}>
                                            <div className="field">
                                                <label htmlFor="chapter">Session Chapter<span
                                                    className="text-red">*</span></label>
                                                <input name="chapter" id="chapter" type="number" step="0.1"
                                                       placeholder="0.0" aria-required="true"
                                                       onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-md-6" style={{paddingRight: 0}}>
                                            <div className="field">
                                                <label htmlFor="sessionDate">Session Date<span
                                                    className="text-red">*</span></label>
                                                <DatePicker selected={this.state.startDate ? new Date(moment(this.state.startDate)) : null }
                                                            onChange={this.handleDateChange} aria-required="true"
                                                            dateFormat="MM-dd-yyyy"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-md-6" style={{paddingLeft: 0}}>
                                            <div className="field">
                                                <label htmlFor="sessionStartTime">Session Start Time<span
                                                    className="text-red">*</span></label>
                                                <TimePicker
                                                    name="sessionStartTime"
                                                    onChange={this.handleChange}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-md-6" style={{paddingRight: 0}}>
                                            <div className="field">
                                                <label htmlFor="sessionEndTime">Session End Time<span
                                                    className="text-red">*</span></label>
                                                <TimePicker
                                                    name="sessionEndTime"
                                                    onChange={this.handleChange}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="sessionShortDescription">Session Short Description</label>
                                        <textarea rows="4" name="sessionShortDescription" id="sessionShortDescription"
                                                  onChange={this.handleChange} maxLength="250"
                                                  placeholder="This is the summary that shows on the session cards"></textarea>
                                        <p style={{margin: 0, padding: 0, textAlign: 'right'}}
                                           className="font-xs">{this.state.sessionShortDescription.length || 0}/250</p>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="sessionDescription">Session Long Description (Markdown
                                            Supported)</label>
                                        <textarea rows="8" name="sessionDescription" id="sessionDescription"
                                                  onChange={this.handleChange}
                                                  style={{marginBottom: 0, paddingBottom: 0}} maxLength="1000"
                                                  placeholder="This is the extended description of the session that will show when a user clicks into a session"></textarea>
                                        <p style={{margin: 0, padding: 0, textAlign: 'right'}}
                                           className="font-xs">{this.state.sessionDescription.length || 0}/1000</p>
                                    </div>
                                    <div className="field">
                                        <button type="submit" className={buttonClasses}
                                                onClick={this.submitSession}>Create Session
                                        </button>
                                        <button className="btn-delete btn-tertiary" onClick={()=>{this.props.history.goBack()}}>Cancel</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </DocumentTitle>
        )
    }

}

export default connect(mapStateToProps)(SessionCreate);
