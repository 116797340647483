export function getAnnouncementsUnreadCount (announcementList) {

    let announcementsUnreadCount = 0;

    for (let i = 0; i < announcementList.length; i++) {
        if (!announcementList[i].handled) {
            announcementsUnreadCount++;
        }
    }

    return announcementsUnreadCount;
}

export function getNotificationsUnreadCount (notificationList) {

    let notificationsUnreadCount = 0;

    for (let i = 0; i < notificationList.length; i++) {
        if (!notificationList[i].handled) {
            notificationsUnreadCount++;
        }
    }

    return notificationsUnreadCount;
}
