import Alert from "components/Alert";
import Input from "components/Input";
import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { useInput } from "helpers/hooks";
import { userActions } from "actions";

const mapStateToProps = ({
    user: { mfaGenerationError, mfaGenerationData, mfaGenerationLoading },
}) => ({
    mfaGenerationError,
    mfaGenerationData,
    mfaGenerationLoading,
});

const MFASecretGenerator = ({
    dispatch,
    mfaGenerationError,
    mfaGenerationData,
    mfaGenerationLoading,
}) => {
    const [emailInput, email] = useInput("");

    const onGenerateCode = () => {
        dispatch(userActions.generateMFACode({ email }));
    };

    const { key, qrCode } = mfaGenerationData ?? {};

    return (
        <div className="centered-container">
            <Input
                aria-required={true}
                label="Email"
                id="email"
                placeholder="Enter your email"
                required={true}
                type="email"
                {...emailInput}
            />
            <button
                className="btn-submit btn-center"
                disabled={!email || mfaGenerationLoading}
                onClick={onGenerateCode}
            >
                Generate
            </button>
            <div aria-live="assertive">
                {!isEmpty(mfaGenerationData) && (
                    <div className="centered-container qr-container">
                        <img alt="QR Code" src={qrCode} />
                        <span>{key}</span>
                    </div>
                )}
                {mfaGenerationError && (
                    <Alert type="error">
                        There was an error generating the MFA Secret
                    </Alert>
                )}
            </div>
        </div>
    );
};

export default connect(mapStateToProps)(MFASecretGenerator);
