import {
    billingActionTypes,
    universalActionTypes,
} from '../constants/actionTypes'

import {
    pending,
    rejected,
    fulfilled
} from '../helpers/asyncStatusGenerator';

const initialState = {
    loadingBilling: false,
    loadedState: false,
    universityPrograms: [],
};

export const billingReducer = (state=initialState, action) => {
    switch (action.type) {
        case universalActionTypes.RESET_ENROLLMENT_INFO:
            return initialState;
        case pending(billingActionTypes.FETCH_BILLING):
            return {
                ...state,
                loadingBilling: true,
                loadedState: false
            };
        case fulfilled(billingActionTypes.FETCH_BILLING):
            return {
                ...state,
                loadingBilling: false,
                universityPrograms: action.payload,
                loadedState: true
            };
        case rejected(billingActionTypes.FETCH_BILLING):
            return {
                ...state,
                loadingBilling: false,
                loadedState: true
            };

        default:
            return state;
    }
};
