import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

class CourseSelectTabs extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const currentPath = this.props.location.pathname;
        return (
            this.props.user.profile.canManageAttendance ? (
                <div className="bcs-tabs">
                    <Link to="/universities" className={currentPath.includes('universities') ? 'active' : ''}>Course Select</Link>
                    <Link to="/remote-attendance-requests/all" className={currentPath.includes('/remote-attendance-requests/all') ? 'active' : ''}>Remote Requests</Link>
                </div>
            ) : null
        )
    }
}

export default connect(mapStateToProps)(CourseSelectTabs);
