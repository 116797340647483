
import AWS from "aws-sdk";
import { isProduction } from "../helpers/utils";

const prod = 'bcs-next-support-photo-upload-prod';
const dev = 'bcs-next-support-photo-upload-dev';

export const config = {
  region: 'us-east-1',
  credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_COGNITO_POOL_ID
  })
};

export const getBucketName = () => isProduction() ? prod : dev;