//  
import { Component } from 'react';
import ReactGA from 'react-ga';
import {GATrackingID} from '../../config/analytics';
import { withRouter } from 'react-router';


import { isProduction } from '../../helpers/utils'

// isprod conditional required here due to test suite breakage in virtual env
if(isProduction()){
  ReactGA.initialize(GATrackingID);
}

class GoogleAnalytics extends Component {

  componentDidMount () {
    this.routeChanged()
  }

  componentDidUpdate (prevProps) {
    let { location: { pathname } } = this.props

    if (prevProps.location.pathname === pathname) return
    this.routeChanged()
  }

  routeChanged () {
    let { location } = this.props
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }

  render () {
    return null
  }
}

export default withRouter(GoogleAnalytics)
