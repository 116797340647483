import React, {Component} from 'react'
import {Link } from 'react-router-dom'
import ReactDOM from 'react-dom';
import {connect} from 'react-redux'
import Icon from '../../components/Icon'
import ReactGA from 'react-ga';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get'
import AskBCS from "../AskBCS";

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
};

class LiveChat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            panelVisible: false,
            chatAvailable: true,
            validPage: true,
            isLiveAgentInitialized: false,
        }

        /*
          - refs for later.
          - not in state because not consequential to render()
        */
        this.chatBubbleRef = null;
        this.liveChatButtonRef = null;
        this.supportButtonRef = null;
        this.liveChatContainerRef = null;

        this.initializingChat = false;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname.includes("universities")) {
            this.setState({
                validPage: false,
                isLiveAgentInitialized: false
            })
        } else {
            this.setState({validPage: true})
        }
        if (!isEmpty(nextProps.user.enrollmentInfo)) {
            const sfWidget = document.getElementById('sf-liveagent-widget');
            if (sfWidget) {
                if (nextProps.flags.liveChat.isEnabled) {
                    const hideLiveChat = get(nextProps, 'user.enrollmentInfo.course.cohort.cohortUiControl.hideLiveChat', false)
                    if (!hideLiveChat) {
                        if (!this.initializingChat && !this.state.isLiveAgentInitialized) {
                            this.initializingChat = true;
                            this.insertLiveAgentCode();
                        }
                    }
                } else {
                    this.setState({chatAvailable: false})
                }
            }
        }
    }

    insertLiveAgentCode = () => {
        delete window.liveagent;
        delete window.liveAgentDeployment;
        this.deployLiveAgent();
    }

    deployLiveAgent = () => {
        let tag = document.createElement('script');
        tag.type = 'text/javascript';
        tag.onload = this.loadLiveAgentScript;
        tag.src = 'https://c.la1-c1cs-iad.salesforceliveagent.com/content/g/js/42.0/deployment.js';

        document.body.appendChild(tag);
    }

    loadLiveAgentScript = () => {
        let tag = document.createElement('script');
        tag.type = 'text/javascript';
        tag.src = '/js/sf-liveagent.js';
        tag.onload = this.initLiveAgent;
        document.body.appendChild(tag);
    }

    initLiveAgent = () => {
        window.initSFLiveAgent();
        this.setState({
            chatAvailable: true,
            isLiveAgentInitialized: true
        })
        this.initializingChat = false;
    }

    startChat() {
        ReactGA.event({
            category: 'Support',
            action: 'Live Chat Started',
        });
        window.startLiveAgentChat();
    }

    isChatAvailable() {
      /*
        Since LiveAgent manipulates the DOM directly, we have no way of knowing
        through props/state whether chat is available or not. Therefore, we
        must get this info from styles.
      */
      const node = ReactDOM.findDOMNode(this.liveChatContainerRef);
      if (node) {
        const display = window.getComputedStyle(node).getPropertyValue('display');
        return display !== 'none';
      }
      return false;
    }

    togglePanelVisibleKeyPress = (e) => {
      /*
        - manually move focus to the next appropriate element
        - note: must do so manually because 'enter', unlike 'tab', does not move
          the focus forward.
      */
      e.preventDefault();
      if (e.key === "Enter") {
          this.setState({panelVisible: !this.state.panelVisible}, () => {
            /* you've just closed the panel; focus needs to go back to the chat bubble: */
            let nextFocusedRefKey = 'chatBubbleRef';

            /* you've just opened the panel; focus needs to go to the next button */
            if (this.state.panelVisible) {
              nextFocusedRefKey = this.isChatAvailable() ? 'liveChatButtonRef' : 'supportButtonRef';
            }
            this.focusNextRef(nextFocusedRefKey);
          })
      }
    }

    focusNextRef(refKey) {
      /*
        - give the next focusable el focus
        - check if ref exists && if that ref is focusable (some html els are not)
        - We need to wait for any css transitions to finish to ensure the
          ref is in the DOM before focusing (750ms)
      */
      if (this[refKey] && this[refKey].focus) {
        setTimeout(() => {
          this[refKey].focus();
        }, 900);
      }
    }

    setRefWithKey = key => ref => {
      /*
        - currying. saves us from having to write a fx for each of the refs
        - given a key, react will create a ref
        - then we set that ref on the component (see above under initial state)
      */
      this[key] = ref;
    }

    //FIXME: refactor revealPanel and hidePanel to flip the boolean in one function instead of having a useless ternary thats hard to read.
    revealPanel = () => { // removing that eslint ignore will break the dev build process
         // eslint-disable-next-line
        this.state.panelVisible === false ? this.setState({panelVisible: true}) : null
    }

    revealPanelOnKeyPress = (event) => { // removing that eslint ignore will break the dev build process
        if (event.key === "Enter") {
            // eslint-disable-next-line
            this.state.panelVisible === false ? this.setState({panelVisible: true}) : null
          }
   }

    hidePanel = () => {
      this.setState({panelVisible: false});
    }

    renderChatBubble() {
      /* trying to dry up some render code */
      return (
        <div onKeyPress={(e) => {
            this.togglePanelVisibleKeyPress(e)
        }} tabIndex="0" className="chat-cta" role="button" aria-expanded={this.state.panelVisible} ref={this.setRefWithKey('chatBubbleRef')}>
            <Icon icon="comments"></Icon>
            <p>Chat</p>
        </div>
      );
    }

    renderCloseButton() {
      /* trying to dry up some render code */
      return (
        <div aria-expanded={this.state.panelVisible} aria-label="Close" role="button"
             className="chat-close" onClick={() => this.hidePanel()} tabIndex="0" onKeyPress={(e) => {
            this.togglePanelVisibleKeyPress(e)
        }}>
            <Icon icon="window-close"></Icon>
        </div>
      );
    }
    render() {
        const hideLiveChat = get(this.props, 'user.enrollmentInfo.course.cohort.cohortUiControl.hideLiveChat', false)
        return (
            !hideLiveChat && this.state.validPage && this.state.chatAvailable && this.props.user.profile.firstName !== undefined && !isEmpty(this.props.user.enrollmentInfo) ? (

                <div id="sf-liveagent-widget"
                     data-username={`${(this.props.user && this.props.user.profile) ? this.props.user.profile.firstName + ' ' + this.props.user.profile.lastName : ''}`}
                     data-cohortname={this.props.user.enrollmentInfo ? this.props.user.enrollmentInfo.course.name : 'No Cohort Available'}
                     data-useremail={(this.props.user && this.props.user.profile) ? this.props.user.profile.email : 'no-email@bootcampspot-v2.com'}
                >
                    <AskBCS
                        onDeepLinkAvailable={({ deepLink, askBcsLogo, dataBcsProp }) => (
                            <>
                                {/* will be shown if agent is available */}
                                <div className={`live-chat ${this.state.panelVisible ? 'active' : ''}`}
                                     id="liveagent_online"
                                     onClick={() => this.revealPanel()}
                                     onKeyDown={(e) => this.revealPanelOnKeyPress(e)}
                                     ref={this.setRefWithKey('liveChatContainerRef')}
                                     role="button"
                                     tabIndex="0"
                                     {...dataBcsProp}
                                >

                                    {this.renderChatBubble()}
                                    <div className="chat-panel ask-bcs-panel">
                                        <div className="student-services">
                                            <span className="text-bold">For help with student services,</span> you can
                                            live chat or create a support ticket.
                                            <div>
                                                <button className="btn-small" onClick={this.startChat} tabIndex="0">
                                                    Live Chat
                                                </button>
                                                {!hideLiveChat &&
                                                <Link to="/support" innerRef={this.setRefWithKey('liveChatButtonRef')}>
                                                    <button tabIndex="-1" className="btn-small">Support Ticket</button>
                                                </Link>
                                                }
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="ask-bcs">
                                            <span className="text-bold">For help with coursework,</span> you can click
                                            below to ask a Learning Assistant in Slack using AskBCS.
                                            <a href={deepLink}
                                               className="display-block text-center"
                                               target="_blank"
                                               rel="noopener noreferrer"
                                               aria-label="AskBCS Learning Assistant">
                                                <img className="ask-bcs ask-bcs-btn" src={askBcsLogo}
                                                     alt="AskBCS Learning Assistant"/>
                                            </a>
                                        </div>
                                    </div>
                                    {this.renderCloseButton()}
                                </div>

                                {/* will be shown if agent is unavailable */}
                                <div className={`live-chat unavailable ${this.state.panelVisible ? 'active' : ''}`}
                                     id="liveagent_offline"
                                     onClick={() => this.revealPanel()}
                                     onKeyDown={(e) => this.revealPanelOnKeyPress(e)}
                                     role="button"
                                     tabIndex="0"
                                     {...dataBcsProp}
                                >
                                    {this.renderChatBubble()}
                                    <div className="chat-panel ask-bcs-panel">
                                        <div className="student-services">
                                            <span className="text-bold">For help with student services,</span> you can
                                            create a support ticket. Live chat is currently unavailable.
                                            <div>
                                                <Link to="/support" innerRef={this.setRefWithKey('supportButtonRef')}>
                                                    <button tabIndex="-1" className="btn-small">Support Ticket</button>
                                                </Link>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="ask-bcs">
                                            <span className="text-bold">For help with coursework,</span> you can click
                                            below to ask a Learning Assistant in Slack using AskBCS.
                                            <a href={deepLink}
                                               className="display-block text-center"
                                               target="_blank"
                                               rel="noopener noreferrer"
                                               aria-label="AskBCS Learning Assistant">
                                                <img className="ask-bcs ask-bcs-btn" src={askBcsLogo}
                                                     alt="AskBCS Learning Assistant"/>
                                            </a>
                                        </div>
                                    </div>
                                    {this.renderCloseButton()}
                                </div>
                            </>
                        )}>
                        <>
                            {/* will be shown if agent is available */}
                            <div className={`live-chat ${this.state.panelVisible ? 'active' : ''}`}
                                 id="liveagent_online"
                                 onClick={() => this.revealPanel()}
                                 onKeyDown={(e) => this.revealPanelOnKeyPress(e)}
                                 ref={this.setRefWithKey('liveChatContainerRef')}
                                 role="button"
                                 tabIndex="0"
                            >
                                {this.renderChatBubble()}
                                <div className="chat-panel">
                                    <button className="btn-small" onClick={this.startChat} tabIndex="0">Live Chat
                                    </button>
                                    {!hideLiveChat &&
                                    <Link to="/support" innerRef={this.setRefWithKey('liveChatButtonRef')}>
                                        <button tabIndex="-1" className="btn-small">Support Ticket</button>
                                    </Link>
                                    }
                                </div>
                                {this.renderCloseButton()}
                            </div>

                            {/* will be shown if agent is unavailable */}
                            <div className={`live-chat unavailable ${this.state.panelVisible ? 'active' : ''}`}
                                 id="liveagent_offline"
                                 onClick={() => this.revealPanel()}
                                 onKeyDown={(e) => this.revealPanelOnKeyPress(e)}
                                 role="button"
                                 tabIndex="0"
                            >
                                {this.renderChatBubble()}
                                <div className="chat-panel">
                                    <span>Chat unavailable</span>
                                    <Link to="/support" innerRef={this.setRefWithKey('supportButtonRef')}>
                                        <button tabIndex="-1" className="btn-small">Support Ticket</button>
                                    </Link>
                                </div>
                                {this.renderCloseButton()}
                            </div>
                        </>
                    </AskBCS>
                </div>
            ) : null

        )
    }
}

export default connect(mapStateToProps)(LiveChat);
