import {Component} from "react";
import DocumentTitle from "react-document-title";
import {connect} from 'react-redux';
import React from "react";
import {getRawPayload} from "../../actions/openDocsActions";
import OpenDocsApiResponse from "../../components/OpenDocsApiResponse";
import OpenDocsHeader from "../../components/OpenDocsHeader";


const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps,
        openDocs: state.openDocs,
        authToken: state.user.data.authToken,
    }
};

class ApiDocsMe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tryItUrl: ''
        };
    }

    componentWillReceiveProps(newProps) {
        if (typeof newProps.openDocs !== "undefined") {
            const newOpenDocs = newProps.openDocs;
            if (newOpenDocs && newOpenDocs[this.state.tryItUrl]) {
                this.setState({
                    payloadRsp: newOpenDocs[this.state.tryItUrl].payload,
                    headersRsp: newOpenDocs[this.state.tryItUrl].headers,
                    status: newOpenDocs[this.state.tryItUrl].status,
                });
            }
        }
    }

    handleChange(event) {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        const obj = {};
        obj[fieldName] = fieldValue;
        this.setState(obj)
    }

    handleOnClick() {
        const {dispatch, authToken} = this.props;
        const ep = `me`;
        this.setState({
            tryItUrl: ep
        });

        dispatch(getRawPayload(ep, {}, authToken));
    }

    render() {
        const {payloadRsp, headersRsp, status} = this.state;

        return (
            <DocumentTitle title="API Access - me | Bootcamp Spot">
                <section>
                    <OpenDocsHeader
                        endpoint={"/api/instructor/v1/me"}
                        description={"Provides detailed profile and authorization information for the caller."}
                        type={"GET"}
                        headers={[{name: "Content-Type", value: "application/json", description: ""},
                            {name: "authToken", value: "<valid token>", description: "The token generated via /login"}]}
                        exampleBody={null}
                        exampleResponses={[
                            {
                                code: 200,
                                body: {
                                    "userAccount": {
                                        "id": 13786,
                                        "userName": "student@trilogyed.com",
                                        "firstName": "John",
                                        "lastName": "Doe",
                                        "email": "student@trilogyed.com",
                                        "githubUserName": "null",
                                        "phoneNumber": null,
                                        "avatarUrl": null,
                                        "mailingLogoUrl": null,
                                        "passwordResetRequired": false,
                                        "active": true,
                                        "bcsExtends": {
                                            "id": 13786,
                                            "isAdmin": false,
                                            "jobTrackActive": false
                                        },
                                        "nexusAvatarUrl": null
                                    },
                                    "enrollments": [
                                        {
                                            "id": 36705,
                                            "courseId": 594,
                                            "userAccountId": 13786,
                                            "courseRoleId": 3,
                                            "active": true,
                                            "course": {
                                                "id": 594,
                                                "cohortId": 476,
                                                "name": "TEST20180825FSF",
                                                "code": "TEST20180825FSF",
                                                "startDate": "2018-08-23T01:00:00-03:00",
                                                "endDate": "2019-03-11T01:00:00-03:00",
                                                "duration": null,
                                                "location": "TBD_2",
                                                "endSurveyEnabled": false,
                                                "enterpriseCourse": null,
                                                "cohort": {
                                                    "id": 476,
                                                    "programId": 240,
                                                    "name": "TESTCOHORT",
                                                    "startDate": "2018-08-23T01:00:00-03:00",
                                                    "endDate": "2019-07-01T01:00:00-03:00",
                                                    "program": {
                                                        "id": 240,
                                                        "name": "Testing Section",
                                                        "university": {
                                                            "id": 57,
                                                            "name": "Test University 2.0",
                                                            "logoUrl": "https://s3.amazonaws.com/trilogy-ed/bcs/dev/assets/images/logo.png"
                                                        },
                                                        "programType": {
                                                            "id": 1004,
                                                            "name": "Full Stack Flex Part-Time",
                                                            "active": true
                                                        }
                                                    }
                                                },
                                                "graduationRequirements": {
                                                    "maxAbsence": 4,
                                                    "maxRemoteAttendance": 4,
                                                    "maxMissedGeneralAssignment": 2,
                                                    "maxMissedRequiredAssignment": 0
                                                }
                                            },
                                            "courseRole": {
                                                "id": 3,
                                                "courseRoleCode": "instructor",
                                                "name": "Instructor"
                                            },
                                            "midCourseSurveySchedule": {
                                                "id": 317962,
                                                "courseId": 594,
                                                "survey": "mid-course",
                                                "dateFrom": "2018-11-25T21:00:00-03:00",
                                                "dateTo": "2018-12-02T20:59:59-03:00"
                                            },
                                            "endCourseSurveySchedule": {
                                                "id": 317963,
                                                "courseId": 594,
                                                "survey": "end-course",
                                                "dateFrom": "2019-03-04T01:00:00-03:00",
                                                "dateTo": "2019-03-25T01:00:00-03:00"
                                            },
                                            "remoteAttendanceCount": 0,
                                            "pendingRemoteAttendanceRequestCount": 0
                                        }
                                    ]
                                },
                                rows: 15,
                                description: "Successful response."
                            }
                        ]}
                    />

                    <div className="row">
                        <div className="col-xs-12">
                            <h1 className={"border-bottom"}>Try it out</h1>
                            <button onClick={this.handleOnClick.bind(this)}>Submit</button>
                        </div>
                    </div>


                    <OpenDocsApiResponse
                        headers={headersRsp}
                        payload={payloadRsp}
                        status={status}
                    />

                </section>
            </DocumentTitle>
        )
    }
};

export default connect(mapStateToProps)(ApiDocsMe);
