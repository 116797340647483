import React from 'react';

const TBody = (props) => {

    return (
        <tbody>
            {props.children}
        </tbody>
    )
}

export default TBody
