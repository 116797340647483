import {
    sessionActionTypes,
    universalActionTypes,
} from '../constants/actionTypes'
import {
    pending,
    rejected,
    fulfilled
} from '../helpers/asyncStatusGenerator';

const initialState = {
    currentWeekSessions: [],
    calendarSessions: [],
    selected: {},
    sessionListLoading: false,
    sessionLoading: false,
    error: false
}

export const sessionReducer = (state=initialState, action) => {
    switch (action.type) {
        case universalActionTypes.RESET_ENROLLMENT_INFO:
            return initialState;
        case pending(sessionActionTypes.FETCH_SESSIONS):
            return {
                ...state,
                sessionListLoading: true,
            };

        case fulfilled(sessionActionTypes.FETCH_SESSIONS):
            return {
                ...state,
                sessionListLoading: false,
                currentWeekSessions: action.payload.currentWeekSessions,
                calendarSessions: action.payload.calendarSessions
            };

        case rejected(sessionActionTypes.FETCH_SESSIONS):
            return {
                ...state,
                sessionListLoading: false
            };

        case pending(sessionActionTypes.FETCH_SINGLE_SESSION):

            return {
                ...state,
                sessionLoading: true
            }

        case rejected(sessionActionTypes.FETCH_SINGLE_SESSION):

            return {
                ...state,
                sessionLoading: false,
                selected: {},
                error: action.payload
            }

        case fulfilled(sessionActionTypes.FETCH_SINGLE_SESSION):

            return {
                ...state,
                selected: action.payload,
                sessionLoading: false
            }

        case fulfilled(sessionActionTypes.REFRESH_STUDENTS):
            return {
                ...state,
                selected: {
                    ...state.selected,
                    students: action.payload
                }
            }

        default:
            return state;
    }
}
