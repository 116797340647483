import React, {Component} from 'react';
import {connect} from 'react-redux';
import DocumentTitle from 'react-document-title';
import {requestRemoteAttendance, createRemoteRequest} from '../../actions/attendanceActions';
import moment from 'moment'
import Loading from '../../components/Loading';
import Alert from '../../components/Alert';
import classNames from 'classnames';
import ReactGA from 'react-ga';
import {getContacts} from "../../actions/contactActions";
import {remoteAllowanceWarn} from "../../helpers/remoteAllowanceWarn";
import Input from '../../components/Input/index';
import {patchEnrollmentList, setActiveEnrollment} from "../../actions/enrollmentsActions";
import {Redirect} from "react-router-dom";
import get from "lodash/get";

const mapStateToProps = (state, ownProps) => {
    return {
        enrollmentId: state.user.enrollmentInfo.id,
        enrollmentList: state.enrollments.enrollmentList,
        remoteAttendanceCount: state.user.enrollmentInfo.remoteAttendanceCount,
        pendingRemoteAttendanceRequestCount: state.user.enrollmentInfo.pendingRemoteAttendanceRequestCount,
        courseId: state.user.enrollmentInfo.courseId,
        graduationRequirements: state.user.enrollmentInfo.course.graduationRequirements,
        hideRemoteAttendance: get(state,'user.enrollmentInfo.course.cohort.cohortUiControl.hideRemoteAttendance',true),
        ...ownProps
    }
}

class RemoteAttendanceRequest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            session: {},
            sessionLoading: true,
            requesting: false,
            checked: ""
        }
    }

    async componentDidMount() {
        const {match, courseId} = this.props;
        const sessionId = parseInt(match.params.sessionId, 10);

        let payload = {
            sessionId: sessionId
        };

        let result = await requestRemoteAttendance(payload);
        let contacts = await getContacts(courseId);


        this.setState({
            session: result.data,
            ssmS: contacts.data.filter(o => o.role.courseRoleCode === "ssm" && o.contact.active),
            sessionLoading: false
        })
    }

    handleSubmit = async() => {
        this.setState({requesting: true});
        const sessionId = this.state.session.session.id;
        const {enrollmentId, dispatch, enrollmentList} = this.props;

        try {
            let payload = {
                id: null,
                studentEnrollmentId: enrollmentId,
                sessionId: parseInt(sessionId),
            };
            const axiosResult = await createRemoteRequest(payload);
            const modifiedEnrollment = axiosResult.data.enrollment;
            const patchEnrollmentListPayload = {
                currentEnrollmentList: enrollmentList,
                enrollment: modifiedEnrollment
            };

            dispatch(patchEnrollmentList(patchEnrollmentListPayload));
            dispatch(setActiveEnrollment(modifiedEnrollment));

            ReactGA.event({
                category: 'Remote Attendance',
                action: 'Submit Request',
            });

            this.setState({
                requesting: false,
                success: `Thanks! Your Instructional Staff will be in touch shortly to confirm or deny your request. 
                Please slack your TAs and let them know you are online remotely so they can approve your attendance properly in the system.`
            })

        } catch (err) {
            console.error(err)
            this.setState({
                requesting: false,
                submissionFailed : "We're sorry, something went wrong with your submission. Please try again."
            });
        }
    }

    handleCheck = async (e) => {
        const chk = e.target.checked ? "true" : "";
        this.setState({
            checked: chk
        });
    };

    render() {
        if (!!this.props.hideRemoteAttendance) {
            return <Redirect to='/unauthorized'/>
        }
        const { session, ssmS, requesting, success, submissionFailed } = this.state;
        const { history, remoteAttendanceCount, pendingRemoteAttendanceRequestCount, graduationRequirements }= this.props;
        const { maxRemoteAttendance } = graduationRequirements;
        const allowRemoteAttendance = maxRemoteAttendance > ( remoteAttendanceCount + pendingRemoteAttendanceRequestCount );

        let buttonClass = classNames({
            'btn-loading': requesting,
            'btn-primary': true
        });

        return (
            <DocumentTitle title="Request Remote Attendance | Bootcamp Spot">
                <section data-component="remote-attendance-request">
                    <div className="row">
                        <div className="col-xs-12 col-gutter-lr padding-t-3">
                            <h1>Request Remote Attendance</h1>
                        </div>
                    </div>
                    {this.state.sessionLoading ? (
                        <div className="row">
                            <div className="col-xs-12">
                                <Loading/>
                            </div>
                        </div>
                    ) : success ? (
                        <div className="row">
                            <div className="col-xs-12">
                                <Alert type="success">
                                    <p>{this.state.success}</p>
                                    <button className="btn btn-edit btn-sm" onClick={() => {
                                        this.props.history.go(-1)
                                    }}>Continue</button>
                                </Alert>
                            </div>
                        </div>
                    ) : allowRemoteAttendance ? (
                        <div className="row">
                            <div className="col-xs-12 col-gutter-lr">
                                <p>
                                    <strong>Session:</strong> {session.session.name}
                                </p>
                                <p>
                                    <strong>Date:</strong> {moment(session.session.startTime).format('dddd - MMMM D, YYYY')}
                                </p>
                                <p>
                                    <strong>Time:</strong> {moment(session.session.startTime).format('h:mm A')} &mdash; {moment(session.session.endTime).format('h:mm A')} Local
                                </p>
                            </div>

                            <div className="col-xs-12 col-gutter-lr">
                                <p>Please read the following regarding your remote attendance request:</p>
                                <ol>
                                    <li>This course is designed to be an in person, collaborative class experience.</li>

                                    <li><p>Remote Attendance is a recommended option if you are unable to attend an in
                                        person class session because of an unforeseen issue.</p>
                                        You will only be approved as remote if you meet the following criteria:
                                        <ul className="bulleted">
                                            <li>You are able to attend live remotely for the entire class time.</li>
                                            <li>You are active via the slack class channel (ask questions, answer
                                                questions, etc.)
                                            </li>
                                            <li>You work to complete class activities and DM them to your TAs at the
                                                end
                                                of each activity
                                            </li>
                                        </ul>
                                        <p>Please note: Remote Attendance is not likely to be granted during project
                                            week
                                            as the work is highly collaborative and you are required to participate 100%
                                            in
                                            all group projects.

                                            If you are not active while remote, you will not be approved for remote
                                            attendance and you will be counted as absent for the class session
                                            requested.
                                        </p>
                                    </li>
                                    <li>While attending remotely please note:
                                        <ul className="bulleted">
                                            <li>Instructional staff support will be focused on students in class.
                                                Slack requests and activities submissions will be reviewed as time
                                                permits.
                                            </li>
                                            <li>There will be aspects of class that you will miss such as
                                                white-boarding activities, questions from students farther away from the
                                                microphone, etc.
                                            </li>
                                            <li>Please be aware that remote attendance is not always possible. If
                                                there are any technical issues with the class video, the instructor
                                                cannot stop class to troubleshoot. If for any reason the remote session
                                                cannot occur, you will be counted absent.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                            <div className="col-xs-12">
                                <Input
                                    label={`I understand that I'm only allowed ${maxRemoteAttendance} remote attendances on the class.`}
                                    type="checkbox"
                                    id="agree"
                                    value={this.state.checked}
                                    onChange={this.handleCheck}
                                    tabIndex={0}
                                />

                                <div className="margin-t-5">
                                    <button className={buttonClass} onClick={this.handleSubmit}
                                            disabled={this.state.requesting || !this.state.checked}>Request Remote
                                        Attendance
                                    </button>
                                    <button className="btn-delete btn-tertiary" onClick={history.goBack}>Cancel</button>
                                </div>

                            </div>
                        </div>
                    ) : submissionFailed ? 
                        <Alert type="warn">{submissionFailed}</Alert> 
                    : <React.Fragment>
                        {remoteAllowanceWarn(ssmS)}
                        <button className="btn-delete btn-tertiary" onClick={history.goBack}>Cancel</button>
                    </React.Fragment>
                    }
                </section>
            </DocumentTitle>
        )
    }
}

export const REMOTEATTENDANCEREQUEST = RemoteAttendanceRequest;
export default connect(mapStateToProps)(RemoteAttendanceRequest);
