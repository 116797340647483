import validator from "validator";

export const urlErrorCheck = (url = "") => {
    if (!url) return "URL field can not be left blank. Please make sure to include the https.";
    if (validator.isURL(url) && !validator.isURL(url, { protocols: ['https'], require_protocol: true }))
        return "URL must be for a secure site. Please make sure to include the https.";
    if (!validator.isURL(url)) return "URL is not a valid URL. Please make sure to include the https.";
    return "";
};

export const urlValidator = url => {
    return (url || "").length > 0 && validator.isURL(url) && url.includes("https");
};
