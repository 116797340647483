import React, {Component} from 'react';
import Icon from "../Icon";
import Link from "react-router-dom/Link";
import {config} from "../../config/privacyPolicyUpdatedBanner"

class PrivacyPolicyUpdatedBanner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            display: !localStorage.getItem('displayPrivacyPolicyBanner')
        };
    }

    handleDismiss(){
        this.setState({
            display: false
        });
        localStorage.setItem('displayPrivacyPolicyBanner','no');
    };

    render() {
        const showPrivacyPolicyBanner = config.isBannerAvailable && this.state.display;
        return showPrivacyPolicyBanner && (
            <div className="row bcs-privacy-policy-banner">
                <div className="col-xs-12 margin-0 padding-r-4-xs padding-r-2-md padding-t-0 padding-b-0 text-right bcs-privacy-policy-banner-close">
                    <Icon
                        icon="window-close"
                        options="text-white"
                        alt="close banner"
                        onClickHandler={()=> this.handleDismiss()}
                        onKeyPress={()=> this.handleDismiss()}
                        role="button"
                        aria-label="close banner"
                        tabIndex="0"
                    />
                </div>
                <div className="col-xs-12 col-md-9 padding-b-3-md padding-b-0-xs">
                    <span>We have updated our
                        <Link to="/privacy-policy"
                              className="text-white text-bold no-underline"
                              role="button"
                              tabIndex="0"
                              alt="View our updated privacy policy"
                              aria-label="View our updated privacy policy"> privacy policy </Link>
                        to better reflect how we collect, use, and share your information
                    </span>
                </div>
                <div className="col-xs-12 col-md-3 text-right-md text-center padding-l-6 padding-r-6 padding-t-4-md padding-t-2-xs">
                <Link
                    to="/privacy-policy"
                    className="btn-edit btn-small btn-centered margin-b-0 margin-r-0 margin-t-2"
                    role="button"
                    tabIndex="0"
                    alt="View our updated privacy policy"
                    aria-label="View our updated privacy policy">
                    View
                </Link>
                </div>
            </div>
        )
    }
}

export default PrivacyPolicyUpdatedBanner;
