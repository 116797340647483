export const baseUrlConfig = {
    'dev': 'https://bcs-next-dev.bootcampspot-v2.com',
    //'qa': 'https://bcs-next-qa.bootcampspot-v2.com',
    'qa': 'http://bcs-api-qa.us-east-1.elasticbeanstalk.com',
    'uat': 'https://uat.bootcampspot-v2.com',
    'staging': 'https://bcs-next-staging.bootcampspot-v2.com',
    'prod': 'https://bootcampspot-v2.com'
}

export const jsonContentTypeHeader = {'Content-Type': 'application/json'}
