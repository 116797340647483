import React, {Component} from 'react'
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {handleNewRoute} from "../../actions/backButtonActions";
import {toInitialSpace} from "../../helpers/toCase"

const mapStateToProps = (state) => {
  return {
      routeHistory: state.backButton.routeHistory
  }
};

class BackButton extends Component {

    componentDidMount() {
      this.props.history.listen(location => {
        const currentPathname = location.pathname;
        this.props.dispatch(handleNewRoute(currentPathname));
      });
    }

    renderLinkText = (previousRoute) => {
      const baseRouteText = toInitialSpace(previousRoute.split( '/' )[1])
      if (this.props.customText) {
        return this.props.customText
      } else {
        return (
          <React.Fragment>
            Return to
            <span className="text-underline"> {baseRouteText}</span>
          </React.Fragment>
        )
      }
    }

    render() {
      const previousRoute = this.props.routeHistory[this.props.routeHistory.length -2]
      const altText = `Back to ${previousRoute}`
      const headerBackButton = this.props.headerBackButton
      return (
        <Link 
          className={ headerBackButton ? 'header-back-container' : ''}
          title={altText} 
          to={previousRoute}>
          { headerBackButton ? (
            <i
              className={'fal fa-chevron-left'}
              alt={altText}
              title={altText}
              onClick={this.props.onClickHandler ? this.props.onClickHandler : null}
              onKeyPress={this.props.onKeyPress ? this.props.onKeyPress : null}
              tabIndex={this.props.tabIndex ? this.props.tabIndex : null}
              aria-label={altText}
              role={this.props.role ? this.props.role : null}
              aria-hidden={this.props.ariaHidden ? this.props.ariaHidden : null}
              aria-expanded={this.props.ariaExpanded}
              style={this.props.style ? this.props.style : null}
            >
          </i>
          ) : (
            this.renderLinkText(previousRoute)
          ) }
        </Link>
      );
    }
  }

  export default connect(mapStateToProps)(BackButton);