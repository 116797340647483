import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';

import FormComponent from '../../components/FormComponent/FormComponent';
import { SurveyName } from '../../constants/surveys';
import {lockActionTypeMessages} from '../../constants/messages'
import {lockRouteForUser, releaseRouteForUser} from "../../actions/routeLockActions";

const mapStateToProps = (state, ownProps) => {
    return {
        dispatch: state.dispatch,
        location: state.location,
        ...ownProps
    }
};

class Agreement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formIsVisible: false
        };
        this.props.removeNav();
    }

    componentDidMount () {
        const {lockRouteForUser, location} = this.props;
        lockRouteForUser(location.pathname,lockActionTypeMessages.LICENSE_AGREEMENT);
    }

    onSuccess = () => {
        this.props.releaseRouteForUser();
        this.props.history.replace('/agreementsuccess');
    };

    render() {
        return (
            <FormComponent {...this.props}
                           formName={SurveyName.Agreement}
                           showDbOnlyError={true}
                           noun={"agreement"}
                           loadingErrorDescr={"terms and conditions"}
                           onSuccessCallback={this.onSuccess} />
        );
    }
}

Agreement.propTypes = {
    releaseRouteForUser: PropTypes.func.isRequired,
    lockRouteForUser: PropTypes.func.isRequired
};

export const AGREEMENT = Agreement;

export default withRouter(connect(mapStateToProps,{releaseRouteForUser,lockRouteForUser})(Agreement));
