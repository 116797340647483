import React from 'react';
import PropTypes from 'prop-types';

// ExternalButtonLink IS A REUSEABLE COMPONENT. Please make sure that you pass the right props in or unexpected behavior will occur.

const ExternalButtonLink = (props) => (
    <a href={props.to}
       target={'_blank'}
       title={ props.title ? props.title : null }
       rel="noopener noreferrer"
       aria-describedby="button goes to external site, and opens in a new window"
       className={ props.className ? 'btn ' + props.className : 'btn' }>
         {props.text}
    </a>
)

ExternalButtonLink.propTypes = {
    to: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string,
    className: PropTypes.string
}

export default ExternalButtonLink
