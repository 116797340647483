import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
}

// FIXME: refactor to a functional component that gets passed history as a prop.
class CareerServicesCurriculumSuccess extends Component {
    comebackToWall = () => {
        this.props.history.replace('/')
    }

    render() {
        return (
            <div className="context-success">
                <h1>Thank you!</h1>
                <p>Your Career Service Curriculum has been successfully sent.</p>
                <button className="btn-small" onClick={this.comebackToWall}>Continue</button>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(CareerServicesCurriculumSuccess));
