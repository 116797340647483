import axios from 'axios'
import {pending, fulfilled, rejected} from '../helpers/asyncActionGenerator'
import {courseworkActionTypes} from '../constants/actionTypes'

function fetchCourseworks(enrollmentId) {
    return async (dispatch) => {
        dispatch(pending(courseworkActionTypes.GET_ASSIGNMENTS));
        try {
            let payload = {enrollmentId: enrollmentId};
            let axiosResult = await axios.post("/broker/coursework", payload, {headers: {'Content-Type': 'application/json',}});
            return dispatch(fulfilled(courseworkActionTypes.GET_ASSIGNMENTS, axiosResult.data));
        } catch (error) {
            return dispatch(rejected(courseworkActionTypes.GET_ASSIGNMENTS, error));
        }
    }
}

export const fetchSingleCoursework = async (assignmentId) => {
    let payload = {assignmentId: assignmentId};
    let axiosResult = await axios.post("/broker/courseworkDetail", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
};

async function createDueDateExtension(assignmentId, bufferDays) {
    let payload = {
        assignmentId: assignmentId,
        bufferDays: bufferDays
    }
    let axiosResult = await axios.post("/broker/extendAssignment", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

async function updateDueDateExtension(assignmentId, bufferDays) {
    let payload = {
        assignmentId: assignmentId,
        bufferDays: bufferDays
    }
    let axiosResult = await axios.post("/broker/extendAssignment", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

async function deleteDueDateExtension(assignmentId, bufferDays) {
    let payload = {
        assignmentId: assignmentId,
        bufferDays: bufferDays
    }
    let axiosResult = await axios.post("/broker/extendAssignment", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

export const createSubmission = async (submission, submissionUrlList) => {
    let payload = {
        submission: submission,
        submissionUrlList: submissionUrlList
    };
    let axiosResult = await axios.post("/broker/createSubmission", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
};

export const updateSubmission = async (submission, submissionUrlList) => {
    let payload = {
        submission: submission,
        submissionUrlList: submissionUrlList
    };
    let axiosResult = await axios.post("/broker/updateSubmission", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
};

async function fetchSubmission(assignmentId, studentId) {
    let payload = {
        assignmentId: assignmentId,
        studentId: studentId
    }
    let axiosResult = await axios.post("/broker/grade", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

async function createSubmissionGrade(submissionId, grade) {
    let payload = {
        id: null,
        submissionId: submissionId,
        grade: grade
    }
    await axios.post("/broker/createSubmissionGrade", payload, {headers: {'Content-Type': 'application/json',}});
}

async function updateSubmissionGrade(submissionId, grade, gradeId) {
    let payload = {
        id: gradeId,
        submissionId: submissionId,
        grade: grade
    }
    await axios.post("/broker/updateSubmissionGrade", payload, {headers: {'Content-Type': 'application/json',}});
}

async function setCareerFeedbackStatus(submissionId, statusId) {
    let payload = {
        submissionId: submissionId,
        statusId: statusId
    }
    await axios.post("/broker/setCareerFeedbackStatus", payload, {headers: {'Content-Type': 'application/json',}});
}

async function addSubmissionComment(payload) {
    return await axios.post("/broker/createSubmissionComment", payload, {headers: {'Content-Type': 'application/json',}});
}

async function updateSubmissionComment(payload) {
    return await axios.post("/broker/updateSubmissionComment", payload, {headers: {'Content-Type': 'application/json',}});
}

async function deleteSubmissionComment(payload) {
    return await axios.post("/broker/deleteSubmissionComment", payload, {headers: {'Content-Type': 'application/json',}});
}

async function csSurveyStatus(enrollmentId) {
    return await axios.post("/broker/csSurveyStatus", {enrollmentId: enrollmentId}, {headers: {'Content-Type': 'application/json',}})
}

export const courseworkActions = {
    fetchCourseworks,
    fetchSingleCoursework,
    createDueDateExtension,
    updateDueDateExtension,
    deleteDueDateExtension,
    createSubmission,
    updateSubmission,
    fetchSubmission,
    createSubmissionGrade,
    updateSubmissionGrade,
    setCareerFeedbackStatus,
    addSubmissionComment,
    updateSubmissionComment,
    deleteSubmissionComment,
    csSurveyStatus
};
