import {
    userActionTypes
} from '../constants/actionTypes'
import {
    pending,
    rejected,
    fulfilled
} from '../helpers/asyncStatusGenerator';


const initialState = {
    errors: {
        loginError: null,
        forgotPasswordError: null,
        resetPasswordError: null,
        getEnrollmentInfoError: null
    },
    loggingIn: false,
    loginSuccess: null,
    forgotPasswordSuccess: null,
    mfaError: false,
    mfaGenerationError: false,
    mfaGenerationData: null,
    mfaGenerationLoading: false,
    resetPasswordSuccess: null,
    data: {},
    profile: {},
};

export const userReducer = (state=initialState, action) => {
    switch (action.type) {
        case pending(userActionTypes.USER_LOGIN):

            return {
                ...state,
                loggingIn: true,
                errors: {
                    loginError: null
                }
            };

        case rejected(userActionTypes.USER_LOGIN):

            return {
                ...state,
                loggingIn: false,
                errors: {
                    loginError: action.payload
                }
            };

        case fulfilled(userActionTypes.USER_LOGIN):
            let data = Object.assign({}, action.payload);
            return {
                ...state,
                data,
                errors: {
                    loginError: null,
                    forgotPasswordError: null,
                    resetPasswordError: null,
                },
                loginSuccess: true
            };
        case pending(userActionTypes.USER_FORGOT_PASSWORD_REQUEST):

            return {
                ...state,
                forgotPasswordSuccess: action.payload,
                errors: {
                    forgotPasswordError: null
                }
            };

        case rejected(userActionTypes.USER_FORGOT_PASSWORD_REQUEST):
            return {
                ...state,
                forgotPasswordSuccess: null,
                errors: {
                    forgotPasswordError: action.payload
                }
            };

        case fulfilled(userActionTypes.USER_FORGOT_PASSWORD_REQUEST):

            return {
                ...state,
                forgotPasswordSuccess: true,
                errors: {
                    loginError: null,
                    forgotPasswordError: null,
                    resetPasswordError: null,
                }
            };

        case rejected(userActionTypes.USER_RESET_PASSWORD):
            return {
                ...state,
                errors: {
                    resetPasswordError: action.payload
                }
            };

        case fulfilled(userActionTypes.USER_RESET_PASSWORD):
            return {
                ...state,
                errors: {
                    loginError: null,
                    forgotPasswordError: null,
                    resetPasswordError: null,
                },
                resetPasswordSuccess: true
            };

        case fulfilled(userActionTypes.USER_GET_DATA):
            return {
                ...state,
                profile: action.payload.userAccount,
            }

        case fulfilled(userActionTypes.USER_GET_INFO):
            return {
                ...state,
                profile: action.payload.userInfo,
                singleUniversity: action.payload.singleUniversity,
                singleCohort: action.payload.singleCohort,
                singleEnrollment: action.payload.singleEnrollment
            }

        case fulfilled(userActionTypes.USER_GET_UNIVERSITIES):
            return {
                ...state,
                universities: action.payload
            }

        case fulfilled(userActionTypes.USER_GET_COHORTS):
            return {
                ...state,
                cohorts: action.payload
            }
        case userActionTypes.USER_RESET_COHORTS:
            return {
                ...state,
                enrollmentInfo: null,
                enrollments: null,
                cohorts: null
            }
        case fulfilled(userActionTypes.USER_GET_ENROLLMENTS):
            return {
                ...state,
                enrollments: action.payload
            }
        case fulfilled(userActionTypes.USER_GET_ENROLLMENT_INFO):
            return {
                ...state,
                enrollmentInfo: action.payload,
            }
        case rejected(userActionTypes.USER_GET_ENROLLMENT_INFO):
            return {
                ...state,
                errors: {
                    ...state.errors,
                    getEnrollmentInfoError: action.payload
                },
            }
        case userActionTypes.USER_ACTIVE_FLAG:
            return {
                ...state,
                data: {
                    active: action.payload
                }
            };
        case userActionTypes.USER_CLEAR_ERROR:
            return {
                ...state,
                errors: {
                    loginError: null,
                    forgotPasswordError: null,
                    resetPasswordError: null,
                },
                loginSuccess: null,
                forgotPasswordSuccess: null,
                resetPasswordSuccess: null
            };
        case fulfilled(userActionTypes.USER_CACHEBUST_AVATAR_URL):
            const dupeState = {...state}
            dupeState.profile.nexusAvatarUrl += action.payload.cacheBustString
            return { ...dupeState }
        case rejected(userActionTypes.USER_MFA_ERROR):
            return {
                ...state,
                mfaError: action.payload
            };
        case pending(userActionTypes.USER_GENERATE_MFA):
            return {
                ...state,
                mfaGenerationError: false,
                mfaGenerationData: null,
                mfaGenerationLoading: true
            };
        case rejected(userActionTypes.USER_GENERATE_MFA):
            return {
                ...state,
                mfaGenerationError: action.payload,
                mfaGenerationLoading: false
            };
        case fulfilled(userActionTypes.USER_GENERATE_MFA):
            return {
                ...state,
                mfaGenerationData: action.payload,
                mfaGenerationLoading: false
            };
        default:
            return state
    }
}


