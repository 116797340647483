import axios from 'axios';

export async function getContacts(courseId) {
    let contacts = {};
    try {
        let request = await axios.post("/broker/classContacts", {courseId: courseId})
        contacts.getContactsSuccess = true
        contacts.data = request.data
    } catch(err) {
        contacts.getContactsSuccess = false
        contacts.error = err
    }
    return contacts
}
