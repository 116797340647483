import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import DocumentTitle from 'react-document-title';

import UniversityLogo from '../../components/UniversityLogo/';
import {Table, TBody, THead, TR, TD, TH} from '../../components/Table';

import {toLowerHyphen} from '../../helpers/toCase.js';
import {setActiveEnrollment, resetEnrollmentInfo} from "../../actions/enrollmentsActions";
import {userActions} from "../../actions/userActions";
import {alphabetize} from "../../helpers/coursesFormatter";

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

class Universities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coursesByUniversity: []
        };

        this.props.removeNav();
    }

    componentDidMount() {
        const {user} = this.props
        if (user.universities.length > 0) {
            const coursesByUniversity = user.universities;
            this.setState({
                coursesByUniversity: alphabetize(coursesByUniversity),
            });
            this.redirectUser(user);
        }
    }

    redirectUser = (user) => {
        if (user.singleUniversity) {
            this.selectUni(user.singleUniversity)
        } else if (user.singleCohort) {
            this.selectCohort(user.singleCohort)
        } else if (user.singleEnrollment) {
            this.setEnrollment(user.singleEnrollment)
        }
    }

    selectUni = (university) => {
        const {dispatch} = this.props;
        dispatch(userActions.getCohorts(university.id, this.props.user.data.authToken));
        const universityExt = toLowerHyphen(university.name);
        this.props.history.push(`/universities/${universityExt}`)
    }

    selectCohort = (cohort) => {
        const {dispatch} = this.props;
        const universityNameURL = toLowerHyphen(cohort.program.university.name);
        const cohortExt = toLowerHyphen(cohort.name);
        dispatch(userActions.getEnrollments(cohort.id, this.props.user.data.authToken));
        this.props.history.push(`/universities/${universityNameURL}/cohorts/${cohortExt}`)
    }

    setEnrollment = (classObj) => {
        const {dispatch} = this.props;
        dispatch(userActions.getEnrollmentInfo(classObj.id, this.props.user.data.authToken));
        dispatch(setActiveEnrollment(classObj));
        dispatch(resetEnrollmentInfo());
        this.props.history.push(`/`);
    }

    renderUniSelector = () => {
        const uniImgStyles = {
            position: "absolute",
            top: "0.6rem",
            left: "1rem",
            maxWidth: "2rem",
            maxHeight: "2rem",
            marginRight: "1rem"
        }

        const uniCellStyles = {
            position: "relative"
        }

        const disabledLink = {
            pointerEvents: "none",
            color: "#4A4A4A",
            borderBottom: "none"
        }

        const tableHeadings = [
            'School',
            'Cohorts',
            'Classes'
        ]

        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <p>
                            <Link to='/universities' style={disabledLink}>Universities</Link>
                        </p>
                        <h1>Select a School</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Table>
                            <THead>
                                <TR>
                                    <TH col="4">{tableHeadings[0]}</TH>
                                    <TH col="4">{tableHeadings[1]}</TH>
                                    <TH col="4">{tableHeadings[2]}</TH>
                                </TR>
                            </THead>
                            <TBody>
                            {this.state.coursesByUniversity.map((course, index) => {
                                return (
                                    <TR key={index}
                                        role="button"
                                        onClick={() => this.selectUni(course)}
                                    >
                                        <TD col="4" heading={tableHeadings[0]} style={uniCellStyles} className="padding-l-3-xs padding-l-6-md">
                                            <UniversityLogo
                                                imgSrc={course.logoUrl}
                                                inlineStyles={uniImgStyles}
                                                imgAlt={course.name}
                                                options="hide-xs show-md"
                                            />
                                            <span className="padding-l-0-xs padding-l-3-md">{course.name}</span>
                                        </TD>
                                        <TD col="4" heading={tableHeadings[1]}>{course.cohortCount}</TD>
                                        <TD col="4" heading={tableHeadings[2]}>{course.courseCount}</TD>
                                    </TR>
                                )
                            })}
                            </TBody>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }

    render() {

        return (
            <DocumentTitle title="Select School | Bootcamp Spot">
                <section>
                    {this.state.coursesByUniversity.length ? (
                        this.renderUniSelector()
                    ) : ''}
                </section>
            </DocumentTitle>
        )
    }
};

export const UNIVERSITIES = Universities;

export default connect(mapStateToProps)(Universities);
