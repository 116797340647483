export function handleAddJoinSession(virtualClassroomCardObject) {
    let relatedSessions = [...virtualClassroomCardObject.state.relatedSessions];
    let index = relatedSessions.findIndex((e) => e.id === virtualClassroomCardObject.state.selectedSession);
    virtualClassroomCardObject.setState(prevState => (
        {
            joinSession: [
                ...prevState.joinSession,
                virtualClassroomCardObject.state.selectedSession
            ],
            relatedSessions: [
                ...prevState.relatedSessions.slice(0, index),
                ...prevState.relatedSessions.slice(index + 1)
            ]
        }), () => updateSelectedSession(virtualClassroomCardObject));
};

export function handleRemoveJoinSession(sessionId, virtualClassroomCardObject) {
    let relatedSessionsProvider = virtualClassroomCardObject.props.dashboard.relatedInstructionalSessions;
    let joinSessions = [...virtualClassroomCardObject.state.joinSession];
    let index = joinSessions.indexOf(sessionId);
    if (index !== -1) {
        let sessionToAdd = relatedSessionsProvider.find((e) => e.id === sessionId);
        virtualClassroomCardObject.setState(prevState => ({
            joinSession: [
                ...prevState.joinSession.slice(0,index),
                ...prevState.joinSession.slice(index + 1)
            ],
            relatedSessions: [
                ...prevState.relatedSessions,
                sessionToAdd
            ]
        }), () => updateSelectedSession(virtualClassroomCardObject));
    }
};

export function updateSelectedSession(virtualClassroomCardObject) {
    virtualClassroomCardObject.setState( prevState => {
        if (prevState.relatedSessions.length)
            return {
                selectedSession: prevState.relatedSessions[0].id
            }
    });
};

export function onChange (event, virtualClassroomCardObject) {
    let newValue = parseInt(event.target.value, 10);
    virtualClassroomCardObject.setState({selectedSession: newValue});
};
