import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty'

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
}

// FIXME: refactor to a functional component that gets passed history as a prop.
class CaseSuccess extends Component {
    componentWillMount(){
        const {enrollmentInfo} = this.props.user
        if(isEmpty(enrollmentInfo)){
            this.props.removeNav()
        }
    }

    comebackToWall = () => {
        this.props.history.push('/')
    }

    render() {
        return (
            <div className="context-success">
                <h1>Thank you!</h1>
                <p>Your message has been successfully sent.</p>
                <p>Your support team will be in touch with you soon.</p>
                <button className="btn-small" onClick={this.comebackToWall}>Continue</button>
            </div>
        )
    }
}


export const CASESUCCESS = CaseSuccess;

export default withRouter(connect(mapStateToProps)(CaseSuccess));
