import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';

import {googleMapsApiKey} from '../../config';
import Tags from "../Tags";

class ReactSelectPlaces extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: ''
        };
    }

    handleInputChange = e => {
        const { value } = e.target;
        this.setState(prevState => {
            return {
                search: value
            };
        });
    };

    handleSelectSuggest = geocodedPrediction => {
        const location = geocodedPrediction.formatted_address;
        const {placelist} = this.props;
        this.setState({search: ""});
        if (!placelist.includes(location)) {
            placelist.push(location);
            this.props.onSelect(placelist);
        }
    };

    handleOnRemoveLocation = location => {
        const {placelist} = this.props;
        const locations = [];
        for (let i = 0; i < placelist.length; i++) {
            if (placelist[i] !== location) {
                locations.push(placelist[i]);
            }
        }
        this.props.onSelect(locations);
    };

    render() {
        const { search } = this.state;
        const { autocompletionRequest } = this.props;
        let searchInput = '';

        if (search.length > 2) {
            searchInput = search;
        }

        return(
            <React.Fragment>
                <ReactGoogleMapLoader
                    params={{
                        key: googleMapsApiKey,
                        libraries: "places,geocode",
                    }}
                    render={googleMaps =>
                        googleMaps && (
                            <ReactGooglePlacesSuggest
                                googleMaps={googleMaps}
                                autocompletionRequest={{
                                    input: searchInput,
                                    types: autocompletionRequest.types
                                }}
                                onSelectSuggest={this.handleSelectSuggest} >
                                <input
                                    type="text"
                                    value={search}
                                    placeholder="Search a city"
                                    onChange={this.handleInputChange}
                                />

                            </ReactGooglePlacesSuggest>
                        )
                    }
                />
                <Tags tags={this.props.placelist} onRemove={this.handleOnRemoveLocation} />
            </React.Fragment>
        )
    }
}

ReactSelectPlaces.propTypes = {
    autocompletionRequest: PropTypes.shape({
        bounds: PropTypes.object,
        componentRestrictions: PropTypes.shape({
            country: PropTypes.string || PropTypes.array
        }),
        location: PropTypes.object,
        offset: PropTypes.number,
        radius: PropTypes.number,
        types: PropTypes.array
    })
};

export default ReactSelectPlaces;
