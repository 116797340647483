import React from 'react'
import Icon from '../../components/Icon';
import {getFormattedDateString} from '../../helpers/dateTime'
import {assignmentContextTypes} from "../../constants/dataTypes";

const StandardCourseworkSubmit = (props) => {
    const {submittedOrGradedNotice, submitButton, disallowSubmedit, singleUrl, urlList, courseworkNotes, goBack, handleChange, handleUrlAddition, coursework, renderUrlList} = props
    return (
        <section className="page" data-component="coursework-submit">
            <div className="col-xs-12">
                <h1>{coursework.context.contextCode === assignmentContextTypes.CAREER ? 'Mark Milestone Complete' : 'Submit Your Coursework'}</h1>
                {coursework.context.contextCode === assignmentContextTypes.CAREER
                && <p className="font-sm"><em>This Milestone is <strong>Required</strong> if you are seeking career
                    assistance.</em></p>
                }
                <p><strong>Title: </strong>{coursework.title}</p>
                {coursework.bufferDays > 0 ? (
                    <p><strong>Extended Due Date: </strong>{getFormattedDateString(coursework.effectiveDueDate)}</p>
                ) : (
                    <p><strong>Due Date: </strong>{getFormattedDateString(coursework.dueDate)}</p>
                )}
                <p><strong>Status: </strong>{submittedOrGradedNotice}</p>
                <h2 className="margin-t-6">Current Submission URLs:</h2>
                <ul>
                    {renderUrlList()}
                </ul>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-8">
                    <label htmlFor="url" id="urlLabel1">Add URLs</label>

                    <input type="url" disabled={disallowSubmedit}
                           onChange={handleChange}
                           value={singleUrl}
                           name="url"
                           id="singleUrl"
                           aria-labelledby="urlLabel1"
                           placeholder="URL to access your work"

                    />
                    <button disabled={disallowSubmedit}
                            className={`btn btn-sm ${disallowSubmedit ? 'btn-disabled' : 'btn-create'}`}
                            onClick={handleUrlAddition}
                    >

                        <Icon className='margin-r-1' icon="plus-square"/>

                        {urlList && urlList.length ? (<span> Add Another URL</span>) : (
                            <span> Add URL</span>)}
                    </button>
                    <label htmlFor="notes" id="courseworkNotesLabel">Notes</label>
                    <textarea disabled={disallowSubmedit}
                              name="notes"
                              onChange={handleChange}
                              value={courseworkNotes}
                              id="courseworkNotes"
                              placeholder="How was this assignment? Too hard? Too easy? Attach any notes for your submission here"
                              aria-labelledby="notesLabel">
                        </textarea>
                    {submitButton}
                    <button className="btn-delete btn-tertiary" onClick={() => goBack()}>Cancel, Go Back</button>
                </div>
            </div>
        </section>
    )
};

export default StandardCourseworkSubmit;
