import {roleTypes} from '../constants/dataTypes';

export function isRole(roleSet, role) {
    return roleSet.filter(r => r === role).length > 0
}

export function isStudent(role) {
    return isRole([roleTypes.STUDENT], role)
}

export function isInstructor(role) {
    return isRole([roleTypes.INSTRUCTOR], role)
}

export function isTA(role) {
    return isRole([roleTypes.TA], role)
}

export function isCD(role) {
    return isRole([roleTypes.CD], role)
}

export function isSSM(role) {
    return isRole([roleTypes.SSM], role)
}

export function isAdmin(role) {
    return isRole([roleTypes.ADMIN], role)
}

export function isCM(role) {
    return isRole([roleTypes.CM], role)
}
