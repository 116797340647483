import {
    pending,
    rejected,
    fulfilled
} from '../helpers/asyncStatusGenerator';
import {
  careerServicesActionTypes,
  universalActionTypes,
} from "../constants/actionTypes";

const initialState = {
    careerServicesLoading: true,
    nextDueAssignment: {},
    upcomingSession: {},
    calendarSessions: [],
    allCareerAssignments: []
}

export const careerServicesReducer = (state=initialState, action) => {
    switch (action.type) {
        case universalActionTypes.RESET_ENROLLMENT_INFO:
            return initialState;
        case pending(careerServicesActionTypes.FETCH_CAREER_SERVICES):
            return {
                ...state,
                careerServicesLoading: true,
            };
        case fulfilled(careerServicesActionTypes.FETCH_CAREER_SERVICES):
            return {
                ...state,
                careerServicesLoading: false,
                nextDueAssignment: action.payload.nextDueAssignment,
                upcomingSession: action.payload.upcomingSession,
                calendarSessions: action.payload.calendarSessions,
                allCareerAssignments: action.payload.allCareerAssignments
            };
        case rejected(careerServicesActionTypes.FETCH_CAREER_SERVICES):
            return {
                ...state,
                careerServicesLoading: false
            };
        default:
            return state;
    }
}
