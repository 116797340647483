import {Component} from "react";
import DocumentTitle from "react-document-title";
import {connect} from 'react-redux';
import React from "react";
import {getRawPayload} from "../../actions/openDocsActions";
import OpenDocsApiResponse from "../../components/OpenDocsApiResponse";
import OpenDocsHeader from "../../components/OpenDocsHeader";
import Input from "../../components/Input";


const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps,
        openDocs: state.openDocs,
        authToken: state.user.data.authToken,
    }
};

class ApiDocsAssignments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tryItUrl: '',
            enrollmentId: '',
        };
    }

    componentWillReceiveProps(newProps) {
        if (typeof newProps.openDocs !== "undefined") {
            const newOpenDocs = newProps.openDocs;
            if (newOpenDocs && newOpenDocs[this.state.tryItUrl]) {
                this.setState({
                    payloadRsp: newOpenDocs[this.state.tryItUrl].payload,
                    headersRsp: newOpenDocs[this.state.tryItUrl].headers,
                    status: newOpenDocs[this.state.tryItUrl].status,
                });
            }
        }
    }

    handleChange(event) {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        const obj = {};
        obj[fieldName] = fieldValue;
        this.setState(obj)
    }

    handleOnClick() {
        const {dispatch, authToken} = this.props;
        const ep = `assignments`;
        this.setState({
            tryItUrl: ep
        });

        dispatch(getRawPayload(ep, {enrollmentId: parseInt(this.state.enrollmentId, 10)}, authToken));
    }

    render() {
        const {payloadRsp, headersRsp, status} = this.state;

        return (
            <DocumentTitle title="API Access - Assignments | Bootcamp Spot">
                <section>
                    <OpenDocsHeader
                        endpoint={"/api/instructor/v1/assignments"}
                        description={"Lists all assignments."}
                        type={"POST"}
                        headers={[{name: "Content-Type", value: "application/json", description: ""},
                            {name: "authToken", value: "<valid token>", description: "The token generated via /login"}]}
                        exampleBody={{enrollmentId: 1}}
                        exampleResponses={[
                            {
                                code: 200,
                                body: {
                                    "currentWeekAssignments": [
                                        {
                                            "id": 17448,
                                            "courseId": 594,
                                            "contextId": 1,
                                            "assignmentDate": "2019-03-09T01:00:00-03:00",
                                            "dueDate": "2019-03-21T00:59:59-03:00",
                                            "bufferDays": 0,
                                            "effectiveDueDate": "2019-03-21T00:59:59-03:00",
                                            "title": "Updated Portfolio Page",
                                            "required": true,
                                            "requiredForGraduation": false,
                                            "assignmentHeader": {
                                                "id": 17448,
                                                "assignmentId": 17448,
                                                "header": "Updated Portfolio Page"
                                            },
                                            "assignmentContent": null,
                                            "assignmentHours": null,
                                            "context": {
                                                "id": 1,
                                                "contextCode": "academic",
                                                "name": "Academic"
                                            },
                                            "academicAssignment": {
                                                "id": 17448,
                                                "prework": false
                                            },
                                            "careerAssignment": null,
                                            "submittedStudentCount": 0,
                                            "gradedStudentCount": 0
                                        }
                                    ],
                                    "courseStudentsCount": 6,
                                    "calendarAssignments": [
                                        {
                                            "id": 17451,
                                            "courseId": 594,
                                            "contextId": 1,
                                            "assignmentDate": "2018-04-17T01:00:00-03:00",
                                            "dueDate": "2018-08-28T00:59:00-03:00",
                                            "bufferDays": 0,
                                            "effectiveDueDate": "2018-08-28T00:59:00-03:00",
                                            "title": "0: Web Development Prework",
                                            "required": false,
                                            "requiredForGraduation": false,
                                            "assignmentHeader": {
                                                "id": 17451,
                                                "assignmentId": 17451,
                                                "header": "0: Web Development Prework"
                                            },
                                            "assignmentContent": {
                                                "id": 17451,
                                                "assignmentId": 17451,
                                                "content": "https://www.gitbook.com/example-book"
                                            },
                                            "assignmentHours": null,
                                            "context": {
                                                "id": 1,
                                                "contextCode": "academic",
                                                "name": "Academic"
                                            },
                                            "year": 0,
                                            "month": 0,
                                            "eventType": {
                                                "code": "prework",
                                                "name": "Pre-Work"
                                            }
                                        }
                                    ]
                                },
                                rows: 15,
                                description: "Successful response."
                            },
                            {
                                code: 401,
                                body: null,
                                rows: 1,
                                description: "A response with a 401 Unauthorized status means that the logged in user does not have access to the specified enrollmentId information."
                            }
                        ]}
                    />

                    <div className="row">
                        <div className="col-xs-12">
                            <h1 className={"border-bottom"}>Try it out</h1>

                            <Input
                                label="enrollmentId"
                                type="number"
                                id="enrollmentId"
                                placeholder="<enrollmentId>"
                                helper="Fetch information for this enrollment Id"
                                aria-required={true}
                                onChange={this.handleChange.bind(this)}
                            />
                            <button onClick={this.handleOnClick.bind(this)}>Submit</button>
                        </div>
                    </div>

                    <OpenDocsApiResponse
                        headers={headersRsp}
                        payload={payloadRsp}
                        status={status}
                    />

                </section>
            </DocumentTitle>
        )
    }
};

export default connect(mapStateToProps)(ApiDocsAssignments);
