import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { RemoteAttendanceRequestManager } from '../../components/RemoteAttendanceRequest/Manager/index';


const mapStateToProps = (state, ownProps) => {
    return{
        ...state,
        ...ownProps
    }
}

export class RemoteAttendanceRequestManagerTab extends Component {

    constructor(props) {
        super(props);
        if (this.props.removeNav) {
            this.props.removeNav()
        }
        this.state = {
            roles: [],
            absenceRequests: [],
            absenceRequestOrg: [],
            absenceRequestsLoading: true
        }
    }

    componentDidMount() {

        if (!this.props.user.profile.canManageAttendance) {
            this.props.history.push('/unauthorized');
        }
    }

    render() {
        return(
            <DocumentTitle title="Manage Remote Attendance Requests | Bootcamp Spot">
                <section className="page" data-component="remote-attendance-request-manager">
                    <RemoteAttendanceRequestManager
                        user={this.props.user}
                        allowedStaff={true}
                        roles={this.state.roles}
                        showAll={this.props.showAll}
                        generalManager={true}
                    />
                </section>
            </DocumentTitle>
        )
    }

}

export default connect(mapStateToProps)(RemoteAttendanceRequestManagerTab);
