import React from 'react';

const PanoptoVideoEdit = (props) => {
    const { editVideo, videoID, calledApiLoading, disabled } = props
    return(
        <button type='submit'
                disabled={calledApiLoading || disabled}
                className={`btn ${calledApiLoading ? 'btn-loading' : 'btn-edit'}`}
                onClick={(event) => editVideo(event, videoID)}
        >
                Edit Video 
        </button>
    )
}

export default PanoptoVideoEdit