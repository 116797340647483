import React, {Component} from 'react'
import {getFormattedDateString} from '../../helpers/dateTime'
import {courseButtonAndLinkGenerator} from '../../constants/careerConstants'
import {assignmentContextTypes} from "../../constants/dataTypes"
import {milestoneType2VarName} from "./helper"

class CareerAssignment extends Component {
    render() {
        const {
            submitCoursework, submittedOrGradedNotice, disallowSubmedit, courseworkNotes, goBack, submissionRequired,
            handleChange, coursework, submissionLoading, buttonDisabled, milestoneTypes, milestoneUrls
        } = this.props;
        const {buttonText, linkLabelChoices} = courseButtonAndLinkGenerator(submissionRequired, milestoneTypes);
        const submitButton = disallowSubmedit ?
            <button disabled='true' className={`btn-disabled grey`}>Submitted & Graded</button>
            : <button disabled={buttonDisabled}
                      className={`btn-primary ${submissionLoading ? 'btn-loading' : 'btn-submit'}`}
                      onClick={(e) => {submitCoursework(e, linkLabelChoices)}}>{buttonText}</button>;
        const careerSubmission = coursework.context.contextCode === assignmentContextTypes.CAREER;
        return (
            <section className="page" data-component="coursework-submit">
                <div className="row">
                    <div className="col-xs-12">
                        <h1>{careerSubmission ? 'Submit Milestone' : 'Submit Your Coursework'}</h1>
                        {submissionRequired
                        && <p className="milestone-required font-sm"><em>This Milestone is <strong>Required</strong> if you are seeking career
                            assistance.</em></p>
                        }
                        <p><strong>Title: </strong>{coursework.title}</p>
                        {coursework.bufferDays > 0 ? (
                            <p><strong>Extended Due Date: </strong>{getFormattedDateString(coursework.effectiveDueDate)}</p>
                        ) : (
                            <p><strong>Due Date: </strong>{getFormattedDateString(coursework.dueDate)}</p>
                        )}
                        <p><strong>Status: </strong>{submittedOrGradedNotice}</p>
                    </div>
                    <div className='col-xs-12 col-md-8'>
                        {milestoneUrls.map(
                            (milestone, index) => {
                                const milestoneId = "milestoneUrl" + milestoneType2VarName(milestone.name)
                                return (
                                    <React.Fragment key={index}>
                                        <label htmlFor="milestoneUrl" id={"milestoneUrl" + index}>Add {milestone.name}</label>
                                        <input
                                            type="url"
                                            disabled={disallowSubmedit}
                                            onChange={handleChange}
                                            name={milestoneId}
                                            value={milestone.url}
                                            id={milestoneId}
                                            aria-labelledby={"milestoneUrlLabel" + index}
                                            placeholder="URL to access your work"
                                        />
                                    </React.Fragment>
                                )
                            }
                        )}
                        <label htmlFor="notes" id="courseworkNotesLabel">Notes</label>
                        <textarea disabled={disallowSubmedit}
                                  name="notes"
                                  onChange={handleChange}
                                  value={courseworkNotes}
                                  id="courseworkNotes"
                                  placeholder="How was this assignment? Too hard? Too easy? Attach any notes for your submission here"
                                  aria-labelledby="notesLabel">

                        </textarea>
                        {submitButton}
                        <button className="btn-delete btn-tertiary" onClick={() => goBack()}>Cancel, Go Back</button>
                    </div>
                </div>
            </section>
        )
    };
}
export default CareerAssignment;
