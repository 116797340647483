import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Loading from '../../components/Loading';
import Alert from '../../components/Alert';
import ManageAttendance from '../../components/ManageAttendance';
import {fetchSingleSession, deleteCareerSession, sessionCheckIn} from '../../actions/sessionActions';
import {isRole, isStudent, isCM} from '../../helpers/roles';
import {sessionDetailDateString} from '../../helpers/dateTime';
import MarkdownRender from '../../components/MarkdownRenderer/markdownRenderer'
import {roleTypes, sessionContextTypes} from '../../constants/dataTypes';
import {attendanceStatusSpecs, remoteRequestStatusSpecs} from '../../constants/attendance';
import {sessionCheckInTypes} from '../../constants/checkIn'
import {isCheckInAvailable} from '../../helpers/utils';
import {isEmpty, forOwn} from 'lodash';

import ReactGA from 'react-ga';

import {getAttendanceStatusText, getRemoteRequestStatusText} from "../../helpers/common";
import moment from "moment/moment";
import get from "lodash/get";

const mapStateToProps = (state, ownProps) => {
    return {
        enrollmentInfo: state.user.enrollmentInfo,
        sessions: state.sessions,
        user: state.user,
        authToken: state.user.data.authToken,
        sessionLoading: state.sessions.sessionLoading,
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        hideRemoteAttendance: get(state,'user.enrollmentInfo.course.cohort.cohortUiControl.hideRemoteAttendance',true),
        ...ownProps
    }
}

class SessionDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            classroom: {},
            error: [],
            wasDeleted: false,
            checkInAvailable: false,
        }
    }

    setStateAsync = (state) => {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    async componentDidMount() {
        const {match, dispatch} = this.props;
        const sessionId = parseInt(match.params.sessionId, 10);
        dispatch(fetchSingleSession(sessionId));
    }

    takeAttendance = async (e) => {
        e.preventDefault();
        const {sessions} = this.props;
        const sessionId = sessions.selected.session.session.id;
        await sessionCheckIn(sessionId);
        this.props.dispatch(fetchSingleSession(sessionId));

        ReactGA.event({
            category: 'Attendance',
            action: 'Take (Session Detail)',
        });

    }

    renderCheckInButton = (startTime, endTime) => {
        const attendanceStatus = getAttendanceStatusText(this.props.sessions.selected.session);
        const checkInStatus = isCheckInAvailable(startTime, endTime);

        const currentAttendanceStatus = attendanceStatusSpecs(attendanceStatus);
        const selectedSession = this.props.sessions.selected.session;

        if (checkInStatus === sessionCheckInTypes.CHECK_IN_ENDED
            || (sessionCheckInTypes.CHECK_IN_TIME && attendanceStatus !== "None")
            || (attendanceStatus === "Pending" || attendanceStatus === "Excused" || attendanceStatus === "Approved" || attendanceStatus === "Exception")) {
            return (
                <React.Fragment>
                    <div className={`text-bold ${currentAttendanceStatus.color ? 'text-' + currentAttendanceStatus.color : ''}`}>Attendance: {currentAttendanceStatus.text}</div>
                    {!!selectedSession.excusedAbsenceReason && <div className={`${currentAttendanceStatus.color ? 'text-' + currentAttendanceStatus.color : ''}`}>{selectedSession.excusedAbsenceReason.description}</div>}
                </React.Fragment>
            )
        } else if (checkInStatus === sessionCheckInTypes.CHECK_IN_TOO_EARLY) {
            return (
                <div className="text-bold margin-b-3">Check In Hasn't Begun</div>
            )
        } else {
            return (
                <button id="take-attendance" onClick={this.takeAttendance} className="btn-small btn-primary">Check In To
                    Class</button>
            )
        }
    }

    renderRemoteRequestButton = (sessionId) => {
        const selectedSession = this.props.sessions.selected.session;
        const {hideRemoteAttendance} = this.props;
        let requestStatus = getRemoteRequestStatusText(selectedSession);
        const currentRemoteRequestStatus = remoteRequestStatusSpecs(requestStatus);
        let linkText = "Request Remote Attendance";

        if (requestStatus === "Pending" || requestStatus === "Denied" || requestStatus === "Approved") {
            return (
                <div
                    className={`text-bold margin-b-3 ${currentRemoteRequestStatus.color ? 'text-' + currentRemoteRequestStatus.color : ''}`}>Remote Request Status: {currentRemoteRequestStatus.text}</div>
            )
        } else if (!hideRemoteAttendance){
            return (
                <Link to={`/attendance/remote-attendance-request/${sessionId}`}
                      className="btn btn-small btn-edit margin-r-0 remoteRequestLink">{linkText}</Link>
            )
        } else return null
    }

    renderPanoptoUrls = () => {
        const videoList = this.props.sessions.selected.session.videoUrlList;
        const sessionId = this.props.match.params.sessionId;
        let display = [];
        // so the videos can be numbered. forOwn doesn't give an index since its an object.
        let i = 1
        forOwn(videoList, (videoObj) => {
            display.push(
                <li className="bulleted" key={videoObj.id}>
                    <p><Link to={{pathname: `${sessionId}/videos/${videoObj.id}`}}> Play
                        Video {`${i}`}</Link></p>
                </li>
            );
            i++
        });
        return (

            <ul className="border-top">{display}</ul>

        )
    }

    deleteSession = async (e) => {
        e.preventDefault();
        const {sessions} = this.props;
        const selectedSession = sessions.selected.session;

        if (window.confirm(`Are you sure that you want to delete the session: ${selectedSession.session.name}`)) {
            this.setState({loading: true});
            let payload = {
                id: selectedSession.session.id
            };
            try {
                await deleteCareerSession(payload);
                ReactGA.event({
                    category: 'Career Session',
                    action: 'Session Deleted',
                });
                this.setState({
                    loading: false,
                    error: [`${selectedSession.session.name} has been successfully deleted.`],
                    wasDeleted: true
                });

            } catch (err) {
                console.error(err)
                ReactGA.event({
                    category: 'Career Session',
                    action: 'Session Delete Error',
                });
            }
        }
    }

    render() {
        const {sessions, sessionLoading, activeRole} = this.props;
        const selectedSession = sessions.selected.session || {};
        return (
            <DocumentTitle title="Session Detail | Bootcamp Spot">
                <section className="page" data-component="session-detail">
                    {sessionLoading ? (
                        <Loading/>
                    ) : this.state.error.length ? (
                        <div className="row">
                            <div className="col-xs-12">
                                <Alert type="error">
                                    {this.state.error.map((item, index) => {
                                        return (
                                            <p key={index}>{item}</p>
                                        )
                                    })}
                                    {this.state.wasDeleted ? (
                                        <Link to="/career-services" className="btn-edit btn-sm btn">Continue</Link>
                                    ) : null}
                                </Alert>
                            </div>
                        </div>
                    ) : isEmpty(selectedSession.session) ? (
                        <Alert type="warn">This session doesn't seem to exist - maybe try the <Link to="/sessions">Sessions
                            page</Link> to get yourself back on track</Alert>
                    ) : (
                        <div className="session-detail-info">
                            <div className="row">
                                <div className="col-xs-12 col-md-7 col-lg-8">
                                    <h1>Session {selectedSession.session.chapter} {selectedSession.session.name}</h1>
                                    <div>
                                        {sessionDetailDateString(selectedSession.session.startTime, selectedSession.session.startTime, selectedSession.session.endTime)}
                                    </div>
                                    {selectedSession.classroom ? (
                                        <div className="classroom-object">

                                            {selectedSession.classroom.videoUrl && selectedSession.classroom.videoUrl.length ? (
                                                <div className="virtual-location">
                                                    <p>
                                                        Online Meeting Room:
                                                        {selectedSession.classroom.videoUrl.includes('www.') || selectedSession.classroom.videoUrl.includes('http') ? (
                                                            <strong><a href={selectedSession.classroom.videoUrl}
                                                                       target="_blank"
                                                                       rel="noopener noreferrer"
                                                                    >
                                                                    {selectedSession.classroom.videoUrl}
                                                                    </a></strong>
                                                        ) : (
                                                            <strong>{selectedSession.classroom.videoUrl}</strong>
                                                        )}
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="physical-location">
                                                    <p>
                                                        Building: <strong>{selectedSession.classroom.campus.buildingName}</strong>
                                                    </p>
                                                    <p>
                                                        Classroom: <strong>{selectedSession.classroom.name} - {selectedSession.classroom.roomNumber}</strong>
                                                    </p>
                                                </div>
                                            )}

                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-xs-12 col-md-5 col-lg-4 first-xs last-md text-right padding-l-0">
                                    <Link to="/sessions">View all Sessions</Link>
                                    {isStudent(activeRole) &&
                                    <div>
                                        {selectedSession.context.contextCode !== sessionContextTypes.CAREER && this.renderCheckInButton(selectedSession.session.startTime, selectedSession.session.endTime)}
                                        {(selectedSession.context.contextCode !== sessionContextTypes.CAREER && moment().isBefore(moment(selectedSession.session.endTime)))
                                            && getAttendanceStatusText(selectedSession) === "None"
                                            && this.renderRemoteRequestButton(selectedSession.session.id)}
                                    </div>
                                    }
                                    {(isRole([roleTypes.CD, roleTypes.ADMIN, roleTypes.SSM], activeRole) && selectedSession.context.id === 2) ?
                                        <div>
                                            <Link to={`/sessions/${selectedSession.session.id}/edit`}>
                                                <button className="btn-small btn-edit">Edit Session</button>
                                            </Link>
                                            <Link to={`#delete${selectedSession.session.id}`}
                                                  onClick={this.deleteSession}>
                                                <button className="btn-small btn-delete">Delete Session</button>
                                            </Link>
                                        </div> : null
                                    }
                                </div>
                            </div>
                            {(selectedSession && !isEmpty(selectedSession)) ? (
                                <div className="row border-top">
                                    {
                                        selectedSession.session.longDescription && (
                                            <div className="col-xs-12 col-sm-8">
                                                <h2>Session Description</h2>
                                                <MarkdownRender text={selectedSession.session.longDescription || ''}/>
                                            </div>
                                        )
                                    }
                                    <div className="col-xs-12 col-sm-4">
                                        {/* if we have URLs, render update, else render link to create */}

                                        { (!isStudent(activeRole) && !isCM(activeRole)) ? (
                                            <React.Fragment>
                                                <h2>Videos</h2>
                                                <Link to={`/sessions/${selectedSession.session.id}/video/create`}>
                                                    Add Videos
                                                </Link>
                                                {!isEmpty(selectedSession.videoUrlList) ? (
                                                    <span>
                                                        | &nbsp;
                                                        <Link
                                                            to={`/sessions/${selectedSession.session.id}/video/update`}>
                                                                Update or Delete Videos
                                                        </Link>
                                                    </span>
                                                ) : null}
                                            </React.Fragment>
                                        ) : null}
                                        {!isEmpty(selectedSession.videoUrlList) ? this.renderPanoptoUrls() : ''}
                                    </div>
                                </div>
                            ) : null}

                            {!isStudent(activeRole) ? (
                                <div className="padding-b-5">
                                    <ManageAttendance/>
                                </div>
                            ) : null}
                        </div>
                    )}
                </section>
            </DocumentTitle>
        )
    }
}

export const SESSIONDETAIL = SessionDetail;
export default connect(mapStateToProps)(SessionDetail);
