export const toInitialCase = ( text ) => {
    return text.replace(/(&)?([a-z])([a-z]{2,})(;)?/ig,function ( all, prefix, letter, word, suffix ) {
        if (prefix && suffix) {
            return all;
        }

        return letter.toUpperCase() + word.toLowerCase();
    });
}

export const toInitialSpace = (str) => {
    return str.split('-').map(function capitalize(part) {
        if (part !== "of" && part !== "at" && part !== "in") {
            return part.charAt(0).toUpperCase() + part.slice(1);
        } else {
            return part;
        }
    }).join(' ');
}

export const toLowerHyphen = str => str.replace(/\s+/g, '-').toLowerCase().replace(/,/g, '');

