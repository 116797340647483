import {fulfilled, pending, rejected} from "../helpers/asyncActionGenerator";
import axios from "axios/index";
import {careerServicesActionTypes} from "../constants/actionTypes";

export function fetchCareerServices(payload) {
    return async (dispatch) => {
        dispatch(pending(careerServicesActionTypes.FETCH_CAREER_SERVICES));
        try {
            let axiosResult = await axios.post("/broker/careerServices", payload, {headers: {'Content-Type': 'application/json',}});
            return dispatch(fulfilled(careerServicesActionTypes.FETCH_CAREER_SERVICES, axiosResult.data));
        } catch (error) {
            return dispatch(rejected(careerServicesActionTypes.FETCH_CAREER_SERVICES, error));
        }
    }
}
