export const LINKEDIN = "LinkedIn";
export const GITHUB = "GitHub";

export const courseButtonAndLinkGenerator = (submissionRequired, milestoneTypes) => {
    const linkLabelChoices = milestoneTypes.map(item => item.name);
    return submissionRequired ? {
        buttonText: "Submit Milestone",
        linkLabelChoices: linkLabelChoices,
    } : {
        buttonText: "Mark Milestone Complete",
        linkLabelChoices: [],
    }
};

export const careerServiceResourceLink = 'https://careernetwork.2u.com/?utm_medium=BCSMilestones&utm_source=boot_camp';
