import { applyMiddleware, createStore, compose } from 'redux';
import {logger} from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { isProduction } from '../helpers/utils';
// import SetTransform from './setTransform';

let middlewareList = [thunk];
let enhancer;

if(!isProduction()) {
    // don't run logger in jsdom or reading the output of our tests will become a nightmare
    if(!(navigator.userAgent.includes("Node.js") || navigator.userAgent.includes("jsdom"))){
        middlewareList.push(logger);
    }
    enhancer = composeWithDevTools(
        applyMiddleware(...middlewareList)
    );
} else {
   enhancer = compose(
        applyMiddleware(...middlewareList)
    );
}

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2
};

const persistReducerToPass = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistReducerToPass, enhancer);
export const persistor = persistStore(store);
