import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const BlankSlate = (props) => (
    <div className="text-center" role="alert">
        <Icon icon={props.icon} options="fa-3x" />
        <h3>{props.header}</h3>
        {props.children}
    </div>
)

BlankSlate.propTypes = {
    icon: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired
}

export default BlankSlate

