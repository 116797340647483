import {
    openDocsTypes
} from '../constants/actionTypes'

import {
    pending,
    rejected,
    fulfilled
} from '../helpers/asyncStatusGenerator';

const initialState = {};

export const openDocsReducer = (state=initialState, action) => {
    switch (action.type) {
        case pending(openDocsTypes.REQUEST):
            return state;
        case fulfilled(openDocsTypes.REQUEST):
        case rejected(openDocsTypes.REQUEST):
            const {endpoint, response} = action.payload;
            const openDocs = state.openDocs || {};
            openDocs[endpoint] = response;

            delete openDocs.response;
            delete openDocs.endpoint;

            return {
                ...openDocs,
            };
        default:
            return state;
    }
};
