export const calendarWeekDays = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
}

export const calendarEventTypeClassNames = {
    'assignment': {
        'prework': 'calendar-event-due',
        'academic': 'calendar-event-due',
        'career': 'calendar-event-workshop'
    },
    'session': {
        'academic': 'calendar-event-session',
        'career': 'calendar-event-workshop',
        'orientation': 'calendar-event-orientation'
    },
    'career': {
        'career': 'calendar-event-workshop'
    }
}
