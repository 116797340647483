import axios from 'axios';
import {pending, fulfilled, rejected} from '../helpers/asyncActionGenerator';
import {sessionActionTypes} from '../constants/actionTypes';
import {jsonContentTypeHeader} from "../constants/axios";

export const fetchSessions = (enrollmentId) => {
    return async (dispatch) => {
        dispatch(pending(sessionActionTypes.FETCH_SESSIONS));
        try {
            let payload = {enrollmentId: enrollmentId};
            let axiosResult = await axios.post("/broker/sessions", payload, {headers: {'Content-Type': 'application/json',}});
            dispatch(fulfilled(sessionActionTypes.FETCH_SESSIONS, axiosResult.data));
        } catch (err) {
            dispatch(rejected(sessionActionTypes.FETCH_SESSIONS, err))
            console.warn(err);
        }
    }
}

export const fetchSingleSession = (sessionId) => {
    const {FETCH_SINGLE_SESSION} = sessionActionTypes;

    return async (dispatch) => {
        dispatch(pending(FETCH_SINGLE_SESSION));
        try {
            let payload = {sessionId: sessionId};
            let axiosResult = await axios.post("/broker/sessionDetail", payload, {headers: {'Content-Type': 'application/json',}});
            dispatch(fulfilled(FETCH_SINGLE_SESSION, axiosResult.data));
        } catch (err) {
            dispatch(rejected(FETCH_SINGLE_SESSION, err));
        }
    }
}

export function refreshStudents(sessionId) {
    return async (dispatch) => {
        let payload = {sessionId: sessionId};
        let axiosResult = await axios.post("/broker/sessionDetail", payload, {headers: {'Content-Type': 'application/json',}});
        dispatch(fulfilled(sessionActionTypes.REFRESH_STUDENTS, axiosResult.data.students))
    }
}

export async function sessionCheckIn(sessionId) {
    let payload = {
        sessionId: sessionId
    }
    let axiosResult = await axios.post("/broker/sessionCheckIn", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

export async function createCareerSession(payload) {
    let axiosResult = await axios.post("/broker/createCareerSession", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

export async function editCareerSession(payload) {
    let axiosResult = await axios.post("/broker/updateCareerSession", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

export async function deleteCareerSession(payload) {
    let axiosResult = await axios.post("/broker/deleteCareerSession", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

export const panoptoActions = {
    createPanoptoVideos,
    getPanoptoSessionVideos
}

async function createPanoptoVideos(authToken, sessionId, videoUrls) {
    const payload = {sessionId: sessionId, urls: videoUrls}
    let result = {}
    try {
        await axios.post("/broker/updateSessionVideos?authToken=" + authToken, payload, {headers: {'Content-Type': 'application/json',}});
        result.success = true
    } catch (error) {
        result.error = error
        result.success = false
    }
    return result
}

async function getPanoptoSessionVideos(authToken, payload) {
    let result = {}
    try {
        const panoptoVideos = await axios.post("/broker/sessionVideos?authToken=" + authToken, payload, {headers: {'Content-Type': 'application/json',}});

        result.data = panoptoVideos.data
    } catch (error) {
        result.error = error
        result.success = false
    }
    return result
}

export async function fetchStudentSessions(enrollmentId) {
    let payload = {enrollmentId: enrollmentId};
    return await axios.post("/broker/academicSessionsForStudent", payload, {headers: jsonContentTypeHeader});
}
