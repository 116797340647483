import {Component} from "react";
import DocumentTitle from "react-document-title";
import {connect} from 'react-redux';
import React from "react";
import {getRawPayload} from "../../actions/openDocsActions";
import OpenDocsApiResponse from "../../components/OpenDocsApiResponse";
import OpenDocsHeader from "../../components/OpenDocsHeader";
import Input from "../../components/Input";


const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps,
        openDocs: state.openDocs,
        authToken: state.user.data.authToken,
    }
};

class ApiDocsSessions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tryItUrl: '',
            enrollmentId: '',
        };
    }

    componentWillReceiveProps(newProps) {
        if (typeof newProps.openDocs !== "undefined") {
            const newOpenDocs = newProps.openDocs;
            if (newOpenDocs && newOpenDocs[this.state.tryItUrl]) {
                this.setState({
                    payloadRsp: newOpenDocs[this.state.tryItUrl].payload,
                    headersRsp: newOpenDocs[this.state.tryItUrl].headers,
                    status: newOpenDocs[this.state.tryItUrl].status,
                });
            }
        }
    }

    handleChange(event) {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        const obj = {};
        obj[fieldName] = fieldValue;
        this.setState(obj)
    }

    handleOnClick() {
        const {dispatch, authToken} = this.props;
        const ep = `sessions`;
        this.setState({
            tryItUrl: ep
        });

        dispatch(getRawPayload(ep, {enrollmentId: parseInt(this.state.enrollmentId, 10)}, authToken));
    }

    render() {
        const { payloadRsp, headersRsp, status } = this.state;

        return (
            <DocumentTitle title="API Access - Sessions | Bootcamp Spot">
                <section>
                    <OpenDocsHeader
                        endpoint={"/api/instructor/v1/sessions"}
                        description={"Lists all sessions."}
                        type={"POST"}
                        headers={[{name: "Content-Type", value: "application/json", description: ""},
                            {name: "authToken", value: "<valid token>", description: "The token generated via /login"}]}
                        exampleBody={{enrollmentId: 1}}
                        exampleResponses={[
                            {
                                code: 200,
                                body: {
                                    "currentWeekSessions": [
                                        {
                                            "session": {
                                                "id": 457333,
                                                "courseId": 594,
                                                "contextId": 1,
                                                "name": "Nother Level of Node",
                                                "shortDescription": "Nother Level of Node",
                                                "longDescription": "",
                                                "startTime": "2019-03-18T19:30:00-03:00",
                                                "endTime": "2019-03-18T22:30:00-03:00",
                                                "chapter": "10.2"
                                            },
                                            "context": {
                                                "id": 1,
                                                "contextCode": "academic",
                                                "name": "Academic"
                                            },
                                            "eventType": {
                                                "code": "academic",
                                                "name": "Academic"
                                            },
                                            "classroom": null,
                                            "videoUrlList": null
                                        }
                                    ],
                                    "calendarSessions": [
                                        {
                                            "session": {
                                                "id": 459656,
                                                "courseId": 594,
                                                "contextId": 2,
                                                "name": "Deploy Sign In Test",
                                                "shortDescription": "Deploy Sign In Test",
                                                "longDescription": "Deploy Sign In Test",
                                                "startTime": "2018-04-22T17:00:00-03:00",
                                                "endTime": "2018-04-22T17:30:00-03:00",
                                                "chapter": "1"
                                            },
                                            "context": {
                                                "id": 2,
                                                "contextCode": "career",
                                                "name": "Career"
                                            },
                                            "eventType": {
                                                "code": "career",
                                                "name": "Career"
                                            },
                                            "classroom": null,
                                            "videoUrlList": null
                                        }
                                    ]
                                },
                                rows: 15,
                                description: "Successful response."
                            },
                            {
                                code: 401,
                                body: null,
                                rows: 1,
                                description: "A response with a 401 Unauthorized status means that the logged in user does not have access to the specified enrollmentId information."
                            }
                        ]}
                    />
                    <div className="row">
                        <div className="col-xs-12">
                            <h1 className={"border-bottom"}>Try it out</h1>

                            <Input
                                label="enrollmentId"
                                type="number"
                                id="enrollmentId"
                                placeholder="<enrollmentId>"
                                helper="Fetch information for this enrollment Id"
                                aria-required={true}
                                onChange={this.handleChange.bind(this)}
                            />
                            <button onClick={this.handleOnClick.bind(this)}>Submit</button>
                        </div>
                    </div>


                    <OpenDocsApiResponse
                        headers={headersRsp}
                        payload={payloadRsp}
                        status={status}
                    />

                </section>
            </DocumentTitle>
        )
    }
};

export default connect(mapStateToProps)(ApiDocsSessions);
