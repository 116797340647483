import { enrollmentActionTypes } from '../constants/actionTypes'

const initialState = {
    enrollmentList: []
};

export const enrollmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case enrollmentActionTypes.GET_COURSE_ENROLLMENTS:
            return {
                ...state,
                enrollmentList: action.payload,
            };
        default:
            return state;
    }
}

export const activeEnrollmentReducer = (state = {}, action) => {
    switch (action.type) {
        case enrollmentActionTypes.SET_ACTIVE_ENROLLMENT:
            return {
                ...action.payload
            };
        default:
            return state;
    }
}