import React from 'react';
import DocumentTitle from 'react-document-title'
import NoEnrollmentPage from '../../components/NoEnrollmentPage'
import get from 'lodash/get';

export const EnrollmentEnforcer = (props) => {
    const isEnrolled = get(props,'profile.enrollmentCount',0)
    // need to allow nav to /support without enrollments
    if(!isEnrolled){// we cannot render our app without being an actively enrolled user
        if(window.location.pathname === "/support" || window.location.pathname === "/casesuccess"){
            // special case - we need to allow navigation to /support and /casesuccess while no enrollments are present. So the user can submit the ticket. 
            return props.children
        }
        return (
            <React.Fragment>
                <DocumentTitle  title={`Dashboard - ERROR: No Enrollments | Bootcamp Spot`}>
                    <NoEnrollmentPage {...props} ></NoEnrollmentPage>
                </DocumentTitle>
            </React.Fragment>
        )
    } else{
        return props.children
    }
}

export default (EnrollmentEnforcer);