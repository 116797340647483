import React from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';
import { get } from 'lodash'

import Alert from '../../components/Alert';
import Icon from '../../components/Icon';
import ProfilePic from '../../components/ProfilePic';
import { FlagWithText } from '../FlagWithText'
import { Table, TBody, THead, TR, TD, TH } from '../../components/Table';

import { gradeColorsMap, getGradeName } from '../../constants/grade';
import { availableCareerStatus, sessionContextTypes } from '../../constants/dataTypes';

import { filterSubmissions } from '../../helpers/gradebook';

const Assignments = ({ submissionsFiltered }) => {
    const tableHeadings = [
        'Assignment',
        'Student',
        'Submitted',
        'Grade',
        'Actions'
    ]
    return (
        <div className="assignments">
            <div className="attendance-stats row text-center">
                <div className="stat col-xs-6">
                    <div className="card">
                        <p className="title">Graded</p>
                        <p className="headline">
                            {filterSubmissions(submissionsFiltered, 'graded')}
                        </p>
                    </div>
                </div>
                <div className="stat col-xs-6">
                    <div className="card">
                        <p className="title">Ungraded</p>
                        <p className="headline">
                            {filterSubmissions(submissionsFiltered, 'ungraded')}
                        </p>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12">
                    {submissionsFiltered.length ? (
                        <Table>
                            <THead>
                                <TR>
                                    <TH col="3">{tableHeadings[0]}</TH>
                                    <TH col="3">{tableHeadings[1]}</TH>
                                    <TH col="2">{tableHeadings[2]}</TH>
                                    <TH col="2">{tableHeadings[3]}</TH>
                                    <TH col="2">{tableHeadings[4]}</TH>
                                </TR>
                            </THead>
                            <TBody>
                                {submissionsFiltered.map((item, index) => {
                                    let shouldShowPlagiarismFlagBool = get(item, "submissionPlagiarism.submissionId", false)
                                    return (
                                        <TR key={index} className="font-sm">
                                            <TD col="3" heading={tableHeadings[0]}><strong>{item.assignment.title}</strong></TD>

                                            <TD col="3" heading={tableHeadings[1]}>
                                                <ProfilePic
                                                    user={item.student}
                                                    inlineStyles={{
                                                        width: '40px',
                                                        height: '40px',
                                                        marginBottom: '-8px',
                                                        marginRight: '12px'
                                                    }}
                                                />
                                                {item.student.firstName} {item.student.lastName}
                                            </TD>

                                            <TD col="2" heading={tableHeadings[2]}>{moment(item.submission.date).format('dddd, MMMM D, YYYY')}</TD>
                                            {item.context.contextCode === sessionContextTypes.CAREER ? (
                                                <TD col="2" heading={tableHeadings[3]}>
                                                    {item.careerFeedback === null ? (
                                                        <span className="text-red">---</span>
                                                    ) : (
                                                            <span className="career-feedback">
                                                                {(item.careerFeedback.careerFeedbackStatusId === 1) ? (
                                                                    <span className="text-yellow">{availableCareerStatus[0].label}</span>
                                                                ) : null}
                                                                {(item.careerFeedback.careerFeedbackStatusId === 2) ? (
                                                                    <span className="text-green">{availableCareerStatus[1].label}</span>
                                                                ) : null}
                                                                {(item.careerFeedback.careerFeedbackStatusId === 3) ? (
                                                                    <span className="text-red">{availableCareerStatus[2].label}</span>
                                                                ) : null}
                                                                {(item.careerFeedback.careerFeedbackStatusId === 4) ? (
                                                                    <span className="text-yellow">{availableCareerStatus[3].label}</span>
                                                                ) : null}
                                                                {(item.careerFeedback.careerFeedbackStatusId === 5) ? (
                                                                    <span className="text-green">{availableCareerStatus[4].label}</span>
                                                                ) : null}
                                                                {(item.careerFeedback.careerFeedbackStatusId === 6) ? (
                                                                    <span className="text-green">{availableCareerStatus[5].label}</span>
                                                                ) : null}
                                                                {(item.careerFeedback.careerFeedbackStatusId === 7) ? (
                                                                    <span className="text-red">{availableCareerStatus[6].label}</span>
                                                                ) : null}
                                                            </span>
                                                        )}
                                                </TD>
                                            ) : (
                                                    <TD col="2" heading={tableHeadings[3]} className="text-center">
                                                        {item.submissionGrade && item.submissionGrade.grade ? (
                                                            <span
                                                                className={gradeColorsMap.get(item.submissionGrade.grade)}>{getGradeName(item.submissionGrade.grade)}
                                                            </span>
                                                        ) : (
                                                                <span className="text-red font-sm">Ungraded</span>
                                                            )}
                                                        <div>
                                                            {shouldShowPlagiarismFlagBool ? (<FlagWithText text="Flagged for Plagiarism"/>) : null}
                                                        </div>
                                                    </TD>
                                                )}
                                            {item.context.contextCode === sessionContextTypes.CAREER ? (
                                                <TD col="2" heading={tableHeadings[4]}>
                                                    <Link className="text-link"
                                                        to={`/gradebook/${item.assignment.id}/grade/${item.student.id}`}>
                                                        <Icon icon="graduation-cap" /> Provide Feedback
                                                                </Link>
                                                </TD>
                                            ) : (
                                                    <TD col="2" heading={tableHeadings[4]}>
                                                        <Link className="text-link"
                                                            to={`/gradebook/${item.assignment.id}/grade/${item.student.id}`}>
                                                            <Icon icon="graduation-cap" /> Grade & View Comments
                                                                </Link>
                                                    </TD>
                                                )}
                                        </TR>
                                    )
                                })}
                            </TBody>
                        </Table>
                    ) : (
                        <Alert type="warn">
                            No assignments have been submitted for your chosen criteria
                        </Alert>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Assignments