import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import DocumentTitle from 'react-document-title';
import UniversityLogo from '../../components/UniversityLogo/';
import {Table, TBody, THead, TR, TD, TH} from '../../components/Table';
import {toInitialSpace} from '../../helpers/toCase.js';
import {sortClassesByStartDate} from '../../helpers/coursesFormatter';
import Loading from "../../components/Loading/index";
import {
  setActiveEnrollment,
  resetEnrollmentInfo,
} from '../../actions/enrollmentsActions.js';
import {userActions} from '../../actions/userActions.js';
import {get} from 'lodash'
import moment from 'moment';

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

class Classes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enrollments: this.props.user.enrollments ? sortClassesByStartDate(this.props.user.enrollments) : [],
            universityLogo: this.props.user.enrollments ? this.props.user.enrollments[0].course.cohort.program.university.logoUrl : '',
            universityName: this.props.user.enrollments ? this.props.user.enrollments[0].course.cohort.program.university.name : ''
        }
        this.props.removeNav();
        this.handleCustomBackButtonBehavior();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user.enrollments && nextProps.user.enrollments.length) {
            let enrollments = sortClassesByStartDate(nextProps.user.enrollments)
            this.setState({
                enrollments: enrollments,
                universityLogo: enrollments[0].course.cohort.program.university.logoUrl,
                universityName: enrollments[0].course.cohort.program.university.name
            });
        }
    }

    componentWillUnmount() {
        window.onpopstate = () => {}
    }

    setEnrollment = (classObj) => {
        const {dispatch} = this.props;
        dispatch(userActions.getEnrollmentInfo(classObj.id, this.props.user.data.authToken));
        dispatch(setActiveEnrollment(classObj));
        dispatch(resetEnrollmentInfo());

        this.props.history.push(`/`);
    }

    handleCustomBackButtonBehavior = () => {
        window.onpopstate = () => {
            this.props.dispatch(resetEnrollmentInfo())
        }
    }

    render() {
        const uniImgStyles = {  
            position: "absolute",
            top: "0.6rem",
            left: "1rem",
            maxWidth: "2rem",
            maxHeight: "2rem",
            marginRight: "1rem"
        }

        const uniCellStyles = {
            position: "relative"
        }

        const disabledLink = {
            pointerEvents: "none",
            color: "#4A4A4A",
            borderBottom: "none"
        }

        const tableHeadings = [
            'Class',
            'Start Date',
            'MidPoint Survey',
            'End Date'
        ]

        return (
            <DocumentTitle title="Select Class | Bootcamp Spot">
                <section>
                    <div className="row">
                        <div className="col-xs-12">
                            <p>
                                <Link to='/universities'
                                      style={this.state.enrollments.length > 1 ? {} : disabledLink}>Universities</Link>{' > '}
                                <Link
                                    to={`/universities/${this.props.match.params.universityName}`}>{this.state.universityName}</Link>{' > '}
                                <Link to='/'
                                      style={disabledLink}>{toInitialSpace(this.props.match.params.cohortName)}</Link>
                            </p>
                            <h1>Select a Class from {this.state.universityName ? (this.state.universityName + ", ") : ""}
                                Cohort {this.props.match.params.cohortName}</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <Table>
                                <THead>
                                    <TR>
                                        <TH col="5">{tableHeadings[0]}</TH>
                                        <TH>{tableHeadings[1]}</TH>
                                        <TH>{tableHeadings[2]}</TH>
                                        <TH>{tableHeadings[3]}</TH>
                                    </TR>
                                </THead>
                                <TBody>
                                {this.state.enrollments.length ?
                                    this.state.enrollments.map((classObj, index) => {
                                        return (
                                            <TR key={index}
                                                role="button"
                                                onClick={() => this.setEnrollment(classObj)}
                                            >
                                                <TD
                                                    col="5"
                                                    heading={tableHeadings[0]}
                                                    style={uniCellStyles}
                                                    className="padding-l-3-xs padding-l-6-md"
                                                >
                                                    <UniversityLogo
                                                        imgSrc={this.state.universityLogo}
                                                        inlineStyles={uniImgStyles}
                                                        imgAlt={this.state.universityName}
                                                        options="hide-xs show-md"
                                                    />
                                                    <span className="padding-l-0-xs padding-l-3-md">{classObj.course.name}</span>
                                                </TD>
                                                <TD heading={tableHeadings[1]}>{get(classObj.course, 'startDate', false) ? moment(classObj.course.startDate).format('MMMM D, YYYY') : "TBD"}</TD>
                                                <TD heading={tableHeadings[2]}>{get(classObj.midCourseSurveySchedule, 'dateFrom', false) ? moment(classObj.midCourseSurveySchedule.dateFrom).format('MMMM D, YYYY') : "TBD"}</TD>
                                                <TD heading={tableHeadings[3]}>{get(classObj.course,'endDate', "") ? moment(classObj.course.endDate).format('MMMM D, YYYY') : "TBD"}</TD>
                                            </TR>
                                        )
                                    })
                                    : <TR>
                                         <TD>
                                            <Loading />
                                        </TD>
                                    </TR>}
                                </TBody>
                            </Table>
                        </div>
                    </div>
                </section>
            </DocumentTitle>
        )
    }
};

export const CLASSES = Classes

export default connect(mapStateToProps)(Classes);
