import React from 'react';
import PropTypes from 'prop-types';

const TimePicker = (props) => {
    // if moment returns an invalid date from converting start/expiration time or meridians therof in the parent component, check vs that string in order to render default selected
    return(
        <div className="row">
                <div className="col-xs-12 col-md-8" style={{padding:0}}>
                    <select name={props.name} id={props.name} aria-required={props.required} onBlur={props.onChange} onChange={props.onChange}>
                        {props.timeValue !== 'Invalid date' ? <option selected="selected">{props.timeValue}</option> : ''}
                        <option value=""></option>
                        {[...Array(12)].map((item,index)=>{
                            return (
                                <React.Fragment key={index}>
                                    <option value={`${index+1}:00`}>{`${index+1}:00`}</option>
                                    <option value={`${index+1}:30`}>{`${index+1}:30`}</option>
                                </React.Fragment>
                        )
                        })}
                    </select>
                </div>
                <div className="col-xs-12 col-md-4" style={{padding:0, paddingLeft:'1em'}}>
                    <select name={`${props.name}_meridian`} id={`${props.name}_meridian`} aria-required={props.required} onBlur={props.onChange} onChange={props.onChange}>
                        {props.meridianValue !== 'Invalid date' ? <option selected="selected">{props.meridianValue}</option> : ''}
                        <option value=""></option>
                        <option value="PM">PM</option>
                        <option value="AM">AM</option>
                    </select>
                </div>
        </div>

    )
}

TimePicker.defaultProps = {
    required: false
}

TimePicker.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    required: PropTypes.bool
};

export default TimePicker;
