export const attendanceStatusSpecs = (attendanceStatus) => {
    const attendanceStatusSpecs = {
        "None": {
            color: '',
            text: "You are currently not checked in"
        },
        "Present": {
            color: 'green',
            text: "You are currently checked in as present"
        },
        "Remote": {
            color: 'orange',
            text: "You are currently checked in as present remotely"
        },
        "Exception": {
            color: 'orange',
            text: "Exception"
        },
        "Absent": {
            color: 'red',
            text: "You are currently checked in as absent"
        }
    }

    return attendanceStatusSpecs[attendanceStatus];
}

export const remoteRequestStatusSpecs = (remoteRequestStatus) => {
    const remoteRequestStatusSpecs = {
        "Approved": {
            color: 'green',
            text: "Approved"
        },
        "Denied": {
            color: 'red',
            text: "Denied"
        },
        "Pending": {
            color: '',
            text: "Pending"
        }
    }

    return remoteRequestStatusSpecs[remoteRequestStatus];
}
