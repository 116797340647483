import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
}

class NpsSuccess extends Component {
    constructor(props) {
        super(props);
        this.props.removeNav();
    }

    comebackToWall = () => {
        this.props.history.replace('/')
    }

    render() {
        let type = this.props.match.params.type;
        return (
            <div>
                 <div className="context-success">
                     {type === 'end' && <div>
                         <h1>Thank you!</h1>
                         <p>NPS Survey has been successfully sent.</p>
                     </div>}
                     {(type === 'additional' && this.props.flags.surveys.reviewPrompt) ? (
                        <div>
                         <p>We are thrilled that you enjoyed your experience in the program.
                             Our instructional and student success teams are truly passionate about helping students succeed.
                             Feedback like yours is essential to helping instructors and SSMs improve the program—so we thank you for your input!

                             We would be very grateful if you would take a moment to also share about your experience on Course Report. Following this link sends you to a website outside of Bootcamp Spot.

                             <br/><a href="https://www.coursereport.com/">https://www.coursereport.com/</a>
                         </p>
                     </div>
                     ) : null }
                    <button className="btn-small" onClick={this.comebackToWall}>Continue</button>
                </div>

            </div>
        )
    }
}

export const NPSSUCCESS = NpsSuccess;
export default withRouter(connect(mapStateToProps)(NpsSuccess));
