import Alert from "../components/Alert";
import React from "react";

export const remoteAllowanceWarn = (ssmsArr) => {
    const ssmNamesArr = (!!ssmsArr) ? ssmsArr.map(s => s.contact.firstName) : [];
    const ssmCount = ssmNamesArr.length;

    let displayedSsmNames = "";
    if (ssmCount === 1) {
        displayedSsmNames = ssmNamesArr[0];
    } else if (ssmCount > 1) {
        const lastSsm = ssmCount - 1;
        const csvNamesArr = ssmNamesArr.slice(0, lastSsm);
        displayedSsmNames = csvNamesArr.join(", ");
        displayedSsmNames = displayedSsmNames + " or " + ssmNamesArr[lastSsm];
    }

    return (
        <div className="col-xs-12 remoteAllowanceWarn">
            <Alert type="warn">
                You have reached your maximum allowable Remote Attendance.
                Please remember that this is an in person class.
                If you need to attend the class remotely today, you may login and
                follow along,
                however it will count against your absence total count.
                Please contact your SSM {displayedSsmNames} with questions or concerns.
            </Alert>
        </div>
    );
};
