import React from 'react';
import {availableCareerStatus} from '../../constants/dataTypes';
import Loading from '../../components/Loading';
import {gradeColorsMap} from '../../constants/grade';

const GradeCareerAssignment = (props) => (
    <section data-component="grade-career-assignment">
        {props.grading ? (
            <Loading />
        ) : null}
        {props.feedback !== null ? (
            <React.Fragment>
                <h3 className={gradeColorsMap.get(props.feedback)}>
                    {availableCareerStatus.filter(item => parseInt(item.value, 10) === parseInt(props.feedback, 10))[0].label}
                </h3>
                <a href="#edit-career-grade" onClick={props.toggleCB}>Change Feedback Status</a>
            </React.Fragment>
        ) : (
            <span className="text-red">No Feedback Provided</span>
        )}
        {(props.showGrades || props.feedback === null) ? (
            <select value={props.feedback === null ? '' : props.feedback} onBlur={props.gradeCB} onChange={props.gradeCB}>
                <option value="">Choose Feedback Status</option>
                {availableCareerStatus.map((item,index)=>{
                    return (
                        <option value={item.value} key={index}>{item.label}</option>
                    )
                })}
            </select>
        ) : null}
    </section>
)


export default GradeCareerAssignment

