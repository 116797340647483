import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import moment from 'moment';
import {orderBy} from 'lodash';

import Alert from '../../components/Alert';
import Loading from '../../components/Loading';
import {Table, TBody, THead, TR, TD, TH} from '../../components/Table';
import Icon from '../../components/Icon'

import {fetchGrades} from '../../actions/gradeActions';
import {isStudent} from '../../helpers/roles';
import {getFormattedDateString} from '../../helpers/dateTime';
import {assignmentContextTypes} from "../../constants/dataTypes";
import {getGradeName, isGradeIncomplete} from "../../constants/grade";
import {displayGrade, convertLetterToPercent} from "../../helpers/grade";

var classNames = require('classnames');

const mapStateToProps = (state, ownProps) => {
    return {
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        enrollmentId: state.user.enrollmentInfo.id,
        enrollmentInfo: state.user.enrollmentInfo,
        ...ownProps
    }
}


class Grades extends Component {

    constructor(props) {
        super(props)

        this.state = {
            metrics: {
                careerCompletion: null,
                academicAverage: null,
                academicAverageValue: null,
                overdue: null,
            },
            loading: true,
            assignmentList: [],
            error: false
        }

    }

    async componentDidMount() {
        const {activeRole, enrollmentId} = this.props;

        if (!isStudent(activeRole)) {
            this.props.history.push('/unauthorized');
        }
        try {
            this.setState({
                loading: true
            })
            let result = await fetchGrades(enrollmentId);
            let average = result.data.academicAverageGrade;
            let metrics = this.calculateMetrics(result.data.grades, average);
            metrics.academicAverageValue = result.data.roundAcademicAverageValue;
            const assignmentList = orderBy(result.data.grades, ['assignment.dueDate'], ['desc']);
            this.setState({
                assignmentList,
                metrics: metrics,
                loading: false,
                error: false
            })
        } catch (err) {
            this.setState({
                error: true,
                loading: false
            })
        }

    }

    calculateMetrics = (assignments, average) => {
        let grades = [];
        assignments.forEach((assignment) => {
            if (assignment.context.contextCode === assignmentContextTypes.ACADEMIC && assignment.assignment.required && assignment.submissionGrade) {
                grades.push(assignment.submissionGrade.grade)
            } else {
                return false
            }
        });

        const careerAssignments = assignments.filter((a) => a.context.contextCode === assignmentContextTypes.CAREER);
        const careerRequired = careerAssignments.filter((assignment)  => assignment.assignment.required).length;
        const careerSubmitted = careerAssignments.filter((assignment) =>
            assignment.assignment.required && assignment.submission && !(assignment.submissionGrade && isGradeIncomplete(assignment.submissionGrade.grade))
        ).length;

        const percentage = careerRequired > 0 ? (careerSubmitted / careerRequired) * 100 : 0;

        const overdue = assignments.filter((item) => {
            return item.assignment.required
                && moment().isAfter(moment(item.assignment.effectiveDueDate))
                && (!item.submission || (item.submissionGrade && isGradeIncomplete(item.submissionGrade.grade)))
        }).length;

        return {
            careerCompletion: percentage.toFixed(),
            academicAverage: average,
            overdue: overdue,
        }
    }


    percentageVisualClass(value) {
        if (value === null) {
            return ''
        }
        return classNames({
            // 'text-red': value <= 50,
            'text-error': value <= 50,
            // 'text-yellow': value > 50 && value <= 80,
            'text-warn': value > 50 && value <= 80,
            // 'text-green': value > 80
            'text-success': value > 80
        })
    }

    gradeVisualClass(grade) {
        if (grade === null || grade === undefined) {
            return ''
        }
        return classNames({
            // 'text-red': grade[0] === 'F' || grade[0] === 'I',
            'text-error': grade[0] === 'F' || grade[0] === 'I',
            // 'text-yellow': grade[0] === 'C' || grade[0] === 'D',
            'text-warn': grade[0] === 'C' || grade[0] === 'D',
            // 'text-green': grade[0] === 'A' || grade[0] === 'B'
            'text-success': grade[0] === 'A' || grade[0] === 'B'
        })
    }


    render() {

        const {enrollmentInfo} = this.props;
        const {assignmentList, metrics, error} = this.state;
        const tableHeadings = [
            'Assignment',
            'Type',
            'Status',
            'Action'
        ]

        return (
            <DocumentTitle title="Grades | Bootcamp Spot">
                <section className="page" data-component="grades">
                    {this.state.loading ? (
                            <div className="row middle-xs" style={{height: '100vh'}}>
                                <div className="col-xs"><Loading/></div>
                            </div>
                        ) :
                        (!assignmentList || assignmentList.length === 0 || error) ? (
                            <Alert type="error">
                                Grades could not be calculated at this time. Please try again - if the problem persists
                                please contact BCS Support
                            </Alert>
                        ) : (
                        <div className="student-grade-info">
                            <div className="row">
                                <div className="col-xs-12 col-gutter-lr">
                                    <h1>Grades</h1>
                                </div>
                                <div className="col-xs-12 col-md-4 col-md-offset-2 text-center">
                                    <div className="card">
                                        <p className="title">Average Academic</p>
                                        <p className={'headline ' + this.gradeVisualClass(metrics.academicAverage)}>
                                            { metrics.academicAverage ? displayGrade(enrollmentInfo, metrics.academicAverage, metrics.academicAverageValue) : '---' }
                                        </p>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-4 text-center">
                                    <div className="card">
                                        <p className="title">Overdue</p>
                                        <p className="headline">{parseInt(metrics.overdue, 10)}</p>
                                    </div>
                                </div>

                                {this.state.loading ? (
                                    <Loading/>
                                ) : (
                                    <div className="col-xs-12">
                                    <Table>
                                        <THead>
                                            <TR>
                                                <TH col="4">{tableHeadings[0]}</TH>
                                                <TH col="2">{tableHeadings[1]}</TH>
                                                <TH col="3">{tableHeadings[2]}</TH>
                                                <TH col="3">{tableHeadings[3]}</TH>
                                            </TR>
                                        </THead>
                                        <TBody>
                                        {assignmentList.map((item, index) => {
                                            const isSubmittedIncomplete = item.context.contextCode === assignmentContextTypes.ACADEMIC && item.submissionGrade && isGradeIncomplete(item.submissionGrade.grade);
                                            return (
                                                <TR key={index}>
                                                    <TD col="4" heading={tableHeadings[0]}>
                                                        <strong>{item.assignment.title}</strong>
                                                    </TD>
                                                    <TD col="2" heading={tableHeadings[1]}>
                                                        {item.prework ? 'Pre-work'
                                                            : (item.context.contextCode === assignmentContextTypes.ACADEMIC ? 'Academic'
                                                                : item.context.contextCode === assignmentContextTypes.CAREER && 'Career')
                                                        }
                                                    </TD>
                                                    {item.submission ? (
                                                        <React.Fragment>
                                                            <TD col="3" heading={tableHeadings[2]}>
                                                                <strong
                                                                    className={isSubmittedIncomplete? "text-red":"text-success"}> {
                                                                        item.context.contextCode === assignmentContextTypes.CAREER ? (
                                                                            'Completed'
                                                                        ) : (
                                                                            isSubmittedIncomplete ? (
                                                                                'Submitted-Incomplete'
                                                                            ) : (
                                                                                item.submission && moment(item.submission.date).isAfter(item.assignment.effectiveDueDate) ? (
                                                                                    'Submitted Late'
                                                                                ) : (
                                                                                    'Submitted'
                                                                                )
                                                                            )
                                                                        )
                                                                    }
                                                                </strong><br/>

                                                                <span className="font-xs">
                                                                    {isSubmittedIncomplete ? (
                                                                            <React.Fragment>
                                                                                <strong>{item.assignment.bufferDays > 0 ? 'Extended Due Date:' : 'Due Date:'}</strong> {getFormattedDateString(item.assignment.effectiveDueDate)} Local
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <strong>{item.context.contextCode === assignmentContextTypes.CAREER ? 'Completed' : 'Submitted'} On:</strong> {moment(item.submission.date).format('dddd MMMM D')}
                                                                            </React.Fragment>
                                                                        )
                                                                    }
                                                                </span><br/>

                                                                {item.assignment.required ? (
                                                                        item.assignment.requiredForGraduation ? (
                                                                            <strong className="font-xs text-error">Required for Graduation</strong>
                                                                        ) : (
                                                                            <strong className="font-xs text-error">Required</strong>
                                                                        )
                                                                    ) : (
                                                                        <strong className="font-xs">Optional</strong>
                                                                    )
                                                                }
                                                            </TD>
                                                            <TD col="3" heading={tableHeadings[3]}>
                                                                <strong>Grade</strong>: {item.submissionGrade ?
                                                                <strong className={this.gradeVisualClass(item.submissionGrade.grade)}>
                                                                    { displayGrade(enrollmentInfo, getGradeName(item.submissionGrade.grade), convertLetterToPercent(item.submissionGrade.grade, '---')) }
                                                                </strong> : <strong>---</strong>}
                                                                <br/>
                                                                {/* Don't render Read Comments for career, since they dont do comments anymore. Instead render a link to the assignment */}
                                                                {item.context.id !== 2 ?
                                                                    <Link to={{pathname: `/coursework/${item.assignment.id}/show`, state: item}} aria-describedby={`read comments`}>
                                                                        Read Comments {item.commentsCount ? `(${item.commentsCount})` : ''}
                                                                    </Link>
                                                                    :
                                                                    <Link to={{pathname: `/coursework/${item.assignment.id}/show`, state: item}} aria-describedby={`view assignment details`}>
                                                                        <Icon icon="copy"/> Assignment Details
                                                                    </Link>
                                                                }
                                                            </TD>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <TD col="3" heading={tableHeadings[2]}>
                                                                {moment().isAfter(moment(item.assignment.effectiveDueDate)) && item.assignment.required ? (
                                                                    <strong className="text-red">Overdue</strong>
                                                                ) : (
                                                                    <strong>Not Submitted</strong>
                                                                )}
                                                                <p className="font-xs margin-b-0">
                                                                    <strong>{item.assignment.bufferDays > 0 ? 'Extended Due Date:' : 'Due Date:'}</strong> {getFormattedDateString(item.assignment.effectiveDueDate)} Local
                                                                </p>
                                                                {item.assignment.required ? (
                                                                    item.assignment.requiredForGraduation ? (
                                                                        <strong className="font-xs text-red">Required for Graduation</strong>
                                                                    ) : (
                                                                        <strong className="font-xs text-red">Required</strong>
                                                                    )
                                                                ) : (
                                                                    <strong className="font-xs">Optional</strong>
                                                                )
                                                                }
                                                            </TD>
                                                            <TD col="3" heading={tableHeadings[3]}>
                                                                <Link to={`/coursework/${item.assignment.id}/show`} className="btn btn-small btn-save">Submit</Link>
                                                            </TD>
                                                        </React.Fragment>
                                                    )}
                                                </TR>
                                            )
                                        })}
                                        </TBody>
                                    </Table>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </section>
            </DocumentTitle>
        )
    }
}

export const GRADES = Grades;

export default connect(mapStateToProps)(Grades);
