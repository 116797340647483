import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';

import Icon from '../../../components/Icon';
import {
    getMonthString,
    getDayNumber,
    getDayString,
    getFormattedTime} from '../../../helpers/dateTime';
import moment from 'moment';
import {isCheckInAvailable} from '../../../helpers/utils';
import {fetchSessions, sessionCheckIn} from '../../../actions/sessionActions';

import {sessionCheckInTypes} from '../../../constants/checkIn';
import {sessionContextTypes} from '../../../constants/dataTypes';
import { isStudent } from '../../../helpers/roles';
import {getAttendanceStatusText} from "../../../helpers/common";
import {attendanceStatusSpecs} from "../../../constants/attendance";
import get from "lodash/get";

const mapStateToProps = (state) => {
    return {
        enrollmentInfo: state.user.enrollmentInfo,
        hideRemoteAttendance: get(state,'user.enrollmentInfo.course.cohort.cohortUiControl.hideRemoteAttendance',true)
    }
};

export class BaseSessionGridCard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            sessionData: null
        }
    }

    classAttendanceHandler = async (e) => {
        e.preventDefault();
        const sessionId = this.state.sessionData.session.id;
        const enrollmentId = this.props.enrollmentInfo.id;

        await sessionCheckIn(sessionId);
        this.props.dispatch(fetchSessions(enrollmentId));
    }

    showCheckInLink = () => {
        const sessionData = this.state.sessionData;
        let checkInStatus = sessionData.session.startTime !== undefined ? isCheckInAvailable(sessionData.session.startTime, sessionData.session.endTime) : null;
        const attendance = getAttendanceStatusText(sessionData);
        const currentAttendanceStatus = attendanceStatusSpecs(attendance);

        if(sessionData.remoteAttendanceRequest
            || checkInStatus === sessionCheckInTypes.CHECK_IN_ENDED
            || (checkInStatus === sessionCheckInTypes.CHECK_IN_TIME && attendance !== "None")) {
            return (
                <li aria-describedby={`Attendance Satus`}>
                    You're checked in as {currentAttendanceStatus.text}
                </li>
            )
        } else if(checkInStatus === sessionCheckInTypes.CHECK_IN_TOO_EARLY) {
            return (
                <li aria-describedby={`Attendance Satus`}>Check In begins 45 minutes before Class</li>
            )
        } else {
            return (
                <li>
                    <Link to={`/attendance/check-in/${sessionData.session.id}`} onClick={this.classAttendanceHandler} aria-describedby={`Attendance Satus`}>
                        <Icon icon="calendar-check" /> Check In To Class
                    </Link>
                </li>
            )
        }
    }

    componentWillMount() {
        if(this.props.session) {
            this.setState({
                sessionData: this.props.session
            })
        }
    }

    render() {
        const sessionData = this.state.sessionData;
        return (
            <div className="col-xs-12">
                <div className="card-with-title card-wide">
                    <div className="card-title">
                        <p className="title">{getDayString(sessionData.session.startTime)}</p>
                        <p className="margin-b-1">
                            <span className="card-title-month text-primary">{getMonthString(sessionData.session.startTime)}</span>
                            <span className="card-title-date margin-l-1 text-primary">{getDayNumber(sessionData.session.startTime, true)}</span>
                        </p>
                        <p className="card-title-time">{getFormattedTime(sessionData.session.startTime)} - {getFormattedTime(sessionData.session.endTime)} Local</p>
                    </div>
                    <div className="card-content">
                        <p className="headline-three" aria-describedby={`${sessionData.session.chapter} - ${sessionData.session.name}`}>{sessionData.session.chapter} - {sessionData.session.name}</p>
                        <p>{sessionData.session.shortDescription}</p>
                    </div>
                    <div className="card-footer">
                        <ul className="card-footer-list">
                            {isStudent(this.props.enrollmentInfo.courseRole.courseRoleCode) ? (        
                                <React.Fragment>
                                    <li><Link to={`/sessions/${sessionData.session.id}`} aria-describedby={`View Session Details for ${sessionData.session.name}`}><Icon icon="copy" /> Session Details</Link></li>
                                    {
                                        !this.props.hideRemoteAttendance &&
                                        (sessionData.type !== sessionContextTypes.CAREER && 
                                        moment().isBefore(moment(sessionData.session.endTime)) && 
                                        (!sessionData.remoteAttendanceRequest)) &&
                                            <li className="remoteRequestLink"><Link to={`/attendance/remote-attendance-request/${sessionData.session.id}`} aria-describedby={`Request Remote Attendance`}><Icon icon="hand-paper" /> Request Remote Attendance</Link></li>
                                    }
                                    {sessionData.type !== sessionContextTypes.CAREER && this.showCheckInLink()}
                                </React.Fragment>
                            ) : (
                                <li><Link to={`/sessions/${sessionData.session.id}`}><Icon icon="copy" /> Manage Session and Attendance</Link></li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export const BASESESSIONGRIDCARD = BaseSessionGridCard;
export default connect(mapStateToProps)(BaseSessionGridCard);
