import {Component} from "react";
import DocumentTitle from "react-document-title";
import {connect} from 'react-redux';
import React from "react";

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps,
        openDocs: state.openDocs,
        apiKey: state.user.data.authToken,
    }
};

class ApiDocsPolicy extends Component {
    render() {

        return (
            <DocumentTitle title="API Policy | Bootcamp Spot">
                <section>
                    <div className="row">
                        <div className="col-xs-12">
                            <h1>API Policy</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                          <ol>
                            <li>
                              <h3>MODIFICATIONS TO POLICY</h3>
                              <p>BootcampSpot reserves the right, in its sole discretion, to modify this Instructor API Policy at any time. You are responsible for reviewing and becoming familiar with any modifications. Modifications are effective when first posted. To receive notifications about changes to this policy and the Instructor API functionality, see the Deprecation and API Changes section below.</p>
                            </li>
                            <li>
                              <h3>PRINCIPLES</h3>
                              <p>Applications that access the Instructor API should adhere to the following principles:</p>
                              <ul className="bulleted">
                                <li>Don't impersonate.</li>
                                <li>Don't surprise users.</li>
                                <li>Respect the privacy of any information retrieved.</li>
                                <li>Additionally, your applications must adhere to Instructor API rate limits (see the API Rate Limits section below).</li>
                              </ul>
                              <ol>
                                <li className="margin-t-2">
                                  <h4>Don't Impersonate</h4>
                                  <ul className="bulleted">
                                    <li>Your application should not mirror or replicate BootcampSpot</li>
                                    <li>Do not impersonate or facilitate impersonation of others in a manner that can mislead, confuse, or deceive users.</li>
                                    <li>End users should understand that your application is integrated with BootcampSpot but is an independent resource.</li>
                                  </ul>
                                </li>
                                <li className="margin-t-2">
                                  <h4>Don’t Surprise Users</h4>
                                  <p>Your application should not:</p>
                                  <ul className="bulleted">
                                    <li>Use the Instructor API for different purposes other than what your application states or implies.</li>
                                    <li>Confuse or mislead users about the source or purpose of your application.</li>
                                    <li>Use business names and/or logos in a manner that can mislead, confuse, or deceive users.</li>
                                    <li>Use the Instructor API on behalf of any third-party.</li>
                                    <li>Facilitate or encourage the publishing of links to malicious or obscene content.</li>
                                    <li>Your service should outline what actions your application will take on the user's behalf as part of the application registration process.</li>
                                  </ul>
                                </li>
                                <li className="margin-t-2">
                                  <h4>Respect the Privacy of any Information Retrieved</h4>
                                  <ul className="bulleted">
                                    <li>Any user information—including course enrollments, grades, profile information, etc.—retrieved through the Instructor API should be considered private information and, in some cases, will be protected by government regulations.</li>
                                    <li>Know what information your tool will disclose to the public or to other products and services, and be clear with end users about what information will be disclosed.</li>
                                    <li>Do not facilitate or encourage the publishing of private or confidential information.</li>
                                    <li>Respect the intellectual property rights of others.</li>
                                  </ul>
                                </li>
                              </ol>
                            </li>
                            <li>
                              <h3>API RATE LIMITS</h3>
                              <p>Applications that access the Instructor API must not place undue load on BootcampSpot servers. BootcampSpot has an automatic rate limiting provision that dynamically adjusts as more concurrent and/or expensive requests occur. When the rate limit is exceeded, API requests will fail. Rate limiting is enforced per user access token so that partners who perform requests on behalf of multiple end users will not be throttled per developer access token that they hold.</p>
                              <p>If an application regularly exceeds the API rate limits or uses a disproportionately large number of high-impact (e.g. non-GET) requests, the access tokens may be revoked, or other measures may be taken to ensure the stability of the system for all users.</p>
                              <p>If you are concerned about hitting the rate limit, please contact support@bootcampspot.com to either adjust your rate limit or seek assistance optimizing your application for lower impact on BootcampSpot performance.</p>
                            </li>
                            <li>
                              <h3>DEPRECATION AND API CHANGES</h3>
                              <p>The Instructor API is versioned to allow for future enhancements. BootcampSpot strives to deliver a platform that is stable, consistent, and secure so you can confidently build awesome applications on top of Instructor APIs.</p>
                              <p>BootcampSpot will add, change, and remove API endpoints and fields from time to time using reasonable efforts to provide communication - however breaking changes will take place in new version namespaces of the API url</p>
                            </li>
                            <li>
                              <h3>API SUPPORT</h3>
                              <p>Developers can submit questions about or issues with the API to the BootcampSpot Support team in one of the following ways:</p>
                              <ul>
                                <li>Email support@bootcampspot.com</li>
                              </ul>
                            </li>
                          </ol>
                        </div>
                    </div>
                </section>
            </DocumentTitle>
        )
    }
};

export default connect(mapStateToProps)(ApiDocsPolicy);
