import { roleActionTypes } from '../constants/actionTypes';
import { pending, rejected, fulfilled } from '../helpers/asyncStatusGenerator';

export const roleReducer = (state={
    allRolesLoading: false,
    allRolesError: null,
    allRoles: null,
    rolesAccess: null
}, action) => {
    const {GET_ALL_ROLES, SET_ROLES_ACCESS} = roleActionTypes;

    switch(action.type) {
        case pending(GET_ALL_ROLES):
            return {
                ...state,
                allRolesLoading: true
            };
        case rejected(GET_ALL_ROLES):
            return {
                ...state,
                allRolesLoading: false,
                allRolesError: action.payload
            };
        case fulfilled(GET_ALL_ROLES):
            return {
                ...state,
                allRolesLoading: false,
                allRolesError: null,
                rolesAccess: action.payload
            };
        case SET_ROLES_ACCESS:
            return {
                ...state,
                rolesAccess: action.payload
            };
        default:
            return state
    }
}