import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { placeholderProfilePic, getProfilePicAlt, getProfilePicSrc } from '../../helpers/profilePic';
import {connect} from 'react-redux';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps
    }
};

class ProfilePic extends Component {
    addDefaultSrc = (e) => {
        e.target.src = placeholderProfilePic;
    };

    render() {
        const {user, options, preview } = this.props;
        const inlineStyles = Object.assign({maxWidth: '250px'}, this.props.inlineStyles);

        let imageAlt = getProfilePicAlt(user);
        let imageSrc = getProfilePicSrc(user);
        return (
            <img
                alt={imageAlt}
                src={preview || imageSrc}
                className={`fill rounded ${options}`}
                style={inlineStyles}
                onError={this.addDefaultSrc}
            />
        )
    }
};

ProfilePic.propTypes = {
    user: PropTypes.object.isRequired,
    options: PropTypes.string,
    inlineStyles: PropTypes.object
};

ProfilePic.defaultProps = {
    options: '',
    inlineStyles: {}
};

export default connect(mapStateToProps)(ProfilePic);
