import React, {Component} from 'react';
import {connect} from 'react-redux';
import DocumentTitle from 'react-document-title';
import BlankSlate from '../../components/BlankSlate';
import AnnouncementCard from '../../components/AnnouncementCard';
import { announcementsChangeFilter, fetchAnnouncements } from "../../actions/announcementsActions"
import Loading from "../../components/Loading/index";
import {isStudent, isCM} from '../../helpers/roles';

const mapStateToProps = (state) => {
    return {
        userId: state.user.data.userId,
        enrollmentInfo: state.user.enrollmentInfo,
        announcements: state.announcements
    }
};

class Announcements extends Component {

    constructor(props) {
        super(props);

        this.state = {
            announcementId: 'new',
        }
    }

    componentDidMount() {
        const enrollmentInfo = this.props.enrollmentInfo;
        const enrollmentId = enrollmentInfo.id;

        this.loadAnnouncements(enrollmentId);
    }

    loadAnnouncements = async(id) => {
        try {
            let payload = {enrollmentId: id};
            this.props.dispatch(fetchAnnouncements(payload))
        } catch (err) {
            console.warn(err)
        }
    }

    onAnnouncementsFilterChanged = (e) => {
        let filter = (e.currentTarget.value).toLowerCase();

        const filteredList = this.props.announcements.announcementList.filter((item) => {
            return "all" === filter || item.contextCode.startsWith(filter)
        });

        this.props.dispatch(announcementsChangeFilter(filter, filteredList));
    }

    newAnnouncement = () => {
        this.props.history.push('/announcement/' + this.state.announcementId);
    }

    render() {
        const {announcements, enrollmentInfo} = this.props;
        const unread = announcements.unreadAnnouncements;

        let announcementBaseList = announcements.announcementFilteredList;
        if (announcements.announcementsFilter === "all") {
            announcementBaseList = announcements.announcementList
        }

        const announcementList = announcementBaseList.map((announcement) =>
            <AnnouncementCard dispatch={this.props.dispatch} item={announcement} key={announcement.id.toString()}/>
        );

        let announcementsSection = null;

        if (announcementList.length === 0) {
            announcementsSection = (
                <div className="row">
                    <div className="col-xs-12 col-gutter-lr">
                        <BlankSlate icon="inbox" header="No Announcements">
                            <p>Currently you don't have any announcements, but don't worry as soon as you do they will show up here.</p>
                        </BlankSlate>
                    </div>
                </div>
            )
        } else {
            announcementsSection = <div className="row">
                <div className="col-xs-12">
                    <ul>{announcementList}</ul>
                </div>
            </div>
        }

        const activeRole = enrollmentInfo.courseRole.courseRoleCode;

        let newAnnouncementButton = null;
        if ( !isStudent(activeRole) && !isCM(activeRole) ) {
            newAnnouncementButton = <button style={{marginBottom: '0'}} onClick={() => this.newAnnouncement()}
                                            className="btn-small btn-create">New Announcement</button>
        }

        let content = null;
        if (announcements.announcementListLoading) {
            content = <div className="row middle-xs" style={{height: '100vh'}}>
                <div className="col-xs"><Loading/></div>
            </div>
        } else {
            content = <div>
                        <div className="row">
                            <div className="col-xs-12 col-md-4">
                                {announcements.announcementList.length > 0 &&
                                <select aria-label="Announcements Menu" className="" name="" onChange={e => this.onAnnouncementsFilterChanged(e)} onBlur={e => this.onAnnouncementsFilterChanged(e)}>
                                    <option value="all">All Announcements</option>
                                    <option value="Academic">Academic</option>
                                    <option value="Career">Career</option>
                                </select>
                                }
                        </div>
                            <div className="col-xs-12 col-md-8 text-right">
                                {newAnnouncementButton}
                            </div>
                        </div>
                {announcementsSection}
            </div>
        }

        return (
            <DocumentTitle
                title={`${unread} ${unread === 1 ? 'Announcement' : 'Announcements' } | Bootcamp Spot`}>
                <section className="page" data-component="announcements">
                    <div className="row">
                        <div className={`col-xs-12 col-gutter-lr`}>
                            <h2>Announcements</h2>
                        </div>
                    </div>
                    {content}
                </section>
            </DocumentTitle>
        )
    }
}

export const ANNOUNCEMENTS = Announcements;

export  default connect(mapStateToProps)(Announcements);

