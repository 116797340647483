import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { Redirect } from "react-router-dom";
import Alert from '../../components/Alert';
import ResetPasswordScreenLayout from '../../components/ResetPasswordScreenLayout';
import { userActions } from '../../actions'
import { parse } from 'query-string';
import ReactGA from 'react-ga';
import Input from "../../components/Input";

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
}

class ResetPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            initialReset: this.props.user && this.props.user.data.errorCode === "MUST_RESET_PASSWORD",
            passwordLengthError: '',
            error: '',
            errorMessage: '',
            redirectToRoot: false
        }

    }

    static getDerivedStateFromProps(nextProps) {
        const { errors, resetPasswordSuccess } = nextProps.user;
        if (errors.resetPasswordError) {
            return { errorMessage: errors.resetPasswordError };
        } else if (resetPasswordSuccess) {
            return { error: '', redirectToRoot: true }
        }
        return null
    }

    handleChange = event => {
        let fieldName = event.target.name
        let fieldValue = event.target.value
        let obj = {}
        obj[fieldName] = fieldValue
        this.setState(obj)
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        const errors = this.validateForm();
        if (!errors) {
            ReactGA.event({
                category: 'Password',
                action: 'Reset Password',
            });
            this.setState({ error: '' })
            const params = parse(window.location.search);
            const { token, user } = params;
            const { data, profile } = this.props.user
            const payload = {
                resetToken: token ? token : (data && data.resetToken) ? data.resetToken : '',
                password: this.state.newPassword,
                userName: user ? user : (profile && profile.username) ? profile.username : '',
            }
            dispatch(userActions.resetPassword(payload));
        }
    }

    validateForm = () => {
        const { oldPassword, newPassword, confirmNewPassword } = this.state;
        const isError = this.validate({ oldPassword, newPassword, confirmNewPassword });
        return isError;
    };

    validate = (passwordObj) => {
        const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()_+=?]).{8,}/
        if (this.state.initialReset && !passwordObj.oldPassword) {
            this.setState({ error: 'Please enter your current password' })
            return true
        }

        if (passwordObj.oldPassword && passwordObj.oldPassword === passwordObj.newPassword) {
            this.setState({ error: 'Your new password must be different than your current password' })
            return true
        }

        if (!passwordObj.newPassword) {
            this.setState({ error: 'The new password field is required' })
            return true
        }

        if (!passwordObj.confirmNewPassword) {
            this.setState({ error: 'The confirm new password field is required' })
            return true
        }

        if (passwordObj.newPassword.length < 8) {
            this.setState({ error: 'Your password must be at least 8 characters in length' })
            return true
        }

        if (passwordObj.newPassword !== passwordObj.confirmNewPassword) {
            this.setState({ error: 'Your new password and confirm password fields must match' })
            return true
        }

        if (!passwordRegex.test(passwordObj.newPassword)) {
            this.setState({ error: 'The password you entered is invalid. Your password needs to have one uppercase letter, one lowercase letter, one number, and one of these special characters `~!@#$%^&*()_+=? and at least have 8 characters.' })
            return true
        }

        return false
    }

    render() {
        if (this.state.redirectToRoot) {
            return <Redirect to="/"></Redirect>
        }
        return (
            <DocumentTitle title="Reset Your Password | Bootcamp Spot">
                <section className="page" data-component="login">
                    <ResetPasswordScreenLayout>
                        <h1>Reset Your Password</h1>
                        <form onSubmit={this.handleSubmit}>
                        {
                            this.state.error ?
                                <Alert type="error" onDismiss={this.handleErrorDismiss}>
                                    <p>{this.state.error}</p>
                                </Alert>
                        : ''}
                        {this.state.initialReset
                            ? <Input
                                label="Old Password"
                                type="password"
                                id="oldPassword"
                                name="oldPassword"
                                placeholder="xxxxxxxx"
                                aria-labelledby="oldPasswordLabel"
                                aria-required="true"
                                onChange={this.handleChange} />
                            : null
                        }

                        <Input
                            label="New Password"
                            type='password'
                            id="newPassword"
                            name="newPassword"
                            placeholder="xxxxxxxx"
                            aria-labelledby="newPasswordLabel"
                            aria-required="true"
                            onChange={this.handleChange} />
                        <Input
                            label="Confirm New Password"
                            type="password"
                            id="confirmNewPassword"
                            name="confirmNewPassword"
                            placeholder="xxxxxxxx"
                            aria-labelledby="confirmNewPasswordLabel"
                            aria-required="true"
                            onChange={this.handleChange} />

                        <button type="submit" className="btn-submit btn-center" disabled={this.state.newPassword && this.state.confirmNewPassword ? false : true}>Reset Password</button>
                        
                        {this.state.passwordLengthError && <p className="context-error">{this.state.passwordLengthError}</p>}
                        </form>
                    </ResetPasswordScreenLayout>
                </section>
            </DocumentTitle>
        )
    }
}

export const RESETPASSWORD = ResetPassword;
export default connect(mapStateToProps)(ResetPassword);
