import axios from 'axios';


export async function setAttendanceStatus(payload) {
    let axiosResult = await axios.post("/broker/setAttendanceStatus", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

export async function fetchAllRemoteAttendanceRequests() {
    let axiosResult = await axios.get("/broker/manageAttendance", {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

export async function fetchRemoteAttendanceRequests(payload) {
    let axiosResult = await axios.post("/broker/attendance", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

export async function fetchStudentAttendance(payload) {
    let axiosResult = await axios.post("/broker/attendance", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

export async function requestRemoteAttendance(payload) {
    let axiosResult = await axios.post("/broker/requestRemoteAttendance", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

export async function createRemoteRequest(payload) {
    let axiosResult = await axios.post("/broker/createRemoteAttendanceRequest", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

export async function deleteRemoteRequest(payload) {
    let axiosResult = await axios.post("/broker/deleteRemoteAttendanceRequest", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}

export async function approveRemoteRequest(payload) {
    let axiosResult
    try {
        axiosResult = await axios.post("/broker/createRemoteAttendanceApproval", payload, {headers: {'Content-Type': 'application/json',}});
    } catch (error) {
        if(error.response){
            return {error: true, errorMessage: error.response.data}
        } else {
            return {error: true}
        }
    }
    return axiosResult;
}

export async function denyRemoteRequest(payload) {
    let axiosResult = await axios.post("/broker/createRemoteAttendanceDenial", payload, {headers: {'Content-Type': 'application/json',}});
    return axiosResult;
}
