import axios from 'axios'
import {pending, fulfilled, rejected} from '../helpers/asyncActionGenerator'
import {dashboardActionTypes, userActionTypes} from '../constants/actionTypes'
import {jsonContentTypeHeader} from "../constants/axios";

export function fetchDashboard(enrollmentId) {
    return async (dispatch) => {
        dispatch(pending(dashboardActionTypes.GET_DASHBOARD));
        try {
            let payload = {enrollmentId: enrollmentId};
            let axiosResult = await axios.post("/broker/dashboard", payload, {headers: jsonContentTypeHeader});
            return dispatch(fulfilled(dashboardActionTypes.GET_DASHBOARD, axiosResult.data));
        } catch (error) {
            return dispatch(rejected(dashboardActionTypes.GET_DASHBOARD, error));
        }
    }
}

export function toggleEndCourseSurvey(enrollmentInfo) {
    return async (dispatch) => {
        try {
            let payload = {
                courseId: enrollmentInfo.courseId,
                value: !enrollmentInfo.course.endSurveyEnabled
            }
            let axiosResult = await axios.post("/broker/enableNpsEndSurvey", payload, {headers: jsonContentTypeHeader});
            dispatch(fulfilled(userActionTypes.USER_GET_ENROLLMENT_INFO, axiosResult.data))
        } catch (error) {
            console.error(error)
            return dispatch(rejected(userActionTypes.USER_GET_ENROLLMENT_INFO, error));
        }
    }
}

export async function fetchSurveyToDisplay(enrollmentId, authToken) {
    let payload = {enrollmentId: enrollmentId};
    let axiosResult = await axios.post("/broker/survey", payload, { headers: {'Content-Type': 'application/json', 'authToken': authToken}});
    return axiosResult.data;
}

