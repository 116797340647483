import React, {Component} from 'react';
import {connect} from 'react-redux';

class OpenDocsApiResponse extends Component {
    render() {
        const {payload, status} = this.props;
        return (
            <React.Fragment>
                {(status !== "" && typeof status !== "undefined") ? (
                <div className="row">
                    <div className="col-xs-12">
                        <h4>Status: {status}</h4>

                        {payload &&
                        <React.Fragment>
                            <h4>Response body</h4>
                            <textarea name="payload" readOnly={true} rows="20" cols="80"
                                      value={JSON.stringify(payload, null, 2)}/>
                        </React.Fragment>}
                    </div>
                </div>
                ):''}
            </React.Fragment>
        )
    }
}

export default connect()(OpenDocsApiResponse);
