import React from 'react';

import moment from 'moment';
import Icon from '../../../components/Icon';
import {Table, TBody, THead, TR, TD, TH} from '../../../components/Table';
import {getAttendanceStatusText, getRemoteRequestStatusText} from "../../../helpers/common";

const tableHeadings = [
    'Session',
    'Location',
    'Reason',
    'Status',
    'Action'
]

const StudentTable = (props) => (
    <Table>
        <THead>
            <TR>
                <TH col="4">{tableHeadings[0]}</TH>
                <TH col="2">{tableHeadings[1]}</TH>
                <TH col="2">{tableHeadings[2]}</TH>
                <TH col="2">{tableHeadings[3]}</TH>
                <TH col="2">{tableHeadings[4]}</TH>
            </TR>
        </THead>
        <TBody>
            {props.filteredRemoteAttendanceRequests.map((item, index) => {
                let requestStatus = getRemoteRequestStatusText(item);
                return (
                    <TR key={index} data-component="absence-request-student-row"  className="font-sm">
                        <TD col="4" heading={tableHeadings[0]}>
                            <strong>{item.session.name}</strong> <br/> {moment(item.session.startTime).format('MMMM D, YYYY')}
                        </TD>
                        <TD col="2" heading={tableHeadings[1]}>{item.university.name} <br/> {item.cohort.name}</TD>
                        <TD col="2" heading={tableHeadings[2]}>{getAttendanceStatusText(item)}</TD>
                        <TD col="2" heading={tableHeadings[3]}>
                            {requestStatus === "Pending" && <strong>Pending</strong> }
                            {requestStatus === "Cancelled" && <strong className="text-orange">Cancelled</strong> }
                            {requestStatus === "Approved" && <strong className="text-green">Approved</strong> }
                            {requestStatus === "Denied" && <strong className="text-red">Denied</strong> }
                        </TD>
                        <TD col="2" heading={tableHeadings[4]}>
                            {props.cancelling ? (
                                <Icon icon="circle-o-notch" options="fa-spin" />
                            ) : (
                                requestStatus !== "Denied" && requestStatus !== "Approved" ? (
                                    <button onClick={(e) => {props.handleCancel(e, item)}} className="btn-secondary btn-delete">Cancel</button>
                                ) : 'N/A'
                            )}
                        </TD>
                    </TR>
                )
            })}
        </TBody>
    </Table>
)


export default StudentTable
