import React, {Component} from 'react';

class RatingStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: ''
        }

    }

    commitStep = async (e) => {
        try {
            await this.setState({
                checked: e.target.value
            })
            this.props.onChange(this.props.step.step.id, this.state.checked);
        } catch (e) {
            console.log(e)
        }

    }

    isChecked = (value) => {
        return (this.state.checked === value);
    }

    render() {
        const initialValue = this.props.step.step.definition.initialValue;  
        const finalValue = this.props.step.step.definition.finalValue;  

        let optionList = [];
        for (var  i = initialValue; i <= finalValue; i++){
            let value = i.toString();
            optionList.push(   <label key={this.props.step.step.id + '-' + i} htmlFor={this.props.step.step.id + '-' + i} className="label-active margin-r-3">
                                <input type="radio" name={this.props.step.step.id + '-' + i} id={this.props.step.step.id + '-' + i}
                                    checked={this.isChecked(value)}
                                    onChange={this.commitStep}
                                    value={value}/>
                                {i}
                            </label>);
        }

        return (
            <li className="margin-b-3">
                <fieldset>
                    <label>{this.props.step.step.definition.text}{this.props.step.step.required ? ' *' : null}</label>
                    <div className="row">
                        {optionList}
                    </div>
                </fieldset>
            </li>
        )
    }
}

export default RatingStep;
