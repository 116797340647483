import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import {connect} from 'react-redux';

import {Table, TBody, THead, TR, TD, TH} from '../../components/Table';
import Alert from '../../components/Alert';

import {getFormattedDateString} from "../../helpers/dateTime";
import { isCM} from '../../helpers/roles';

const mapStateToProps = (state) => {
    return {
        role: state.user.enrollmentInfo.courseRole.courseRoleCode,
        currentWeekCoursework: state.coursework.currentWeekCoursework,
        courseStudentsCount: state.coursework.courseStudentsCount,
        enrollmentInfo: state.user.enrollmentInfo,
    }
}

class InstructionalCoursework extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseworkView: 'month',
            currentWeek: props.weekNum,
            availableAssignmentTypes: [],
            hasAssignments: 0
        }
    }

    render() {
        const {courseStudentsCount, enrollmentInfo} = this.props;
        const tableHeadings = [
            'Assignment',
            'Submitted',
            'Graded',
            'Actions'
        ]
        const activeRole = enrollmentInfo.courseRole.courseRoleCode;

        return (
            <DocumentTitle title="Coursework | Bootcamp Spot">
                <section className="page" data-component="coursework">
                    <div className="row">
                        <div className="col-xs-12 col-md-8">
                            <h1>Coursework</h1>
                        </div>
                    </div>
                    <div className='row margin-b-5 padding-b-5'>
                        {this.props.currentWeekCoursework.length > 0 ? (
                            <Table>
                                <THead>
                                    <TR>
                                        <TH col="4">{tableHeadings[0]}</TH>
                                        <TH col="2">{tableHeadings[1]}</TH>
                                        <TH col="2">{tableHeadings[2]}</TH>
                                        <TH col="4">{tableHeadings[3]}</TH>
                                    </TR>
                                </THead>
                                <TBody>
                                {this.props.currentWeekCoursework.map((item, index) => {
                                    return (
                                            <TR key={index} className="font-sm">
                                                <TD col="4" heading={tableHeadings[0]}>
                                                    <p className="title">{item.title}</p>
                                                    {item.bufferDays > 0 ? (
                                                        <React.Fragment>
                                                            <p><strong>Original Due Date: </strong>{getFormattedDateString(item.dueDate)}</p>
                                                            <p><strong>Extended Due Date: </strong>{getFormattedDateString(item.effectiveDueDate)}</p>
                                                        </React.Fragment>
                                                    ) : (
                                                        <p>
                                                            <strong>Due: </strong>{getFormattedDateString(item.effectiveDueDate)}
                                                        </p>
                                                    )}
                                                </TD>
                                                <TD col="2" heading={tableHeadings[1]}><p className="headline-three">{item.submittedStudentCount}/{courseStudentsCount}</p></TD>
                                                <TD col="2" heading={tableHeadings[2]}><p className="headline-three">{item.gradedStudentCount}/{item.submittedStudentCount}</p></TD>
                                                <TD col="4" heading={tableHeadings[3]}>
                                                        <Link to={`/coursework/${item.id}/show`}
                                                            className="btn btn-small btn-tertiary btn-create margin-r-0 margin-b-1"
                                                        >
                                                            Assignment Details
                                                        </Link>
                                                        { !isCM(activeRole) ? (
                                                            <Link to={`/gradebook/assignment/${item.id}`}
                                                                className="btn btn-small btn-tertiary btn-edit margin-r-0 margin-b-0"
                                                            >
                                                                Grade Assignment
                                                            </Link>
                                                        ) : null}
                                                </TD>
                                            </TR>
                                            )
                                    })
                                }
                                </TBody>
                            </Table>
                        ) : (
                            <div className="row">
                                <div className="col-xs-12">
                                    <Alert type="warn">
                                        No coursework items found for this week
                                    </Alert>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </DocumentTitle>
        )
    }
}

export const INSTRUCTIONALCOURSEWORK = InstructionalCoursework
export default connect(mapStateToProps)(InstructionalCoursework)
