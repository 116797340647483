import React, {Component} from 'react';
import {connect} from 'react-redux';
import DocumentTitle from 'react-document-title';

import Loading from '../../components/Loading'
import {getContacts} from '../../actions/contactActions';
import ContactCard from './contactCard'
import Alert from '../../components/Alert'

import get from 'lodash/get'

const mapStateToProps = (state) => {
    const enrollmentInfo = Object.assign({}, state.user.enrollmentInfo)
    return {
        enrollmentInfo: enrollmentInfo,
    }
}

class Contacts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            instructors: [],
            tas: [],
            uniContacts: [],
            contactsReceived: false
        }
    }

    async componentDidMount() {
        let result = await getContacts(this.props.enrollmentInfo.courseId)
        if (result.getContactsSuccess) {
            this.setState({
                instructors: result.data.filter(o => o.role.courseRoleCode === "instructor"),
                tas: result.data.filter(o => o.role.courseRoleCode === "ta"),
                uniContacts: result.data.filter(o => o.role.courseRoleCode === "ssm" || o.role.courseRoleCode === "cd"),
                contactsReceived: true
            })
        } else {
            console.warn('ERROR IN GETTING CONTACTS')
        }
    }

    renderContactCards = (contacts) => {
        return contacts.map((item) => {
            return (
                <div key={item.contact.id}
                     className="col-xs-12 col-md-6">
                    <ContactCard contact={item}/>
                </div>
            )
        });
    }

    render() {
        const isColumbiaUniversity = get(this.props, 'enrollmentInfo.course.cohort.program.university.id', 0) === 94; // hard coded to avoid time consuming work for low priority change
        const isUConn = get(this.props, 'enrollmentInfo.course.cohort.program.university.id', 0) === 234; // hard coded to avoid time consuming work for low priority change

        let contacts = (
            <div>
                <div className="row padding-b-3 card-group">
                    <div className="col-xs-12 col-gutter-lr">
                        <h2>Instructor</h2>
                    </div>
                    {this.renderContactCards(this.state.instructors)}
                </div>
                <div className="row padding-b-3 card-group">
                    <div className="col-xs-12 col-gutter-lr">
                        <h2>TA</h2>
                    </div>
                    {this.renderContactCards(this.state.tas)}
                </div>
                <div className="row padding-b-3 card-group">
                    <div className="col-xs-12 col-gutter-lr">
                        <h2>Student Support & Career Staff</h2>
                    </div>
                    {this.renderContactCards(this.state.uniContacts)}
                </div>
            </div>
        )
        return (
            <DocumentTitle title="Class Contacts | Bootcamp Spot">
                <section className="page" data-component="contacts">
                    <div className="col-xs-12">
                        <div className="row">
                            <div className="col-xs-12 col-gutter-lr">
                                <h1>Class Contacts</h1>
                            </div>
                        </div>
                        {isColumbiaUniversity || isUConn ? (
                            <p className="font-lg text-bold">
                                <Alert type="info">
                                    If you need to escalate an issue, please contact Bernard Augustin, Northeast Regional Director, at <a href="mailto:baugustin@bootcampspot.com">baugustin@bootcampspot.com</a>.
                                    {
                                        isColumbiaUniversity &&
                                        <div>
                                            If you have an issue you need to escalate to Columbia directly, please email <a href="mailto:execed@cvn.columbia.edu">execed@cvn.columbia.edu</a> for immediate response.
                                        </div>
                                    }
                                    {
                                        isUConn &&
                                        <div>
                                            If you have an issue you need to escalate to UConn directly, please email <a href="mailto:soeprofed@uconn.edu">soeprofed@uconn.edu</a> for immediate response.
                                        </div>
                                    }
                                </Alert>
                            </p>) : null}
                        {this.state.contactsReceived ? contacts :
                            <div key="loading" className="col-xs-12 col-md-6">
                                <Loading/>
                            </div>}
                    </div>

                </section>
            </DocumentTitle>
        )
    }
}

export default connect(mapStateToProps)(Contacts);
