import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Table, TBody, THead, TR, TD, TH} from '../../components/Table';
import UniversityLogo from '../../components/UniversityLogo/';

const mapStateToProps = (state, ownProps) => {
    return{
        ...state,
        ...ownProps
    }
}

class Courses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            courses: this.props.user.courses,
            coursesByUniversity: [],
            filteredCoursesByUniversity: [],
            programTypeFilters: [
                {
                    type: "all",
                    display: "All types"
                },
                {
                    type: "Full Stack Flex Part-Time",
                    display: "Full Stack Flex Part-Time"
                },
                {
                    type: "Full Stack Flex Full-Time",
                    display: "Full Stack Flex Full-Time"
                },
                {
                    type: "Data Visualization Part-Time",
                    display: "Data Visualization Part-Time"
                },
                {
                    type: "Data Visualization Full-Time",
                    display: "Data Visualization Full-Time"
                },
                {
                    type: "UX/UI Part-Time",
                    display: "UX/UI Part-Time"
                },
                {
                    type: "UX/UI Full-Time",
                    display: "UX/UI Full-Time"
                }
            ],
            selectedProgramType: "all",
            universityFilters: [{
                type: "all",
                display: "All universities"
            }],
            selectedUniversity: "all"
        }

        this.props.removeNav();

        // Create coursesByUniversity array of unique universities
        this.state.courses.forEach((course) => {
            if (this.state.coursesByUniversity.indexOf(course.universityName) < 0) {
                this.state.coursesByUniversity.push(course.universityName);
                this.state.universityFilters.push({type: course.universityName, display: course.universityName});
            }
        })

        //  Restructure coursesByUniversity to contain an array to be used for courses for each unique university
        this.state.coursesByUniversity.forEach((universityName, index) => {
            this.state.coursesByUniversity.splice(index, 1, {
                universityName: universityName,
                courses: []
            });
        })

        // add courses to each object in the coursesByUniversity array
        this.state.coursesByUniversity.forEach((courseByUni) => {
            this.state.courses.forEach((course) => {
                if (courseByUni.universityName === course.universityName) {
                    courseByUni.courses.push(course);
                }
            })
        })

        this.state.filteredCoursesByUniversity = JSON.parse(JSON.stringify(this.state.coursesByUniversity));
    }

    selectProgramTypeFilter = (event) => {
        const selectedProgramType = event.target.value;
        this.setState({selectedProgramType});

        if (this.state.selectedUniversity === 'all') {
            if (selectedProgramType === 'all') {
                this.setState({ filteredCoursesByUniversity: JSON.parse(JSON.stringify(this.state.coursesByUniversity)) });
            } else {
                this.selectFilter(selectedProgramType, "programtype", "coursesByUniversity");
            }
        } else {
            // If there is a university filter applied and someone chooses to see all program types
            if (selectedProgramType === 'all') {
                // Set filteredCoursesByUniversity to coursesByUniversity with the university filter applied
                this.selectFilter(this.state.selectedUniversity, "universityName", "coursesByUniversity");
            } else { // else if there is a university filter applied and someone chooses a program type
                this.selectDoubleFilter([selectedProgramType, this.state.selectedUniversity], ["programtype", "universityName"], "coursesByUniversity")
            }
        }
    }

    selectUniversityFilter = (event) => {
        const selectedUniversity = event.target.value;
        this.setState({selectedUniversity});

        if (this.state.selectedProgramType === 'all') {
            if (selectedUniversity === 'all') {
                this.setState({ filteredCoursesByUniversity: JSON.parse(JSON.stringify(this.state.coursesByUniversity)) });
            } else {
                this.selectFilter(selectedUniversity, "universityName", "coursesByUniversity");
            }
        } else {
            // If there is a program type filter applied and someone chooses to see all universities
            if (selectedUniversity === 'all') {
                // Set filteredCoursesByUniversity to coursesByUniversity with the programtype filter applied
                this.selectFilter(this.state.selectedProgramType, "programtype", "coursesByUniversity");
            } else { // else if there is a programtype filter applied and a pre-existing university filter, someone chooses a new university
                this.selectDoubleFilter([selectedUniversity, this.state.selectedProgramType], ["universityName", "programtype"], "coursesByUniversity")
            }
        }
    }

    selectFilter = (selectedFilter, courseProp, courseArrayToFilter) => {
        let filteredCoursesByUniversity = JSON.parse(JSON.stringify(this.state[courseArrayToFilter]));
        filteredCoursesByUniversity.forEach((courseByUni) => {
            let filteredByProgramType = courseByUni.courses.filter((course) => {
                if (course[courseProp] === selectedFilter) {
                    return course;
                } else {
                    return false
                }
            })
            courseByUni.courses = filteredByProgramType;
        })
        this.setState({filteredCoursesByUniversity});
    }

    selectDoubleFilter = (selectedFilterArr, coursePropArr, courseArrayToFilter) => {
        let filteredCoursesByUniversity = JSON.parse(JSON.stringify(this.state[courseArrayToFilter]));
        //  
        filteredCoursesByUniversity.forEach((courseByUni, index) => {
            //  
            let filteredByProgramType = courseByUni.courses.filter((course) => {
                if (course[coursePropArr[0]] === selectedFilterArr[0] && course[coursePropArr[1]] === selectedFilterArr[1]) {
                    return course;
                } else {
                    return false
                }
            })
            courseByUni.courses = filteredByProgramType;
        })
        this.setState({filteredCoursesByUniversity});
    }

    selectCourse = (sectionId) => {
        alert(sectionId);
    }

    render() {
        const uniImgStyles = {
            position: "absolute",
            top: "0.6rem",
            left: "1rem",
            maxWidth: "2rem",
            maxHeight: "2rem",
            marginRight: "1rem"
        }

        const uniCellStyles = {
            position: "relative"
        }

        const tableHeadings = [
            'School',
            'Program Type',
            'Cohort',
            'Section'
        ]

        return (
            <section>
                <div className="row">
                    <div className="col-xs-12 col-gutter-lr">
                        <h1>Select a Course</h1>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <label htmlFor="filterByProgramType">Filter by Program Type</label>
                        <select aria-label="filter by program type" id="filterByProgramType" value={this.state.selectedProgramType} onChange={this.selectProgramTypeFilter} onBlur={this.selectProgramTypeFilter}>
                            {this.state.programTypeFilters.map( (programTypeFilter, index) => {
                                return (
                                    <option key={index} value={programTypeFilter.type}>{programTypeFilter.display}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <label htmlFor="filterByUniversity">Filter by University</label>
                        <select aria-label="filter by university" id="filterByUniversity" value={this.state.selectedUniversity} onChange={this.selectUniversityFilter} onBlur={this.selectUniversityFilter}>
                            {this.state.universityFilters.map( (universityFilter, index) => {
                                return (
                                    <option key={index} value={universityFilter.type}>{universityFilter.display}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-xs-12">
                        <Table>
                            <THead>
                                <TR>
                                    <TH>{tableHeadings[0]}</TH>
                                    <TH>{tableHeadings[1]}</TH>
                                    <TH>{tableHeadings[2]}</TH>
                                    <TH>{tableHeadings[3]}</TH>
                                </TR>
                            </THead>
                            <TBody>
                                {this.state.filteredCoursesByUniversity.map((courseByUni, index) => {
                                    return courseByUni.courses.map((course) => {
                                        return (
                                            <TR
                                                key={course.SectionID}
                                                role="button"
                                                onClick={() => this.selectCourse(course.SectionID)}
                                            >
                                                <TD heading={tableHeadings[0]} style={uniCellStyles} className="padding-l-3-xs padding-l-6-md">
                                                    <UniversityLogo
                                                        imgSrc={course.universityLogo}
                                                        inlineStyles={uniImgStyles}
                                                        imgAlt={course.university}
                                                        options="hide-xs show-md"
                                                    />
                                                    <span className="padding-l-0-xs padding-l-3-md">{course.universityName}</span>
                                                </TD>
                                                <TD heading={tableHeadings[1]}>{course.programtype}</TD>
                                                <TD heading={tableHeadings[2]}>{course.CohortName}</TD>
                                                <TD heading={tableHeadings[3]}>{course.SectionName}</TD>
                                            </TR>
                                        )
                                    })
                                })}
                            </TBody>
                        </Table>
                    </div>
                </div>
            </section>
        )
    }
}

export default connect(mapStateToProps)(Courses);
