import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Alert from '../Alert';

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: this.props.checked,
            labelId: this.props.id + 'Label',
            showHelper: false
        }
    }

    renderInput = () => {
        const {
            type,
            id,
            value,
            placeholder,
            required,
            onChange,
            onKeyPress,
            pattern,
            disabled,
            loading,
            error,
            success,
            warn,
            checked
        } = this.props;

        const inputProps = {
            type,
            id,
            value,
            placeholder,
            required,
            pattern,
            disabled
        }

        inputProps.name = id;

        if (type === 'checkbox') {
            inputProps.checked = this.state.isChecked;
            inputProps.onChange = (e) => {
                e.persist()
                const isCheckedState = this.state.isChecked;
                this.setState({isChecked: !isCheckedState}, () => {
                    onChange(e)
                })
            }
        } if (type === 'radio') {
            inputProps.checked = checked;
            inputProps.onChange = onChange;
        } else {
            inputProps.onChange = onChange;
            inputProps.onKeyPress = onKeyPress;
        }

        return (
            <input
                {...inputProps}
                aria-labelledby={this.state.labelId}
                aria-required={this.props.required}
                style={ type === 'checkbox' || type === 'radio' ? {'marginRight': '10px'} : {} }
                className={
                    ( type === 'search' ? ' input-search' : '') +
                    (
                        disabled ? ' input-disabled' :
                        loading ? ' input-loading' :
                        error ? ' input-error' :
                        success ? ' input-success' :
                        warn ? 'input-warn' :
                        ''
                    )
                }
                onFocus={() => this.setState({showHelper: true})}
                onBlur={() => this.setState({showHelper: false})}
            />
        )
    }

    renderLabelText = () => {
        const { label, required } = this.props;
        return `${label}${required ? ' *' : ''}`
    }

    renderHelper = () => {
        return (
            <Alert type="info">
                {this.props.helper}
            </Alert>
        )
    }

    render() {
        const { type, id, helper } = this.props;
        return (
            type === 'checkbox' || type === 'radio' ? (
                <label htmlFor={id} id={this.state.labelId}>
                    {this.renderInput()}
                    {this.renderLabelText()}
                </label>
            ) : (
                <React.Fragment>
                    <label htmlFor={id} id={this.state.labelId}>
                        {this.renderLabelText()}
                    </label>
                    {this.renderInput()}
                    { helper && this.state.showHelper ? this.renderHelper() : null}
                </React.Fragment>
            )
        )
    }
}

Input.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    placeholder: PropTypes.string,
    helper: PropTypes.string,
    checked: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func,
    tabIndex: PropTypes.number,
    pattern: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    success: PropTypes.bool,
    warn: PropTypes.bool
}

Input.defaultProps = {
    label: '',
    type: '',
    id: '',
    // value: '',
    required: false,
    disabled: false,
    loading: false,
    error: false,
    success: false,
    warn: false
}

export default Input

// Parent Component mock setup:

// Mock input handler callback functions

// handleEmailChange = (event) => {
//     this.setState({email: event.target.value});
// }

// handleGitHubUserNameChange = (event) => {
//     this.setState({gitHubUserName: event.target.value});
// }

// handleSlackIdChange = (event) => {
//     this.setState({slackId: event.target.value});
// }

// handleCheckboxChange = (event) => {
//     console.log('Checkbox checked status: ', event.target.checked)
//     console.log('Checkbox Value: ', event.target.value)
// }

// handleRadioChange = (event) => {
//     console.log('Radio checked status: ', event.target.checked)
//     console.log('Radio Value: ', event.target.value)
// }

// Mock input configurations:

//
/* <Input
    label="Email Address"
    type="email"
    id="inputForEmail"
    value={this.state.email}
    onChange={this.handleEmailChange}
    required={true}
/>
<Input
    label="GitHub User Name"
    type="text"
    id="inputForGitHubUserName"
    value={this.state.gitHubUserName}
    onChange={this.handleGitHubUserNameChange}
/>
<Input
    label="Slack ID"
    type="text"
    id="inputForSlackId"
    value={this.state.slackId}
    onChange={this.handleSlackIdChange}
/>
<Input
    label="Tester Checkbox"
    type="checkbox"
    id="inputForCheckbox"
    value={this.state.checkbox}
    onChange={this.handleCheckboxChange}
/>
<Input
    label="Tester Radio"
    type="radio"
    id="inputForRadio"
    value={"some value"}
    checked={this.state.radio === "some value"}
    onChange={this.handleRadioChange}
/> */
