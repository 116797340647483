export const roleTypes = {
	ADMIN: "admin",
	STUDENT: "student",
	INSTRUCTOR: "instructor",
	TA: "ta",
	SSM: "ssm",
  CD: "cd",
  CM: "cm" // curriculum manager
};

export const assignmentContextTypes = {
  CAREER: 'career',
  ACADEMIC: 'academic'
}

export const sessionContextTypes = {
    CAREER: 'career',
    ACADEMIC: 'academic',
    ORIENTATION: 'orientation'
}

export const finalCareerUrlList = ['Brand Statement', 'Resume', 'Portfolio', 'LinkedIn', 'Github']

export const availableCareerStatus = [
    {
        label: "Feedback Pending",
        value: 1
    },
    {
        label: "Feedback Provided",
        value: 2
    },
    {
        label: "Unable to Review",
        value: 3
    },
    {
        label: "Not Started",
        value: 4
    },
    {
        label: "In Progress",
        value: 5
    },
    {
        label: "Approved",
        value: 6
    },
    {
        label: "At Risk",
        value: 7
    }
]

export const availableGrades = [
  {
    label: "A+",
    grade: "A+"
  },
  {
    label: "A",
    grade: "A"
  },
  {
    label: "A-",
    grade: "A-"
  },
  {
    label: "B+",
    grade: "B+"
  },
  {
    label: "B",
    grade: "B"
  },
  {
    label: "B-",
    grade: "B-"
  },
  {
    label: "C+",
    grade: "C+"
  },
  {
    label: "C",
    grade: "C"
  },
  {
    label: "C-",
    grade: "C-"
  },
  {
    label: "D+",
    grade: "D+"
  },
  {
    label: "D",
    grade: "D"
  },
  {
    label: "D-",
    grade: "D-"
  },
  {
    label: "F",
    grade: "F"
  },
  {
    label: "Incomplete",
    grade: "Incomplete"
  },
]
