import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Alert from '../../components/Alert'

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
};

class AgreementSuccess extends Component {
    comebackToWall = () => {
        this.props.history.replace('/');
    };

    render() {
        return (
            <Alert type='success'>
                <h1>Thank you!</h1>
                <p>Your Agreement has been successfully sent.</p>
                <button className="btn-small" onClick={this.comebackToWall}>Continue</button>
            </Alert>
        )
    }
}

export const AGREEMENTSUCCESS = AgreementSuccess;

export default withRouter(connect(mapStateToProps)(AgreementSuccess));
