import {isStudent, isSSM, isCD, isInstructor, isTA} from "../helpers/roles";
import {getFormattedDateNoYearString} from "../helpers/dateTime";

export function getCourseReportUrl(universityId) {
    let url = null;
    if (universityId === 1) {
        url = "https://www.coursereport.com/schools/rutgers-coding-bootcamp#/reviews"
    } else if (universityId === 5) {
        url = "https://www.coursereport.com/schools/coding-boot-camp-at-ut-austin"
    } else if (universityId === 13) {
        url = "https://www.coursereport.com/schools/northwestern-coding-boot-camp"
    } else if (universityId === 16) {
        url = "https://www.coursereport.com/schools/george-washington-university-coding-boot-camp"
    } else if (universityId === 4) {
        url = "https://www.coursereport.com/schools/the-coding-boot-camp-at-uc-san-diego-extension"
    } else if (universityId === 9) {
        url = "https://www.coursereport.com/schools/berkeley-coding-boot-camp"
    } else if (universityId === 12) {
        url = "https://www.coursereport.com/schools/ucf-coding-boot-camp#/reviews"
    } else if (universityId === 6) {
        url = "https://www.coursereport.com/schools/ucla-extension-boot-camps/"
    } else if (universityId === 17) {
        url = "https://www.coursereport.com/schools/the-coding-boot-camp-at-unc-chapel-hill#/reviews"
    } else if (universityId === 53) {
        url = "https://www.coursereport.com/schools/the-coding-boot-camp-at-unc-charlotte"
    } else if (universityId === 14) {
        url = "https://www.coursereport.com/schools/georgia-tech-coding-boot-camp"
    } else if (universityId === 54) {
        url = "https://www.coursereport.com/schools/the-coding-boot-camp-at-uci-continuing-education"
    } else if (universityId === 3) {
        url = "https://www.coursereport.com/schools/university-of-arizona-coding-boot-camp"
    } else if (universityId === 55) {
        url = "https://www.coursereport.com/schools/unh-coding-boot-camp"
    } else if (universityId === 10) {
        url = "https://www.coursereport.com/schools/case-western-reserve-university-boot-camps"
    } else if (universityId === 11) {
        url = "https://www.coursereport.com/schools/southern-methodist-university-coding-boot-camp"
    } else if (universityId === 15) {
        url = "https://www.coursereport.com/schools/university-of-denver-coding-boot-camp"
    } else if (universityId === 50) {
        url = "https://www.coursereport.com/schools/university-of-utah-professional-education-coding-boot-camp"
    } else if (universityId === 52) {
        url = "https://www.coursereport.com/schools/university-of-kansas-coding-bootcamp"
    } else if (universityId === 73) {
        url = "https://www.coursereport.com/schools/university-of-richmond-coding-boot-camp"
    } else if (universityId === 118) {
        url = "https://www.coursereport.com/schools/university-of-miami-coding-boot-camp"
    } else if (universityId === 99) {
        url = "https://www.coursereport.com/schools/university-of-minnesota-coding-boot-camp"
    } else if (universityId === 110) {
        url = "https://www.coursereport.com/schools/usc-viterbi-data-analytics-boot-camp"
    } else if (universityId === 56) {
        url = "https://www.coursereport.com/schools/uc-davis-extension-coding-boot-camp"
    } else if (universityId === 67) {
        url = "https://www.coursereport.com/schools/penn-arts-and-sciences-coding-boot-camp"
    } else if (universityId === 113) {
        url = "https://www.coursereport.com/schools/university-of-toronto-school-of-continuing-studies-coding-boot-camp"
    } else if (universityId === 117) {
        url = "https://www.coursereport.com/schools/tecnologico-de-monterrey-coding-boot-camp"
    } else if (universityId === 219) {
        url = "https://www.coursereport.com/schools/washington-university-data-analytics-boot-camp"
    } else if (universityId === 94) {
        url = "https://www.coursereport.com/schools/columbia-engineering-coding-boot-camp"
    } else if (universityId === 105) {
        url = "https://www.coursereport.com/schools/uw-coding-boot-camp"
    } else {
        url = 'https://www.coursereport.com/'
    }
    return url;
}

export function getNotificationClickUrl(notification, role, userId, universityId) {
    let link = null;
    let type = notification.type;
    if (type === "homeworkDue") {
        if (isStudent(role)) {
            link = `/coursework/${notification.content.assignmentId}/submit`;
        }
    } else if (type === 'homeworkSubmissionInTime') {
        if (isStudent(role)) {
            link = `/coursework/${notification.content.assignmentId}/show`;
        }
    } else if (type === 'homeworkSubmissionAdvice') {
        if (isStudent(role)) {
            link = `/coursework/${notification.content.assignmentId}/submit`;
        }
    } else if (type === "homeworkOverdue") {
        if (isStudent(role)) {
            if (notification.content.context === "Career") {
                link = `/coursework/${notification.content.assignmentId}/show`;
            } else if (notification.content.context === "Academic") {
                link = `/coursework/${notification.content.assignmentId}/submit`;
            }
        }
    } else if (type === "homeworkSubmissionLate") {
        if (isStudent(role)) {
            link = `/coursework/${notification.content.assignmentId}/show`;
        }
    } else if (type === "homeworkSubmissionStudentComment") {
        if (isInstructor(role) || isTA(role)) {
            link = `/coursework/${notification.content.assignmentId}/show`;
        } else if (isCD(role)) {
            link = `/gradebook/${notification.content.assignmentId}/grade/${notification.content.studentId}`;
        }
    } else if (type === "homeworkSubmissionInstructorComment") {
        if (isStudent(role)) {
            link = `/coursework/${notification.content.assignmentId}/show`;
        }
    } else if (type === "homeworkSubmissionCount") {
        if (isInstructor(role) || isTA(role)) {
            link = `/coursework/${notification.content.assignmentId}/show`;
        }
    } else if (type === 'homeworkGraded') {
        if (isStudent(role)) {
            link = `/coursework/${notification.content.assignmentId}/show`;

        }
    } else if (type === "sessionScheduled") {
        if (isStudent(role) || isInstructor(role) || isTA(role)) {
            link = `/sessions/${notification.content.sessionId}`
        }

    } else if (type === "sessionToday") {
        if (isStudent(role)) {
            link = `/sessions/${notification.content.sessionId}`
        }
    } else if (type === "sessionMissed") {
        if (isStudent(role)) {
            link = `/sessions/${notification.content.sessionId}`
        }
    } else if (type === "remoteAttendanceRequest") {
        if (isSSM(role) || isTA(role)) {
            link = `/remote-attendance-requests`
        }
    } else if (type === "remoteAttendanceDenial") {
        if (isStudent(role)) {
            link = `/attendance`
        }
    } else if (type === "remoteAttendanceApproval") {
        if (isStudent(role)) {
            link = `/attendance`
        }
    } else if (type === "sectionEnded") {
        if (isInstructor(role) || isTA(role)) {
            link = `${notification.content.courseReportUrl}`
        }
    } else if (type === "endOfCourseSurveyAdvice") {
        if (isStudent(role)) {
            link = `${getCourseReportUrl(universityId)}`
        }
    } else if (type === "homeworkExtension") {
        if (isStudent(role)) {
            link = `/coursework/${notification.content.assignmentId}/submit`;
        }
    } else if (type === "recommendation") {
        if (isStudent(role)) {
            link = `${notification.content.youCanBookMeUrl}`
        }
    } else if (type === "graduationStatus") {
        if (isStudent(role)) {
            link = `/students/${notification.content.studentEnrollmentId}`
        }
    }
    if (link === null) {
        link = '/'
    }
    return link;
}

export const notificationSpecs = (notification) => {
    const notificationsSpecsObj = {
        'homeworkOverdue': {
            titleIcon: {
                type: 'exclamation-circle',
                color: 'red'
            },
            contentPre: 'Overdue: ',
            footerIcon: 'cloud-download-alt'
        },
        'homeworkDue': {
            titleIcon: {
                type: 'exclamation-circle',
                color: 'orange'
            },
            contentPre: 'Due: ',
            footerIcon: 'cloud-download-alt'
        },
        'homeworkSubmissionCount': {
            titleIcon: {
                type: 'exclamation-circle',
                color: 'orange'
            },
            contentPre: 'Submission count: ',
            footerIcon: 'cloud-download-alt'
        },
        'homeworkGraded': {
            titleIcon: {
                type: 'clipboard-check',
                color: ''
            },
            contentPre: 'Graded: ',
            footerIcon: 'comments'
        },
        'homeworkSubmissionInstructorComment': {
            titleIcon: {
                type: 'comment-alt',
                color: 'primary'
            },
            contentPre: 'Instructor commented: ',
            footerIcon: 'comments'
        },
        'homeworkSubmissionStudentComment': {
            titleIcon: {
                type: 'comment-alt',
                color: 'primary'
            },
            contentPre: 'Student commented: ',
            footerIcon: 'comments'
        },
        'sectionAnnouncement': {
            titleIcon: {
                type: 'bullhorn',
                color: 'purple'
            },
            contentPre: 'Announcement: ',
            footerIcon: 'bookmark'
        },
        'announcement': {
            titleIcon: {
                type: 'bullhorn',
                color: 'purple'
            },
            contentPre: 'Announcement: ',
            footerIcon: 'bookmark'
        },
        'sessionScheduled': {
            titleIcon: {
                type: notification.content.context === "Career" ? 'briefcase' : 'code',
                color: notification.content.context === "Career" ? 'yellow' : 'blue'
            },
            contentPre: 'Reminder: ',
            footerIcon: 'copy'
        },
        'remoteAttendanceApproval': {
            titleIcon: {
                type: 'thumbs-up',
                color: 'green'
            },
            contentPre: 'Remote Attendance Request: ',
            footerIcon: 'forward'
        },
        'remoteAttendanceDenial': {
            titleIcon: {
                type: 'thumbs-down',
                color: 'red'
            },
            contentPre: 'Remote Attendance Request: ',
            footerIcon: 'forward'
        },
        "remoteAttendanceRequest": {
            titleIcon: {
                type: 'hand-paper',
                color: 'yellow'
            },
            contentPre: 'Remote Attendance Request: ',
            footerIcon: 'forward'
        },
        'feedbackReminder': {
            titleIcon: {
                type: 'comment-alt',
                color: 'orange'
            },
            contentPre: 'Reminder: ',
            footerIcon: 'bullhorn'
        },
        'homeworkSubmissionLate': {
            titleIcon: {
                type: 'stopwatch',
                color: 'red'
            },
            contentPre: notification.content.context === "Career" ? 'Career Milestone Submitted Late: ' : 'Homework submitted late: ',
            footerIcon: 'forward'
        },
        'homeworkSubmissionInTime': {
            titleIcon: {
                type: 'clock',
                color: 'green'
            },
            contentPre: 'Homework submitted on time: ',
            footerIcon: 'forward'
        },
        'homeworkSubmissionAdvice': {
            titleIcon: {
                type: 'info-circle',
                color: 'green'
            },
            contentPre: 'Friendly tip: ',
            footerIcon: 'forward'
        },
        'sessionToday': {
            titleIcon: {
                type: 'bell',
                color: 'green'
            },
            contentPre: 'Reminder: ',
            footerIcon: 'forward'
        },
        'sessionMissed': {
            titleIcon: {
                type: 'stopwatch',
                color: 'red'
            },
            contentPre: 'Session is overdue: ',
            footerIcon: 'forward'
        },
        'sectionEnded': {
            titleIcon: {
                type: 'exclamation-circle',
                color: 'orange'
            },
            contentPre: '',
            footerIcon: 'forward'
        },
        'endOfCourseSurveyAdvice': {
            titleIcon: {
                type: 'exclamation-circle',
                color: 'orange'
            },
            contentPre: '',
            footerIcon: 'forward'
        },
        'homeworkExtension': {
            titleIcon: {
                type: 'clock',
                color: 'green'
            },
            contentPre: 'Extension: ',
            footerIcon: 'cloud-download-alt'
        },
        'recommendation': {
            titleIcon: {
                type: 'chalkboard-teacher',
                color: 'green'
            },
            contentPre: '',
            footerIcon: 'forward'
        },
        'recommendationsAdvice': {
            titleIcon: {
                type: 'chalkboard-teacher',
                color: 'orange'
            },
            contentPre: '',
            footerIcon: 'forward'
        },
    }

    return notificationsSpecsObj[notification.type];
}

export const parseNotificationMessage = (notification) => {
    const nContent = notification.content;
    const {message} = nContent;
    let displayMessage;

    switch(notification.type){
        case "homeworkExtension":
            const {effectiveDueDate} = nContent;
            const formattedEffectiveDueDate = getFormattedDateNoYearString(effectiveDueDate);

            // eslint-disable-next-line no-template-curly-in-string
            displayMessage = message.replace("${effectiveDueDate}", formattedEffectiveDueDate);
            break;
        case "sessionScheduled":
            const {startTime} = nContent;
            const formattedStartTime = getFormattedDateNoYearString(startTime);

            // eslint-disable-next-line no-template-curly-in-string
            displayMessage = message.replace("${startTime}", formattedStartTime);
            break;
        default:
            displayMessage = message;
    }

    return displayMessage;
}





