import React from 'react';
import PropTypes from 'prop-types';

const UniversityLogo = (props) => {

    const placeholderUniversityLogo = 'https://s3.amazonaws.com/trilogy-ed/bcs/dev/assets/images/logo.png';
    const imgSrc = props.imgSrc ? props.imgSrc : placeholderUniversityLogo;
    const inlineStyles = props.inlineStyles ? props.inlineStyles : '';
    const imgAlt = props.imgAlt ? props.imgAlt : 'University Logo';

    const addDefaultSrc = (e) => {
        e.target.src = placeholderUniversityLogo;
    }

    return (
        <img
            src={imgSrc}
            className={props.options}
            style={inlineStyles}
            alt={imgAlt}
            onError={addDefaultSrc}
        />
    )
}

UniversityLogo.propTypes = {
    //imgSrc: PropTypes.string.isRequired,
    options: PropTypes.string,
    inlineStyles: PropTypes.object,
    imgAlt: PropTypes.string
}

UniversityLogo.defaultProps = {
    imgSrc: '',
    options: '',
    inlineStyles: {},
    imgAlt: ''
}

export default UniversityLogo;
