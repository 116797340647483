import React from 'react';

import {Table, TBody, THead, TR, TD, TH} from '../../components/Table';

const FeedbackCommentTable = (props) => {
    const comments = props.feedback.filter(item => item[props.field] !=null && item[props.field].length > 1);
    const tableHeadings = [
        'Student',
        'Comments'
    ]

    return (
        (comments && comments.length) ? (
            <Table className="font-sm">
                <THead>
                    <TR>
                        {props.isSsmOrAdmin  && <TH col="3">{tableHeadings[0]}</TH>}
                        <TH col="9">{tableHeadings[1]}</TH>
                    </TR>
                </THead>
                <TBody>
                    {comments.map((item,index) => {
                        return (
                            <TR key={index}>
                                {props.isSsmOrAdmin && <TD col="3" heading={tableHeadings[0]}>{item.studentName}</TD>}
                                <TD col="9" heading={tableHeadings[1]}>{item[props.field]}</TD>
                            </TR>
                        )
                    })}
                </TBody>
            </Table>
        ) : (
            <em className="font-sm">No comments provided in this area - for this week</em>
        )
    )
};

FeedbackCommentTable.defaultProps = {
    feedback: []
}

export default FeedbackCommentTable
