import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import moment from 'moment';

import Loading from '../../components/Loading';
import RemoteAttendanceRequestManager from '../../components/RemoteAttendanceRequest/Manager';
import {Table, TBody, THead, TR, TD, TH} from '../../components/Table';

import {fetchStudentAttendance} from '../../actions/attendanceActions';
import {isSSM, isAdmin, isStudent} from '../../helpers/roles';
import {getAttendanceStatusText, getRemoteRequestStatusText} from "../../helpers/common";
import AttendanceInfo from "../../components/AttendanceInfo";
import get from 'lodash/get';
import {attendanceStatusSpecs} from "../../constants/attendance";

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        hideRemoteAttendance: get(state,'user.enrollmentInfo.course.cohort.cohortUiControl.hideRemoteAttendance',true),
        enrollmentId: state.user.enrollmentInfo.id,
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        ...ownProps
    }
};

class Attendance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            metricsLoading: false,
            metrics: {attendance: {course: {}}},
            absenceRequestsLoading: true,
            remoteAttendanceRequests: [],
            warningMessage: `You have not submitted any absence requests for this course, when you do they can be managed here`,
            cancelling: false,
            cancelSuccess: false,
            cancelError: false,
            logLoading: true,
            attendance: [],
            attendanceHistory: [],
        }
    }

    async componentDidMount() {
        const {activeRole} = this.props;
        if (!isStudent(activeRole)) {
            this.props.history.push('/unauthorized');
        }
        await this.refreshAttendance();
    }

    refreshAttendance = async () => {
        const {enrollmentId} = this.props;
        let payload = {
            enrollmentId: enrollmentId
        };
        let result = await fetchStudentAttendance(payload);
        this.setState({
            attendanceHistory: result.data.attendanceHistory,
            remoteAttendanceRequests: result.data.remoteAttendanceRequests || [],
            logLoading: false
        })
    }

    requestedRemoteAttendanceAlready = (status) => {return status === 'Pending' || status === 'Denied' || status === 'Approved'};

    render() {
        const {activeRole, hideRemoteAttendance} = this.props;
        const tableHeadings = [
            'Sessions',
            'Status',
            'Exception Reason'
        ]

        return (
            <DocumentTitle title="Attendance History | Bootcamp Spot">
                <section className="page" data-component="attendance">
                    {this.state.metricsLoading || this.state.logLoading ? (
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="text-center"><Loading/></div>
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className="row">
                                <AttendanceInfo
                                    title={"Attendance"}
                                    topLevel={true}
                                    remoteAttendanceRequests={this.state.remoteAttendanceRequests}
                                    attendanceHistory={this.state.attendanceHistory}/>
                            </div>
                        </React.Fragment>
                    )}


                    {!hideRemoteAttendance && (isSSM(activeRole) || isAdmin(activeRole) || isStudent(activeRole)) ? (
                        <RemoteAttendanceRequestManager
                            user={this.props.user}
                            hideRemoteAttendance={hideRemoteAttendance}
                            remoteAttendanceRequests={this.state.remoteAttendanceRequests}
                            roles={activeRole}
                            cancelRefreshCB={this.refreshAttendance}
                        />
                    ) : null}


                    <div className="row padding-t-5">
                        <div className="col-xs-12 col-gutter-lr">
                            <h2>Attendance History</h2>
                        </div>
                        <div className="col-xs-12">
                            {this.state.logLoading ? (
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="text-center"><Loading/></div>
                                    </div>
                                </div>
                            ) : (
                                <Table>
                                    <THead>
                                      <TR>
                                        <TH col="8">{tableHeadings[0]}</TH>
                                        <TH col="4">{tableHeadings[1]}</TH>
                                      </TR>
                                    </THead>
                                    <TBody>
                                    {this.state.attendanceHistory.map((item, index) => {
                                        const title = (!!item.session.chapter && item.session.chapter !== "Career")? `${item.session.chapter} ${item.session.name}`:item.session.name;
                                        let attendanceStatus = getAttendanceStatusText(item);
                                        let requestStatus = getRemoteRequestStatusText(item);
                                        const currentAttendanceStatus = attendanceStatusSpecs(attendanceStatus);
                                        let isAcademic = !!item.context && item.context.contextCode === "academic";
                                        const hasExceptionReason = !!item.excusedAbsenceReason;
                                        return (
                                            <TR key={index}>
                                                <TD col="8" heading={tableHeadings[0]}>
                                                    <p className="margin-b-1"><strong>{title}</strong></p>
                                                    <p className="margin-b-1">{moment(item.session.startTime).format('MMMM D, YYYY')}</p>
                                                </TD>
                                                <TD col="4" heading={tableHeadings[1]}>
                                                    {(moment().isAfter(moment(item.session.endTime))) ? (
                                                        <React.Fragment>
                                                        <div className="attendance-status">
                                                            {(attendanceStatus === 'None')    && <span>None</span>}
                                                            {(attendanceStatus === 'Present') && <span className="text-success">Present</span>}
                                                            {(attendanceStatus === 'Remote') && <span className="text-warn">Present Remotely</span>}
                                                            {(attendanceStatus === 'Absent')  && <span className="text-error">Absent</span>}
                                                            {(attendanceStatus === 'Exception')  && <span  className={`${currentAttendanceStatus.color ? 'text-' + currentAttendanceStatus.color : ''}`}>Exception</span>}
                                                        </div>
                                                        {hasExceptionReason &&
                                                            <div>
                                                                <span className={`${currentAttendanceStatus.color ? 'text-' + currentAttendanceStatus.color : ''}`}>{item.excusedAbsenceReason.description}</span>
                                                            </div>
                                                        }
                                                        </React.Fragment>

                                                    ) : (
                                                        <div className="request-absence-link">
                                                        {attendanceStatus === 'Remote' ? (
                                                            <span className="text-warn">Present Remotely</span>
                                                        ) : (
                                                            this.requestedRemoteAttendanceAlready(requestStatus) ? (
                                                                <span>{`${requestStatus} - Remote Requested`}</span>
                                                            ) : !hideRemoteAttendance ? (
                                                                <React.Fragment>
                                                                    {isAcademic ?
                                                                            <Link to={`/attendance/remote-attendance-request/${item.session.id}`}
                                                                                className="btn btn-small btn-tertiary btn-create remoteRequestLink"
                                                                            >
                                                                                    Request Remote Attendance
                                                                            </Link>
                                                                        : "None"
                                                                    }
                                                                </React.Fragment>
                                                            ) : "None"
                                                        )}
                                                        </div>
                                                    )}
                                                </TD>
                                            </TR>
                                        )
                                    })}
                                    </TBody>
                                </Table>
                            )}
                        </div>
                    </div>

                </section>
            </DocumentTitle>
        )
    }

}

export const ATTENDANCE = Attendance;

export default connect(mapStateToProps)(Attendance);
