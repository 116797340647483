import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import OptionsStep from '../../components/FormComponent/OptionsStep';
import MultiOptionStep from '../../components/FormComponent/MultiOptionStep';
import BoolStep from "../../components/FormComponent/BoolStep";
import ShortTextStep from "../../components/FormComponent/ShortTextStep";
import TextareaStep from "../../components/FormComponent/TextareaStep";
import SelectPlacesStep from "../../components/FormComponent/SelectPlacesStep";
import LicenseStep  from "../../components/FormComponent/LicenseStep";
import RatingStep from './RatingStep';
import FormRunner from "../../runner";
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import Alert from '../../components/Alert'
import {userActions} from '../../actions';
import { releaseRouteForUser} from "../../actions/routeLockActions";
import FormNavigationPrompt from '../../components/NavigationPrompt'
import Loading from "../../components/Loading/index";
import {get} from "lodash";

let classNames = require('classnames');

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
};

class FormComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadError: false,
            submitError: "",
            isError: false,
            error: "",
            buttonClicked: false,
            loading: true,
            formTouched: false,
            multiOptionStep: false
        }
        var placeholders = new Map();
        if (this.props.placeholders !== undefined){
            placeholders = this.props.placeholders
        }

        this.formRunner = new FormRunner("", placeholders);
        if (this.props.ignoreSteps !== undefined) {
            this.formRunner.setIgnoreSteps(this.props.ignoreSteps);
        }
    }

    componentDidMount() {
        const enrollmentId = this.props.user.enrollmentInfo.id;
        const authToken = this.props.user.data.authToken;
        this.loadRunner(authToken, enrollmentId);

    }

    loadRunner = async (authToken, enrollmentId) => {
        var res = await this.formRunner.loadForm(authToken, this.props.formName, enrollmentId, this.props.extra);
        this.setState({ loadError: !res, loading: false});
    }

    getListCurrentPageStepData = () => {
        return this.formRunner.listCurrentPageStepData();
    }

  
    unlockUser = () => {
            const {dispatch,history} = this.props;
            dispatch(releaseRouteForUser());
            history.replace('/');
    }

    stepCommitted = (stepId, answer) => {
        this.setState({formTouched: true})
        this.formRunner.commitStep(stepId, answer);
        this.forceUpdate();
    }

    submitForm = async (e) => {
        e.preventDefault();

        this.setState({
            buttonClicked: true,
            submitError: ""
        })

        if (this.validatePage()) {
            try {
                let response = await this.formRunner.submit();
                
                if (response.success){
                    this.setState({
                        submitError : "",
                        formTouched: false
                    });
                    this.props.onSuccessCallback(this.formRunner.stepDataList);
                } else {
                    let updatedProperties = {}
                
                    if (get(response,'error','').toLowerCase() === "already submitted"){    
                        updatedProperties = {
                            error: response.error,
                            isError: true,
                            buttonClicked: false,
                        };
                    } else {
                        updatedProperties = {
                            submitError: response.error,
                            buttonClicked: false
                        }
                    }
                    if(response.redirectToLogout){
                        window.alert('Your session has expired, please log in again')
                        this.logout()
                    }
                    this.setState(updatedProperties)
                }
            } catch (e) {
                this.formRunner.listCurrentPageStepData();
                this.forceUpdate();
            }
        } else {
            this.setState({
                buttonClicked: false,
            });
        }
    }

    validatePage = () => {
        let validityResult = this.formRunner.checkPageValidity();
        if (!isEmpty(validityResult)) {
            this.setState({
                isError: true,
                error: validityResult
            });
            return false;
        }
    
        const listCurrentPageStepData = this.getListCurrentPageStepData();

        if (listCurrentPageStepData) {
            for (let i = 0; i < listCurrentPageStepData.length; i++) {
                let step = listCurrentPageStepData[i];
                if (step.step.required && (step.step.answer === null || (step.step.answer !== null && isEmpty(step.step.answer.value))) && step.step.visible) {
                    this.setState({
                        isError: true,
                        error: "you have not answered all the required questions"
                    });
                    return false;
                }
            }
        }
        return true;
    }

    nextPage = () => {
        if (!this.validatePage()) {
            return;
        }
        let nextPage = this.formRunner.getCurrentPage() + 1;
        for (nextPage; nextPage <= this.formRunner.getPageCount(); nextPage++){
            if (this.hasPageVisibleSteps(nextPage)){
                this.formRunner.setCurrentPage(nextPage);
                this.setState({
                    isError: false,
                    error: "",
                });
                return;
            }
        }
    }

    hasPageVisibleSteps = (page) => {
        let pageStepsList = this.formRunner.listPageStepData(page);
        for (var i = 0; i < pageStepsList.length; i++){
            if (pageStepsList[i].step.visible === true){
                return true;
            }
        }
        return false;
    }

    isCurrentPageLastVisiblePage = () => {
        var page = this.formRunner.getCurrentPage() + 1;
        while (page <= this.formRunner.getPageCount()) {
            if (this.hasPageVisibleSteps(page)){
                return false;
            }
            page++;
        }
        return true;
    }

    logout = () => {
        const {dispatch} = this.props;
        dispatch(userActions.logout());
    }

    render() {
        const listCurrentPageStepData = this.getListCurrentPageStepData();
        let stepList = null;
        if (listCurrentPageStepData) {
            stepList = listCurrentPageStepData.map((step) => {
                    let stepToShow = null;
                    let errorToShow = null;
                    if (step.step.definition.type === 'LICENSE' && step.step.visible) {
                        stepToShow = <LicenseStep onChange={this.stepCommitted} step={step} key={step.step.id}/>
                    } else if (step.step.definition.type === 'SINGLE_OPTION' && step.step.visible && step.step.definition.ui.component === 'RADIO') {
                        stepToShow = <OptionsStep onChange={this.stepCommitted} step={step} key={step.step.id}/>
                    } else if (step.step.definition.type === 'MULTIPLE_OPTIONS' && step.step.visible && step.step.definition.ui.component === 'RADIO') {
                        stepToShow = <MultiOptionStep onChange={this.stepCommitted} step={step} key={step.step.id}/>
                    } else if (step.step.definition.type === 'SINGLE_OPTION' && step.step.visible && step.step.definition.ui.component === 'CHECKBOX') {
                        stepToShow = <BoolStep onChange={this.stepCommitted} step={step} key={step.step.id}/>
                    } else if (step.step.definition.type === 'TEXT' && step.step.definition.ui.component === "TEXTEDIT" && step.step.visible) {
                        stepToShow = <ShortTextStep onChange={this.stepCommitted} step={step} key={step.step.id}/>
                    } else if (step.step.definition.type === 'TEXT' && step.step.definition.ui.component === "TEXTAREA" && step.step.visible) {
                        stepToShow = <TextareaStep onChange={this.stepCommitted} step={step} key={step.step.id}/>
                    } else if (step.step.definition.type === 'GOOGLE_PLACES' && step.step.visible) {
                        stepToShow = <SelectPlacesStep onChange={this.stepCommitted} step={step} key={step.step.id}/>
                    } else if (step.step.definition.type === 'RATING' && step.step.visible) {
                        stepToShow = <RatingStep onChange={this.stepCommitted} step={step} key={step.step.id}/>
                    }
                    return [stepToShow, errorToShow];
                }
            );
        }



        const AlreadySubmitedButtonClasses = classNames({
            'btn-create'  : true,
            'btn-tertiary': true
        });

        const buttonClasses = classNames({
            'btn-submit'  : true,      
            'btn-small'   : true,
            'btn-loading' : this.state.buttonClicked,
        });

        let content = null;

        let {showDbOnlyError} =  this.props
        let alreadySubmitted =  get(this.state,'error','').toLowerCase() === "already submitted" 

        if (this.state.loading) {
            content = <div className="row middle-xs" style={{height: '100vh'}}>
                <div className="col-xs"><Loading/></div>
            </div>
        } else if (this.state.loadError){
            content = <Alert type='error'>
                    We encountered an error while loading the
                    {(this.props.loadingErrorDescr)? " "+this.props.loadingErrorDescr:" requested survey"}. Please try <Link to="/" onClick={()=>this.logout()} aria-label="Logout of Bootcampspot">logging out</Link> and back in again to complete the survey. This sometimes occurs when your session with bootcampspot expires. If you need additional support please work with your SSM or as always you may contact us via support by using the link on the login page. 
            </Alert>
        } else if (this.state.submitError !== ""){
            content = <Alert type='error'>
                <b>There has been an error submitting the {(this.props.noun) ? " "+this.props.noun : " survey"}.</b>
                <div>This sometimes occurs if you have transferred courses between signing out of Bootcamp Spot. Please try <Link to="/" onClick={()=>this.logout()} aria-label="Logout of Bootcampspot">logging out</Link> and logging back in. If this problem persists, contact your SSM.</div>
            </Alert>
        } else {
            content = <form onSubmit={e => {
                e.preventDefault();
            }}>
                <ol className="margin-b-0">
                    {stepList}
                </ol>
                {this.state.isError &&
                    <Alert type='error'>
                        {(showDbOnlyError === true) ? 
                            this.state.error
                        : 
                            (alreadySubmitted === true)?
                                "You have already submitted this survey."
                            :
                                "This survey could not be submitted - "+this.state.error+". Please check your answers and try again."
                        }
                      
                    </Alert>}
                <div className="row margin-t-3">
                    <div className="col-xs-12 col-no-gutter">
                        {this.isCurrentPageLastVisiblePage()? 
                            (alreadySubmitted)?
                                <button
                                    role="link" 
                                    onClick={this.unlockUser}
                                    className={AlreadySubmitedButtonClasses}
                                    disabled={this.state.buttonClicked}
                                    type="button">{"Continue to the Dashboard"}
                                </button>
                            : 
                                <button onClick={(e) => this.submitForm(e)}
                                        className={buttonClasses}
                                        disabled={this.state.buttonClicked}
                                        type="button">{this.props.submitLabel || "SUBMIT"}
                                </button>
                        :
                            <button onClick={(e) => this.nextPage(e)}
                                className={buttonClasses}
                                disabled={this.state.buttonClicked}
                                type="button">{"NEXT PAGE"}
                            </button>
                        }
                    </div>
                </div>
            </form>}
        const { formTouched } = this.state
        return (
            <section className="page" data-content="NPSForm">
                <FormNavigationPrompt message='Are you sure you want to navigate away from this page while you have this form in progress?' valueExists={formTouched && ! alreadySubmitted} /> 
                <div className="row">
                    <div className="col-xs-12">
                        <h1>{this.props.title || this.formRunner.getDescription()}</h1>

                        {content}

                    </div>
                </div>
            </section>
        )
    }
}
FormComponent.propTypes = {
    formName: PropTypes.string,
    extra: PropTypes.object,
    ignoreSteps: PropTypes.arrayOf(PropTypes.number)
}

export const FORMCOMPONENT  = FormComponent;

export default connect(mapStateToProps)(FormComponent);
