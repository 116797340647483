import React, {Component} from 'react';
import {connect} from 'react-redux';
import DocumentTitle from 'react-document-title';
import Icon from '../../components/Icon';
import Loading from '../../components/Loading';
import GradeCareerAssignment from '../../components/GradeCareerAssignment';
import {FlagWithText} from '../../components/FlagWithText'

import CommentManager from '../../components/CommentManager';

import {getGradeName, gradeColorsMap} from '../../constants/grade';
import {roleTypes} from '../../constants/dataTypes';

import {courseworkActions} from '../../actions/courseworkActions';
import {assignmentContextTypes, availableGrades} from '../../constants/dataTypes';
import {Link} from 'react-router-dom';
import {isRole, isStudent} from '../../helpers/roles';
import ReactGA from 'react-ga';

const mapStateToProps = (state, ownProps) => {
    return {
        enrollmentInfo: state.user.enrollmentInfo,
        user: state.user,
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        ...ownProps
    }
}


export class GradebookAssignment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            assignment: {},
            loading: true,
            submission: {},
            comments: [],
            commentsLoading: false,
            commentAdded: false,
            feedback: '',
            showGrades: false,
            grading: false
        }
    }

    async componentDidMount() {
        const {activeRole, history} = this.props
        if(isStudent(activeRole)){
            history.push("/unauthorized")
        } else {
            await this.refreshSubmission();
        }
    }

    componentDidUpdate(prevProps){
        const {activeRole, history} = this.props
        if(prevProps.activeRole !== activeRole){
            if(isStudent(activeRole)){
                history.push("/unauthorized")
            }
        }
    }

    refreshSubmission = async () => {
        const {match} = this.props;
        let assignmentId = parseInt(match.params.assignmentId, 10);
        let studentId = parseInt(match.params.userId, 10);
        let result = await courseworkActions.fetchSubmission(assignmentId, studentId);
        this.createState(result.data.assignment, result.data.submission);
    }

    createState = (assignment, submission) => {
        this.setState({
            loading: false,
            assignment: assignment,
            submission: submission
        })
    }

    handleChange = (event) => {
        let fieldName = event.target.name
        let fieldValue = event.target.value
        let obj = {}
        obj[fieldName] = fieldValue

        this.setState(obj)
    }

    gradeAssignment = async (e) => {
        const {match} = this.props;
        const {submission} = this.state;
        if (e.target.value.length) {
            this.setState({grading: true})

            ReactGA.event({
                category: 'Homework',
                action: 'Grade Assignment',
            });

            let grade = e.target.value;
            let submissionId = submission.id;
            let assignmentId = this.state.assignment.id;
            let studentId = parseInt(match.params.userId, 10);
            let gradeId = submission.submissionGrade ? submission.submissionGrade.id : null;
            if (submission.submissionGrade) {
                await courseworkActions.updateSubmissionGrade(submissionId, grade, gradeId);
            } else {
                await courseworkActions.createSubmissionGrade(submissionId, grade);
            }
            let result = await courseworkActions.fetchSubmission(assignmentId, studentId);
            this.setState({
                grading: false,
                showGrades: false,
                assignment: result.data.assignment,
                submission: result.data.submission
            })

        }
    }

    gradeCareerAssignment = async (e) => {
        const {match} = this.props;
        if (e.target.value.length) {
            this.setState({grading: true})

            ReactGA.event({
                category: 'Homework',
                action: 'Grade Career Assignment',
            });

            let statusId = parseInt(e.target.value, 10);
            let submissionId = this.state.submission.id;
            let assignmentId = this.state.assignment.id;
            let studentId = parseInt(match.params.userId, 10);
            await courseworkActions.setCareerFeedbackStatus(submissionId, statusId);
            let result = await courseworkActions.fetchSubmission(assignmentId, studentId);
            this.setState({
                submission: result.data.submission,
                grading: false,
                showGrades: false
            })
        }
    }

    updateGrade = async (e) => {
        e.preventDefault()
        this.setState({showGrades: !this.state.showGrades})
    }

    render() {
        const {assignment, submission} = this.state;
        const {activeRole} = this.props;

        return (
            <DocumentTitle title="Grade Assignment | Bootcamp Spot">
                <section className="page" data-component="grade-assignment">
                    {this.state.loading ? (
                        <Loading/>
                    ) : (
                        <div className="grade-assignment">
                            <div className="row">
                                <div className="col-xs-8 col-gutter-lr margin-t-3">
                                    <h1>Grade Coursework</h1>
                                </div>
                                <div className="col-xs-4 col-gutter-lr margin-t-3 text-right">
                                    <Link to="/gradebook">View Gradebook</Link>
                                </div>
                                <div className="col-xs-12">
                                    <p>
                                        <strong>Student:</strong>{' '}
                                        <Link to={`/students/${submission.studentEnrollmentId}`}
                                              aria-describedby={`View ${submission.student.firstName} ${submission.student.lastName}'${submission.student.lastName.endsWith('s') ? '' : 's'} Profile`}>
                                            {`${submission.student.firstName} ${submission.student.lastName}`}
                                        </Link>
                                    </p>
                                    <p><strong>Assignment:</strong> {assignment.title}</p>
                                    <p><strong>Student Notes:</strong> {submission.notes ? submission.notes : 'None'}
                                    {(submission && submission.submissionPlagiarism) ? <FlagWithText text="This assignment has been flagged for plagiarism by a grader"/> : null}
                                    </p>
                                </div>
                                <div className="col-xs-12 col-md-6 col-gutter-lr">
                                    {assignment.context.contextCode === assignmentContextTypes.CAREER ? (
                                        <h2>Feedback</h2>) : (
                                        <h2>Current Grade</h2>)}
                                    {(submission && submission.submissionPlagiarism) ? <FlagWithText text="Flagged for Plagiarism"/> : null}
                                    {assignment.context.contextCode === assignmentContextTypes.CAREER ? (
                                        <GradeCareerAssignment
                                            assignment={assignment}
                                            submission={submission}
                                            feedback={submission.careerFeedback.careerFeedbackStatusId}
                                            showGrades={this.state.showGrades}
                                            grading={this.state.grading}
                                            gradeCB={this.gradeCareerAssignment}
                                            toggleCB={this.updateGrade}
                                        />
                                    ) : (
                                        // Academic Assignment
                                        submission.submissionGrade ? (
                                            <div>
                                                {this.state.grading ? (
                                                    <Loading/>
                                                ) : (
                                                    <React.Fragment>
                                                        <h1 className={gradeColorsMap.get(submission.submissionGrade.grade)}>{getGradeName(submission.submissionGrade.grade)}</h1>
                                                        {!isRole([roleTypes.CD, roleTypes.CM], activeRole) && <a href="#edit-academic-grade" onClick={this.updateGrade}>Edit</a>}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                {this.state.grading ? (
                                                    <Loading/>
                                                ) : (
                                                    <span className="text-red font-sm">Ungraded<br/></span>
                                                )}
                                            </div>
                                        )
                                    )}
                                    {(this.state.showGrades || !submission.submissionGrade) && (assignment.context.contextCode !== "career" && !isRole([roleTypes.CD, roleTypes.CM], activeRole)) ? (
                                        <select name="assignment_grade" onBlur={this.gradeAssignment} onChange={this.gradeAssignment}>
                                            <option value="">Choose Grade</option>
                                            {availableGrades.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.grade}>{item.label}</option>
                                                )
                                            })}
                                        </select>
                                    ) : null}
                                </div>
                                <div className="col-xs-12 col-md-6 col-gutter-lr">
                                    <h2>Submitted Links</h2>
                                    <ul>
                                        {submission.submissionUrlList.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                                                        <Icon icon="code-fork"/>{item.title ? item.title + ': ' : ''}Submitted
                                                        Link
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    <em className="font-xs">These links will open in a new window</em>
                                </div>
                                {!isRole([roleTypes.CM], activeRole) ? (
                                    <div className="col-xs-12">
                                        <CommentManager refresh={this.refreshSubmission} submission={submission} assignment={assignment} />
                                    </div>
                                ) : null }
                            </div>
                        </div>
                    )}
                </section>
            </DocumentTitle>
        )
    }

}

export const GRADEBOOKASSIGNMENT = GradebookAssignment;
export default connect(mapStateToProps)(GradebookAssignment);
