import queryString from 'query-string';
import { isGradeIncomplete } from '../constants/grade'
import { gradeStatuses, allBasicGrades } from '../constants/grade';
import { requiredHomeworkTypes } from '../constants/assignments';
import { isCD, isAdmin, isSSM } from '../helpers/roles';
import { sessionContextTypes } from '../constants/dataTypes';
import { find, orderBy } from 'lodash';

export const searchParams = (searchParams) => {
  // contains prop (names of state props) and param (name of the individual query params in URL)
  const paramsArray = [
    { prop: 'selectedAssignment', param: 'assignment' },
    { prop: 'selectedAssignmentType', param: 'assignmentType' },
    { prop: 'selectedGrade', param: 'grade' },
    { prop: 'selectedGradeStatus', param: 'gradeStatus' },
    { prop: 'selectedRequired', param: 'required' },
    { prop: 'selectedStudent', param: 'student' },
  ];

  // take URL string and parse query params
  const params = new URLSearchParams(searchParams);
  let searchParamsObj = {};

  // cycle through paramsArray and see if 'params' holds the individual param found in the paramsArray object
  // if the param is found (potentialParam) then add that that value to the appropriate searchParamsObj prop
  // update the state to reflect the values of all params in the URL that are present
  paramsArray.forEach(({ prop, param }) => {

    let potentialParam = params.get(param);

    if (potentialParam) {
      searchParamsObj[prop] = potentialParam;
    };

  });
  return searchParamsObj
}


export const filterAssignmentList = (props, state) => {
  const parsed = queryString.parse(props.location.search);
  let { assignment, gradeStatus, student, required, assignmentType, grade } = parsed;
  let { submissionsAll } = state;

  let filteredAssignments = submissionsAll;
  let selectedAssignmentId = assignment;
  let selectedGradeStatus = gradeStatus;
  let selectedStudent = student;
  let selectedAssignmentType = assignmentType;
  let selectedGrade = grade;
  let isRequired = required;

  //first filter by assignment
  if (selectedAssignmentId !== 'all') {
    filteredAssignments = state.submissionsAll.filter(item => parseInt(item.assignment.id) === parseInt(selectedAssignmentId))
  }

  //filter by gradeStatus
  if (selectedGradeStatus !== gradeStatuses.all) {
    if (selectedGradeStatus === gradeStatuses.graded) {
      filteredAssignments = filteredAssignments.filter(item => item.submissionGrade);
    }
    if (selectedGradeStatus === gradeStatuses.ungraded) {
      filteredAssignments = filteredAssignments.filter(item => !item.submissionGrade);
    }
  }

  //filter by isRequired
  if (isRequired !== requiredHomeworkTypes.All) {
    if (isRequired === requiredHomeworkTypes.Optional) {
      filteredAssignments = filteredAssignments.filter(item => !item.assignment.required);
    }

    if (isRequired === requiredHomeworkTypes.Required) {
      filteredAssignments = filteredAssignments.filter(item => item.assignment.required && !item.assignment.requiredForGraduation);
    }

    const requiredForGraduation = Object.keys(requiredHomeworkTypes)[Object.values(requiredHomeworkTypes).indexOf(requiredHomeworkTypes.RequiredForGraduation)];
    if (isRequired === requiredForGraduation) {
      filteredAssignments = filteredAssignments.filter(item => item.assignment.requiredForGraduation);
    }
  }

  // filter by assignment type (career, academic)
  if (selectedAssignmentType !== 'all') {
    filteredAssignments = filteredAssignments.filter(item => item.context.contextCode === selectedAssignmentType);
  }

  //and then by student
  if (selectedStudent !== 'all') {
    filteredAssignments = filteredAssignments.filter(item => parseInt(item.student.id, 10) === parseInt(selectedStudent, 10));
  }

  // filter by grade
  if (selectedGrade !== allBasicGrades.all) {
    /* first filter out submissions where submissionGrade == null */
    filteredAssignments = filteredAssignments.filter((item) => {
      return item.submissionGrade !== null
    });
    /* then filter for selectedGrade. Note: if selectedGrade == "A", show "A", "A+" & "A-" */
    filteredAssignments = filteredAssignments.filter((item) => {
      // workaround logic for backend storing both I and Incomplete as values.
      if (isGradeIncomplete(item.submissionGrade.grade) && (selectedGrade === 'I' || selectedGrade === 'Incomplete')) {
        return true
      } else {
        return (item.submissionGrade.grade === selectedGrade) || (item.submissionGrade.grade === `${selectedGrade}+`) || (item.submissionGrade.grade === `${selectedGrade}-`)
      }
    });
  }

  return filteredAssignments
}

export const filterSubmissions = (submissions, type) => {
  let count = 0;
  for (let i = 0; i < submissions.length; i++) {
    const current = submissions[i]
    if (current.context.id === 2) {
      const id = current.careerFeedback.careerFeedbackStatusId
      if (type === gradeStatuses.graded && (id === 2 || id === 3 || id === 5 || id === 6 || id === 7)) {
        count++
      } else if (type === gradeStatuses.ungraded && current.submissionGrade) {
        count++
      }
    } else if (current.context.id === 1) {
      if (type === gradeStatuses.graded && current.submissionGrade) {
        count++
      } else if (type === gradeStatuses.ungraded && !current.submissionGrade) {
        count++
      }
    }
  }
  return count
}

/*
  - Admins & SSMs see all assignments
  - CDs only see career assignments
  - Instructors & TAs only see academic assignments
*/

export const filterSubmissionsByUserType = (submissions, activeRole) => (
  submissions.filter((item, index) => {
    if (isAdmin(activeRole) || isSSM(activeRole)) {
      return true;
    } else {
      if (isCD(activeRole)) {
        if (item.context.contextCode === sessionContextTypes.CAREER) {
          return true;
        }
      } else if (item.context.contextCode === sessionContextTypes.ACADEMIC) {
        return true;
      } 
    }
    return false 
  })
)

export const filterAssignmentAndStudentDropDownList = (data) => {
  let assignmentFilterList = [];
  let studentFilter = [];

  data.forEach((item) => {
    if (!find(assignmentFilterList, { AssignmentID: item.assignment.id })) {
      //setup assignment filters
      assignmentFilterList.push({
        AssignmentID: item.assignment.id,
        Title: item.assignment.title
      })
    }
    //build student filter list
    let studentName = `${item.student.firstName} ${item.student.lastName}`
    if (!find(studentFilter, { UserID: item.student.id })) {
      studentFilter.push({
        UserID: item.student.id,
        Name: studentName,
        FirstName: item.student.firstName,
        LastName: item.student.lastName,
        UserAccountAvatar: item.userAccountAvatar
      })
    }
    studentFilter = orderBy(studentFilter, ['Name'], ['asc']);
  })

  return {
    assignmentFilterList,
    studentFilter
  }
}
