import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import axios from 'axios';
import { ytConfig } from '../../constants/youTube';
import Loading from '../../components/Loading';
import Alert from '../../components/Alert';
import YouTube from 'react-youtube';
import ReactGA from 'react-ga';

const mapStateToProps = (state, ownProps) => {
    return{
        ...state,
        ...ownProps
    }
  }

class VideoWatch extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
            loading: true,
            video: false,
            backLink: (this.props.removeNav) ? '/videos/onboarding' : '/videos',
            backLinkText: (this.props.removeNav) ? 'More Onboarding Videos' : 'Video Library'
        }
        ReactGA.event({
            category: 'Video Library',
            action: 'Video View',
        });
        if(this.props.removeNav)
        {
            this.props.removeNav()
            ReactGA.event({
                category: 'Video Library',
                action: 'Onboarding Video View',
            });
        }
    }

    componentDidMount()
    {
        const videoId = this.props.match.params.videoId;
        this.getVideoDetails(videoId)
        .then(videoData => {
            this.setState({video: videoData.data.items[0], loading: false});
        })
        .catch(err => {
            this.setState({loading: false});
        });
    }

    async getVideoDetails(videoId)
    {
        const { YT_API_KEY, YT_API_URL} = ytConfig;
        const videoURL = `${YT_API_URL}/videos?key=${YT_API_KEY}&id=${videoId}&part=snippet,status`;
        return await axios.get(videoURL);
    }

    onVideoEnd(event)
    {
        // const {videoId} = this.props.match.params;
        // const {userId} = this.props.user.data;
        // axios.post('/api/video/youtube/track', {event: "end", user_id: userId, video_id: videoId});
    }

    onVideoReady(event)
    {
        // const {videoId} = this.props.match.params;
        // const {userId} = this.props.user.data;
        // axios.post('/api/video/youtube/track', {event: "play", user_id: userId, video_id: videoId});
    }

    render()
    {
        const {loading, video} = this.state;
        const playerOpts = {
            height: '545',
            width: '100%',
            playerVars: {
              autoplay: 1,
              modestbranding: 1,
              rel: 0
            }
          };
        return(
            <DocumentTitle title={`${(video) ? video.snippet.title : 'Watch Video'} | Bootcamp Spot`}>
                <section className="page" data-component="video-watch">
                    <div className="row">
                        <div className="col-xs-12">
                            <Link to={this.state.backLink} className="btn btn-secondary btn-small">Back To {this.state.backLinkText}</Link>
                        </div>
                    </div>

                        <div className="row">
                            <div className="col-xs-12">
                                <YouTube
                                    videoId={this.props.match.params.videoId}
                                    opts={playerOpts}
                                    onEnd={this.onVideoEnd.bind(this)}
                                    onReady={this.onVideoReady.bind(this)}
                                />
                            </div>
                        </div>

                    <div className="row margin-t-3">
                        <div className="col-xs-12 video-details card">
                            {loading ? (
                                <Loading>Loading Video Details</Loading>
                            ) : (
                                video ? (
                                    <div className="details">
                                        <p className="title">{video.snippet.title}</p>
                                        <p>{video.snippet.description}</p>
                                    </div>
                                ) : (
                                    <Alert type="warn">There was an error fetching the details for this video. Try refreshing the page. If the problem persists, reach out to support</Alert>
                                )
                            )}
                        </div>
                    </div>
                </section>
            </DocumentTitle>
        )
    }

}

export default connect(mapStateToProps)(VideoWatch);
