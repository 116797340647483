

export const getAttendanceStatusText = (session) => {
    let text = '';

    if (session.attendance !== null && session.attendance) {
        if (session.attendance.remote) {
            text = 'Remote'
        } else {
            text = 'Present'
        }
    } else if (session.absence !== null && session.absence) {
        if (session.absence.excused) {
            text = 'Exception'
        } else {
            text = 'Absent'
        }
    } else {
        text = 'None'
    }

    return text;
}

export const getRemoteRequestStatusText = (session) => {
    let text = '';

    if (session.remoteAttendanceApproval === null
        && session.remoteAttendanceDenial === null
        && session.remoteAttendanceRequest !== null) {
        text = 'Pending'
    } else if (session.remoteAttendanceApproval !== null) {
        text = 'Approved'
    } else if (session.remoteAttendanceDenial !== null) {
        text = 'Denied'
    }

    return text;
}
