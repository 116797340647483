import {backButtonActionTypes} from '../constants/actionTypes';

const initialState = {
  routeHistory: ['/', '/']
};

export const backButtonReducer = (state = initialState, action) => {
  switch (action.type) {
    case backButtonActionTypes.ADD_ROUTE_ITEM:
      if(state.routeHistory[state.routeHistory.length-1] === action.payload) {
        return state;
      }
      else {
        return { 
          routeHistory: [...state.routeHistory, action.payload]
        }
      }
    default:
      return state;
  }
};
