import React from 'react';
import PropTypes from 'prop-types';

import SingleCourseworkLinks from './singleCourseworkLinks'
import { dateRangeHelpers } from '../../helpers/dateTime';
import {assignmentContextTypes} from "../../constants/dataTypes";

const SingleCoursework =  (props) => {
    const { coursework, userRole, hideDueDate } = props;
    const effectiveDueDate = dateRangeHelpers.splitDateIntoDisplayObject(coursework.effectiveDueDate);
    const date = effectiveDueDate;

    return(
        <section className={`card-with-title card-wide ${(!hideDueDate && props.overdue) ? "text-grey" : ""} ${props.optionalClasses}`}>
            {!hideDueDate ? 
                 (<div className="card-title">
                    <p className="headline-three">{coursework.bufferDays > 0 ? 'Extended Due Date:' : 'Due:'}</p>
                    <p className="title">{date.day}</p>
                    <time className="margin-b-1">
                        <span className="card-title-month text-primary">{date.month}</span>
                        <span className="card-title-date margin-l-1 text-primary">{date.date}</span>
                    </time>
                    <br/>
                    <time className="card-title-time"> by {date.time} Local</time>
                 </div>)
                : null
            }

            <div className="card-content">
                <h3>{coursework.title}</h3>
                {coursework.assignmentHeader ? <p>{coursework.assignmentHeader.header}</p> : null}
                <p className="font-sm">
                    {coursework.context.contextCode === assignmentContextTypes.CAREER ? (
                        null
                    ) : (
                        <em>
                        This Assignment is {coursework.required ? (
                            coursework.requiredForGraduation ? (
                                <strong>Required for Graduation</strong>
                            ) : (
                                <strong>Required</strong>
                            )
                        ) : (
                            <strong>Not Required</strong>
                        )} and {coursework.submission ? (
                            'has'
                        ): (
                            'has not'
                        )} been submitted.
                    </em>
                    )}
                </p>
            </div>
            <div className="card-footer">
                <SingleCourseworkLinks userRole={userRole} coursework={coursework}/>
            </div>
        </section>
    )
}

SingleCoursework.propTypes = {
    userRole: PropTypes.string.isRequired,
    coursework: PropTypes.object.isRequired
}

export default SingleCoursework;
