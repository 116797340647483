import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import DocumentTitle from 'react-document-title';
import Alert from '../../components/Alert';
import LoginScreenLayout from '../../components/LoginScreenLayout';
import {userActions} from '../../actions';
import ReactGA from 'react-ga';

var classNames = require('classnames');

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
}
// test2
/// alksmdlamlsdm
class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: '',
            resetUrl: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        let href = window.location.href;
        let url = href.toString();
        let resetUrl = url.replace('forgot-password','reset-password');
        this.setState({
            resetUrl: resetUrl
        })
    }

    componentWillUnmount() {
        const { dispatch } = this.props
        dispatch(userActions.resetUserLoginAndErrorState())
    }

    handleErrorDismiss() {
        this.setState({errorMessage: ''});
    }

    handleChange(event) {
        let fieldName = event.target.name
        let fieldValue = event.target.value
        let obj = {}
        obj[fieldName] = fieldValue

        this.setState(obj)
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit(e)
        }
    }

    handleSubmit() {
        const {dispatch} = this.props;
        if (this.state.emailAddress.length) {
            ReactGA.event({
                category: 'Password',
                action: 'Forgot Password',
            });
            this.handleErrorDismiss();
            dispatch(userActions.forgotPassword(this.state.emailAddress, this.state.resetUrl))
        }
    }

    render() {
        const {user, location} = this.props;
        const buttonClasses = classNames({
            'btn-submit': true,
            'btn-center': true,
            'btn-loading': false,
            'btn-edit': !this.state.emailAddress.length
        })
        return (
            <DocumentTitle title="Reset Your Password | Bootcamp Spot">
                <section className="page" data-component="forgot-password">
                    <LoginScreenLayout>
                        <h1>Reset your password</h1>
                        <p>Forgot your password? That's okay - enter the email address that you use to login to
                            BootcampSpot and we'll give you information on how to reset your password</p>
                        {location.state && location.state.message ? (
                            <Alert type="info">
                                <p>{location.state.message}</p>
                            </Alert>
                        ) : null}
                        {user.forgotPasswordSuccess ? (
                            <Alert type="success">
                                <p>Success! We've sent instructions on resetting your password to your email address</p>
                            </Alert>
                        ) : null}

                        {user.errors.forgotPasswordError ? (
                            <Alert type="error">
                                <p>Your email address or Password is incorrect. Please try again.</p>
                            </Alert>
                        ) : null}

                        <label htmlFor="emailAddress" id="emailAddressLabel">Email Address</label>
                        <input type="email" id="emailAddress" name="emailAddress" placeholder="student@example.com"
                               aria-labelledby="emailAddressLabel" aria-required="true" onChange={this.handleChange}
                               onKeyPress={this.handleKeyPress}/>

                        <button className={buttonClasses} onClick={() => this.handleSubmit()}
                                disabled={!this.state.emailAddress.length}>Reset Password
                        </button>
                        <p className="text-center"><Link to="/login">Remember your password? Then it's time to
                            login!</Link></p>
                    </LoginScreenLayout>
                </section>
            </DocumentTitle>
        )
    }


}

export const FORGOTPASSWORD = ForgotPassword;
export default connect(mapStateToProps)(ForgotPassword);
