import React from 'react'

export const AskBCSCallToAction = ({dataBcsProp, deepLink, askBcsLogoDark}) => {
    return (
        <div className="row" {...dataBcsProp}>
            <div className="col-no-gutter col-xs-12 col-md-12">
                <div>
                    <div>
                        <span className="text-bold">Have a question about your coursework? </span> 
                        <span>Your learning assistants are here to help you with homework and in-class activities. </span>
                        <a href={deepLink}
                            className="text-underline"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="AskBCS Learning Assistant">Use the AskBCS Learning Assistant in Slack</a>
                        <a href={deepLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="AskBCS Learning Assistant">
                            <img className="ask-bcs" src={askBcsLogoDark}
                                    alt="AskBCS Learning Assistant"/>
                        </a>
                    </div>
                    <div className="padding-b-4">
                        <span className="text-bold">For help with student services or other inquiries, </span>
                        <span>
                            use the support form below to create a support ticket. This includes questions about technical support, tuition & financing, course progress, tutoring, attendance, career services, and all other inquiries.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}