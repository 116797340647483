import React from 'react';

const ResetPasswordScreenLayout = (props) => (
    <div data-component="reset-password-screen-layout">
        <div className="login-marketing"></div>
        <div className="login-container padding-t-6" role="application">
            {props.children}
        </div>
    </div>
)

export default ResetPasswordScreenLayout
