import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import Icon from '../../components/Icon';
import Alert from '../../components/Alert'
import ProfilePic from '../../components/ProfilePic';
import {Table, TBody, THead, TR, TD, TH} from '../../components/Table';
import { isRole } from '../../helpers/roles';
import {getAttendanceStatusText} from "../../helpers/common";
import {refreshStudents} from "../../actions/sessionActions";
import {setAttendanceStatus} from "../../actions/attendanceActions";
import {attendanceStatusSpecs} from "../../constants/attendance";
import {roleTypes} from '../../constants/dataTypes';
import {isAfterEndOfDay} from '../../helpers/timeUtils'
import get from "lodash/get";

const mapStateToProps = (state) => {
    return{
        attendanceLog: state.sessions.selected.students,
        session: state.sessions.selected,
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        sessionId: state.sessions.selected.session.session.id,
        exceptionReasons: state.sessions.selected.excusedAbsenceReasons,
        hideRemoteAttendance: get(state,'user.enrollmentInfo.course.cohort.cohortUiControl.hideRemoteAttendance',true)
    }
}

export class ManageAttendance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedStudent: null,
            attendanceLog: this.props.attendanceLog || [],
            exceptionReasons: this.props.exceptionReasons || [],
            working: false,
            workingStudents: [],
            attendanceFilter: 'all',
            filteredAttendanceLog: [],
            attendanceLocked: false,
            success: false,
            exceptionSelected: false
        }
    }

    componentDidMount() {
       this.checkAttendanceLock()
    }

    checkAttendanceLock = () => {
        const {session, activeRole} = this.props
        if(isRole([activeRole], roleTypes.SSM) || isRole([activeRole], roleTypes.ADMIN)) return // if user is a SSM or Admin, we don't want to lock attendance ever.

        const sessionEndDateTime = new Date(session.session.session.endTime)
        const isLocked = isAfterEndOfDay(sessionEndDateTime)
        this.setState({attendanceLocked: isLocked})
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.attendanceLog !== nextProps.session.students) {
            this.setState({
                attendanceLog: nextProps.session.students,
                working: false,
                success: true,
                exceptionSelected: false
            })
            this.refreshAttendanceList();
        }
    }

    async updateAttendanceStatus(status, reasonId, studentId) {
        let tmpState = Object.assign({},this.state);
        tmpState.working = true;
        tmpState.selectedStudent = studentId;
        this.setState(tmpState)

        const {sessionId, dispatch} = this.props;

        let payload = {
            sessionId: sessionId,
            studentId: studentId,
            statusCode: status,
            excusedAbsenceReasonId: reasonId
        }
        try {
            await setAttendanceStatus(payload);
            ReactGA.event({
                category: 'Attendance',
                action: 'Mark Attendance',
            });
            dispatch(refreshStudents(sessionId));
        } catch(err) {
            console.error(err)
        }
    }

    handleAttendanceStatusChange = (e, student) => {
        let value = e.target.value;
        // console.log('e', e.target.value)
        if (value === "Exception") {
            this.setState({
                selectedStudent: student.id,
                success: false,
                exceptionSelected: true
            });
        } else { // extra condition here? prolly
            this.updateAttendanceStatus(value, null, student.id)
        }

    }

    handleReasonChange = (e, student) => {
        // to avoid onBlur submitting if the user changes focus without selecting a reason
        const { value } = e.target
        if(value === "None") return 

        let reasonId = parseInt(e.target.value, 10);
        let status = "Exception";
        this.updateAttendanceStatus(status, reasonId, student.id)
    }

    applyAttendanceFilter(filter) {
        const filteredList = this.state.attendanceLog.filter((item) => {
            let attendanceStatus = getAttendanceStatusText(item);
            return "all" === filter || attendanceStatus.startsWith(filter)
        });

        this.setState({
            attendanceFilter: filter,
            filteredAttendanceLog: filteredList
        })
    }

    refreshAttendanceList() {
        this.applyAttendanceFilter(this.state.attendanceFilter)
    }

    onAttendanceFilterChanged(e) {
        this.applyAttendanceFilter(e.currentTarget.value)
    }

    generateAttendanceMessageAndLockAttendance = () => {
        const { attendanceLocked } = this.state
        if(attendanceLocked){
            return(
                <Alert type="warn">Attendance is locked at end of day. Please reach out to your SSM with change requests.</Alert>
            )
        }
        else {
            return(
                <Alert type="info">Attendance will be locked at end of day(midnight) for all non-SSM users.</Alert>
            )
        }
    }

    render() {
        let classAttendanceBaseList = this.state.filteredAttendanceLog;
        //disables editing for attendance after end of day, since the element is already disables.
        const {attendanceLocked} = this.state
        if (this.state.attendanceFilter === "all") {
            classAttendanceBaseList = this.state.attendanceLog
        }

        const {activeRole} = this.props;

        const profilePicStyles = {
            position: "absolute",
            top: "0.6rem",
            left: "1rem",
            maxWidth: "2rem",
            maxHeight: "2rem",
            marginRight: "1rem"
        }

        const studentCellStyles = {
            position: "relative"
        }

        const tableHeadings = [
            'Student',
            'Status',
            'Reason',
            'Action'
        ]
        
        const canChangeAttendanceForInstructorsTAsMessage = this.generateAttendanceMessageAndLockAttendance()

        return(
            <section data-component="manage-attendance">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="row">
                            <div className="col-xs-12 col-md-6 col-no-gutter">
                                <h2>Class Attendance</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-md-6 col-gutter-tb">
                                <label htmlFor="filterByStatus">Filter by Status</label>
                                <select aria-label="filter by status" id="filterByStatus"
                                        onBlur={e => this.onAttendanceFilterChanged(e)}
                                        onChange={e => this.onAttendanceFilterChanged(e)}        
                                >
                                    <option value="all">All</option>
                                    <option value="None">None</option>
                                    <option value="Present">Present</option>
                                    <option value="Absent">Absent</option>
                                    <option value="Remote">Present Remotely</option>
                                    <option value="Exception">Exception</option>
                                </select>
                            </div>
                        </div>
                        {canChangeAttendanceForInstructorsTAsMessage}
                        <div className="row">
                            <Table>
                                <THead>
                                    <TR>
                                        <TH>{tableHeadings[0]}</TH>
                                        <TH>{tableHeadings[1]}</TH>
                                        <TH>{tableHeadings[2]}</TH>
                                    </TR>
                                </THead>
                                <TBody>
                                    {classAttendanceBaseList.map((item,index)=>{
                                        let attendanceStatus = getAttendanceStatusText(item);
                                        const currentAttendanceStatus = attendanceStatusSpecs(attendanceStatus);
                                        const currentAttendanceStatusColorStringForClassName = currentAttendanceStatus.color ? 'text-' + currentAttendanceStatus.color : ''
                                        const isStudentSelected = item.student.id === this.state.selectedStudent;
                                        const isExceptionSelected = !!this.state.exceptionSelected;
                                        const showSuccessMessage = !!this.state.success && isStudentSelected;
                                        const showReasonMessage = isStudentSelected && isExceptionSelected && !this.state.success;
                                        const isExceptionSelectedForStudent = isExceptionSelected && isStudentSelected;
                                        const showReasonDropdown = !attendanceLocked && (isExceptionSelectedForStudent || item.excusedAbsenceReason);
                                        const isSubmittingAttendanceStatus = this.state.working && isStudentSelected;
                                        const {hideRemoteAttendance} = this.props;
                                        return (
                                            <TR key={index}>
                                                <TD heading={tableHeadings[0]} style={studentCellStyles} className="padding-l-3-xs padding-l-6-md">
                                                    <ProfilePic user={item.student} inlineStyles={profilePicStyles} options="hide-xs show-md"/>
                                                    <span className="padding-l-0-xs padding-l-3-md">{item.student.firstName + ' ' + item.student.lastName}</span>
                                                </TD>
                                                {isSubmittingAttendanceStatus ? (
                                                    <React.Fragment>
                                                        <TD heading={tableHeadings[1]}>
                                                            <div className="attendance-saving-message">
                                                                <Icon icon="circle-o-notch" options="fa-spin fa-fw" />
                                                                <span className="font-sm"> Saving Attendance...</span>
                                                            </div>
                                                        </TD>
                                                        <TD heading={tableHeadings[2]}></TD>
                                                    </React.Fragment>
                                                ) :
                                                isRole([roleTypes.TA, roleTypes.INSTRUCTOR, roleTypes.ADMIN, roleTypes.SSM], activeRole) ? (
                                                    (
                                                        <React.Fragment>
                                                            <TD heading={tableHeadings[1]}>
                                                                <select value={isExceptionSelectedForStudent ? "Exception" : attendanceStatus}
                                                                        onBlur={(e) => this.handleAttendanceStatusChange(e, item.student)}
                                                                        onChange={(e) => this.handleAttendanceStatusChange(e, item.student)}
                                                                        data-test="attendance-selector"
                                                                        disabled={attendanceLocked}
                                                                >
                                                                            <option disabled={true}>None</option>
                                                                            <option value="Present">Present</option>
                                                                            {!hideRemoteAttendance && <option value="Remote">Present Remotely</option>}
                                                                            <option value="Absent">Absent</option>
                                                                            {(isRole([roleTypes.ADMIN, roleTypes.SSM], activeRole) || attendanceLocked) && <option value="Exception">Exception</option>}
                                                                </select>
                                                                {showSuccessMessage && <p className="context-success font-xs" aria-live={showSuccessMessage ? "polite" : 'off'}>Attendance status has been marked as {attendanceStatus}.</p>}
                                                            </TD>
                                                            <TD heading={tableHeadings[2]}>
                                                                <select aria-live={showReasonDropdown ? "polite" : 'off'}
                                                                        value={!!item.excusedAbsenceReason ? item.excusedAbsenceReason.id : "None"}
                                                                        onBlur={(e) => this.handleReasonChange(e, item.student)}
                                                                        onChange={(e) => this.handleReasonChange(e, item.student)}
                                                                        disabled={!showReasonDropdown}

                                                                >
                                                                    <option disabled={true}>None</option>
                                                                    {this.state.exceptionReasons.map((reason) => {
                                                                            return (<option value={reason.id} key={reason.id}>{reason.description}</option>)
                                                                        }
                                                                    )}
                                                                </select>
                                                                {showReasonMessage && <p className="context-info font-xs" aria-live={showReasonMessage ? "polite" : 'off'}>Please select a reason for the exception.</p>}
                                                            </TD>
                                                        </React.Fragment>
                                                    )
                                                ) : (
                                                    <React.Fragment>
                                                        <TD>
                                                            <div className="attendance-actions">
                                                                {(attendanceStatus === "None") && <span>None</span>}
                                                                {(attendanceStatus === "Present") && <span className={`${currentAttendanceStatusColorStringForClassName}`}>Present</span>}
                                                                {(attendanceStatus === "Remote") && <span className={`${currentAttendanceStatusColorStringForClassName}`}>Present Remotely</span>}
                                                                {(attendanceStatus === "Absent") && <span className={`${currentAttendanceStatusColorStringForClassName}`}>Absent</span>}
                                                                {(attendanceStatus === "Exception") && <span className={`${currentAttendanceStatusColorStringForClassName}`}>Exception</span>}
                                                            </div>
                                                        </TD>
                                                        <TD/>
                                                    </React.Fragment>
                                                    )
                                                }
                                            </TR>
                                        )
                                    })}
                                </TBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}

export default connect(mapStateToProps)(ManageAttendance);
