import React, {Component} from 'react';
import {connect} from 'react-redux';
import DocumentTitle from 'react-document-title';
import {Redirect} from 'react-router-dom'
import Alert from '../../components/Alert';
import TimePicker from '../../components/TimePicker';
import {editCareerSession} from '../../actions/sessionActions';
import {sessionEditValidation} from '../../constants/validationMessages';
import {getDateForFormEdit, getTimeForFormEdit, getTimeMeridian} from '../../helpers/dateTime';
import {roleTypes} from '../../constants/dataTypes';
import {isRole} from '../../helpers/roles';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ReactGA from 'react-ga';
import FormNavigationPrompt from '../../components/NavigationPrompt'

import 'react-datepicker/dist/react-datepicker.css';


let classNames = require('classnames');

const mapStateToProps = (state, ownProps) => {
    return {
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        sessions: state.sessions,
        ...ownProps
    }
}

export class SessionEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            editing: false,
            edited: false,
            sessionName: '',
            chapter: '',
            sessionDate: '',
            sessionStartTime: '',
            sessionEndTime: '',
            sessionStartTime_meridian: '',
            sessionEndTime_meridian: '',
            sessionShortDescription: '',
            sessionDescription: '',
            startDate: null,
            formTouched: false
        }

        this.requiredFields = [
            'sessionName',
            'chapter',
            'sessionDate',
            'sessionStartTime',
            'sessionEndTime',
            'sessionStartTime_meridian',
            'sessionEndTime_meridian'
        ];

        this.validationMessages = sessionEditValidation
    }

    componentDidMount() {
        const {match, sessions, history} = this.props;
        if (parseInt(match.params.sessionId, 10) !== parseInt(sessions.selected.session.session.id, 10)) {
            history.push(`/sessions/${match.params.sessionId}`);
        }
        this.setState({
            sessionId: sessions.selected.session.session.id,
            sessionName: sessions.selected.session.session.name,
            chapter: sessions.selected.session.session.chapter,
            sessionDate: getDateForFormEdit(sessions.selected.session.session.startTime),
            sessionStartTime: getTimeForFormEdit(sessions.selected.session.session.startTime),
            sessionEndTime: getTimeForFormEdit(sessions.selected.session.session.endTime),
            sessionStartTime_meridian: getTimeMeridian(sessions.selected.session.session.startTime),
            sessionEndTime_meridian: getTimeMeridian(sessions.selected.session.session.endTime),
            sessionShortDescription: sessions.selected.session.session.shortDescription,
            sessionDescription: sessions.selected.session.session.longDescription || '',
            startDate: moment(sessions.selected.session.session.startTime)
        })

    }

    handleChange = (event) => {
        // just don't name any fieldnames "formTouched" please
        let fieldName = event.target.name
        let fieldValue = event.target.value
        let obj = {formTouched: true}
        obj[fieldName] = fieldValue
        this.setState(obj)
    }

    handleDateChange = (date) => {
        this.setState({
            startDate: date,
            sessionDate: moment(date).format('MM-DD-YYYY'),
            formTouched: true
        });
    }

    submitSession = async () => {
        this.setState({editing: true, errors: []});

        if (this.validate()) {
            let start = `${this.state.sessionStartTime} ${this.state.sessionStartTime_meridian}`;
            let end = `${this.state.sessionEndTime} ${this.state.sessionEndTime_meridian}`;
            let startTime = moment(start, 'HH:mm A');
            let endTime = moment(end, 'HH:mm A');
            let date = moment(this.state.sessionDate, 'MM-DD-YYYY');

            let startDateTime = date.set({
                hour: startTime.get('hour'),
                minute: startTime.get('minute'),
                second: startTime.get('second')
            });
            let formattedStartDateTime = moment(startDateTime).format()

            let endDateTime = date.set({
                hour: endTime.get('hour'),
                minute: endTime.get('minute'),
                second: endTime.get('second')
            });
            let formattedEndDateTime = moment(endDateTime).format()

            let payload = {
                id: this.state.sessionId,
                name: this.state.sessionName,
                shortDescription: this.state.sessionShortDescription,
                longDescription: this.state.sessionDescription,
                startTime: formattedStartDateTime,
                endTime: formattedEndDateTime,
                chapter: this.state.chapter
            }

            try {
                await editCareerSession(payload);
                this.setState({edited: true, formTouched: false});
                ReactGA.event({
                    category: 'Career Session',
                    action: 'Session Updated',
                });
            } catch (err) {
                console.error(err)
                window.scrollTo(0, 0);
                this.setState({
                    editing: false,
                    formTouched: false,
                    errors: ['There was an error updating this session. Please try again. If the problem persists please reach out to BCS Support']
                })
                ReactGA.event({
                    category: 'Career Session',
                    action: 'Session Update Error',
                });
            }
        } else {
            this.setState({editing: false});
        }
    };

    validate() {
        const errors = [];
        this.requiredFields.forEach((item) => {
            if (this.state[item] === undefined || !this.state[item].length) {
                errors.push(this.validationMessages[item]);
            }
        });

        //we've made it through the first round so we know that the requireds are present
        //so we can do some other checking
        if (!errors.length) {
            let startTime = moment(`${this.state.sessionStartTime}${this.state.sessionStartTime_meridian}`, 'h:mmA');
            let endTime = moment(`${this.state.sessionEndTime}${this.state.sessionEndTime_meridian}`, 'h:mmA');

            if (endTime.isBefore(startTime)) {
                errors.push('Session End Time must be AFTER Session Start Time');
            }
        }

        //do the actual return of true/false for validity
        if (errors.length) {
            this.setState({errors: errors});
            window.scrollTo(0, 0);
            return false;
        } else {
            return true;
        }

    }


    render() {
        if (isRole([roleTypes.STUDENT, roleTypes.INSTRUCTOR, roleTypes.TA], this.props.activeRole)) {
            return <Redirect to='/unauthorized'/>
        }

        const buttonClasses = classNames({
            'btn-save': true,
            'btn-loading': this.state.editing
        });
        const { formTouched } = this.state
        return (
            <DocumentTitle title="Edit Session | Bootcamp Spot">
                <section className="page" data-component="session-edit">
                    <FormNavigationPrompt message='Are you sure you want to navigate away from this page while you have a session edit in progress?' valueExists={formTouched} /> 
                    <div className="row">
                        <div className="col-xs-12 col-md-8">
                            <h1>Edit Career Session</h1>
                            {this.state.errors.length ? (
                                <Alert type="error">
                                    <ul>
                                        {this.state.errors.map((error, index) => {
                                            return (
                                                <li key={index}>{error}</li>
                                            )
                                        })}
                                    </ul>
                                </Alert>
                            ) : null}
                            {this.state.edited ? (
                                <Alert type="success">
                                    Your career session has been edited.<br/>
                                    <button className="btn btn-edit btn-sm" 
                                            onClick={() => {this.props.history.replace('/career-services')}
                                    }>
                                        Continue
                                    </button>
                                </Alert>
                            ) : (
                                <div id="create-session-form">
                                    <div className="field">
                                        <label htmlFor="sessionName">Session Title<span
                                            className="text-red">*</span></label>
                                        <input name="sessionName" id="sessionName" type="text" aria-required="true"
                                               onChange={this.handleChange} value={this.state.sessionName}/>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-md-6 col-no-gutter padding-r-3">
                                            <div className="field">
                                                <label htmlFor="chapter">Session Chapter<span
                                                    className="text-red">*</span></label>
                                                <input name="chapter" id="chapter" type="number" step="0.1"
                                                       placeholder="0.0" aria-required="true"
                                                       onChange={this.handleChange} value={this.state.chapter}/>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-md-6 col-no-gutter padding-l-3">
                                            <div className="field">
                                                <label htmlFor="sessionDate">Session Date<span
                                                    className="text-red">*</span></label>
                                                <DatePicker selected={this.state.startDate ? new Date(moment(this.state.startDate)) : null}
                                                            onChange={this.handleDateChange} aria-required="true"
                                                            dateFormat="MM-dd-yyyy"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-md-6 col-no-gutter padding-r-3">
                                            <div className="field">
                                                <label htmlFor="sessionStartTime">Session Start Time<span
                                                    className="text-red">*</span></label>
                                                <TimePicker
                                                    name="sessionStartTime"
                                                    onChange={this.handleChange}
                                                    required={true}
                                                    timeValue={this.state.sessionStartTime}
                                                    meridianValue={this.state.sessionStartTime_meridian}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-md-6 col-no-gutter padding-l-3">
                                            <div className="field">
                                                <label htmlFor="sessionEndTime">Session End Time<span
                                                    className="text-red">*</span></label>
                                                <TimePicker
                                                    name="sessionEndTime"
                                                    onChange={this.handleChange}
                                                    required={true}
                                                    timeValue={this.state.sessionEndTime}
                                                    meridianValue={this.state.sessionEndTime_meridian}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="sessionShortDescription">Session Short Description</label>
                                        <textarea rows="4" name="sessionShortDescription" id="sessionShortDescription"
                                                  maxLength="250" onChange={this.handleChange}
                                                  value={this.state.sessionShortDescription}
                                                  placeholder="This is the summary that shows on the session cards"></textarea>
                                        <p className="font-xs text-right">{(this.state.sessionShortDescription && this.state.sessionShortDescription.length) || 0}/250</p>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="sessionDescription">Session Long Description (Markdown
                                            Supported)</label>
                                        <textarea rows="8" name="sessionDescription" id="sessionDescription"
                                                  onChange={this.handleChange} value={this.state.sessionDescription}
                                                  style={{marginBottom: 0, paddingBottom: 0}} maxLength="1000"
                                                  placeholder="This is the extended description of the session that will show when a user clicks into a session"></textarea>
                                        <p className="font-xs text-right">{(this.state.sessionDescription && this.state.sessionDescription.length) || 0}/1000</p>
                                    </div>
                                    <div className="field">
                                        <button type="submit" className={buttonClasses}
                                                onClick={this.submitSession}>Save Changes
                                        </button>
                                        <button className="btn-tertiary btn-delete" onClick={() => {
                                            this.props.history.goBack()
                                        }}>Cancel, Go Back
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </DocumentTitle>
        )
    }

}

export default connect(mapStateToProps)(SessionEdit);
