import axios from 'axios';
import {userActions} from '../actions/userActions'
import {baseUrlConfig} from '../constants/axios';
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export function getBaseUrl() {
	const hostname = window.location.hostname
	for (let key in baseUrlConfig) {
		if (hostname.includes(key)){
			return baseUrlConfig[key]
		}
	}
	return null;
}

export function axiosAuthRequiredHandler(dispatch){
	axios.interceptors.response.use(function (response) {
		return response;
	}, async function (error) {
		if (error.response.status && error.response.status === 401) {
			source.cancel('Session expired');
			window.alert("Your session has expired, please log in again.") // pause till user acknowledges logout
			dispatch(userActions.logout())
			return Promise.reject(error);
		} else {
            console.error(error);
			return Promise.reject(error);
		}
	});
}


export function axiosInit() {
	axios.interceptors.request.use(function (config) {
		const token = localStorage.getItem('user');
        if(!config.url.includes('http')){
            if (token) config.headers['authToken'] = token;
		}
		config.cancelToken = source.token
		return config;
	}, function (error) {
		return Promise.reject(error);
	});
}

// [REFERENCE] http://stackoverflow.com/questions/667555/detecting-idle-time-in-javascript-elegantly
// export function idleInit() {
// 	if (isLoggedIn()) {
// 		var timeout;

// 		window.onload = resetTimer;
// 		document.onload = resetTimer;
// 		document.onmousemove = resetTimer;
// 		document.onmousedown = resetTimer;
// 		document.ontouchstart = resetTimer;
// 		document.onclick = resetTimer;
// 		document.onscroll = resetTimer;
// 		document.onkeypress = resetTimer;

// 		function resetTimer() {
// 			clearTimeout(timeout);
// 			timeout = setTimeout(logout, 7200000);
// 		}
// 	}
// }
