import {urlErrorCheck, urlValidator} from "../../helpers/validators";
import {GITHUB, LINKEDIN} from "../../constants/careerConstants";

export const runCareerServiceMilestoneUrlValidation = (currentUrls = [], currentUrlTitle, currentUrl) => {
    if (!urlValidator(currentUrl)) {
        return urlErrorCheck(currentUrl);
    }
    if (currentUrlTitle === LINKEDIN && !currentUrl.toLowerCase().includes('linkedin.com')) {
        return `You must add provide a ${LINKEDIN} url for ${LINKEDIN} submissions.`
    }

    if (currentUrlTitle === GITHUB && !currentUrl.toLowerCase().includes('github.com')) {
        return `You must add provide a ${GITHUB} url for ${GITHUB} submissions.`
    }

    return null;
};

export const runCareerServiceUrlValidation = (currentUrls = [], currentUrlTitle, currentUrl) => {
    let validation = runCareerServiceMilestoneUrlValidation(currentUrls, currentUrlTitle, currentUrl);
    if (validation == null) {
        if ((currentUrls || []).map(e => e.url).includes(currentUrl)) {
            validation = 'You have already submitted this URL.';
        }
    }
    return validation;
};

export const milestoneType2VarName = (milestoneType) => (
    milestoneType.replace(/\s/g, '')
);

export const hasDuplicateUrlsValidation = (currentUrls = [], currentUrl) => {
    const repeatedUrls = (currentUrls || []).filter( e => e.url.toLowerCase() === currentUrl.toLowerCase());
    return repeatedUrls.length > 1;
};
