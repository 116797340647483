import React, {Component} from 'react';
import {connect} from 'react-redux';
import ReactGA from 'react-ga'
import FormComponent from '../../components/FormComponent/FormComponent';
import { SurveyName } from '../../constants/surveys';
import {lockRouteForUser, releaseRouteForUser} from "../../actions/routeLockActions";
import {lockActionTypeMessages} from "../../constants/messages";


const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
};

class NPSForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formIsVisible: false
        }

        this.props.removeNav();
        this.isEndOfCourse = this.props.match.params.course === "ec";
    }

    componentDidMount(){
        const {dispatch, location} = this.props;
        dispatch(lockRouteForUser(location.pathname,lockActionTypeMessages.NPS_SURVEY));
    }

    changeFormVisibility = () => {
        this.setState({
            formIsVisible: true
        })
    }

    onSuccess =(stepsList) => {
        ReactGA.event({
            category: 'NPS Survey',
            action: 'Submission',
        });

        const dstRoute = (this.isEndOfCourse && parseInt(stepsList[0].step.answer.value, 10) > 8)? '/npssuccess/additional':'/npssuccess/end';
        this.props.dispatch(releaseRouteForUser());
        this.props.history.replace(dstRoute);
    }

    render() {
        const {location} = this.props;
        const enterpriseCourse = (location.state && location.state.enterprise && location.state.enterprise === true);
        let formName;
        let ignoreSteps = [];
        if (this.isEndOfCourse){
            formName = SurveyName.EndCourseSurvey;
            if (enterpriseCourse) {
                formName = SurveyName.EnterpriseEndCourseSurvey;
            }
        } else {
            formName = SurveyName.MidCourseSurvey;
            if (enterpriseCourse) {
                formName = SurveyName.EnterpriseMidCourseSurvey;
            }
        }
        var content;
        if (this.state.formIsVisible === false){
            content = <section className="page" data-content="NPSForm">
            <div className="row card-group">
                <div className="col-xs-12">
                    <div className="card">
                    {this.isEndOfCourse  && <div>
                                <p>Congratulations on completing the course. </p>
                                <p>Please take a few minutes to complete the end of course survey (student satisfaction)
                                    before we start class. This gives you the opportunity to provide feedback regarding
                                    your experience. The survey is based on the number scale outlined below.</p>

                                <p>9 and 10's are considered promoters (those who are overall satisfied with the program
                                    and would recommend the course to a colleague or friend).</p>
                                <p>7 and 8 are neutral (neither satisfied nor dissatisfied).</p>
                                <p>6 and below are detractors (not happy with the service being provided).</p>

                                <p>If you feel you will rate us below a 9 or a 10, I'd like to set up a follow up call
                                    to address any of your concerns with the course. Thank you for providing us with
                                    your valuable feedback and have a great class!</p>
                            </div>
                    }
                    {!this.isEndOfCourse  && <div>
                            <p>Congratulations, you have reached the halfway point in your course. </p>
                            <p>Please take a few minutes to complete the midpoint survey (student satisfaction)
                                before
                                we start class. This gives you the opportunity to provide feedback regarding
                                your
                                experience thus far. The survey is based on the number scale outlined below.</p>

                            <p>9 and 10's are considered promoters (those who are overall satisfied with the
                                program
                                and
                                would recommend the course to a colleague or friend).</p>
                            <p>7 and 8 are neutral (neither satisfied nor dissatisfied).</p>
                            <p>6 and below are detractors (not happy with the service being provided).</p>

                            <p>If you feel you will rate us below a 9 or a 10, I'd like to set up a follow up
                                call
                                to
                                address any of your concerns with the course. Thank you for providing us with
                                your
                                valuable feedback and have a great class!</p>
                        </div>}


                        <button className="btn-secondary btn-small"
                                onClick={() => this.changeFormVisibility()}>Start
                            the
                            Survey
                        </button>
                    </div>
                </div>
            </div>
            </section>
        } else {
            let placeholders = new Map();
            placeholders.set("universityName", this.props.user.enrollmentInfo.course.cohort.program.university.name);
            placeholders.set("programName", this.props.user.enrollmentInfo.course.cohort.program.name);
            content =<FormComponent {...this.props}
                        placeholders={placeholders}
                        ignoreSteps={ignoreSteps}
                        formName={formName}
                        onSuccessCallback={this.onSuccess} />
        }

        return (<div>{content}</div>)
    }
}

export const NPSFORM = NPSForm ;
export default connect(mapStateToProps)(NPSForm);
