import React, {Component} from 'react';
import { withRouter } from "react-router-dom"
import Icon from '../Icon'
import {userActions} from '../../actions/userActions'


class LogoutButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logoutInProgress: false
        }
    }

    logout = () => {
        const {history, dispatch} = this.props
        this.setState({logoutInProgress: true})

        // doing it in this order prevents a wierd looking double render that happens if the dispatch is first. 
        history.replace('/login')
        dispatch(userActions.logout())
    }
    
    render(){
        const {logoutInProgress} = this.state
        return(
            <button className={logoutInProgress ? "btn-loading" : "btn-primary"} aria-label="Sign out of BCS" title="Logout" disabled={logoutInProgress} onClick={this.logout}>
                <Icon icon="power-off" options="fa-fw" ariaHidden="false"/> Logout
            </button>
        )
    }
}

export default withRouter(LogoutButton)