import React from 'react';
import PropTypes from 'prop-types';

const TH = (props) => {

    // If no col prop is provided the cell will auto size itself to fit the row (md screens up)
    const col = props.col !== '' ? ` col-md-${props.col}` : ' col-md'

    return (
        <th className={props.className + ' hide-xs show-md' + col} onClick={props.onClick}>
            {props.children}
        </th>
    )
}

TH.propTypes = {
    className: PropTypes.string,
    col: PropTypes.string,
    onClick: PropTypes.func
}

TH.defaultProps = {
    className: '',
    col: ''
}

export default TH