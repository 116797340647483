export const getActiveEnrollment = (enrollmentList, activeIndex) => {
    let result = null;
    for (let i = 0; i < enrollmentList.length ; i++) {
        if (i === activeIndex) {
            result = enrollmentList[i];
            break;
        }
    }
    return result;
}

export const getAllRoles = (enrollmentList) => {
    let roleList = []
    enrollmentList.forEach(enrollment => {
        roleList.push(enrollment.courseRole.courseRoleCode)
    })
    return roleList;
}

export const getProgramTypeShortName = (programName) => {
    if (!programName) {
        return 'fsf'
    } else if (programName.includes('Netflix')) {
        return 'netflix'
    } else if (programName.includes('Full Stack Flex')) {
        return 'fsf'
    } else if (programName.includes('Data V')) {
        return 'dataViz'
    } else if (programName.includes('UX')) {
        return 'uxui'
    } else if (programName.includes('Cyber')) {
        return 'cyber'
    } else if (programName.includes('FinTech')) {
        return 'finTech'
    } else if (programName.includes('Digital Marketing')) {
        return 'digitalMkt'
    } else {
        return 'fsf'
    }
}
