import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import {Line} from 'react-chartjs-2';
import { ExportToCsv } from 'export-to-csv';

import {
    getWeeklyFeedbackData,
    formatDataForChart,
    feedbackChartOptions,
    submissionsChartOptions,
    formatDataForSubmissionsChart,
    formatDataForOutsideTimeSpentChart,
    outsideTimeSpentChartOptions
} from '../../actions/weeklyFeedbackActions';

import Loading from '../../components/Loading';
import Alert from '../../components/Alert';
import FeedbackCommentTable from '../../components/FeedbackCommentTable';
import WeeklyFeedbackReportStatLabel from "../../components/WeeklyFeedbackReportStatLabel";

import {isRole} from '../../helpers/roles';
import {getDateForFormEdit} from "../../helpers/dateTime";
import {isIOS, isSafari} from "../../helpers/utils";
import { roleTypes } from '../../constants/dataTypes';

const mapStateToProps = (state, ownProps) => {
    return{
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        user: state.user,
        enrollmentInfo: state.user.enrollmentInfo,
        ...ownProps
    }
};

export class WeeklyFeedbackReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            chartData: {},
            allComments: [],
            detailsForCsv: [],
            filteredComments: [],
            averages: new Map(),
            weeks: [],
            chartOptions: feedbackChartOptions(),
            submissionsChartOptions: {},
            outsideTimeSpentChartData: {},
            outsideTimeSpentChartOptions: outsideTimeSpentChartOptions(),
            selectedWeek: false,
            authorizedRole: isRole([roleTypes.ADMIN, roleTypes.SSM, roleTypes.INSTRUCTOR, roleTypes.TA], this.props.activeRole),
            tabIndex: 0,
            csvExportError: false
        }
    }

    componentDidMount() {
        const {activeRole, history} = this.props;
        if (isRole([roleTypes.CD, roleTypes.STUDENT], activeRole)){
            history.push('/unauthorized');
        } else {
            this.fetchWeeklyFeedbackReport();
        }
    }

    async fetchWeeklyFeedbackReport() {
        const {enrollmentInfo} = this.props;
        let data = await getWeeklyFeedbackData(enrollmentInfo.id, enrollmentInfo.courseId);
        if (!!data.chart){
            const chartData = formatDataForChart(data.chart);
            const submissionsChartData = formatDataForSubmissionsChart(data.chart);
            const outsideTimeSpentChartData = formatDataForOutsideTimeSpentChart(data.chart);
            const averagesMap = new Map(data.chart.map(avg => [avg.week, avg]));
            const detailsForCsv = this.formatDetailsForCsv(data.details)
            this.setState({
                loading: false,
                chartData: chartData,
                submissionsChartData: submissionsChartData,
                submissionsChartOptions: submissionsChartOptions(data.chart),
                outsideTimeSpentChartData: outsideTimeSpentChartData,
                allComments: data.comments,
                detailsForCsv: detailsForCsv,
                averages: averagesMap,
                weeks: data.chart})
        } else {
            this.setState({loading: false});
        }
    }

    formatDetailsForCsv(data) {
        data.forEach(submission => {
                submission.submittedDate = getDateForFormEdit(submission.submittedDate);
                submission.outsideTimeSpent = submission.outsideTimeSpent === 1 ? '0-5 hrs' :
                    submission.outsideTimeSpent === 2 ? '6-10 hrs' :
                        submission.outsideTimeSpent === 3 ? '11-15 hrs' :
                            submission.outsideTimeSpent === 4 ? '15-20 hrs' : submission.outsideTimeSpent === 5 ? '+20 hrs' : null;
            }
        );
        return data;
    }

    onChooseWeek = async (e) => {
        let week = parseInt(e.target.value, 10);

        if(e.target.value === ""){
            this.setState({filteredComments: [], selectedWeek: false});
        } else {
            let filteredComments = this.state.allComments.filter( e => e.week === week);
            this.setState({filteredComments: filteredComments, selectedWeek: week});
        }
    };

    setTabIndex = (tabIndex) => {
        this.setState({
            tabIndex: tabIndex
        })
    }

    renderAverages = (selectedWeekAvg) => {
        const showTargetRange = isRole([roleTypes.INSTRUCTOR, roleTypes.SSM, roleTypes.ADMIN], this.props.activeRole)

        return <React.Fragment>
            <div className="row">
                <div className="col-xs-12 col-md-3 text-center">
                    <p className="title">Overall Satisfaction</p>
                    <p className="headline">{selectedWeekAvg.classOverall}</p>
                </div>
                <div className="col-xs-12 col-md-3 text-center">
                    <p className="title">Class Pace</p>
                    <WeeklyFeedbackReportStatLabel id="paceOfClass" value={selectedWeekAvg.paceOfClass}/>
                    {showTargetRange && <p className="font-sm">Target Range &gt;3.24 and &lt;3.87</p>}
                </div>
                <div className="col-xs-12 col-md-3 text-center">
                    <p className="title">Apply Learning Outside Class</p>
                    <WeeklyFeedbackReportStatLabel id="applyLearningOutsideClass" value={selectedWeekAvg.applyLearningOutsideClass}/>
                    {showTargetRange && <p className="font-sm">Target Range &gt;3.56</p>}
                </div>
                <div className="col-xs-12 col-md-3 text-center">
                    <p className="title">Academic Support</p>
                    <WeeklyFeedbackReportStatLabel id="academicSupport" value={selectedWeekAvg.academicSupport}/>
                    {showTargetRange && <p className="font-sm">Target Range &gt;4.29</p>}
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-3 text-center">
                    <p className="title">Instructor Engagement</p>
                    <WeeklyFeedbackReportStatLabel id="instructorEngagement" value={selectedWeekAvg.instructorEngagement}/>
                    {showTargetRange && <p className="font-sm">Target Range &gt;4.34</p>}
                </div>
                <div className="col-xs-12 col-md-3 text-center">
                    <p className="title">Instructor Clarity</p>
                    <WeeklyFeedbackReportStatLabel id="instructorClarity" value={selectedWeekAvg.instructorClarity}/>
                    {showTargetRange && <p className="font-sm">Target Range &gt;4.26</p>}
                </div>
                <div className="col-xs-12 col-md-3 text-center">
                    <p className="title">Instructor Knowledge</p>
                    <WeeklyFeedbackReportStatLabel id="instructorKnowledge" value={selectedWeekAvg.instructorKnowledge}/>
                    {showTargetRange && <p className="font-sm">Target Range &gt;4.51</p>}
                </div>
                <div className="col-xs-12 col-md-3 text-center">
                    <p className="title">Homework Feedback Rating</p>
                    <WeeklyFeedbackReportStatLabel id="homeworkFeedbackRating" value={selectedWeekAvg.homeworkFeedbackRating}/>
                    {showTargetRange && <p className="font-sm">Target Range &gt;4.51</p>}
                </div>
                <div className="col-xs-12 col-md-3 text-center outsideTimeValues">
                    <p className="title">Outside Time Spent</p>
                    <div>0-5 hrs: {selectedWeekAvg.OutsideTimeSpent.option1Percentage}%</div>
                    <div>6-10 hrs: {selectedWeekAvg.OutsideTimeSpent.option2Percentage}%</div>
                    <div>11-15 hrs: {selectedWeekAvg.OutsideTimeSpent.option3Percentage}%</div>
                    <div>16-20 hrs: {selectedWeekAvg.OutsideTimeSpent.option4Percentage}%</div>
                    <div>+20 hours: {selectedWeekAvg.OutsideTimeSpent.option5Percentage}%</div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-3 text-center">
                    <p className="title">Submissions</p>
                    <p className="headline">{selectedWeekAvg.feedbackPercentage}%</p>
                </div>
            </div>
        </React.Fragment>;
    }

    exportCSV = () => {
        let headers = [
            'Submission Date',
            'Week',
            'Course Name',
            'Student Name',
            'Overall Satisfaction',
            'Class Pace',
            'Academic Support',
            'Apply Learning Outside Class',
            'Instructor Engagement',
            'Instructor Clarity',
            'Instructor Knowledge',
            'Homework Feedback Rating',
            'Outside Time Spent',
        ];

        let data = this.state.detailsForCsv;

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            filename: 'weeklyFeedback',
            decimalSeparator: '.',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers
        };

        const csvExporter = new ExportToCsv(options);

        try {
            csvExporter.generateCsv(data);
        } catch(err) {
            this.setState({
                csvExportError: true
            })
        }
    }

    getIsSsmOrAdmin = () => {
        return isRole([roleTypes.ADMIN, roleTypes.SSM], this.props.activeRole);
    }

    render() {
        const {authorizedRole, averages, selectedWeek, tabIndex, filteredComments, csvExportError} = this.state;
        const selectedWeekAvg = averages.get(selectedWeek);
        const isSsmOrAdmin = this.getIsSsmOrAdmin(); // SSMs and Admins can always see averages, and the can see student names in comments
        const showAveragesToInstructorsAndTAs = isRole([roleTypes.TA, roleTypes.INSTRUCTOR], this.props.activeRole) && !!selectedWeekAvg && (selectedWeekAvg.feedbackPercentage > 50); // TAs and Instructors can see averages only if 50% of surveys have vebb submitted
        const showCSVDownloadButton = isRole([roleTypes.ADMIN, roleTypes.SSM, roleTypes.INSTRUCTOR, roleTypes.TA], this.props.activeRole);

        return(
            <DocumentTitle title="Weekly Feedback Report | Bootcamp Spot">
                <section className="page" data-component="weekly-feedback-report">
                    {this.state.loading ? (
                        <Loading />
                    ) : (
                        this.state.weeks.length ? (
                            <div>
                                <h1>Weekly Feedback Report</h1>
                                <div className="bcs-tabs">
                                    {/* tagged for accessibility enhancement */}
                                    {/* eslint-disable-next-line */}
                                    <a className={tabIndex === 0 ? "active" : ''} onClick={() => {this.setTabIndex(0)}}>Student Feedback</a>
                                    {/* eslint-disable-next-line */}
                                    <a className={tabIndex === 1 ? "active" : ''} onClick={() => {this.setTabIndex(1)}}>Number of Submissions</a>
                                    {/* eslint-disable-next-line */}
                                    <a className={tabIndex === 2 ? "active outsideTimeTab" : 'ousideTimeTab'} onClick={() => {this.setTabIndex(2)}}>Outside Time Spent</a>
                                </div>
                                {tabIndex === 0 && <div className="row">
                                    <div className="col-xs-12">
                                        <h2>Student Feedback</h2>
                                        <Line data={this.state.chartData} options={this.state.chartOptions}/>
                                    </div>
                                </div>}
                                {tabIndex === 1 && <div className="row">
                                    <div className="col-xs-12">
                                        <h2>Number of Submissions</h2>
                                        <Line data={this.state.submissionsChartData} options={this.state.submissionsChartOptions}/>
                                    </div>
                                </div>}
                                {tabIndex === 2 && <div className="row">
                                    <div className="col-xs-12">
                                        <h2>Outside Time Spent</h2>
                                        <Line data={this.state.outsideTimeSpentChartData} options={this.state.outsideTimeSpentChartOptions}/>
                                    </div>
                                </div>}
                                {
                                    showCSVDownloadButton &&
                                    <div className="row">
                                        <div className="col-xs-12">
                                            {
                                                (isIOS() && isSafari()) &&
                                                <Alert type="warn">Export not available on Safari mobile.</Alert>
                                            }
                                            <button className="btn-small btn-submit download-csv" onClick={this.exportCSV}>{(isIOS() && isSafari()) ? "OPEN CSV" : "DOWNLOAD CSV"}
                                            </button>
                                            {csvExportError && <Alert type="error">There was an error exporting CSV.</Alert>}
                                        </div>
                                    </div>
                                }
                                <div className="summary-stats">
                                    {authorizedRole ? (
                                        <div className="row">
                                            <div className="col-xs-12 col-md-6">
                                                <label htmlFor="chooseFeedbackWeek">Choose a Week</label>
                                                <select id="chooseFeedbackWeek" name="chooseFeedbackWeek" onBlur={this.onChooseWeek} onChange={this.onChooseWeek} value={this.state.selectedWeek}>
                                                    <option value="">None selected</option>
                                                    {this.state.weeks.map((item,index)=>{
                                                        return (
                                                            <option key={index} value={item.week}>Week {item.week}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    ) : null}

                                    {!this.state.selectedWeek &&
                                    <div className="row">
                                        <div className="col-xs-12">
                                            {authorizedRole ? (
                                                <Alert type="warn">
                                                    Choose a week to view detailed feedback for that specific week
                                                </Alert>
                                            ) : null}
                                        </div>
                                    </div>
                                    }
                                    {!!this.state.selectedWeek &&
                                    <div className="summary-stat-data">
                                        {(!!selectedWeekAvg && authorizedRole) ? (
                                            isSsmOrAdmin ? (
                                                this.renderAverages(selectedWeekAvg)
                                            ) : showAveragesToInstructorsAndTAs ? (
                                                this.renderAverages(selectedWeekAvg)
                                            ) : <div className="row">
                                                    <div className="col-xs-12">
                                                        <Alert type="warn">Weekly Survey information will be made available after 50% of the surveys have been submitted.</Alert>
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                        {!!filteredComments &&
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-xs-12 margin-t-3">
                                                    <p className="title">Comments on Instructor</p>
                                                    <FeedbackCommentTable
                                                        isSsmOrAdmin={isSsmOrAdmin}
                                                        feedback={filteredComments}
                                                        field='commentsOnInstructor'
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xs-12 margin-t-3">
                                                    <p className="title">Other Comments</p>
                                                    <FeedbackCommentTable
                                                        isSsmOrAdmin={isSsmOrAdmin}
                                                        feedback={filteredComments}
                                                        field='additionalNotes'
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        }
                                    </div>
                                    }
                                </div>
                            </div>
                        ) : (
                            <Alert type="warn">
                                No feedback has been collected for this class yet. If you feel this is an error - please contact BCS Support
                            </Alert>
                        )
                    )}
                </section>
            </DocumentTitle>
        )
    }
}

export const WEEKLYFEEDBACKREPORT = WeeklyFeedbackReport;

export default connect(mapStateToProps)(WeeklyFeedbackReport);
