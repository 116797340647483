import {isInstructor, isRole} from "../../helpers/roles";
import React, {Component} from 'react';
import Link from "react-router-dom/Link";
import Icon from "../Icon";
import ExternalLink from "../ExternalLink";
import {connect} from "react-redux";
import {roleTypes} from "../../constants/dataTypes";
import Loading from "../Loading";
import get from 'lodash/get';
import { isEqual } from 'lodash'
import {handleZoomMeeting, handleEndZoomMeeting, handleRefresh} from "../../actions/VirtualClassroomCardActions";
import {handleAddJoinSession, handleRemoveJoinSession, onChange} from "../../helpers/virtualClassroomCard";
import SupportLink from "../SupportLink";

const mapStateToProps = (state) => {
    return {
        userId: state.user.data.userId,
        enrollmentInfo: (state.user.enrollmentInfo ? state.user.enrollmentInfo : {}),
        authToken: state.user.data.authToken,
        dashboard: state.dashboard
    }
};

class VirtualClassroomCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zoomMeetingEnabled: false,
            selectedSession: null,
            relatedSessions: [],
            joinSession: [],
            errorMessage: null,
            warnMessage: null,
            zoomLoading: false
        }
    };

    componentDidMount() {
        this.load();
        this.scheduleRefresh();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.props.dashboard.relatedInstructionalSessions, prevProps.dashboard.relatedInstructionalSessions)) {
            this.load();
        }
    };

    componentWillUnmount() {
        clearTimeout(this.schedule);
    };


    load() {
        const { dashboard } = this.props;
        this.setState({
            zoomMeetingEnabled: get(dashboard, 'zoomMeetingEnabled', false),
            selectedSession: get(dashboard, 'relatedInstructionalSessions[0].id', null),
            relatedSessions: get(dashboard, 'relatedInstructionalSessions', []),
            zoomLoading: false
        })
    }

    scheduleRefresh() {
        this.schedule = setTimeout(() => handleRefresh(this), 15000);
    };

    renderZoomDynamicExperience = () => {
        const {activeRole, upcomingSession} = this.props;
        const {relatedSessions, joinSession, errorMessage, warnMessage, zoomLoading} = this.state;
        const relatedSessionsProvider = this.props.dashboard.relatedInstructionalSessions;

        const isStaff = isRole([roleTypes.INSTRUCTOR, roleTypes.TA, roleTypes.SSM], activeRole);
        const isZoomMeetingEnabled = this.state.zoomMeetingEnabled;
        const isMultisessionCard = isStaff && !isZoomMeetingEnabled && relatedSessionsProvider && relatedSessionsProvider.length > 0;
        const hasMessage = ( errorMessage || warnMessage );

        return (
            <div className={`card card-skinny`}>
                <div style={{textAlign: 'center'}}>
                    <h3>Click Here to Join Virtual Classroom</h3>
                    { !isStaff && !isZoomMeetingEnabled &&
                    <React.Fragment>
                        <Loading>
                            <p>Please stand by..</p>
                            <p>You will be able to join the virtual classroom when staff starts the class at scheduled time.</p>
                        </Loading>
                    </React.Fragment>
                    }
                    { zoomLoading &&
                        <React.Fragment>
                            <Loading>
                                <p>Please stand by..</p>
                            </Loading>
                        </React.Fragment>
                    }
                    <div className={`${ (isMultisessionCard || hasMessage) ? "margin-b-4": ""}`}>
                        <button
                            onClick={() => handleZoomMeeting(this)}
                            onKeyPress={() => handleZoomMeeting(this)}
                            disabled={!(isStaff || (!isStaff && isZoomMeetingEnabled)) || zoomLoading}
                            aria-label={ isStaff ? (isZoomMeetingEnabled ? 'join class': 'start class') : 'join class' }
                            className="btn btn-primary margin-0">
                            { isStaff ? (isZoomMeetingEnabled ? 'join': 'start') : 'join' } class
                        </button>
                        { isStaff && isZoomMeetingEnabled &&
                        <button
                            className="margin-4 btn-edit"
                            aria-label="end class"
                            disabled={zoomLoading}
                            onKeyPress={() => handleEndZoomMeeting(this)}
                            onClick={() => handleEndZoomMeeting(this)}>
                            end class
                        </button>
                        }
                    </div>
                </div>
                { isMultisessionCard && !zoomLoading &&
                <div className="col-xs-12 padding-0 padding-t-2 border-top">
                    <h3>Meeting information</h3>
                    <span>Hosting session: {upcomingSession.session.name}</span>
                    <div className="margin-b-4">
                        {joinSession.map( (sessionId) => {
                            let sessionObj = relatedSessionsProvider.find(element => element.id === sessionId);
                            return (
                                <div key={sessionId}>
                                    <span className="padding-r-2">Joining session: {sessionObj.name}</span>
                                    <Icon icon="window-close"
                                          options="font-md"
                                          role={"button"}
                                          ariaHidden="false"
                                          ariaLabel={`Remove joining session: ${sessionObj.name}`}
                                          onClickHandler={() => handleRemoveJoinSession(sessionId, this)}
                                          onKeyPress={() => handleRemoveJoinSession(sessionId, this)}
                                          tabIndex="0"
                                    />
                                </div>
                            )
                        })}
                    </div>
                    { relatedSessions && relatedSessions.length > 0 &&
                    <React.Fragment>
                        <h5>Select session to join</h5>
                        <div className="row ">
                            <div className="col-xs-8 padding-0 padding-t-2">
                                <select aria-label="relatedSessions"
                                        onBlur={(event) => onChange(event, this)}
                                        onChange={(event) => onChange(event, this)}>
                                    { relatedSessions.map((session) => {
                                        return (
                                            <option key={session.id} value={session.id}>{session.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="col-xs-4 padding-0 padding-t-2">
                                <button className="btn-small margin-0 margin-l-2" onClick={() => handleAddJoinSession(this)}>
                                    <Icon className="padding-r-2 margin-r-2" icon="plus-square" options="font-md"/>
                                    <span> Add</span>
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                    }
                </div>
                }
                { ( hasMessage ) &&
                <div className="card-footer">
                    <p className={`text-left context-${errorMessage ? "error" : "warn"}`}>{errorMessage ? errorMessage : warnMessage } If the problem persists, please contact <SupportLink/>.</p>
                </div>
                }
            </div>
        )
    };

    renderLinkCredentialExperience = () => {
        const {virtualClassroom, virtualClassroomCredentials, activeRole} = this.props;
        return (
            <div className={`card ${!!virtualClassroomCredentials ? "card-with-title card-skinny" : "text-center"}`}>
                <div className={`${!!virtualClassroomCredentials ? "card-title": ""}`} style={{textAlign: 'center'}}>
                    <h3>Click Here to Join Virtual Classroom</h3>
                    { isInstructor(activeRole) ? (
                        <React.Fragment>
                            <p><Icon icon="laptop-code" alt="laptop" options="font-xxl"/></p>
                            <p><ExternalLink to={virtualClassroom.link} text={virtualClassroom.link}/></p>
                        </React.Fragment>
                    ) : (
                        <Link
                            target={'_blank'}
                            rel="noopener noreferrer"
                            to={virtualClassroom.link}
                            className="btn btn-primary margin-0">
                            join class
                        </Link>
                    )
                    }
                </div>
                {(isInstructor(activeRole) && !!virtualClassroomCredentials) &&
                <div  className="card-content">
                    <div><span className="text-bold">Username</span></div>
                    <div>{virtualClassroomCredentials.username}</div>

                    <div className="margin-t-2"><span className="text-bold">First-Login/Temporary Password</span></div>
                    <div>{virtualClassroomCredentials.temporaryPassword}</div>
                </div>
                }
            </div>
        )
    };

    render() {
        const {virtualClassroom} = this.props;
        return virtualClassroom.dynamic ?
            this.renderZoomDynamicExperience()
        :
            this.renderLinkCredentialExperience()
    }

}

export default connect(mapStateToProps)(VirtualClassroomCard);
