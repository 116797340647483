import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import axios from 'axios';
import { ytConfig } from '../../constants/youTube';
import Icon from '../../components/Icon';
import Alert from '../../components/Alert';
import Loading from '../../components/Loading';
import { isTA, isInstructor, isStudent, isCD, isAdmin, isSSM } from '../../helpers/roles';
// FIXME: Seems to be unused, confirm the package is unused in this file and the code that uses it won't be commented back in.
// import YouTube from 'react-youtube';
import { intersection } from 'lodash';


const mapStateToProps = (state, ownProps) => {
    return{
        ...state,
        ...ownProps
    }
  }

class VideoOnboarding extends Component {

    constructor(props)
    {
        super(props)
        this.props.removeNav();

        this.state = {
            videos: [],
            loading: true,
            error: false,
            redirect: '',
            liveChatUniversity: 0
        }
    }

    componentWillReceiveProps(nextProps)
    {
        const {user, flags} = nextProps
        const {YT_SUPPORT_STUDENT, YT_SUPPORT_INSTRUCTOR, YT_SUPPORT_CAREER, TY_SUPPORT_ADMINSSM} = ytConfig
        let playlist = '';
        if(isStudent(user.roles))
        {
            playlist = YT_SUPPORT_STUDENT;
        }
        if(isInstructor(user.roles) || isTA(user.roles))
        {
            playlist = YT_SUPPORT_INSTRUCTOR;
        }
        if(isCD(user.roles))
        {
            playlist = YT_SUPPORT_CAREER;
        }
        if(isAdmin(user.roles) || isSSM(user.roles))
        {
            playlist = TY_SUPPORT_ADMINSSM;
        }

        let redirect = (this.props.user.courses.length > 1) ? '/universities' : '/universities'

        let userCourses = user.courses.map((item, index)=>{
            return item.universityName
        })

        let liveChatUniversity = intersection(flags.liveChat.availableUniversities, userCourses)

        this.setState({redirect: redirect, liveChatUniversity: liveChatUniversity.length})

        //if we already have video data - dont ping again
        if(!this.state.videos.length && playlist.length){
            this.getPlaylist(playlist)
            .then(video => {
                if(video.data.items.length){
                    this.setState({videos: video.data.items, loading: false});
                    console.warn(video.data.items);
                }else{
                    throw new Error("No videos found in playlist");
                }
            })
            .catch(err => {
                this.setState({error: true, loading: false});
            });
        }
    }

    async getPlaylist(playlist)
    {
        const { YT_API_KEY, YT_API_URL} = ytConfig;
        const playlistUrl = `${YT_API_URL}/playlistItems?key=${YT_API_KEY}&playlistId=${playlist}&part=snippet,status&maxResults=50`;
        return await axios.get(playlistUrl);
    }

    onVideoEnd(event)
    {
        // const {videoId} = this.props.match.params;
        // const {userId} = this.props.user.data;
        // axios.post('/api/video/youtube/track', {event: "end", user_id: userId, video_id: videoId});
    }

    onVideoReady(event)
    {
        // const {videoId} = this.props.match.params;
        // const {userId} = this.props.user.data;
        // axios.post('/api/video/youtube/track', {event: "play", user_id: userId, video_id: videoId});
    }

    // navigateToVideo = (videoId) => {
    //     const {history} = this.props
    //     this.props.includeNav();
    //     history.push(`/videos/onboarding/${videoId}`)
    // }



    render()
    {
        const {videos, loading, error} = this.state;
        // FIXME: This seems to be unused, confirm we can delete it and will never
        // const playerOpts = {
        //     height: '545',
        //     width: '100%',
        //     playerVars: {
        //       autoplay: 0,
        //       modestbranding: 1,
        //       rel: 0
        //     }
        //   };

        return(
            <DocumentTitle title="Welcome to the NEXT BootcampSpot! | Bootcamp Spot">
                <section className="page" data-component="videos">

                    <div className="row margin-t-3 text-center">
                        <div className="col-xs-12">
                            <Alert type="warn">
                                <p>This welcome screen will appear until <strong>May 1, 2018</strong> - after that you can find all these videos by using the "Video Library" link</p>
                            </Alert>
                        </div>
                    </div>
                    <div className="row text-center">
                        {this.state.redirect.length ? (
                            <div className="col-xs-12">
                                <p><Link to={this.state.redirect} className="btn">Continue to Bootcamp Spot</Link> - OR -  check out the help videos below</p>
                            </div>
                        ) : null}
                    </div>
                    <div className="row text-center">
                        <div className="col-xs-12">
                            <h1>Welcome to the NEW Bootcamp Spot!</h1>
                        </div>
                        <div className="col-xs-12">
                            <p>Welcome to the launch of the new BootcampSpot - things look a little different around here but mostly work the same - to help ease the transition you'll find some videos below that walk you through common tasks.</p>
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-xs-12">
                            <YouTube
                                videoId="Z6rndiEXh_w"
                                opts={playerOpts}
                                onEnd={this.onVideoEnd.bind(this)}
                                onReady={this.onVideoReady.bind(this)}
                            />
                        </div>
                    </div> */}

                    {loading ? (
                        <Loading> Loading videos... </Loading>
                    ) : (
                        error ? (
                            <Alert type="warn">
                                <p>There are no videos added to this playlist. If you feel like this is an error, please try again or reach out to support.</p>
                            </Alert>
                        ) : (
                            videos.length ? (
                                <div className="row card-group">
                                    {videos.map((item,index)=>{
                                        return (
                                        (item.snippet.resourceId.videoId === 'T9IpXtM4_xk' && !this.state.liveChatUniversity) ? null : (
                                        <div className="col-xs-12 col-sm-6 col-md-4" key={index}>
                                            <div className="card">
                                                <Link to={`/videos/onboarding/watch/${item.snippet.resourceId.videoId}`}><img src={item.snippet.thumbnails.medium.url} alt={`${item.title} Thumbnail`} style={{maxWidth: '100%'}}/></Link>
                                                <p className="title">{item.snippet.title}</p>
                                                <div className="card-footer">
                                                    <Link to={`/videos/onboarding/watch/${item.snippet.resourceId.videoId}`}><Icon icon="film" /> Watch Video</Link>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                        )
                                    })}
                                </div>
                            ) : (
                                <Alert type="warn">
                                    <p>No videos were found to display in the library. If you feel this is an error, try refreshing the page or reaching out to support</p>
                                </Alert>
                            )

                        )
                    )}
                </section>
            </DocumentTitle>
        )
    }

}

export default connect(mapStateToProps)(VideoOnboarding);
