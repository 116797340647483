import { universalActionTypes } from '../constants/actionTypes'

const initialState = {
    condenseNav: false
};

export const condenseNavReducer = (state = initialState, action) => {
    switch (action.type) {
        case universalActionTypes.CONDENSE_NAV:
            return {
                ...state,
                condenseNav: !state.condenseNav
            }
        default:
            return state;
    }
}