export const config = {
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'bcs',
    env: 'production',
    version: '1.0.0',
    sampleRate: 100,
    resourceSampleRate: 100,
    silentMultipleInit: true
};