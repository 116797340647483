import {Component} from "react";
import DocumentTitle from "react-document-title";
import {connect} from 'react-redux';
import React from "react";
import {getRawPayload} from "../../actions/openDocsActions";
import OpenDocsApiResponse from "../../components/OpenDocsApiResponse";
import OpenDocsHeader from "../../components/OpenDocsHeader";
import Input from "../../components/Input";


const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps,
        openDocs: state.openDocs,
        authToken: state.user.data.authToken,
    }
};

class ApiDocsWeeklyFeedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tryItUrl: '',
            sessionId: '',
        };
    }

    componentWillReceiveProps(newProps) {
        if (typeof newProps.openDocs !== "undefined") {
            const newOpenDocs = newProps.openDocs;
            if (newOpenDocs && newOpenDocs[this.state.tryItUrl]) {
                this.setState({
                    payloadRsp: newOpenDocs[this.state.tryItUrl].payload,
                    headersRsp: newOpenDocs[this.state.tryItUrl].headers,
                    status: newOpenDocs[this.state.tryItUrl].status,
                });
            }
        }
    }

    handleChange = (event) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        const obj = {};
        obj[fieldName] = fieldValue;
        this.setState(obj)
    };

    handleOnClick = () => {
        const {dispatch, authToken} = this.props;
        const ep = `weeklyFeedback`;
        this.setState({
            tryItUrl: ep
        });

        dispatch(getRawPayload(ep, {courseId: parseInt(this.state.courseId, 10)}, authToken));
    };

    render() {
        const {payloadRsp, headersRsp, status} = this.state;
        return (
            <DocumentTitle title="API Access - Weekly Feedback | Bootcamp Spot">
                <section>
                    <OpenDocsHeader
                        endpoint={"/api/instructor/v1/weeklyFeedback"}
                        description={"Lists weekly feedback for the specified course."}
                        type={"POST"}
                        headers={[{name: "Content-Type", value: "application/json", description: ""},
                            {name: "authToken", value: "<valid token>", description: "The token generated via /login"}]}
                        exampleBody={{courseId: 1}}
                        exampleResponses={[
                            {
                                code: 200,
                                body: {
                                    "surveyDefinition": {
                                        "surveyDescription": "Weekly Feedback Survey - Student",
                                        "steps": [{
                                                "stepNumber": "1",
                                                "text": "How would you rate your overall satisfaction with class this past week? (1 - unsatisfied, 5 - satisfied)"
                                            }, {
                                                "stepNumber": "2",
                                                "text": "How would you rate the pace of class? (1 - too slow, 5 - too fast)"
                                            }, {
                                                "stepNumber": "3",
                                                "text": "How satisfied were you with the level of academic support you received this past week? (Instructor, TA's, Learning Assistants, etc.) (1 - unsatisfied, 5 - satisfied) (1 unsatisfied, 5 satisfied)"
                                            }, {
                                                "stepNumber": "4",
                                                "text": "Do you think you are prepared to apply what you learned this past week outside of the course setting? (1 - strongly no, 5 - strongly yes)"
                                            }, {
                                                "stepNumber": "5",
                                                "text": "How engaging was your instructor this past week? (1 - unengaging, 5 - engaging)"
                                            }, {
                                                "stepNumber": "6",
                                                "text": "How clear was your instructor this past week? (1 - unclear, 5 - clear)"
                                            }, {
                                                "stepNumber": "7",
                                                "text": "How knowledgeable did your instructor seem to be this past week? (1 - unknowledgeable, 5 - knowledgeable)"
                                            }, {
                                                "stepNumber": "8",
                                                "text": "How would you rate the usefulness of the feedback you received on your homework this week? (If no feedback was given this week, please select N/A.) (0 - N/A, 1 - not very useful, 5 - very useful)"
                                            }, {
                                                "stepNumber": "9",
                                                "text": "How many hours did you spend outside of class this past week on homework, reviewing materials, and additional learning tasks? (1 (0-5 hrs), 2 (6-10 hrs), 3 (11-15 hrs), 4 (16-20 hrs), 5 (+20 hrs)"
                                            }, {
                                                "stepNumber": "10",
                                                "text": "Is there anything else you would like to share regarding your class experience this past week?"
                                            }, {
                                                "stepNumber": "11",
                                                "text": "In what ways did your instructional team support or not support you this past week? (Instructor, TA's, Learning Assistants, etc.)"
                                            }
                                        ]
                                    },
                                    "submissions": [{
                                            "username": "someuser@hostname.com",
                                            "date": "2017-09-12T20:12:45.65-03:00",
                                            "answers": [{
                                                    "stepNumber": "1",
                                                    "answer": {"value": "1"}
                                                }, {
                                                    "stepNumber": "2",
                                                    "answer": {"value": "1"}
                                                }, {
                                                    "stepNumber": "3",
                                                    "answer": {"value": "2"}
                                                }, {
                                                    "stepNumber": "4",
                                                    "answer": {"value": "3"}
                                                }, {
                                                    "stepNumber": "5",
                                                    "answer": {"value": "4"}
                                                }, {
                                                    "stepNumber": "6",
                                                    "answer": {"value": "4"}
                                                }, {
                                                    "stepNumber": "7",
                                                    "answer": {"value": "4"}
                                                }, {
                                                    "stepNumber": "8",
                                                    "answer": {"value": "0"}
                                                }, {
                                                    "stepNumber": "9",
                                                    "answer": {"value": "5"}
                                                    }, {
                                                    "stepNumber": "10",
                                                    "answer": {"value": "Overall an informative and fun class."}
                                                }, {
                                                    "stepNumber": "11",
                                                    "answer": {"value": "They helped me clearly understand the concepts."}
                                                }
                                            ]
                                        }
                                    ]
                                },
                                rows: 15,
                                description: "Successful response with data."
                            }
                        ]}
                    />

                    <div className="row">
                        <div className="col-xs-12">
                            <h1 className={"border-bottom"}>Try it out</h1>

                            <Input
                                label="courseId"
                                type="number"
                                id="courseId"
                                placeholder="<courseId>"
                                helper="Fetch information for this course Id"
                                aria-required={true}
                                onChange={this.handleChange}
                            />
                            <button onClick={this.handleOnClick}>Submit</button>
                        </div>
                    </div>


                    <OpenDocsApiResponse
                        headers={headersRsp}
                        payload={payloadRsp}
                        status={status}
                    />

                </section>
            </DocumentTitle>
        )
    }
}

export default connect(mapStateToProps)(ApiDocsWeeklyFeedback);
