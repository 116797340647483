import React, {Component} from 'react'
import DocumentTitle from 'react-document-title';
import {Link} from 'react-router-dom'

export default class ErrorCatcher extends Component {
    constructor(props) {
      super(props);
      // Add some default error states
      this.state =  {
        error: null
      };
    }

    static getDerivedStateFromError({ message }) {
      // Update state so the next render will show the fallback UI.
      return { error: message };
    };
  
    render() {
      if(this.state.error) {
        // Some error was thrown. Let's display something helpful to the user
        return (
          <DocumentTitle title={`System Error Page | Bootcamp Spot`} id="error-catcher">
            <React.Fragment>
              <React.Fragment>
                <h1>Sorry, an unexpected system error has occurred!</h1>
                <p > Please try refreshing your browser. If the error persists, please contact <Link data-test="support-link" to="/support" aria-label="Course Support">Support</Link>. Thank you!</p>
              </React.Fragment>
              <React.Fragment>
                <div className="error-info" style={{display: "none"}}>{this.state.error}</div>
              </React.Fragment>
            </React.Fragment>
          </DocumentTitle>
        );
      } else {
        // No errors were thrown. As you were.
        return this.props.children;
      }
    }
  }