import moment from 'moment-timezone'
import {dateTimeFormats as dt} from '../constants/dateTime';

/**
 * parse a full ISO datetime string as UTC and output raw time
 * @param {*} dateTime
 */
export const convertUTCToRawTime = (dateTime) => {
    return moment.utc(moment(dateTime).valueOf()).format(dt.MILITARY_TIME)
}

/**
 * parse a full ISO datetime string as UTC and output raw date string
 * @param {*} dateTime
 */
export const convertUTCToRawDate = (dateTime) => {
    return moment.utc(dateTime).format(dt.YMD)
}

/**
 * parse a full ISO datetime string as UTC and output raw datetime string
 * @param {*} dateTime
 */
export const convertUTCToRawDateTime = (dateTime) => {
    return moment.utc(dateTime).format(dt.YMD_MILITARY)
}

/**
 * convert a time string to a moment object in the section timezone
 * @param {*} timeString
 * @param {*} timeZone
 */
export const convertTimeToSectionTZ = (timeString, timeZone) => {
    const strippedTime = convertUTCToRawTime(timeString);
    return moment.tz(strippedTime, dt.MILITARY_TIME, timeZone)
}

/**
 * convert a time string into a moment object in the local timezone
 * @param {*} timeString
 */
export const convertTimeToLocalTZ = (timeString) => {
    const strippedTime = stripUTCFromDateTime(timeString);
    return moment(strippedTime, dt.MILITARY_TIME)
}

/**
 * convert a given date and given time into a single moment object in the section timezone
 * @param {*} date
 * @param {*} time
 * @param {*} timeZone
 */
export const convertDateTimeToSectionTZ = (date, time, timeZone) => {
    const rawTime = (typeof time === 'string' && time.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})*/)) ? convertUTCToRawTime(time) : time;
    const rawDate = convertUTCToRawDate(date);
    return moment.tz(rawDate + ' ' + rawTime, dt.YMD_MILITARY, timeZone)
}

/**
 * convert a given date and given time into a single moment object in the section timezone
 * @param {*} timeString
 */
export const convertDateTimeToLocalTZ = (timeString) => {
    const strippedTime = stripUTCFromDateTime(timeString);
    return moment(strippedTime, dt.YMD_MILITARY)
}

/**
 *
 * @param {*} timeString
 * @param {*} timeZone
 */
export const getSectionTimeToString = (timeString, timeZone) => {
    const strippedTime = stripUTCFromDateTime(timeString);
    const date = getDateStringFromObject(moment.tz(timeZone))
    const time = getTimeStringFromObject(moment.tz(strippedTime, timeZone))
    return moment.tz(date +' '+ time, dt.YMD_MILITARY, timeZone).format(dt.MILITARY_TIME)
}

/**
 * convert a time string into a datetime moment object
 * @param {*} time
 * @param {*} date
 * @param {*} timeZone
 */
export const convertTimeToDateTimeObject = (time, date, timeZone = 'UTC') => {
    const formattedTime = getTimeStringFromObject(moment.tz(stripUTCFromDateTime(time), dt.MILITARY_TIME, timeZone));
    const formattedDate = getDateStringFromObject(moment.tz(date, timeZone));
    return moment.tz(formattedDate + ' ' + formattedTime, dt.YMD_MILITARY, timeZone);
}

/**
 * format moment object as a time string
 * @param {*} momentObj
 */
export const getTimeStringFromObject = (momentObj) => {
    return momentObj.format(dt.MILITARY_TIME)
}

/**
 * format moment object as a date string
 * @param {*} momentObj
 */
export const getDateStringFromObject = (momentObj) => {
    return momentObj.format(dt.YMD)
}

/**
 * format moment as a datetime string
 * @param {*} momentObj
 */
export const getDateTimeStringFromObject = (momentObj) => {
    return momentObj.format(dt.YMD_MILITARY)
}

/**
 * format moment object as a YYYY-MM string
 * @param {*} momentObj
 */
export const getYearMonthFromObject = (momentObj) => {
    return momentObj.format(dt.YM)
}

/**
 * Strip out the 'T' and 'Z' from a utc-encoded ISO datetime string
 * @param {*} dateTimeString
 */
export const stripUTCFromDateTime = (dateTimeString) => {
    return typeof dateTimeString.includes('T') ? dateTimeString.replace('Z', '').replace('T', ' ') : dateTimeString;
};

/**
 * Gets the section-based date time string
 * @param {*} dateTimeString
 * @param {*} timeZone
 */
export const getSectionDateTimeToString = (dateTime, timeZone) => {
    return getDateTimeStringFromObject(moment.tz(stripUTCFromDateTime(dateTime), dt.YMD_MILITARY, timeZone));
}

/**
 * Get the moment dateTime object in the timezone given
 * @param {*} dateTime
 * @param {*} timeZone
 */
export const getSectionDateTimeObject = (dateTime, timeZone) => {
    return moment.tz(convertUTCToRawDateTime(dateTime), timeZone);
}

/**
 * get the moment dateTime object in the local timeZone
 * @param {*} dateTime
 */
export const getLocalDateTimeObject = (dateTime) => {
    return moment(stripUTCFromDateTime(dateTime));
}

/**
 * Get the current time in the section timezone as a string
 * @param {*} timeZone
 */
export const getCurrentSectionTimeToString = (timeZone) => {
    return getDateTimeStringFromObject(moment().clone().tz(timeZone));
}

/**
 * Get the current time in the section timezone as a string
 * @param {*} timeZone
 */
export const getCurrentSectionDateTimeObject = (timeZone) => {
    return moment().clone().tz(timeZone);
}

/**
 * Returns true if the passed in number of time has elapsed, false otherwise
 * @param {*} hoursElapsed
 * @param {*} initialTime
 */

export const isTimeAfterNHours = (initialTime, hoursElapsed) => {
    const initialTimeFormatted = moment(initialTime)
    const timeWithHoursElapsed = initialTimeFormatted.add(hoursElapsed, 'hours')
    return moment().isAfter(timeWithHoursElapsed)
 }

 /**
 * Returns true if it is after the end of day for the dateTime provided
 * @param {*} dateTime
 */

 export const isAfterEndOfDay = (dateTime) => {
    const endOfDay = moment(dateTime).endOf('day')
    return moment().isAfter(moment(endOfDay))
 }