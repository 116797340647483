import moment from 'moment'

const setupUniversitiesList = () => {
    let list = [
        'Test University',
        'University of California, Berkeley',
        'University of California, Irvine',
        'George Washington University',
        'University of Texas at Austin',
        'Northwestern University',
        'Rutgers University'
    ];

    if(moment().isAfter('2018-04-30'))
    {
        list.push('University of Denver')
        list.push('Georgia Institute of Technology')
        list.push('University of Central Florida')
        list.push('University of California, Los Angeles')
        list.push('UNC Chapel Hill')
        list.push('University of Kansas')
    }

    if(moment().isAfter('2018-05-06'))
    {
        list.push('University of Arizona')
        list.push('UNC Charlotte')
        list.push('Case Western Reserve University')
        list.push('Southern Methodist University')
        list.push('University of Pennsylvania')
        list.push('University of Texas at Austin (Houston)')
        list.push('University of Utah')
        list.push('University of Minnesota')
        list.push('University of Richmond')
    }

    if(moment().isAfter('2018-05-13'))
    {
        list.push('University of Massachusetts')
        list.push('University of New Hampshir')
        list.push('University of California, Davi')
        list.push('University of Miam')
        list.push('University of Southern Californi')
        list.push('Technologico de Monterre')
        list.push('University of Toronto')
    }

    return list
}


export const features = {
    auth: {
        authServer: true,
        github: false,
        showAuthServerInvite: true
    },
    surveys:{
        reviewPrompt: true,
    },
    dev: {
        demoMode: false
    },
    sideNav: {
        classFlipper: false
    },
    homework: {
        dateExtend: true
    },
    support: {
        web2case: true
    },
    billing: {
        bcsBilling: true
    },
    liveChat: {
        isEnabled: true,
        availableUniversities: setupUniversitiesList()
    },
    isStudentTesting: true,
    isInstructorTesting: false,
    showOnboarding: moment().isBefore('2018-05-01')
}

