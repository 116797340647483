/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

// ICON IS A REUSEABLE COMPONENT. Please make sure that you pass the right props in or unexpected behavior will occur. 
const Icon = (props) => (
    <i
        className={`${props.brand ? 'fab' : 'fal'}${props.solid ? " fas" : ""} fa-${props.icon} ${props.options}`}
        onClick={props.onClickHandler ? props.onClickHandler : null}
        onKeyPress={props.onKeyPress ? props.onKeyPress : null}
        tabIndex={props.tabIndex ? props.tabIndex : null}
        aria-label={props.ariaLabel ? props.ariaLabel : props.icon}
        role={props.role ? props.role : null}
        aria-hidden={props.ariaHidden ? props.ariaHidden : null}
        aria-expanded={props.ariaExpanded}
        style={props.style ? props.style : null}

        alt={props.alt ? props.alt : null}
        title={props.alt ? props.alt : null}
    >
        {props.unread > 0 ? (
            <span className="unread">{props.unread}</span>
        ) : null}
    </i>
)

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    options: PropTypes.string,
    brand: PropTypes.bool,
    onClickHandler: PropTypes.func,
    onKeyPress: PropTypes.func,
    style: PropTypes.object
}

Icon.defaultProps = {
    brand: false,
    unread: 0,
    tabIndex: false,
    ariaLabel: false,
    role: false,
    ariaHidden: true,
    options: ''
}


export default Icon
