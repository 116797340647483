import React, {Component} from 'react';
import Icon from '../Icon';
import moment from 'moment';
import * as utils from '../../helpers/utils';
import CalendarWeek from './CalendarWeek';

//  
const PREVIOUS = "previous";

/*
CALENDAR USAGE EXAMPLE

<Calendar
    data={}
    title="Calendar Title Example"
    eventLinkCallback={}
    eventCssClassCallback={}
    startDate={}
    endDate={}
/>

data:
Required. Must be an event objects array..
An event object should look like:
{
    id: 55555,
    title: "Event Title",
    date: moment(),
    eventType: {code: "session", name: "Session"} ----> (ie: session, coursework),
    category: {code: "academic", name: "Academic"} ----> (ie: career, academic, orientation)
}

title:
Optional. String.

eventLinkCallback:
Optional. A function that takes an event and returns the URI you want to navigate to when clicked.

eventCssClassCallback:
Optional. A function that takes an event item and returns a CSS class. Use this callback to customize the default grey color of your events.

startDate:
Required. A Javascript Date, a Moment.js object, or a Moment.js compatible date string representation.

endDate:
Required. A Javascript Date, a Moment.js object, or a Moment.js compatible date string representation.

*/

class Calendar extends Component {
    constructor(props) {
        super(props);
        const today = moment();
        const calendarStartDate = today.isBetween(this.props.startDate, this.props.endDate) ? today : this.props.startDate;
        const calendarStartYearMonth = calendarStartDate.format('YYYY-MM');

        this.state = {
            metadata: {
                title: this.props.title
            },
            calendarMonth: moment(calendarStartDate),
            calendarMonthObject: utils.getCalendarMonth(calendarStartYearMonth),
        }
    }

    keyPress = (e, month) => {
        e.preventDefault();
        if(e.key === "Enter"){
            month === PREVIOUS ? this.previousMonthHandler() : this.nextMonthHandler();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.selectedMonth && (nextProps.selectedMonth !== this.state.selectedMonth)) {
            this.setState({
                calendarMonth: moment(nextProps.selectedMonth),
                calendarMonthObject: utils.getCalendarMonth(nextProps.selectedMonth)
            })
        }
    }

    renderCalendarTitle = () => {
        return (
            <div className="col-xs-12">
                <h2>{this.state.metadata.title}</h2>
            </div>
        )
    }

    renderCalendarYearNav = () => {
        const {calendarMonth} = this.state;
        const startDate = moment(this.props.startDate);
        const endDate = moment(this.props.endDate);
        return (
            <div className="col-xs-12 text-center">
                <div aria-controls="bcsCalendar">
                    {calendarMonth.isAfter(startDate, 'month') ?
                        <Icon
                            icon="chevron-left"
                            ariaHidden="false"
                            role="button"
                            tabIndex="0"
                            onClickHandler={this.previousMonthHandler}
                        />
                        : ''
                    }
                    <strong className="padding-l-2">{calendarMonth.format('MMMM')}</strong>
                    <span className="padding-r-2 text-light"> {calendarMonth.format('YYYY')}</span>
                    {calendarMonth.isBefore(endDate, 'month') ?
                        <Icon
                            icon="chevron-right"
                            ariaHidden="false"
                            role="button"
                            tabIndex="0"
                            onClickHandler={this.nextMonthHandler}
                        />
                        : ''
                    }
                </div>
            </div>
        )
    }

    previousMonthHandler = (e) => {
        const {calendarMonth} = this.state;
        const previousMonth = calendarMonth.subtract(1, 'months').format('YYYY-MM');
        this.setState({
            calendarMonth: moment(previousMonth),
            calendarMonthObject: utils.getCalendarMonth(previousMonth)
        })
        if (this.props.selectedMonthChangedCallback) {
            this.props.selectedMonthChangedCallback(null, previousMonth)
        }
    }

    nextMonthHandler = (e) => {
        const {calendarMonth} = this.state;
        const nextMonth = calendarMonth.add(1, 'months').format('YYYY-MM');
        this.setState({
            calendarMonth: moment(nextMonth),
            calendarMonthObject: utils.getCalendarMonth(nextMonth)
        })
        if (this.props.selectedMonthChangedCallback) {
            this.props.selectedMonthChangedCallback(null, nextMonth)
        }
    }

    renderCalendarDayHeader = () => {
        return (
            // LINTER will throw on role="row", but that's how we have to do it for accessibility for the calendar. Approved by Drew.
            // eslint-disable-next-line
            <ul className="calendar-days" role="row">
                <li role="cell">Sun</li>
                <li role="cell">Mon</li>
                <li role="cell">Tue</li>
                <li role="cell">Wed</li>
                <li role="cell">Thu</li>
                <li role="cell">Fri</li>
                <li role="cell">Sat</li>
            </ul>
        )
    }

    generateCalendarWeeks = () => {
        const {calendarMonthObject, calendarMonth} = this.state;
        let currentMonthData = this.props.data.filter(item => moment(item.date).format('YYYY-MM') === calendarMonth.format('YYYY-MM'));
        let weeks = [];

        for (let i = 0; i <= calendarMonthObject.weeksInMonth; i++) {
            let firstWeek = (i === 0) ? true : false;
            let lastWeek = (i === calendarMonthObject.weeksInMonth) ? true : false;
            let startDate = firstWeek ? 1 : ((i * 7) - calendarMonthObject.startDay + 1);
            let startDay = firstWeek ? calendarMonthObject.startDay : 0;

            weeks.push(
                <CalendarWeek
                    key={i + 'calWeek'}
                    data={currentMonthData}
                    week={i}
                    startDayOfWeek={startDay}
                    startDate={startDate}
                    daysInMonth={calendarMonthObject.daysInMonth}
                    isFirstWeek={firstWeek}
                    isLastWeek={lastWeek}
                    eventLinkCallback={this.props.eventLinkCallback}
                    eventCssClassCallback={this.props.eventCssClassCallback}
                />
            );
        }
        return weeks
    }

    render() {
        const {title} = this.props;
        return (
            <span>
                <div className="row padding-t-3">
                    {title && this.renderCalendarTitle()}
                    {this.renderCalendarYearNav()}
                    <div className="col-xs-12">
                        <div className="bcs-calendar" aria-live="polite" role="table" aria-label="Calendar">
                            {this.renderCalendarDayHeader()}
                            {this.generateCalendarWeeks()}
                        </div>
                    </div>
                </div>
            </span>
        )
    }
}

export default Calendar;
