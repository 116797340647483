import React, { Component } from 'react';
import CalendarItem from './CalendarItem';
import moment from 'moment';

class CalendarWeek extends Component {
    generateCalendarWeek = (data, weekNumber, startDay, startDate, daysInMonth, isFirstWeek, isLastWeek) => {
        let emptySpaces = 0;
        let items = [];
        let week;
        let lastWeekBuffer = 7 - (daysInMonth - startDate) - 1;

        if(isFirstWeek) emptySpaces = startDay;
        if(isLastWeek) emptySpaces = lastWeekBuffer;

        let weekIterator = 7 - emptySpaces;
        let emptyDays = emptySpaces ? this.generateEmptyCalendarItems(emptySpaces) : [];

        for(let i = 0; i < weekIterator; i++) {
            let date = startDate + i;
            let dayOfWeek = startDay + i;
            let dailyData = (data && data.length) ? data.filter(x => {
                let itemDate = x.date
                return date === moment(itemDate).get('date');
            }) : null;
            items.push(<CalendarItem
                date={date}
                eventLinkCallback={this.props.eventLinkCallback}
                eventCssClassCallback={this.props.eventCssClassCallback}
                dayOfWeek={dayOfWeek} key={i+10}
                data={dailyData}
            />);
        }

        if(isFirstWeek) {
            week = emptyDays.concat(items);
        } else if(isLastWeek) {
            week = items.concat(emptyDays);
        } else {
            week = items;
        }
        return week;
    }

    generateEmptyCalendarItems = (number) => {
        let spaces = [];
        for(let i = 0; i < number; i++) {
            spaces.push(<CalendarItem empty={true} key={i+20} />)
        }

        return spaces;
    }

    render() {
        const {data, week, startDayOfWeek, startDate, daysInMonth, isFirstWeek, isLastWeek} = this.props;
        return (
            // LINTER will throw on role="row", but that's how we have to do it for accessibility for the calendar. Approved by Drew.
            // eslint-disable-next-line
            <ul className="calendar-grid" role="row">
                {this.generateCalendarWeek(data, week, startDayOfWeek, startDate, daysInMonth, isFirstWeek, isLastWeek)}
            </ul>
        )
    }
}

export default CalendarWeek;
