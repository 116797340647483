import React from 'react';
import moment from 'moment';

export const getDateString = (date) => {
    return moment(date).format('MMDDYYYY')
}

export const getFormattedDateNowString = () => {
    let now = moment();
    return now.format('dddd, MMMM Do YYYY')
}

export const getFormattedDateString = (date) => {
    return moment(date).format('LLLL')
}

export const getFormattedDateNoYearString = (date) => {
    return moment(date).format('dddd, MMMM Do')
}

export const getDayNumber = (date) => {
    return moment(date).format('D')
}

export const getDayString = (date, short=false) => {
    return short ? moment(date).format('ddd') : moment(date).format('dddd')
}

export const getMonthString = (date, short = false) => {
    return short ? moment(date).format('MMM') : moment(date).format('MMMM')
}

export const getYear = (date) => {
    return moment(date).format('YYYY')
}

export const getFormattedTime = (time) => {
    return moment(time).format('h:mmA')
}

export const getTimeFromNow = (time) => {
    return moment(time).fromNow();
}

export const getDateDiffFromNow = (time) => {
    return moment().diff(time, `days`, true);
}

export const sessionDetailDateString = (date, start, end, tz=null) =>
{
    return (
        <p>
            <strong>{`${getDayString(date)} ${getMonthString(date)} ${getDayNumber(date)}, ${getYear(date)}`}</strong>
            <em> from </em>
            <strong>{`${moment(start).format("h:mm A")}`}</strong>
            <em> to </em>
            <strong>{`${moment(end).format("h:mm A")}`} </strong>
            Local
        </p>
    )
}

export const getDateForFormEdit = (date) =>
{
   return moment(date).format("MM-DD-YYYY");
}

export const getTimeForFormEdit = (time) =>
{
    return moment(time).format("h:mm");
}

export const getTimeMeridian = (time) =>
{
    return moment(time).format("A");
}

export const dateRangeHelpers = {
    splitDateIntoDisplayObject
}

function splitDateIntoDisplayObject(date) {
    return {
        day: moment(date).format("dddd"),
        month: moment(date).format("MMMM"),
        date: moment(date).format("DD"),
        time: moment(date).format("hh:mm a")
    }
}

