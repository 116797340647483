import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import moment from "moment"
import {Flag} from 'flag';
import {connect} from "react-redux";
import {get} from 'lodash'

import MarkdownRender from '../../components/MarkdownRenderer/markdownRenderer';
import {Table, TBody, THead, TR, TD, TH} from '../../components/Table';
import Loading from '../../components/Loading'
import Alert from '../../components/Alert'
import {FlagWithText} from '../../components/FlagWithText'

import { isRole} from "../../helpers/roles";
import {roleTypes} from '../../constants/dataTypes';
import {getFormattedDateString} from '../../helpers/dateTime'
import {courseworkActions} from '../../actions/courseworkActions'
import {assignmentContextTypes, availableCareerStatus} from '../../constants/dataTypes';
import {getGradeName} from "../../constants/grade";

function mapStateToProps(state, ownProps) {
    return {
        userId: state.user.data.userId,
        role: state.user.enrollmentInfo.courseRole.name,
        enrollmentId: state.user.enrollmentInfo.id,
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        ...ownProps
    }
}

class InstructionalCourseworkDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            courseworkLoading: false,
            isExtendingDueDate: false,
            dueDateExtension: 0,
            dueDateExtensionSuccess: '',
            dueDateExtensionError: ''
        }
    }

    toggleDueDateExtension = () => {
        this.setState({
            isExtendingDueDate: !this.state.isExtendingDueDate,
            dueDateExtensionSuccess: ''
        });
    }

    postDueDateExtension = async () => {
        if (this.state.dueDateExtension !== 0) {
            try {
                let bufferDays = this.state.dueDateExtension;
                let selectedAssignment = this.props.selectedAssignment;
                if (selectedAssignment.assignment.bufferDays > 0) {
                    await courseworkActions.updateDueDateExtension(selectedAssignment.assignment.id, bufferDays);
                } else {
                    await courseworkActions.createDueDateExtension(selectedAssignment.assignment.id, bufferDays);
                }

                this.toggleDueDateExtension();
                this.setState({
                    dueDateExtension: 0,
                    dueDateExtensionSuccess: `Due date extended by ${this.state.dueDateExtension} day${this.state.dueDateExtension > 1 ? 's' : ''}.`,
                    dueDateExtensionError: ''
                });
                await this.props.refreshAssignment();
            } catch (err) {
                this.setState({dueDateExtensionError: 'Failed to set due date extension.'})
                console.error(err)
            }
        } else {
            this.setState({dueDateExtensionError: 'Please select number of days to extend due date.'});
        }
    }


    removeDueDateExtension = async () => {
        try {
            let bufferDays = 0;
            let selectedAssignment = this.props.selectedAssignment;
            await courseworkActions.deleteDueDateExtension(selectedAssignment.assignment.id, bufferDays);
            this.setState({
                dueDateExtension: '0',
                dueDateExtensionSuccess: `Due date reset to original due date.`,
                dueDateExtensionError: ''
            });
            await this.props.refreshAssignment();
        } catch (err) {
            this.setState({dueDateExtensionError: 'Failed to reset due date extension.'});
            console.error(err);
        }
    }

    dueDateExtensionRadio = (e) => {
        let value = parseInt(e.currentTarget.value, 10)
        this.setState({
            dueDateExtension: value,
            dueDateExtensionError: ''
        });
    }

    cancelDueDateExtension = () => {
        this.setState({
            dueDateExtension: 0,
            dueDateExtensionError: ''
        })
        this.toggleDueDateExtension()
    }


    renderDueDateExtensionOptions = () => {
        let dueDateExtensionOptions = [];
        for (let i = 1; i <= 7; i++) {
            dueDateExtensionOptions.push(
                <label key={i} className="display-block-xs display-inline-block-md margin-r-4">
                    <input type="radio" value={i} name="extend-date-group" onChange={this.dueDateExtensionRadio}/>
                    {i} Day{i > 1 ? 's' : ''}
                </label>
            )
        }
        return dueDateExtensionOptions;
    }

    countPlagiarismByStudents = (selectedAssignment) => {
        let plagiarismCount = 0;
        if(selectedAssignment && selectedAssignment.students){
            selectedAssignment.students.forEach((studentSubmission) => {
                if(studentSubmission.submissionPlagiarism){
                    plagiarismCount += 1
                }
            })
        }
        return plagiarismCount
    }

    render() {
        const { selectedAssignment, activeRole } = this.props;
        const tableHeadings = [
            'Student',
            'Submission Date',
            'Status',
            'Grade',
            'Action'
        ]
        const plagiarismCount = this.countPlagiarismByStudents(selectedAssignment)
        

        return (
            <div className="session-detail-info">
                {selectedAssignment.assignment.dueDate ? (
                    <div className="row">
                        <div className="col-xs-12 hide-md text-right">
                            <Link to="/coursework">View all Coursework</Link>
                        </div>
                        <div className="col-xs-12 col-md-8 col-lg-9 padding-b-0">
                            <h1>{selectedAssignment.assignment.title}</h1>
                            {selectedAssignment.assignment.context.contextCode === assignmentContextTypes.CAREER
                                ? <p className="font-sm"><em>This Milestone is <strong>Required</strong> if you are
                                    seeking career assistance.</em></p>
                                : <p className="font-sm"><em>This Assignment
                                    is {selectedAssignment.assignment.required ? (
                                            selectedAssignment.assignment.requiredForGraduation ? (
                                                <strong>Required for Graduation</strong>
                                            ) : (
                                                <strong>Required</strong>
                                            )
                                        ) : (
                                            <strong>Not Required</strong>
                                        )}</em></p>
                            }
                        </div>
                        <div className="col-md-4 col-lg-3 text-right hide-xs show-md">
                            <Link to="/coursework">View all Coursework</Link>
                        </div>

                        <div className="col-xs-12 col-gutter-lr">
                            {this.state.dueDateExtensionSuccess !== '' ? (
                                <Alert type="success">{this.state.dueDateExtensionSuccess}</Alert>
                            ) : null}
                            <p>
                                <strong>{selectedAssignment.assignment.bufferDays > 0 ? `Original Due Date:` : `Due:`}</strong> {getFormattedDateString(selectedAssignment.assignment.dueDate)}
                            </p>

                            {selectedAssignment.assignment.bufferDays > 0 ? (
                                <Flag name="homework.dateExtend">
                                    <p>
                                        <strong>Extended Due Date:</strong> {getFormattedDateString(selectedAssignment.assignment.effectiveDueDate)}
                                    </p>
                                </Flag>

                            ) : null}
                        </div>
                        { isRole([roleTypes.TA, roleTypes.INSTRUCTOR, roleTypes.ADMIN, roleTypes.SSM], activeRole) ? (
                            <React.Fragment>
                                {this.state.isExtendingDueDate ? (
                                    <div className="col-xs-12 bg-secondary margin-b-3">
                                        <p>Extend Due date by:</p>

                                        {this.renderDueDateExtensionOptions()}

                                        <div className="margin-t-3">
                                            <button className="btn-small btn-save"
                                                    onClick={() => this.postDueDateExtension()}>Apply Extension
                                            </button>
                                            <button className="btn-small btn-tertiary btn-delete"
                                                    onClick={() => this.cancelDueDateExtension()}>Cancel
                                            </button>
                                        </div>
                                        {this.state.dueDateExtensionError !== '' ? (
                                            <Alert type="error">{this.state.dueDateExtensionError}</Alert>
                                        ) : null}
                                    </div>
                                ) : (
                                    <Flag name="homework.dateExtend">
                                        <div className="col-xs-12 col-gutter-lr">
                                            {
                                                !this.props.hideExtendDueDate &&
                                                <button className="btn-small btn-edit"
                                                        onClick={() => this.toggleDueDateExtension()}>Extend due date
                                                </button>
                                            }
                                            {selectedAssignment.assignment.bufferDays > 0 ? (
                                                <button className="btn-small btn-delete"
                                                        onClick={() => this.removeDueDateExtension()}>Reset to original due date</button>
                                            ) : null}
                                        </div>
                                    </Flag>
                                )}
                            </React.Fragment>
                        ) : null }

                        <div className="col-xs-12 col-gutter-lr">
                            <p>
                                <strong>Submitted: </strong>{selectedAssignment.assignment.submittedStudentCount}/{selectedAssignment.students.length}
                            </p>
                            {selectedAssignment.assignment.assignmentHeader &&
                            <p>{selectedAssignment.assignment.assignmentHeader.header}</p>}
                            {selectedAssignment.assignment.assignmentContent &&
                            <MarkdownRender text={selectedAssignment.assignment.assignmentContent.content || ''}/>}
                            {
                                plagiarismCount ? <FlagWithText text={`${this.countPlagiarismByStudents(selectedAssignment)} student(s) have been flagged for plagiarism for this view`} ></FlagWithText> : null
                            }
                        </div>
                    </div>
                ) : null}

                <div className="row">
                    {this.state.courseworkLoading ? (
                        <div className="col-xs-12">
                            <div className="text-center"><Loading/></div>
                        </div>
                    ) : (
                        <div className="col-xs-12">
                            <Table className="font-sm">
                                <THead>
                                    <TR>
                                        <TH>{tableHeadings[0]}</TH>
                                        <TH>{tableHeadings[1]}</TH>
                                        <TH>{tableHeadings[2]}</TH>
                                        <TH>{tableHeadings[3]}</TH>
                                        <TH>{tableHeadings[4]}</TH>
                                    </TR>
                                </THead>
                                <TBody>
                                {selectedAssignment.students.map((coursework, index) => {
                                    let shouldShowPlagiarismFlagBool = get(coursework, "submissionPlagiarism.submissionId", false)
                                    return (
                                        <TR key={index}>
                                            <TD heading={tableHeadings[0]}>
                                                <strong>{coursework.student.firstName + ' ' + coursework.student.lastName}</strong>
                                                <br/>
                                            </TD>
                                            <TD heading={tableHeadings[1]}>
                                                {(coursework.submission && coursework.submission.date) ? moment(coursework.submission.date).format('MMMM D, YYYY') : ""}
                                            </TD>
                                            {coursework.submission && selectedAssignment.assignment.context.contextCode === assignmentContextTypes.CAREER ? (

                                                <TD heading={tableHeadings[2]}>
                                                    {(coursework.submission) &&
                                                    <span className="text-green">Submitted</span>}
                                                    {(!coursework.submission) &&
                                                    <span className="text-red">Unsubmitted</span>}
                                                </TD>
                                            ) : (
                                                <TD heading={tableHeadings[2]}>
                                                    {(coursework.submission && coursework.grade) &&
                                                        <span className="text-green">Submitted & Graded</span>}
                                                    {(coursework.submission && !coursework.grade) &&
                                                        <span className="text-yellow">Submitted & Ungraded</span>}
                                                    {(!coursework.submission) &&
                                                        <span className="text-red">Unsubmitted</span>}
                                                    {(shouldShowPlagiarismFlagBool) && 
                                                        <FlagWithText text="Flagged for Plagiarism"/>}

                                                </TD>
                                            )}
                                            {selectedAssignment.assignment.context.contextCode === assignmentContextTypes.CAREER ? (
                                                (coursework.careerFeedback === null) ?
                                                    <TD heading={tableHeadings[3]}>
                                                        <span className="text-red">---</span>
                                                    </TD>
                                                    : (
                                                        <TD heading={tableHeadings[3]}>
                                                            {(coursework.careerFeedback.careerFeedbackStatusId === 1) ? (
                                                                <span className="text-yellow">{availableCareerStatus[0].label}</span>
                                                            ) : null}
                                                            {(coursework.careerFeedback.careerFeedbackStatusId === 2) ? (
                                                                <span className="text-green">{availableCareerStatus[1].label}</span>
                                                            ) : null}
                                                            {(coursework.careerFeedback.careerFeedbackStatusId === 3) ? (
                                                                <span className="text-red">{availableCareerStatus[2].label}</span>
                                                            ) : null}
                                                            {(coursework.careerFeedback.careerFeedbackStatusId === 4) ? (
                                                                <span className="text-yellow">{availableCareerStatus[3].label}</span>
                                                            ) : null}
                                                            {(coursework.careerFeedback.careerFeedbackStatusId === 5) ? (
                                                                <span className="text-green">{availableCareerStatus[4].label}</span>
                                                            ) : null}
                                                            {(coursework.careerFeedback.careerFeedbackStatusId === 6) ? (
                                                                <span className="text-green">{availableCareerStatus[5].label}</span>
                                                            ) : null}
                                                            {(coursework.careerFeedback.careerFeedbackStatusId === 7) ? (
                                                                <span className="text-red">{availableCareerStatus[6].label}</span>
                                                            ) : null}
                                                        </TD>
                                                    )
                                            ) : (
                                                coursework.grade ? (
                                                    <TD heading={tableHeadings[3]}>
                                                        <span className={coursework.grade.grade ? "text-green" : "text-red"}>{coursework.grade.grade ? getGradeName(coursework.grade.grade) : ""}</span>
                                                    </TD>
                                                ) : <TD heading={tableHeadings[3]}></TD>
                                            )}
                                            <TD heading={tableHeadings[4]}>
                                                {(coursework.submission && coursework.submission.id) ? (
                                                    <Link to={`/gradebook/${selectedAssignment.assignment.id}/grade/${coursework.student.id}`}>
                                                        {selectedAssignment.assignment.context.contextCode === assignmentContextTypes.CAREER ? (

                                                            isRole([roleTypes.CD], activeRole) ? (
                                                                <button className="btn-small btn-edit margin-b-0">Give Feedback</button>
                                                            ) : null

                                                        ) : (
                                                            isRole([roleTypes.CM], activeRole) ? (
                                                                <button className="btn-small btn-edit margin-b-0">View Grade and Feedback</button>
                                                            ) : (
                                                                <button className="btn-small btn-edit btn-tertiary font-xs margin-b-0">Grade Assignment</button>
                                                            )
                                                        )}
                                                    </Link>
                                                ) : (
                                                    <span></span>
                                                )}
                                            </TD>
                                        </TR>
                                    )
                                })}
                                </TBody>
                            </Table>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export const INSTRUCTIONALCOURSEWORKDETAIL = InstructionalCourseworkDetail;
export default connect(mapStateToProps)(InstructionalCourseworkDetail);

