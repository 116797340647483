export const universalActionTypes = {
  RESET_ENROLLMENT_INFO: 'RESET_ENROLLMENT_INFO',
  CONDENSE_NAV: 'CONDENSE_NAV'
}

export const backButtonActionTypes = {
    ADD_ROUTE_ITEM: 'ADD_ROUTE_ITEM',
}

export const userActionTypes = {
    USER_LOGIN: "USER_LOGIN",
    USER_ACTIVE_FLAG: "USER_ACTIVE_FLAG",
    USER_GET_DATA: "USER_GET_DATA",
    USER_RESET_PASSWORD: "USER_RESET_PASSWORD",
    USER_FORGOT_PASSWORD_REQUEST: "USER_FORGOT_PASSWORD_REQUEST",
    USER_GET_INFO: "USER_GET_INFO",
    USER_GET_UNIVERSITIES: "USER_GET_UNIVERSITIES",
    USER_GET_COHORTS: "USER_GET_COHORTS",
    USER_RESET_COHORTS: "USER_RESET_COHORTS",
    USER_GET_ENROLLMENTS: "USER_GET_ENROLLMENTS",
    USER_GET_ENROLLMENT_INFO: "USER_GET_ENROLLMENT_INFO",
    USER_CLEAR_ERROR: "USER_CLEAR_ERROR",
    USER_CACHEBUST_AVATAR_URL: "USER_CACHEBUST_AVATAR_URL",
    USER_GENERATE_MFA: "USER_GENERATE_MFA",
    USER_MFA_ERROR: "USER_MFA_ERROR"
};

export const dashboardActionTypes = {
    GET_DASHBOARD: "GET_DASHBOARD",
    RESET_WALL_DATA: 'RESET_WALL_DATA',
}

export const persistenceActionTypes = {
    PERSIST_STATE: "PERSIST_STATE",
    LOAD_STATE: "LOAD_STATE",
    HYDRATE: "HYDRATE",
    AUTO_REHYDRATE: "AUTO_REHYDRATE",
    PURGE_STATE: "PURGE_STATE"
}

export const sessionActionTypes = {
    FETCH_SESSIONS: 'FETCH_SESSIONS',
    FETCH_SINGLE_SESSION: 'FETCH_SINGLE_SESSION',
    REFRESH_STUDENTS: 'REFRESH_STUDENTS'
}

export const roleActionTypes = {
    GET_ALL_ROLES: "GET_ALL_ROLES",
    SET_ROLES_ACCESS: "SET_ROLES_ACCESS"
}

export const courseworkActionTypes = {
    GET_ASSIGNMENTS: "GET_ASSIGNMENTS",
    GET_SINGLE_ASSIGNMENT: "GET_SINGLE_ASSIGNMENT"
}

export const lockActionTypes = {
    ROUTE_LOCK: 'ROUTE_LOCK',
    ROUTE_LOCK_RELEASE: 'ROUTE_LOCK_RELEASE'
}

export const announcementsActionTypes = {
    ANNOUNCEMENTS_CHANGE_FILTER: 'ANNOUNCEMENTS_CHANGE_FILTER',
    FETCH_ANNOUNCEMENTS: 'FETCH_ANNOUNCEMENTS',
    ANNOUNCEMENTS_UPDATE_LIST: 'ANNOUNCEMENTS_UPDATE_LIST'
}

export const enrollmentActionTypes = {
    GET_COURSE_ENROLLMENTS: 'GET_COURSE_ENROLLMENTS',
    SET_ACTIVE_ENROLLMENT: 'SET_ACTIVE_ENROLLMENT',
    REPLACE_ENROLLMENT: 'REPLACE_ENROLLMENT'
}

export const careerServicesActionTypes = {
    FETCH_CAREER_SERVICES: 'FETCH_CAREER_SERVICES'
}

export const billingActionTypes = {
    FETCH_BILLING: 'FETCH_BILLING'
}

export const openDocsTypes = {
    REQUEST: 'REQUEST',
}

export const notificationsActionTypes = {
    FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
    NOTIFICATIONS_UPDATE: 'NOTIFICATIONS_UPDATE',
    NOTIFICATIONS_CHANGE_FILTER: 'NOTIFICATIONS_CHANGE_FILTER',
    FETCH_NOTIFICATIONS_DROPDOWN: 'FETCH_NOTIFICATIONS_DROPDOWN'
}
