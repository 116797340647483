import React from 'react';
import Icon from '../../components/Icon';

const ContactCard = (props) => {
    const { contact } = props;
    // assign individual role only if they are not TA/instructor
    let individualRole;
    let displayEmail = false;
    const emailDisplay = (<div><Icon icon="envelope" options="margin-r-2"/> <span>{ contact.contact.email ? contact.contact.email : "N/A"}</span></div> )
    if(contact.role.courseRoleCode !== "instructor" && contact.role.courseRoleCode !== "ta"){
        individualRole =  <h5>{contact.role.name ? contact.role.name : "N/A"}</h5>
        displayEmail = true
    }

    return(
        <div className="card">
            <h3>{contact.contact.firstName && contact.contact.lastName ? contact.contact.firstName + ' ' + contact.contact.lastName : "N/A"}</h3>
            { individualRole }
            <div>
            { displayEmail ? emailDisplay : '' }
            </div>
        </div>
    );
}

export default ContactCard
