import React from 'react'
import {capitalizeString} from '../../helpers/punctuation';
import {isCD, isAdmin, isSSM} from '../../helpers/roles';
import {gradeStatuses} from '../../constants/grade';
import {requiredHomeworkTypes} from '../../constants/assignments';

const GradebookFilterForm = (props) => {
    const {
        activeRole,
        assignmentsAll,        
        studentsAll,          
        selectedAssignment,
        selectedRequired,
        selectedGradeStatus,
        selectedStudent,
        selectedAssignmentType,
        selectedGrade,
        renderAssignmentTypeList,
        updateStateAndParams
      } = props;
      
    return (
        <div className="row">
            <div className="col-xs-12 col-md-4">
                <label htmlFor="grade-status">Status</label>
                <select aria-label="grade-status" id="grade-status" data-selected-filter="selectedGradeStatus" onChange={updateStateAndParams} onBlur={updateStateAndParams} value={selectedGradeStatus}>
                    {Object.keys(gradeStatuses).map((item, index) =>
                        <option key={index} value={item}>{capitalizeString(item)}</option>
                    )
                    }
                </select>
            </div>
            <div className="col-xs-12 col-md-4">
                <label htmlFor="assignment">Assignment</label>
                <select aria-label="assignment" id="assignment" data-selected-filter="selectedAssignment" onChange={updateStateAndParams} onBlur={updateStateAndParams} value={selectedAssignment}>
                    <option value="all">All</option>
                    {assignmentsAll.map((item, index) => {
                        return (
                            <option key={index} value={item.AssignmentID}>{item.Title}</option>
                        )
                    })}
                </select>
            </div>
            <div className="col-xs-12 col-md-4">
                <label htmlFor="required" >Required</label>
                <select htmlFor="required" aria-label="required" data-selected-filter="selectedRequired" onChange={updateStateAndParams} onBlur={updateStateAndParams} value={selectedRequired}>
                    {Object.keys(requiredHomeworkTypes).map((item, index) =>
                        <option key={index} value={item}>{requiredHomeworkTypes[item]}</option>
                    )}
                </select>
            </div>
            <div className="col-xs-12 col-md-4">
                <label htmlFor="student">Student</label>
                <select aria-label="student" id="student" data-selected-filter="selectedStudent" onChange={updateStateAndParams} onBlur={updateStateAndParams} value={selectedStudent}>
                    <option value="all">All</option>
                    {studentsAll.map((item, index) => {
                        return (
                            <option key={index} value={item.UserID}>{item.Name}</option>
                        )
                    })}
                </select>
            </div>
            {(isAdmin(activeRole) || isSSM(activeRole)) &&
                <div className="col-xs-12 col-md-4">
                    <label htmlFor="assignment-type">Assignment Type</label>
                    <select aria-label="assignment-type" id="assignment-type" data-selected-filter="selectedAssignmentType" onChange={updateStateAndParams} onBlur={updateStateAndParams} value={selectedAssignmentType}>
                        <option value="all">All</option>
                        {renderAssignmentTypeList()}
                    </select>
                </div>
            }


            {/* CDs don't see props dropdown because career assignments do not have a grade */}
            {!isCD(activeRole) &&
                <div className="col-xs-12 col-md-4">
                    <label htmlFor="grade">Grade</label>
                    <select aria-label="grade" id="grade" data-selected-filter="selectedGrade" onChange={updateStateAndParams} onBlur={updateStateAndParams} value={selectedGrade}>
                        <option value="all">All</option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                        <option value="F">F</option>
                        <option value="Incomplete">Incomplete</option>
                    </select>
                </div>
            }
        </div>
    )
}

export default GradebookFilterForm
