import {announcementsActionTypes} from '../constants/actionTypes'
import {fulfilled, pending, rejected} from "../helpers/asyncStatusGenerator";

const initialState = {
    announcementList: [],
    unreadAnnouncements: 0,
    announcementsFilter: "all",
    announcementFilteredList: [],
    announcementListLoading: false
};

export const announcementsReducer = (state=initialState, action) => {
    switch (action.type) {
        case pending(announcementsActionTypes.FETCH_ANNOUNCEMENTS):
            return {
                ...state,
                announcementListLoading: true,
            };
        case fulfilled(announcementsActionTypes.FETCH_ANNOUNCEMENTS):
            return {
                ...state,
                announcementListLoading: false,
                announcementList: action.payload.announcementList,
                unreadAnnouncements: action.payload.announcementsUnread
            };
        case rejected(announcementsActionTypes.FETCH_ANNOUNCEMENTS):
            return {
                ...state,
                announcementListLoading: false
            };

        case announcementsActionTypes.ANNOUNCEMENTS_CHANGE_FILTER:
            return {
                ...state,
                announcementsFilter: action.payload.announcementsFilter,
                announcementFilteredList: action.payload.announcementFilteredList
            };

        default:
            return state
    }
}
