import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import BlankSlate from '../../components/BlankSlate';
import NotificationCard from '../../components/NotificationCard';
import { notificationsChangeFilter, fetchNotifications } from "../../actions/notificationsActions"
import Loading from "../../components/Loading/index";

const mapStateToProps = (state) => {
    return {
        userId: state.user.data.userId,
        enrollmentInfo: state.user.enrollmentInfo,
        authToken: state.user.data.authToken,
        notifications: state.notifications
    }
};

class Notifications extends Component {
    componentDidMount() {
        const { enrollmentInfo } = this.props;
        const enrollmentId = enrollmentInfo.id;

        this.loadNotifications(enrollmentId);
    }

    loadNotifications = async (id) => {
        try {
            let payload = { enrollmentId: id };
            this.props.dispatch(fetchNotifications(payload))
        } catch (err) {
            console.warn(err)
        }
    }

    onNotificationsFilterChanged = (e) => {
        let filter = e.currentTarget.value;

        const filteredList = this.props.notifications.notificationList.filter((item) => {
            return "all" === filter || item.type.startsWith(filter)
        });

        this.props.dispatch(notificationsChangeFilter(filter, filteredList));
    }
    
    renderNotificationList = () => {
        const { notifications } = this.props;
        let notificationBaseList = notifications.notificationFilteredList;
        if (notifications.notificationsFilter === "all") {
            notificationBaseList = notifications.notificationList
        }

        if (notificationBaseList.length === 0) {
            return (
                <div className="row car">
                    <div className="col-xs-12">
                        <BlankSlate icon="inbox" header="No New Notifications">
                            <p>Currently you don't have any notifications, but don't worry as soon as you do they will show up here.</p>
                        </BlankSlate>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="row">
                    {notificationBaseList.map((notification) => (
                        <div className="col-xs-12 padding-t-0 padding-b-3" key={notification.id.toString()}>
                            <NotificationCard dispatch={this.props.dispatch} item={notification} />
                        </div>
                    ))}
                </div>
            )
        }

    }
    render() {
        const { notifications } = this.props;
        const unread = notifications.unreadNotifications;
        return (
            <DocumentTitle
                title={`${unread} ${unread === 1 ? 'Notification' : 'Notifications'} - Notifications | Bootcamp Spot`}>
                <section className="page" data-component="notifications">
                    <div className="row">
                        <div className="col-xs-12 col-gutter-lr">
                            <h1>Notifications</h1>
                        </div>
                    </div>
                    <div>
                        {notifications.notificationListLoading ?
                            <div className="row middle-xs" style={{ height: '100vh' }}>
                                <div className="col-xs"><Loading /></div>
                            </div>
                            :
                            notifications.notificationList.length > 0 &&
                            <div className="row">
                                <div className="col-xs-12 col-md-4">
                                    <select aria-label="Notifications Menu" className="" name="" onChange={e => this.onNotificationsFilterChanged(e)} onBlur={e => this.onNotificationsFilterChanged(e)}>
                                        <option value="all">All Notifications</option>
                                        <option value="session">Session</option>
                                        <option value="homework">Homework</option>
                                        <option value="absence">Absence Request</option>
                                    </select>
                                </div>
                            </div>
                        }
                        {this.renderNotificationList()}
                    </div>
                </section>
            </DocumentTitle>
        )
    }
}

export const NOTIFICATIONS = Notifications;
export default connect(mapStateToProps)(Notifications);
