import React, {Component} from 'react';
import {connect} from 'react-redux';
import FormComponent from '../../components/FormComponent/FormComponent';
import { SurveyName } from '../../constants/surveys';
import {lockRouteForUser, releaseRouteForUser} from "../../actions/routeLockActions";
import {lockActionTypeMessages} from "../../constants/messages";

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
};

class PreCourseSurvey extends Component {

    constructor(props) {
        super(props);
        this.props.removeNav();
    }

    componentDidMount () {
        const {dispatch, location} = this.props;
        dispatch(lockRouteForUser(location.pathname,lockActionTypeMessages.PRECOURSE_SURVEY));
    }

    onSuccess =(stepsList) => {
        this.props.dispatch(releaseRouteForUser());
        this.props.history.replace('/precoursesuccess');
    }

    render() {
        let ignoreSteps = [];
        const {location} = this.props;
        if (this.props.user.enrollmentInfo.course.cohort.program.university.id === 222){
            ignoreSteps.push(2);
        }
        let surveyName = SurveyName.PreCourseSurvey;
        if (location.state && location.state.enterprise && location.state.enterprise === true) {
            surveyName = SurveyName.EnterprisePreCourseSurvey;
        }

        return (
            <FormComponent {...this.props}
                           ignoreSteps={ignoreSteps}
                           formName={surveyName}
                           onSuccessCallback={this.onSuccess} />
        )
    }
}

export const PRECOURSESOURVEY = PreCourseSurvey; 
export default connect(mapStateToProps)(PreCourseSurvey);
