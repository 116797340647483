import React from 'react';
import {Link} from 'react-router-dom'

const SupportLink = () => {
    return (
        <Link data-test="support-link" to="/support" aria-label="Course Support">Support</Link>
    )
};

export default SupportLink;
