import React, {Component} from 'react';

const Align = {
    horizontal: 'horizontal',
    vertical:   'vertical'
  }

class MultiOptionStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedList: []
        }

    }

    commitStep = async (e) => {
        const {value} = e.target.attributes.optionnumber
        try {
            await this.setState({
                checkedList: [...this.state.checkedList, parseInt(value, 10)]
            })
            await this.triggerOnChangeWithModifiedState()
        } catch (e) {
            console.log(e)
        }

    }

    unCommitStep = async (e) => {
        const { checkedList } = this.state
        const {value} = e.target.attributes.optionnumber
        if(!checkedList.includes(parseInt(value, 10))){ return } //if our option number isnt in the list, don't do anything.
        // when we uncommit a step we also need to uncommit all questions dependant on it.
        let newState = checkedList.filter((singleValue) => {
            //also filter out anything that is not visible, which we can get from the DOM
            return singleValue !== parseInt(value, 10)
        })
        await this.setState({
            checkedList: newState
        })
        await this.triggerOnChangeWithModifiedState()
    }

    triggerOnChangeWithModifiedState = async () => {
        const { checkedList } = this.state
        const { options } = this.props.step.step.definition
        const modifiedStateClone = await checkedList.map((optionNum) => {
            return options[optionNum].value
        })
        this.props.onChange(this.props.step.step.id, modifiedStateClone);
    }

    isChecked = (optionNum) => {
        const { checkedList } = this.state
        return checkedList.includes(optionNum)
    }

    render() {
        let isHorizontalAlign = (this.props.step.step.definition.ui.align && this.props.step.step.definition.ui.align === Align.horizontal) ? true : false
        const options = this.props.step.step.definition.options;

        let optionList = options.map((option, index) => {
            if (isHorizontalAlign) {
                return (
                    <label key={this.props.step.step.id + '-' + option.value} htmlFor={this.props.step.step.id + '-' + option.value} className="label-active margin-r-3">
                        <input type="radio" name={this.props.step.step.id + '-' + option.value} id={this.props.step.step.id + '-' + option.value}
                             checked={this.isChecked(index)}
                             onChange={this.commitStep}
                             onClick={(index) => this.unCommitStep(index)}
                             value={option.value}
                             optionnumber={index}
                        />
                        {option.display}
                    </label>
                );
            } else {
                return (
                    <li key={this.props.step.step.id + '-' + option.value} className="margin-b-1">
                        <label htmlFor={this.props.step.step.id + '-' + option.value} className="label-active" id={this.props.step.step.id}>
                            <input type="radio" name={this.props.step.step.id + '-' + option.value} id={this.props.step.step.id + '-' + option.value} aria-labelledby={this.props.step.step.id}
                                checked={this.isChecked(index)}
                                onChange={this.commitStep}
                                onClick={(index) => this.unCommitStep(index)}
                                value={option.value}
                                optionnumber={index}
                            />
                            {option.display}
                        </label>
                    </li>
                );
            }
        });

        let content = null;
        if (isHorizontalAlign){
            content = optionList
        } else {
            content = <ul>{optionList}</ul>
        }

        // get rid of div?
        return (
            <li className="margin-b-3">
                <fieldset>
                    <legend className="label" aria-required={this.props.step.step.required ? "true" : "false"}>{this.props.step.step.definition.text}{this.props.step.step.required ? ' *' : null}</legend>
                        {content}
                </fieldset>
            </li>
        )
    }
}

export default MultiOptionStep;