import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Redirect } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ReactGA from 'react-ga';
import 'react-datepicker/dist/react-datepicker.css';
import DocumentTitle from 'react-document-title';

import { createAnnouncement, updateAnnouncement } from "../../actions/announcementsActions";
import Loading from "../../components/Loading/index";
import TimePicker from '../../components/TimePicker';
import FormNavigationPrompt from '../../components/NavigationPrompt'
import {isStudent, isCM } from '../../helpers/roles';

const MAX_DESCRIPTION_LENGTH = 10000;

const mapStateToProps = (state) => {
    return {
        ...state
    }
};

class AnnouncementForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            courseEnrollmentId: '',
            contextCode: 'Type',
            title: '',
            titleError: '',
            body: '',
            descriptionError: '',
            activationStartDate: '',
            activationDate: null,
            activationTime: '',
            activationTime_meridian: '',
            activationDateTime: '',
            experationStartDate: '',
            expirationDate: null,
            expirationTime: '',
            expirationTime_meridian: '',
            expirationDateTime: '',
            announcementId: null,
            missingError: '',
            dateParseError: '',
            formTitle: 'New Announcement',
            formTouched: false
        }
    }

    componentDidMount() {
        this.setState({
            courseEnrollmentId: this.props.user.enrollmentInfo.id
        });

        let selectedAnnouncement = null;
        let newAnnouncementId = this.props.match.params.announcementId;
        if (newAnnouncementId !== 'new') {
            selectedAnnouncement = this.props.announcements.announcementList.filter(function (item) {
                return item.id.toString() === newAnnouncementId
            })
        }

        if (selectedAnnouncement) {
            let formatedActivationDate = moment(selectedAnnouncement[0].activationDate).format();
            let formatedExpirationDate = moment(selectedAnnouncement[0].expirationDate).format();
            this.setState({
                announcementId: selectedAnnouncement[0].id,
                contextCode: selectedAnnouncement[0].contextCode,
                title: selectedAnnouncement[0].title,
                body: selectedAnnouncement[0].body,
                activationDate: formatedActivationDate.substr(0, 10),
                activationTime: formatedActivationDate.substr(11, 5),
                expirationDate: formatedExpirationDate.substr(0, 10),
                expirationTime: formatedExpirationDate.substr(11, 5),
                titleError: '',
                descriptionError: '',
                missingError: '',
                formTitle: 'Edit Announcement'
            });
        }
    }

    handleInputChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        this.setState({
            [name]: value,
            formTouched: true
        });
    }

    createAnnouncement = (e) => {
        e.preventDefault();

        const errors = this.validateForm();

        let dates;
        try {
            dates = this.parseDates();
        } catch (error) {
            this.setState({
                dateParseError: "Invalid date or time"
            });
            return;
        }

        if (!errors) {
            let newAnnouncement = {
                contextCode: this.state.contextCode,
                enrollmentId: this.state.courseEnrollmentId,
                title: this.state.title,
                body: this.state.body,
                activationDate: dates.activation,
                expirationDate: dates.expiration
            }
            this.postNewAnnouncement(newAnnouncement);
        }
    }

    postNewAnnouncement = async (payload) => {
        try {
            ReactGA.event({
                category: 'Announcement',
                action: 'Create',
            });
            await createAnnouncement(payload);
            this.setState({
                formTouched: false
            });

            this.props.history.push('/announcements');
        } catch (err) {
            console.warn(err)
        }
    }

    updateAnnouncement = (e) => {
        e.preventDefault();

        const errors = this.validateForm();

        let dates;
        try {
            dates = this.parseDates();
        } catch (error) {
            this.setState({
                dateParseError: "Invalid date or time"
            });
            return;
        }

        let updatedAnnouncement = {
            id: this.state.announcementId,
            contextCode: this.state.contextCode,
            enrollmentId: this.state.courseEnrollmentId,
            title: this.state.title,
            body: this.state.body,
            activationDate: dates.activation,
            expirationDate: dates.expiration
        }

        if (!errors) {
            this.postUpdatedAnnouncement(updatedAnnouncement);
        }
    }

    postUpdatedAnnouncement = async (payload) => {
        try {
            ReactGA.event({
                category: 'Announcement',
                action: 'Edit',
            });

            await updateAnnouncement(payload);
            this.setState({
                formTouched: false
            });

            this.props.history.push('/announcements');
        } catch (err) {
            console.warn(err)
        }
    }

    parseDates = () => {
        let expirationDateTime = moment(`${this.state.expirationDate} ${this.state.expirationTime}${this.state.expirationTime_meridian}`, "YYYY-MM-DD HH:mmA").format();

        let activationDateTime = moment(`${this.state.activationDate} ${this.state.activationTime}${this.state.activationTime_meridian}`, "YYYY-MM-DD HH:mmA").format();

        return {expiration: expirationDateTime, activation: activationDateTime}
    }

    validateForm = () => {
        let isError = false;
        const errors = {
            titleError: "",
            descriptionError: "",
            missingError: ""
        };

        if (this.state.title.length > 100) {
            isError = true;
            errors.titleError = "Your title is too long, the max length is 100 characters.";
        }

        if (this.state.body.length > MAX_DESCRIPTION_LENGTH) {
            isError = true;
            errors.descriptionError = `Your description is too long, the max length is ${MAX_DESCRIPTION_LENGTH.toLocaleString('en-US')} characters.`;
        }
        if (this.state.title === ""
            || this.state.body === ""
            || this.state.contextCode === "Type"
            || this.state.activationTime === ""
            || this.state.activationDate === ""
            || this.state.expirationDate === ""
            || this.state.expirationTime === "") {
            isError = true;
            errors.missingError = "All fields are required.";
        }

        this.setState({
            ...this.state,
            ...errors
        });

        return isError;
    }

    handleCancel = () => {
        this.props.history.push('/');
    }

    handleActivationDateChange = (date) => {
        this.setState({
            activationStartDate: date,
            activationDate: moment(date).format('YYYY-MM-DD'),
            formTouched: true
        });
    }

    handleExpirationDateChange = (date) => {
        this.setState({
            experationStartDate: date,
            expirationDate: moment(date).format('YYYY-MM-DD'),
            formTouched: true
        });
    }


    render() {
        const activeRole = this.props.user.enrollmentInfo.courseRole.courseRoleCode;
        if(isStudent(activeRole) || isCM(activeRole)){
            return <Redirect to='/unauthorized'/>
        }
        let content = null;
        const { activationTime, expirationTime, activationDate, expirationDate, formTitle, formTouched } = this.state
        // NOTE: meridian values weren't being populated into state, so we're calculating them on the fly from our times using moment
        if (this.props.announcements.announcementListLoading) {
            content = <div className="row middle-xs" style={{height: '100vh'}}>
                <div className="col-xs"><Loading/></div>
            </div>
        } else {
            content =
                <div className="row">
                    <div className="col-xs-12 col-md-8">
                        <FormNavigationPrompt message='Are you sure you want to navigate away from this page while you have an announcement in progress?' valueExists={formTouched} />
                        <h1>{formTitle}</h1>
                        <form>
                            <div className="row">
                                <div className="col-xs-12 col-no-gutter">
                                    <label htmlFor="type">Type</label>
                                    <select aria-label="type" id="type" value={this.state.contextCode} name="contextCode" onChange={this.handleInputChange} onBlur={this.handleInputChange}>
                                        <option>Type</option>
                                        <option value="academic">Academic</option>
                                        <option value="career">Career</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-no-gutter">
                                    <label htmlFor="text-input">Name</label>
                                    <input onChange={(e) => this.handleInputChange(e)} type="text" name="title"
                                           value={this.state.title}
                                           placeholder="Name" maxLength="100"/>
                                    <p style={{margin: 0, padding: 0, textAlign: 'right'}} className="font-xs">{this.state.title.length || 0}/100</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-no-gutter">
                                    <label htmlFor="textarea">Description</label>
                                    <textarea onChange={(e) => this.handleInputChange(e)} name="body" rows="8" cols="80"
                                              placeholder="Description" value={this.state.body} maxLength={MAX_DESCRIPTION_LENGTH}/>
                                    <p style={{margin: 0, padding: 0, textAlign: 'right'}} className="font-xs description">{(this.state.body.length || 0).toLocaleString('en-US')}/{MAX_DESCRIPTION_LENGTH.toLocaleString('en-US')}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-6 col-no-gutter" style={{paddingRight: '0.5rem'}}>
                                    <label htmlFor="text-input">Publish date</label>
                                    <DatePicker selected={activationDate ? new Date(moment(activationDate)) : null}
                                                onChange={this.handleActivationDateChange}
                                                dateFormat="yyyy-MM-dd"
                                                minDate={moment().toDate()}
                                    />
                                </div>
                                <div className="col-xs-6 col-no-gutter" style={{paddingLeft: '0.5rem'}}>
                                    <label htmlFor="text-input">Publish time</label>
                                    <TimePicker
                                        name="activationTime"
                                        onChange={(e) => this.handleInputChange(e)}
                                        timeValue={moment(activationTime, 'hh mm').format('h:mm')}
                                        meridianValue={moment(activationTime, 'hh mm a').format('A')}
                                        //required={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-6 col-no-gutter" style={{paddingRight: '0.5rem'}}>
                                    <label htmlFor="text-input">Expiration date</label>
                                    <DatePicker selected={expirationDate ? new Date(moment(expirationDate)) : null}
                                                onChange={this.handleExpirationDateChange}
                                                dateFormat="yyyy-MM-dd"
                                                minDate={moment().toDate()}
                                    />
                                </div>
                                <div className="col-xs-6 col-no-gutter" style={{paddingLeft: '0.5rem'}}>
                                    <label htmlFor="text-input">Expiration time</label>
                                    <TimePicker
                                        name="expirationTime"
                                        onChange={(e) => this.handleInputChange(e)}
                                        timeValue={moment(expirationTime, 'hh mm').format('h:mm')}
                                        meridianValue={moment(expirationTime, 'hh mm a').format('A')}
                                        //required={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-no-gutter">
                                    {this.state.titleError && <p className="context-error">{this.state.titleError}</p>}
                                    {this.state.descriptionError &&
                                    <p className="context-error">{this.state.descriptionError}</p>}
                                    {this.state.missingError &&
                                    <p className="context-error">{this.state.missingError}</p>}
                                    {this.state.dateParseError &&
                                    <p className="context-error">{this.state.dateParseError}</p>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-no-gutter">
                                    {!this.state.announcementId &&
                                    <button onClick={(e) => this.createAnnouncement(e)}
                                            className="btn-create btn-small"
                                            type="submit"
                                            value="Submit">Post
                                    </button>}

                                    {this.state.announcementId &&
                                    <button onClick={(e) => this.updateAnnouncement(e)}
                                            className="btn-save btn-small"
                                            type="submit"
                                            value="Submit">Save
                                    </button>}

                                    <button onClick={(e) => this.handleCancel(e)}
                                            className="btn-small btn-delete btn-tertiary">Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
        }

        return (
            <DocumentTitle title="Create Announcement | Bootcamp Spot">
                <section className="page" data-content="announcementForm">
                    {content}
                </section>
            </DocumentTitle>
        )
    }
}

export const ANNOUNCEMENTFORM = AnnouncementForm;

export default connect(mapStateToProps)(AnnouncementForm);
