import React, {Component} from 'react';
import {connect} from 'react-redux';
import DocumentTitle from 'react-document-title';
import queryString from 'query-string';
import Loading from '../../components/Loading';
import {capitalizeString} from '../../helpers/punctuation';
import {fetchGradebookSubmissions} from '../../actions/gradeActions';
import {isStudent} from '../../helpers/roles';
import {assignmentContextTypes} from '../../constants/dataTypes';
import {searchParams, filterAssignmentList, filterSubmissionsByUserType, filterAssignmentAndStudentDropDownList} from '../../helpers/gradebook';
import Assignments from '../../components/Assignments';
import GradebookFilterForm from '../../components/GradebookFilterForm';

const mapStateToProps = (state, ownProps) => {
    return {
        activeRole: state.user.enrollmentInfo.courseRole.courseRoleCode,
        courseId: state.user.enrollmentInfo.courseId,
        authToken: state.user.data.authToken,
        ...ownProps
    }
}

export class Gradebook extends Component {
    constructor(props) {
      super(props)
      this.state = {
        assignLoading: true,
        assignmentsAll: [],         /* all assignments for this course ID */
        studentsAll: [],            /* all students for this course ID */
        submissionsAll: [],         /* all submissions for this course ID */
        submissionsFiltered: [],    /* submissions w/chosen filters applied */
        selectedAssignment: 'all',
        selectedRequired: 'All',
        selectedGradeStatus: 'all',
        selectedStudent: 'all',
        selectedAssignmentType: 'all',
        selectedGrade: 'all'
      }
    }

    async componentDidMount() {
        const {activeRole, courseId} = this.props;
        if (isStudent(activeRole)) {
            this.props.history.push('/unauthorized');
        } else {
            const result = await fetchGradebookSubmissions(courseId);
            const submissions = result.data;
            const data = filterSubmissionsByUserType(submissions, activeRole)
            const {assignmentFilterList, studentFilter}  = filterAssignmentAndStudentDropDownList(data)

            this.setState({
                assignLoading: false,
                submissionsFiltered: data,
                submissionsAll: data,
                assignmentsAll: assignmentFilterList,
                studentsAll: studentFilter
            })

            if (this.props.location.search !== '') {
              this.handleFilter();
            }
        }

        const search = this.props.location.search;

        if (search) {
          const searchParamsObj = searchParams(search);
          this.setState(() => (searchParamsObj));
        }
    }

    componentDidUpdate(prevProps) {
      if (this.props.location.search !== prevProps.location.search) {
        const previous = queryString.parse(prevProps.location.search)
        const current = queryString.parse(this.props.location.search)
        const difference = Object.keys(current).reduce((diff, key) => {
          if (previous[key] === current[key]) return diff
          return {
            ...diff,
            [key]: current[key]
          }
        }, {})
        let key = Object.keys(difference)[0];
        const value = difference[key];
        key = key.charAt(0).toUpperCase() + key.slice(1)

        this.setState({[`selected${key}`]: value}, () => {
            this.handleFilter()
        })
      }
    }

    handleFilter() {
        const filteredAssignments = filterAssignmentList(this.props, this.state);
        this.setState({
            submissionsFiltered: filteredAssignments
        })
    }

    updateStateAndParams = (e) => {
      let selectedFilter = e.target.getAttribute('data-selected-filter');
      let selectedChoice = e.target.value;
      this.setState({ [`${selectedFilter}`]: selectedChoice }, () => {
        this.pushParams();
      });
    }

    renderAssignmentTypeList = () => {
      return Object.values(assignmentContextTypes).map((item, index) => {
        return (
          <option key={index} value={item}>{capitalizeString(item)}</option>
        )
      })
    }

    pushParams = () => {
      const {
        selectedAssignment,
        selectedRequired,
        selectedGradeStatus,
        selectedStudent,
        selectedAssignmentType,
        selectedGrade,
      } = this.state;

      const routeParams = {
        assignment: selectedAssignment,
        required: selectedRequired,
        gradeStatus: selectedGradeStatus,
        student: selectedStudent,
        assignmentType: selectedAssignmentType,
        grade: selectedGrade
      }

      const stringifiedParams = queryString.stringify(routeParams);
      this.props.history.push(`?${stringifiedParams}`);
    }

    render() {
      const {
        assignLoading,
        submissionsFiltered,
      } = this.state;
      const {activeRole} = this.props;

        return (
            <DocumentTitle title="Gradebook | Bootcamp Spot">
                <section className="page" data-component="gradebook">
                    <div className="row">
                        <div className="col-xs-12">
                            <h1>Grade Assignments</h1>
                        </div>
                    </div>
                    {assignLoading ? null : (
                        <GradebookFilterForm
                          activeRole={activeRole}
                          renderAssignmentTypeList={this.renderAssignmentTypeList}
                          updateStateAndParams={this.updateStateAndParams}
                          {...this.state}
                         />
                    )}
                    {assignLoading ? (
                        <Loading/>
                    ) : (
                        <Assignments submissionsFiltered={submissionsFiltered}/>
                    )}
                </section>
            </DocumentTitle>
        )
    }
}

export const GRADEBOOK = Gradebook;
export default connect(mapStateToProps)(Gradebook);
