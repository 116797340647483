import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import FormComponent from '../../components/FormComponent/FormComponent';
import { SurveyName } from '../../constants/surveys';
import {lockRouteForUser, releaseRouteForUser} from "../../actions/routeLockActions";
import {lockActionTypeMessages} from "../../constants/messages";

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
};

class Feedback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formIsVisible: false
        }
        this.props.removeNav();
    }

    componentDidMount () {
        const {dispatch, location} = this.props;
        dispatch(lockRouteForUser(location.pathname,lockActionTypeMessages.WEEKLY_SURVEY));
    }

    changeFormVisibility = () => {
        this.setState({
            formIsVisible: true
        })
    }

    onSuccess =(stepsList) => {
        this.props.dispatch(releaseRouteForUser());
        this.props.history.replace('/weeklysuccess');
    }

    render() {
        const weekNum = parseInt(this.props.match.params.week, 10);
        let extra = {"week": weekNum};
        var content;
        if (this.state.formIsVisible === false) {
            content = <section className="page" data-content="NPSForm">
            <div className="row card-group">
                <div className="col-xs-12">
                    <div className="card">
                    <div>
                        <p>Congratulations on completing another week!</p>
                        <p>Please take a moment to reflect back over last week and answer the following questions.
                            In order to gain a better understanding of how you are doing in class the instructor and
                            TAs will be able to view your individual responses for the questions 1, 2, 4, and 5.
                        </p>
                        <p>Your student success manager will be the only person on your academic team able to view your individual
                            responses and comments to the full survey. Thank you for helping us improve our program by completing our
                            weekly survey.
                        </p>
                    </div>
                    <button className="btn-secondary btn-small"
                            onClick={() => this.changeFormVisibility()}>Start the Survey
                    </button>
                    </div>
                </div>
            </div>
            </section>
        } else {
            content = <FormComponent {...this.props}
                        title="Weekly Report"
                        submitLabel="SUBMIT FEEDBACK"
                        formName={SurveyName.WeeklySurvey}
                        extra={extra}
                        onSuccessCallback={this.onSuccess} />
        }

        return (content)
    }
}

export const FEEDBACK = Feedback ;

export default withRouter(connect(mapStateToProps)(Feedback))