import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import DocumentTitle from 'react-document-title';
import {get, isEmpty} from 'lodash';
import Loading from "../../components/Loading";
import UpcomingSessionGridCard from '../../components/Sessions/SessionGrid/UpcomingSessionGridCard';
import NpsButtonContainer from '../../components/NpsButtonContainer';
import CalendarContainer from '../../components/Calendar/CalendarContainer';
import Icon from '../../components/Icon'
import ExternalLink from '../../components/ExternalLink';
import { SurveyName } from '../../constants/surveys';
import {gradeColorsMap} from "../../constants/grade";
import {fetchSurveyToDisplay, fetchDashboard} from "../../actions/dashboardActions"
import {isStudent, isRole} from "../../helpers/roles";
import {getDayString, getMonthString, getDayNumber, getFormattedTime, getDateDiffFromNow} from '../../helpers/dateTime'
import {roleTypes} from "../../constants/dataTypes";
import VirtualClassroomCard from "../../components/VirtualClassroomCard";
import AskBCS from "../../components/AskBCS";
import ContinuationContentCard from "../../components/ContinuationContentCard";
import {displayGrade} from "../../helpers/grade";

const mapStateToProps = (state) => {
    return {
        userId: state.user.data.userId,
        enrollmentInfo: (state.user.enrollmentInfo ? state.user.enrollmentInfo : {}),
        authToken: state.user.data.authToken,
        dashboard: state.dashboard
    }
};

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.props.includeNav();
    }

    // NOTE:
    // fbab49976154906e0793a3e9c60d78c12f5a716a changed loading logic to use legacy and unsafe componentWillReceiveProps
    //
    // I'm observing odd, potentially async state conditions that cause the dashboard to sometimes never load
    // This implicates the enrollmentInfo gating AND/OR the componentWillReceiveProps lifecycle method.
    //
    // https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
    // React has noted:
    // Using this lifecycle method often leads to bugs and inconsistencies
    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.enrollmentInfo !== this.state.enrollmentInfo) {
    //         let enrollmentInfo = nextProps.enrollmentInfo;
    //         this.setState({
    //             enrollmentInfo: enrollmentInfo
    //         }, () => {
    //             this.loadDashboardContent();
    //             this.loadSurveyToDisplay();
    //         });
    //     }
    // }

    componentDidMount() {
        if (this.props.enrollmentInfo.id) {
            this.loadDashboardContent();
            this.loadSurveyToDisplay();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.enrollmentInfo.id && prevProps.enrollmentInfo.id !== this.props.enrollmentInfo.id) {
            this.loadDashboardContent();
            this.loadSurveyToDisplay();
        }
    }

    loadDashboardContent = () => {
        const {enrollmentInfo, dispatch} = this.props;
        const enrollmentId = enrollmentInfo.id;
        dispatch(fetchDashboard(enrollmentId));
    }

    loadSurveyToDisplay = async() => {
        const {enrollmentInfo, history} = this.props;
        const enrollmentId = enrollmentInfo.id;
        const authToken = this.props.authToken;
        try {
            let survey = await fetchSurveyToDisplay(enrollmentId, authToken);
            if (survey && survey.surveyName){
                switch (survey.surveyName){
                    case SurveyName.PreCourseSurvey:
                        history.push('/precourse');
                        break;
                    case SurveyName.MidCourseSurvey:
                        history.push('/nps/mc/pt');
                        break;
                    case SurveyName.EndCourseSurvey:
                        history.push('/nps/ec/pt');
                        break;
                    case SurveyName.WeeklySurvey:
                        history.push('/feedback/' + survey.week);
                        break;
                    case SurveyName.Agreement:
                        history.push('/agreement/');
                        break;
                    case SurveyName.CsPreferences:
                        history.push('/careerSurvey/');
                        break;
                    case SurveyName.DataCsCurriculumSurvey:
                    case SurveyName.FtFsfCsCurriculumSurvey:
                    case SurveyName.PtFsfCsCurriculumSurvey:
                    case SurveyName.UiUxCsCurriculumSurvey:
                        history.push({pathname: '/cscurriculum',state: {surveyName: survey.surveyName}})
                        break;
                    case SurveyName.EnterprisePreCourseSurvey:
                        history.push({pathname:'/precourse', state:{enterprise: true}});
                        break;
                    case SurveyName.EnterpriseMidCourseSurvey:
                        history.push({pathname:'/nps/mc/pt', state:{enterprise: true}});
                        break;
                    case SurveyName.EnterpriseEndCourseSurvey:
                        history.push({pathname:'/nps/ec/pt', state:{enterprise: true}});
                        break;
                    default:
                        console.warn("Unknown survey");
                }
            }
        } catch (err) {
            console.warn(err);
        }
    }

    renderAllWorkshopsLink = () => (
        <ExternalLink to="https://careerservicesonlineevents.splashthat.com" text="View All Upcoming Career Services Workshops"/>
    )

    renderCareerWorkshop = (showCareerServicesEvents) => {
        const { upcomingCareerSession } = this.props.dashboard
        return(
            <div className="card-with-title card-wide">
                <div className="card-title">
                    <h2>Upcoming Workshop</h2>
                    <h4>{getDayString(upcomingCareerSession.startTime)}</h4>
                    <p className="card-title-month">{getMonthString(upcomingCareerSession.startTime)} {getDayNumber(upcomingCareerSession.startTime, true)}</p>
                    <p className="card-title-time">{getFormattedTime(upcomingCareerSession.startTime)} - {getFormattedTime(upcomingCareerSession.endTime)} Local</p>
                </div>
                <div className="card-content">
                    <h3>{upcomingCareerSession.name}</h3>
                    <p>{upcomingCareerSession.shortDescription}</p>
                    <p>
                        <Link to={`/sessions/${upcomingCareerSession.id}`}>
                            <Icon icon="briefcase"/>{' '}
                            Workshop Details
                        </Link>
                    </p>
                </div>
                { showCareerServicesEvents ? (
                    <div className="card-footer">
                        <p>{this.renderAllWorkshopsLink()}</p>
                    </div>
                ) : null }
            </div>
        )
    }

    render() {
        const {enrollmentInfo, dashboard} = this.props;

        const {overdueAssignmentCount, remainingAbsenceCount, academicAverageGrade, academicAverageValue} = dashboard.dashboardStudentStats ? dashboard.dashboardStudentStats : {};
        const studentStatsCardSize = dashboard.upcomingSession ? "col-md-6" : "col-md-4";

        const activeRole = get(enrollmentInfo, 'courseRole.courseRoleCode', false)
        const hideCareerServices = get(enrollmentInfo, 'course.cohort.cohortUiControl.hideCareerServices', false)
        const courseRoleName = get(enrollmentInfo, 'courseRole.name', '')
        const showCareerServicesEvents = get(enrollmentInfo, 'showCareerServicesEvents', false)
        const showVirtualClassroomCard = isRole([
            roleTypes.INSTRUCTOR,
            roleTypes.TA,
            roleTypes.STUDENT,
            roleTypes.SSM
        ], activeRole) && !isEmpty(dashboard.virtualClassroom) && !isEmpty(dashboard.upcomingSession) ? true : false;
        const continuedContentAvailabilityDate = get(dashboard, 'continuationContent.availabilityDate', null)
        const showContinuationContent = getDateDiffFromNow(continuedContentAvailabilityDate) >= 0 && dashboard.continuationContent && dashboard.continuationContent.link;

        const cardStyle = {
            height: '100%'
        }

        return (
            <DocumentTitle
                title={`Dashboard | Bootcamp Spot`}>
                <section className="page" data-component="dashboard">
                    <div className="row">
                        <div className="col-xs-12 col-gutter-lr">
                            <h1>{courseRoleName} Dashboard</h1>
                        </div>
                        { !activeRole || dashboard.dashboardLoading ? (
                            <Loading>
                                <p>Please stand by..</p>
                                <p>If this page does not load, please contact Support. Thank you!</p>
                            </Loading>
                        ) : (
                            <React.Fragment>
                                <div className="row eq-height">
                                    {showVirtualClassroomCard &&
                                    <div className="col-md-6 col-xs-12 virtualclassroom">
                                        <VirtualClassroomCard activeRole={activeRole} virtualClassroom={dashboard.virtualClassroom} virtualClassroomCredentials={dashboard.virtualClassroomCredentials} upcomingSession={dashboard.upcomingSession}/>
                                    </div>
                                    }
                                    <AskBCS
                                        onDeepLinkAvailable={({ deepLink, askBcsLogo, dataBcsProp }) => (
                                            <div className={`col-xs-12 ${ showVirtualClassroomCard ? 'col-md-6' : 'col-md-12' }`}
                                                {...dataBcsProp}>
                                                <div className="card text-center row">
                                                    <div className={`padding-t-0 col-xs-12 ${ showVirtualClassroomCard ? 'col-md-12' : 'col-md-6 text-left-md' }`}>
                                                        <h3>Question about coursework?</h3>
                                                        Ask a Learning Assistant in Slack using AskBCS
                                                    </div>
                                                    <a href={deepLink}
                                                       className={`padding-t-0 col-xs-12 ${ showVirtualClassroomCard ? 'col-md-12' : 'col-md-6' }`}
                                                       target="_blank"
                                                       rel="noopener noreferrer"
                                                       aria-label="AskBCS Learning Assistant">
                                                        <img className="ask-bcs ask-bcs-btn" src={askBcsLogo} alt="AskBCS Learning Assistant"/>
                                                    </a>
                                                </div>
                                            </div>
                                        )}/>
                                    {(isRole([roleTypes.STUDENT], activeRole) && showContinuationContent)  &&
                                    <div className="col-xs-12 continuationcontent">
                                        <ContinuationContentCard continuationContent={dashboard.continuationContent}/>
                                    </div>
                                    }
                                </div>
                                {dashboard.upcomingSession ? (
                                    <div className="col-xs-12 col-md-4">
                                        <UpcomingSessionGridCard
                                            sessionData={dashboard.upcomingSession}
                                            refresh={this.loadDashboardContent}
                                            cardStyle={cardStyle}
                                        />
                                    </div>
                                ) : null}
                                {!isStudent(activeRole) ? (
                                    <React.Fragment>
                                        <div className="col-xs-12 col-md-4">
                                            <NpsButtonContainer/>
                                        </div>
                                        <div className="col-xs-12 col-md-4">
                                            <div className="card text-center">
                                                <h2>Announcements</h2>
                                                <p>{dashboard.dashboardAnnouncements.totalCount} total</p>
                                                <p className="text-bold">{dashboard.dashboardAnnouncements.unreadCount} Unread</p>
                                                <p><Link to="/announcements">View Announcements</Link></p>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ) :  (
                                    <React.Fragment>
                                        <div className={`col-xs-12 ${dashboard.upcomingSession ? "col-md-8" : "col-md-12"} col-no-gutter`}>
                                            <div className="row eq-height">
                                                <div className={`col-xs-12 ${studentStatsCardSize}`}>
                                                    <div className="card text-center">
                                                        <p>Overdue Assignments</p>
                                                        <p className="headline-two">{overdueAssignmentCount ? overdueAssignmentCount : 0}</p>
                                                        <p><Link to="/coursework">Review Coursework</Link></p>
                                                    </div>
                                                </div>
                                                <div className={`col-xs-12 ${studentStatsCardSize}`}>
                                                    <div className="card text-center">
                                                        <p>Remaining Absences</p>
                                                        <p className="headline-two">{remainingAbsenceCount ? remainingAbsenceCount : 0}</p>
                                                        <p><Link to="/attendance">Manage Attendance</Link></p>
                                                    </div>
                                                </div>
                                                <div className={`col-xs-12 ${studentStatsCardSize}`}>
                                                    <div className="card text-center">
                                                        <p>Academic Average</p>
                                                        <p className={`headline-two ${academicAverageGrade ? gradeColorsMap.get(academicAverageGrade) : ''}`}>
                                                            {academicAverageGrade ? displayGrade(enrollmentInfo, academicAverageGrade, academicAverageValue) : 'Unavailable'}
                                                        </p>
                                                        <p><Link to="/grades">Review Grades</Link></p>
                                                    </div>
                                                </div>
                                                <div className={`col-xs-12 ${studentStatsCardSize}`}>
                                                    <div className="card text-center" style={cardStyle}>
                                                        <p>Announcements</p>
                                                        {/* <p>{dashboard.dashboardAnnouncements.totalCount} total</p> */}
                                                        <p className="text-bold">{dashboard.dashboardAnnouncements.unreadCount} Unread</p>
                                                        <p><Link to="/announcements">View Announcements</Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {!hideCareerServices ? (
                                            <React.Fragment>
                                                <div className="col-xs-12 col-md-8">
                                                    {dashboard.upcomingCareerSession ? this.renderCareerWorkshop(showCareerServicesEvents) : (
                                                        <div className="card text-center" style={cardStyle}>
                                                            <p>There are no current career services workshops</p>
                                                            <p><Icon icon="briefcase" alt="" options="font-xxl"/></p>
                                                            { showCareerServicesEvents ? (
                                                                <p>{this.renderAllWorkshopsLink()}</p>
                                                            ) : null }
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-xs-12 col-md-4">
                                                    <div className="card text-center">
                                                        <p>Become Employer Competitive</p>
                                                        <p><Icon icon="briefcase" alt="" options="font-xxl"/></p>
                                                        <p><HashLink to={'/career-services/#allCareerMilestones'}>View all Milestones</HashLink></p>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ) : null}
                                    </React.Fragment>
                                )}
                                <div className="col-xs-12" id="coursecalendar">
                                    <CalendarContainer
                                        history={this.props.history}
                                        location={this.props.location}
                                    />
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </section>
            </DocumentTitle>
        )
    }
}

export const DASHBOARD = Dashboard;

export default connect(mapStateToProps)(Dashboard);
